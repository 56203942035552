import React, { useState, useEffect } from "react";
import Navbar from "../Navbar";
import Footer from "../Footer";
import Webinar from "../dashboard/Webinar";
import { useSelector } from "react-redux";
import TitleBar from "../TitleBar";

import paypal_logo from "../../images/paypal.png";
import bitpay_logo from "../../images/bitpay.png";
import coingate_logo from "../../images/coingate.png";
import zapier_logo from "../../images/zapier.png";
import PaymentModel from "./PaymentModel";
import axios from "axios";
import { baseURL } from "../../global/global";
import ConFirmo_logo from "../../images/ConFirmo.png";
import GoUrl_logo from "../../images/GoUrl.png";
import SpicePay_logo from "../../images/SpicePay.png";
import CoinRemitter_logo from "../../images/CoinRemitter.png";
import NowPayments_logo from "../../images/NowPayments.png";
import Crypto_logo from "../../images/Crypto.png";
import Strike_logo from "../../images/Strike.png";
import AutoResponder from "./AutoResponder";
import IntegrationConnected from "./IntegrationConnected";

const Integration = () => {
    const auth = useSelector(state => state.auth)
    const storeData = useSelector(state => state.base.storeData);
    const intergation = useSelector(state => state.payment)
    const [connection, setConnection] = useState({
        type: "",
        endPoint: ''
    })
    const [membership, setMembership] = useState([]);
    const [paypalActive, setPaypalActive] = useState(false)

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [zapier, setZapier] = useState([])

    const checkPaypal = (data) => {
        if (data.length > 0) {
            const isInPaypal = data.findIndex(({ code }) => code === storeData.baseCurrency)
            if (isInPaypal !== -1) {
                setPaypalActive(true)
            }
        }
    }
    const handleModel = (type, endPoint) => {
        setConnection({
            ...connection,
            type: type,
            endPoint: endPoint
        })
        handleShow()
    }

    useEffect(() => {
        if (auth.user) {
            if (auth.user.membership !== '' && auth.user.membership !== null) {
                setMembership(auth.user.membership.split('__'))
            }
        }
    }, [auth])


    useEffect(() => {
        axios({
            method: 'POST',
            url: `${baseURL}payment-currency`,
            data: {},
            headers: { 'Content-Type': 'application/json', 'Authorization': auth.token }
        }).then((res) => {
            if (res.data.status === true) {
                let val = res.data.data
                checkPaypal(val.paypalCurrency)
            }
        }).catch((error) => {
            console.log(error)
        })
    }, [])


    return (
        <>
            <TitleBar title="Integration" />
            <Navbar />

            <div className="siteWrap">

                <section className="connection">
                    <div className="container">
                        <h2 className="titleBdr mb-5">My Payment Integration</h2>

                        <IntegrationConnected
                            data={intergation.bitpay ? intergation.bitpay : []}
                            logo={bitpay_logo}
                            name="BitPay"
                            endPoint="bitpay-auth"
                            handleModel={handleModel}
                            signUpUrl={"https://bitpay.com/signup/"}
                        />
                        <IntegrationConnected
                            data={intergation.coingate ? intergation.coingate : []}
                            logo={coingate_logo}
                            name="CoinGate"
                            endPoint="coingate-auth"
                            handleModel={handleModel}
                            signUpUrl={"https://dashboard.coingate.com/register"}

                        />
                        {paypalActive ?
                            <IntegrationConnected
                                data={intergation.paypal ? intergation.paypal : []}
                                logo={paypal_logo}
                                name="Paypal"
                                endPoint="paypal-auth"
                                handleModel={handleModel}
                                signUpUrl={"https://www.paypal.com/in/welcome/signup/#/mobile_conf"}

                            /> : ''}
                        <IntegrationConnected
                            data={intergation.crypto ? intergation.crypto : []}
                            logo={Crypto_logo}
                            name="Crypto.com"
                            endPoint="crypto-auth"
                            handleModel={handleModel}
                            signUpUrl={"https://merchant.crypto.com/users/sign_up"}
                        />
                        {membership[membership.length - 1] === "commercial" ?
                            ""
                            :
                            <>
                                <IntegrationConnected
                                    data={intergation.confirmo ? intergation.confirmo : []}
                                    logo={ConFirmo_logo}
                                    name="ConFirmo"
                                    endPoint="confirmo-auth"
                                    handleModel={handleModel}
                                    signUpUrl={"https://confirmo.net/home"}

                                />

                                <IntegrationConnected
                                    data={intergation.coinremitter ? intergation.coinremitter : []}
                                    logo={CoinRemitter_logo}
                                    name="CoinRemitter"
                                    endPoint="coinremitter-auth"
                                    handleModel={handleModel}
                                    signUpUrl={"https://coinremitter.com/signup"}

                                />

                                <IntegrationConnected
                                    data={intergation.nowPayments ? intergation.nowPayments : []}
                                    logo={NowPayments_logo}
                                    name="NowPayments"
                                    endPoint="nowpayments-auth"
                                    handleModel={handleModel}
                                    signUpUrl={"https://account.nowpayments.io/create-account"}

                                />

                            </>}

                        {/* <IntegrationConnected
                                data={intergation.alfaCoins ? intergation.alfaCoins : []}
                                logo={AlfaCoins_logo}
                                name="AlfaCoins"
                                endPoint=""
                                handleModel={handleModel}
                            />

                            <IntegrationConnected
                                data={intergation.goUrl ? intergation.goUrl : []}
                                logo={GoUrl_logo}
                                name="GoUrl"
                                endPoint=""
                                handleModel={handleModel}
                            />

                            <IntegrationConnected
                                data={intergation.spicePay ? intergation.spicePay : []}
                                logo={SpicePay_logo}
                                name="SpicePay"
                                endPoint=""
                                handleModel={handleModel}
                            />

                            <IntegrationConnected
                                data={intergation.strike ? intergation.strike : []}
                                logo={Strike_logo}
                                name="Strike"
                                endPoint=""
                                handleModel={handleModel}
                            /> */}




                        <AutoResponder />


                        <h2 className="titleBdr mt-5 mb-5 pt-3">My Zapier Integration</h2>
                        <div className="connection-box mt-3">
                            <div className="row">
                                <div className="col-md-1 d-flex justify-content-center">
                                    <div className={`connection-img ${zapier.length > 0 ? '' : 'notConnected'}`}><img alt="bitpay" src={zapier_logo} /></div>
                                </div>
                                <div className="col-md-11">
                                    <div className="youbtube-connection text-white d-flex align-items-center justify-content-between notConnected">
                                        <div className="youtube-content">
                                            <h6><strong>Zapier (Coming Soon)</strong></h6>
                                            <p>Not Connected</p>
                                        </div>
                                        <div className="facebook-connect-account">
                                            <button onClick={""} className="btn-change7">Connect</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <Webinar />
            </div>

            <PaymentModel
                show={show}
                handleClose={handleClose}
                type={connection.type}
                endpoint={connection.endPoint}
            />
            <Footer />
        </>
    )
}

export default Integration;