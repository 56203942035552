import axios from "axios";
import { baseURL } from "../global/global";
import { setAlert } from "./alert";

export const getIntegration = (token) => (dispatch) => {
    axios({
        method: 'POST',
        url: `${baseURL}payment-list`,
        data: {},
        headers: { 'Content-Type': 'application/json', 'Authorization': token }
    }).then((res) => {
        if (res.data.status === true) {
            let data = res.data.data
            dispatch({ type: "ADD_PAYMENT_ACCOUNTS", payload: data })
        }
    }).catch((error) => {
        console.log(error)
    })
}


export const checkResponse = (string1, type, token, interval, myWindow, setCheckStatus) => (dispatch) => {
    axios({
        method: 'POST',
        url: `${baseURL}check-network-status`,
        data: {
            key: string1,
            tbl: type
        },
        headers: { 'Content-Type': 'application/json', 'Authorization': token }
    }).then((res) => {
        if (res.data.status === true) {
            dispatch(fetchAutoResponder(token))
            myWindow.close()
            setCheckStatus(false)
            dispatch(setAlert(res.data.message, "success"));
            clearInterval(interval);
        }
        else {
            setCheckStatus(false)
        }
    }).catch((error) => {
        window.close();
        console.log(error)
    })
}

export const fetchAutoResponder = (token) => (dispatch) => {
    axios({
        method: 'POST',
        url: `${baseURL}autoresponder-list`,
        data: {},
        headers: { 'Content-Type': 'application/json', 'Authorization': token }
    }).then((res) => {
        if (res.data.status === true) {
            dispatch({type : "ADD_AUTORESPONSE", payload : res.data.data})
        }
    }).catch((error) => {
        console.log(error)
    })
}

