import styled from "styled-components";
export const Termstyle = styled.div`

.terms {
    font-size: 16px;
    color: #333;
    font-family: 'Lora', serif;
    line-height: 1.6;
}

a {
    color: #2262e0;
    text-decoration: none;
}

.mt-3, .my-3 {
    margin-top: 1rem;
}

.mb-3, .my-3 {
    margin-bottom: 1rem;
}

.text-wrapper p:not(:last-child) {
    margin-bottom: 25px;
}

.header h1 {
    font-size: 35px;
    line-height: 1.4;
}

.text-center {
    text-align: center;
}

.group h2 {
    font-weight: 700;
}

.h {
    background: #c9daf8;
    display: inline;
}

.dul {
    color: #2262e0;
    text-decoration:underline;
    border-bottom: 1px solid #2262e0;
}

.list li:not(:last-child) {
    margin-bottom: 15px;
}

ol.list {
    margin-top: 15px;
}

.container {
    padding: 0 0 10px;
    border-bottom: 2px solid #d5d5d5;
}



`;