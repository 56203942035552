import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
// import {addScrapText} from "../../actions/videoAction";
import { Modal } from 'react-bootstrap';
import iconUplod from '../../images/icon-upload.svg';
import iconWrite from '../../images/icon-write.svg';
import iconMic from '../../images/icon-mic.svg';
import iconRight from '../../images/icon-arrow.svg';
import modalCloseIcon from '../../images/modal-close.png';
// import terms from './terms.html'
import { GrFormClose } from 'react-icons/gr'
import logoLarge from '../../images/logo-large.svg';
import BaseInfo from "./BaseInfo";
import Terms from "./Terms";
import stop from '../../images/terms-icons.png'
import { baseURL } from "../../global/global";
import { setAlert } from "../../actions/alert";
import { checkTerms, updateTerms } from "../../actions/authAction";

const DashboardHead = () => {


    const dispatch = useDispatch()
    const auth = useSelector(state => state.auth);
    const rebrandData = useSelector(state => state.rebrand.data);

    const [rebrand, setRebrand] = useState(rebrandData);
    const [acceptTerm, setAcceptTerm] = useState(false)
    const [loader, setLoader] = useState({
        acceptLoader: false,
        baseModal: false
    })
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [show2, setShow2] = useState(false);
    const handleClose2 = () => setShow2(false);
    const handleShow2 = () => setShow2(true)

    useEffect(() => {
        dispatch(checkTerms(handleClose2, handleShow2, setLoader, loader))
    }, [])

    useEffect(() => {
        setRebrand(rebrandData);
    }, [rebrandData])

    const handleSubmit = (e) => {
        e.preventDefault()
        setLoader({
            ...loader,
            acceptLoader: true
        })
        dispatch(updateTerms(acceptTerm, setLoader, loader, handleClose2))
    }
    return (
        <>

            <section className="hero-banner bashboard-banner" style={{ backgroundImage: "url(" + + ")" }}>
                <div id="bg-video">
                    <iframe src="https://player.vimeo.com/video/728808597?background=1&amp;autoplay=1&amp;loop=1&amp;byline=0&amp;title=0" width="640" height="360" frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen></iframe>
                </div>
                <div className="hero-banner-txt">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-7">
                                <div className="hero-banner-left">
                                    <h2>WELCOME TO</h2>
                                    <div className={`logoLarge ${rebrand ? 'm-0' : ''}`}>
                                        {rebrand ?
                                            <span className="app-name-css">
                                                {rebrand.name}
                                            </span>
                                            :
                                            <img src={logoLarge} alt="" />}
                                    </div>

                                    <p>
                                        {rebrand ? rebrand.name : 'Koincart'} is a first-to-marketing tech that allows businesses anywhere to sell digital or physical products with cryptocurrency checkout fast & easy, creates gorgeous sales sites with templates, & auto-delivers products after crypto purchase. Cryptocurrency adoption is skyrocketing faster than internet adoption was and {rebrand ? rebrand.name : 'Koincart'} is here to help you tap into this.</p>
                                    <a onClick={handleShow} className="demoLink"><i className="fa fa-play" aria-hidden="true"></i> Watch Demo Video</a>
                                </div>
                            </div>
                            <div className="col-lg-5">
                                <div className="hero-banner-right">
                                    <Link to="/product-list" className="bannerLink">
                                        <div className="bannerLink-left"><img src={iconWrite} />View Products</div>
                                        <div className="bannerLink-right"><img src={iconRight} /></div>
                                    </Link>
                                    {auth.user.is_client_account === "1" ? "" :
                                        <Link to="/create-product" data-toggle="modal" data-target="#MyBlog" className="bannerLink bannerLink-2">
                                            <div className="bannerLink-left"><img src={iconUplod} />Create Product</div>
                                            <div className="bannerLink-right"><img src={iconRight} /></div>
                                        </Link>
                                    }
                                    <Link to={'/products-report'} className="bannerLink bannerLink-3">
                                        <div className="bannerLink-left"><img src={iconMic} />Reports</div>
                                        <div className="bannerLink-right"><img src={iconRight} /></div>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>



            <Modal className="VideoModal" show={show} onHide={handleClose} centered>
                <Modal.Body>
                    <div onClick={handleClose} className="vidClose"><img src={modalCloseIcon} /></div>
                    <div className="modalVidWrap">
                        <div className="modalVid">
                            <iframe width="560" height="315" src={"https://player.vimeo.com/video/732997056?h=c08df59a6f"} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal className="terms-modal" show={show2} centered>
                <div className="banner">
                    <div className="banner-text">
                        <p>Please scroll to the bottom in order to use Koincart</p>
                    </div>
                </div>
                <div className="term-close-button"><GrFormClose /></div>
                <div class="modal-header-terms">
                    <img src={stop} alt="" width="100" />
                    <span>STOP!</span>
                </div>
                <Modal.Body>
                    <Terms />
                    <form className="trems-btn" onSubmit={handleSubmit}>
                        <div className="chack-wrapper">
                            <div className="form-check" >
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    checked={acceptTerm}
                                    onChange={(e) => setAcceptTerm(e.target.checked)}
                                    required
                                />
                                <label className="form-check-label" for="flexCheckDefault">
                                    Yes, I have read & I agree to the TOS. I also understand there will be no <br />refunds in case of TOS Violation(s)
                                </label>
                            </div>
                        </div>
                        <div>
                            <button type="submit" className="btn">Accept {loader.acceptLoader ? <i className="fa fa-spinner fa-spin" /> : ''}</button>
                        </div>
                    </form>
                </Modal.Body>

            </Modal>
            {loader.baseModal ?
                <BaseInfo /> : ''}



        </>
    )
}

export default DashboardHead;