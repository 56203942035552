import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { setAlert } from '../../actions/alert';
import { getIntegration } from '../../actions/paymentAction';
import { baseURL } from '../../global/global';
import modalCloseIcon from '../../images/modal-close.png';
import Alert from '../Alert';

const PaymentModel = ({ show, handleClose, type, endpoint }) => {

    const auth = useSelector(state => state.auth)
    const [loader, setLoader] = useState(false)
    const [walletCode, setWalletCode] = useState([])
    const [currencyCode, setCurrencyCode] = useState([])
    const [tokenData, setTokenData] = useState({
        name: '',
        sandboxToken: '',
        liveToken: '',
        client_id: "",
        secret_id: "",
        live_client_id: "",
        live_secret_id: "",
        settlementCur: "",
        testApiKey: "",
        liveApiKey: '',
        wallet: "",
        apiKey: '',
        password: '',
        currency : '',
    })

    const dispatch = useDispatch()

    useEffect(() => {
        setTokenData({
            ...tokenData,
            name: '',
            sandboxToken: '',
            liveToken: '',
            client_id: "",
            secret_id: "",
            live_client_id: "",
            live_secret_id: "",
            settlementCur: "",
            testApiKey: "",
            liveApiKey: '',
            token: '',
            wallet: "",
            apiKey: '',
            password: '',
            currency : '',
            liveapiKey : ''
        })
    }, [show]);

    const onChange = (e) => {
        const { name, value } = e.target
        setTokenData({
            ...tokenData,
            [name]: value
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        setLoader(true)
        let data
        if (type === "BitPay" || type === "CoinGate") {
            data = {
                name: tokenData.name,
                sandboxToken: tokenData.sandboxToken,
                liveToken: tokenData.liveToken
            }
        }
        else if (type === "Paypal") {
            data = {
                name: tokenData.name,
                client_id: tokenData.client_id,
                secret_id: tokenData.secret_id,
                live_client_id: tokenData.live_client_id,
                live_secret_id: tokenData.live_secret_id
            }
        }
        else if (type === "Crypto.com") {
            data = {
                name: tokenData.name,
                testApiKey: tokenData.testApiKey,
                liveApiKey: tokenData.liveApiKey,
            }
        }

        else if (type === "ConFirmo") {
            data = {
                name: tokenData.name,
                apiKey: tokenData.token,
                settlementCurrency: tokenData.settlementCur
            }
        }

        else if (type === "CoinRemitter") {
            data = {
                name: tokenData.name,
                wallet: tokenData.wallet,
                apiKey: tokenData.apiKey,
                password: tokenData.password
            }
        }
        else if (type === "NowPayments") {
            data = {
                name: tokenData.name,
                liveapiKey: tokenData.liveApiKey,
                apiKey: tokenData.apiKey,
                currency: tokenData.currency
            }
        }



        if (data) {
            axios({
                method: "POST",
                url: `${baseURL}${endpoint}`,
                data: data,
                headers: { 'Content-Type': 'application/json', 'Authorization': auth.token }
            }).then((res) => {
                if (res.data.status === true) {
                    dispatch(setAlert(res.data.message, "success"))
                    dispatch(getIntegration(auth.token))
                    handleClose()
                }
                else {
                    dispatch(setAlert(res.data.message, "danger"))
                }
                setLoader(false)
            }).catch((error) => {
                setLoader(false)
                console.log(error)
            })
        }
    }

    useEffect(() => {
        if (type === "CoinRemitter") {
            axios({
                method: "POST",
                url: `${baseURL}wallet-list`,
                data: {},
                headers: { 'Content-Type': 'application/json', 'Authorization': auth.token }
            }).then((res) => {
                if (res.data.status === true) {
                    setWalletCode(res.data.data)
                }
            }).catch((error) => {
                console.log(error)
            })
        }
    }, [type])

    useEffect(() => {
        if (type === "NowPayments") {
            axios({
                method: "POST",
                url: `${baseURL}nowpayments-currency`,
                data: {},
                headers: { 'Content-Type': 'application/json', 'Authorization': auth.token }
            }).then((res) => {
                if (res.data.status === true) {
                    setCurrencyCode(res.data.data)
                }
            }).catch((error) => {
                console.log(error)
            })
        }
    }, [type])

    return (
        <>
            <Alert />
            <Modal className="VideoModal smModal" show={show} centered>
                <Modal.Body>
                    <div onClick={handleClose} className="vidClose"><img src={modalCloseIcon} alt="" /></div>
                    <form className="modalForm" onSubmit={handleSubmit}>
                        <h2 className='text-capitalize'>{type} Payment Integration</h2>
                        <div className="form-group form-group-alt">
                            <label htmlFor=''>Name</label>
                            <input
                                className="form-control"
                                placeholder="Name"
                                type="text"
                                name="name"
                                value={tokenData.name}
                                onChange={(e) => onChange(e)}
                                required
                            ></input>
                        </div>

                        {(type === "BitPay" || type === "CoinGate") ?
                            <>
                                <div className="form-group form-group-alt">
                                    <label htmlFor=''>Sandbox Token</label>
                                    <input
                                        className="form-control"
                                        placeholder="Sandbox Token"
                                        type="text"
                                        name="sandboxToken"
                                        value={tokenData.sandboxToken}
                                        onChange={(e) => onChange(e)}
                                        required
                                    ></input>
                                </div>
                                <div className="form-group form-group-alt">
                                    <label htmlFor=''>Live Token</label>
                                    <input
                                        className="form-control"
                                        placeholder="Live Token"
                                        type="text"
                                        name="liveToken"
                                        value={tokenData.liveToken}
                                        onChange={(e) => onChange(e)}
                                        // required
                                    ></input>
                                </div>
                            </>
                            : ''}

                        {type === 'Crypto.com' ?
                            <>
                                <div className="form-group form-group-alt">
                                    <label htmlFor=''>Test Api Key</label>
                                    <input
                                        className="form-control"
                                        placeholder="Test Api Key"
                                        type="text"
                                        name="testApiKey"
                                        value={tokenData.testApiKey}
                                        onChange={(e) => onChange(e)}
                                        required
                                    ></input>
                                </div>
                                <div className="form-group form-group-alt">
                                    <label htmlFor=''>Live Api Key</label>
                                    <input
                                        className="form-control"
                                        placeholder="Live Api Key"
                                        type="text"
                                        name="liveApiKey"
                                        value={tokenData.liveApiKey}
                                        onChange={(e) => onChange(e)}
                                        // required
                                    ></input>
                                </div>
                            </>
                            : ''}

                        {type === "ConFirmo" ?
                            <>
                                <div className="form-group form-group-alt">
                                    <label htmlFor=''>Settlement Currency</label>
                                    <select
                                        className="form-control"
                                        placeholder="Settlement Currency"
                                        name="settlementCur"
                                        value={tokenData.settlementCur}
                                        onChange={(e) => onChange(e)}
                                        required
                                    >
                                        <option value={""}>Select Settlement Currency</option>
                                        <option value={"BTC"}>BTC</option>
                                        <option value={"LTC"}>LTC</option>

                                    </select>
                                </div>
                                <div className="form-group form-group-alt">
                                    <label htmlFor=''>Access Token</label>
                                    <input
                                        className="form-control"
                                        placeholder="Access Token"
                                        type="text"
                                        name="token"
                                        value={tokenData.token}
                                        onChange={(e) => onChange(e)}
                                        required
                                    ></input>
                                </div>

                            </>
                            : ""}

                        {type === "Paypal" ?
                            <>
                                <div className="form-group form-group-alt">
                                    <label htmlFor=''>Sendbox Client ID</label>
                                    <input
                                        className="form-control"
                                        placeholder="Sendbox Client ID"
                                        type="text"
                                        name="client_id"
                                        value={tokenData.client_id}
                                        onChange={(e) => onChange(e)}
                                        required
                                    ></input>
                                </div>
                                <div className="form-group form-group-alt">
                                    <label htmlFor=''>Sendbox Secret ID</label>
                                    <input
                                        className="form-control"
                                        placeholder="Sendbox Secret ID"
                                        type="text"
                                        name="secret_id"
                                        value={tokenData.secret_id}
                                        onChange={(e) => onChange(e)}
                                        required
                                    ></input>
                                </div>
                                <div className="form-group form-group-alt">
                                    <label htmlFor=''>Live Client ID</label>
                                    <input
                                        className="form-control"
                                        placeholder="Live Client ID"
                                        type="text"
                                        name="live_client_id"
                                        value={tokenData.live_client_id}
                                        onChange={(e) => onChange(e)}
                                        // required
                                    ></input>
                                </div>
                                <div className="form-group form-group-alt">
                                    <label htmlFor=''>Live Secret ID</label>
                                    <input
                                        className="form-control"
                                        placeholder="Live Secret ID"
                                        type="text"
                                        name="live_secret_id"
                                        value={tokenData.live_secret_id}
                                        onChange={(e) => onChange(e)}
                                        // required
                                    ></input>
                                </div>
                            </> : ''
                        }

                        {type === "CoinRemitter" ?
                            <>
                                <div className="form-group form-group-alt">
                                    <label htmlFor=''>Wallet</label>
                                    <select
                                        className="form-control"
                                        placeholder="Wallet"
                                        name="wallet"
                                        value={tokenData.wallet}
                                        onChange={(e) => onChange(e)}
                                        required
                                    >
                                        <option value={""}>Select Wallet</option>
                                        {walletCode.length > 0 ?
                                            walletCode.map((wal) => {
                                                return (
                                                    <option value={wal.code}>{wal.code}</option>
                                                )
                                            })
                                            : ''}
                                    </select>
                                </div>
                                <div className="form-group form-group-alt">
                                    <label htmlFor=''>API Key</label>
                                    <input
                                        className="form-control"
                                        placeholder="API Key"
                                        type="text"
                                        name="apiKey"
                                        value={tokenData.apiKey}
                                        onChange={(e) => onChange(e)}
                                        required
                                    ></input>
                                </div>
                                <div className="form-group form-group-alt">
                                    <label htmlFor=''>Password</label>
                                    <input
                                        className="form-control"
                                        placeholder="Password"
                                        type="password"
                                        name="password"
                                        value={tokenData.password}
                                        onChange={(e) => onChange(e)}
                                        required
                                    ></input>
                                </div>

                            </>
                            : ""}

                        {type === "NowPayments" ?
                            <>
                                <div className="form-group form-group-alt">
                                    <label htmlFor=''>Currency</label>
                                    <select
                                        className="form-control"
                                        placeholder="Currency"
                                        name="currency"
                                        value={tokenData.currency}
                                        onChange={(e) => onChange(e)}
                                        required
                                    >
                                        <option value={""}>Select Currency</option>
                                        {currencyCode.length > 0 ?
                                            currencyCode.map((cur) => {
                                                return (
                                                    <option value={cur.code}>{cur.code}</option>
                                                )
                                            })
                                            : ''}
                                    </select>
                                </div>
                                <div className="form-group form-group-alt">
                                    <label htmlFor=''>Sandbox API Key</label>
                                    <input
                                        className="form-control"
                                        placeholder="Sandbox API Key"
                                        type="text"
                                        name="apiKey"
                                        value={tokenData.apiKey}
                                        onChange={(e) => onChange(e)}
                                        required
                                    ></input>
                                </div>
                                <div className="form-group form-group-alt">
                                    <label htmlFor=''>Live API Key</label>
                                    <input
                                        className="form-control"
                                        placeholder="Live API Key"
                                        type="text"
                                        name="liveApiKey"
                                        value={tokenData.liveApiKey}
                                        onChange={(e) => onChange(e)}
                                        // required
                                    ></input>
                                </div>

                            </>
                            : ""}

                        {/* {type !== 'paypal' ? <p className="infoTxt">
                            <a href="https://www.google.co.in/" target="_blank"> How to get the Access Token ?</a>
                        </p> : <p className="infoTxt">
                            <a href="https://www.google.co.in/" target="_blank"> How to get the Client Id and Secret Key</a>
                        </p>} */}

                        <div className="inp-wrap">
                            <button type='submit' className='btn btn-primary inpBtn'> Submit {loader ? <i className="fa fa-spinner fa-spin" /> : ''}</button>
                            {/* <input className="btn btn-primary inpBtn" type="submit" value="Submit" /> */}
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default PaymentModel;
