import React, { useState } from "react";

import vidThumb from '../../images/kc-thumb.png';
import modalCloseIcon from '../../images/modal-close.png';

const HelpVideos = (props) => {

    const videosData = [
        {
            name: 'Products',
            url: 'https://player.vimeo.com/video/734436025?h=56b79cdc26',
        },
        {
            name: 'Business Setup',
            url: 'https://player.vimeo.com/video/734435725?h=3e777f4d0a',
        },
        {
            name: 'Managing your Profile Info',
            url: 'https://player.vimeo.com/video/734435930?h=610bee8924',
        },
        {
            name: 'Team Member',
            url: 'https://player.vimeo.com/video/734435644?h=ebc9e0aa9f',
        },
        {
            name: 'Client Account               ',
            url: 'https://player.vimeo.com/video/734435821?h=685787b406',
        },
        {
            name: 'Change Password',
            url: 'https://player.vimeo.com/video/734435771?h=ddbb57b414',
        },
        {
            name: 'Integrations',
            url: 'https://player.vimeo.com/video/734435875?h=ecf4b4fd3a',
        },
        {
            name: 'Privacy',
            url: 'https://player.vimeo.com/video/734435974?h=568cf69bb0',
        },
        {
            name: 'Reports',
            url: 'https://player.vimeo.com/video/734436108?h=489c8effb7',
        },
        {
            name: 'Payment Integrations (part 1)',
            url: 'https://player.vimeo.com/video/735615128?h=3c2cd1bbd8',
        },
        {
            name: 'Payment Integrations (part 2)',
            url: 'https://player.vimeo.com/video/735615028?h=c38ef5a1f7',
        },
        {
            name: 'AutoResponder Integration',
            url: 'https://player.vimeo.com/video/735615004?h=80c099655b',
        },
        {
            name: 'Training Webinar Replay',
            url: 'https://player.vimeo.com/video/741005794',
        },
    ]
    const [url, setUrl] = useState('');
    const [showModal, setShowModal] = useState(false);

    const playVideo = (url) => {
        setUrl(url);
        setShowModal(true);
    }

    const closeModal = () => {
        setUrl('');
        setShowModal(false);
    }

   

    return (
        <>
            <section className="popularVideo">
                <div className="container">
                    <div className="text-center">
                        <h2>Popular Video Tutorials</h2>
                    </div>
                    <div className="row">
                        {
                            videosData.length > 0 ?
                                videosData.map((video, index) => {
                                    return (
                                        <div className="col-xl-3 col-lg-4 col-sm-6" key={index}
                                            style={{ cursor: "pointer" }}
                                            onClick={(e) => playVideo(video.url)}>
                                            <div className="popularVideo-single">
                                                <div className="popularVideo-vid"><img className="img-fluid" style={{ objectFit: 'unset' }} src={vidThumb} /></div>
                                                <div className="popularVideo-txt" style={{ justifyContent: "center" }}>
                                                    <span>{video.name}</span>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                                : ''
                        }
                        {
                            props.loadVideos ?
                                <div className="col-12">
                                    <h4 className="text-center load-icon-help"><i className="fa fa-spinner fa-spin mr-2" /></h4>
                                </div>
                                : ''
                        }
                    </div>
                </div>
            </section>

            <div className={`modal VideoModal ${showModal ? 'show-modal' : ''}`} id="myModal" aria-labelledby="myModalLabel" aria-hidden="true" tabIndex="-1" role="dialog">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div onClick={(e) => closeModal()} className="vidClose"><img src={modalCloseIcon} /></div>
                        <div className="modal-body">
                            <div className="embed-responsive embed-responsive-16by9">
                                <iframe id="frameSrc" className="embed-responsive-item" src={url} allowFullScreen=""></iframe>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default HelpVideos;