import React, { useState } from 'react';
import mailChimp_logo from "../../images/mail-chimp.png";
import getResponse_logo from "../../images/get-response.png";
import awebar_logo from "../../images/AWeber.png";
import iContact_logo from "../../images/iContact.png";
import Sendgrid_logo from "../../images/Sendgrid.png";
import Sendlane_logo from "../../images/Sendlane.png";
import Sandiio_logo from "../../images/Sandiio_logo1.png";
import Mailvio_logo from "../../images/Mailvio_logo.png";

// import AutoRespondModel from './AutoRespondModel';
import axios from 'axios';
import { baseURL } from '../../global/global';
import { useDispatch, useSelector } from 'react-redux';
import { setAlert } from '../../actions/alert';
import { checkResponse } from '../../actions/paymentAction';
import { randomstring } from 'randomstring-js'
import IntegrationAutoResponse from './IntegrationAutoResponse';
import AutoResponseModel from './AutoResponseModel';

const AutoResponder = () => {

    const auth = useSelector(state => state.auth)
    const intergation = useSelector(state => state.payment)
    const dispatch = useDispatch()

    const [checkStatus, setCheckStatus] = useState(true);
    const [randomString, setRandomString] = useState(randomstring());
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [inputData, setInputData] = useState({
        type: '',
        name: ''
    })


    const [loader, setLoader] = useState({
        loader1: false,
        loader2: false,
        loader3: false,
        loader4: false,
    })

    const handleClickModel = (type) => {

        let data = {
            type: type,
            key: randomString
        }
        if (type === "gr") {
            setLoader({
                ...loader,
                loader1: true
            })
        }
        if (type === "aw") {
            setLoader({
                ...loader,
                loader2: true
            })
        }
        if (type === "mc") {
            setLoader({
                ...loader,
                loader3: true
            })
        }

        axios({
            method: 'POST',
            url: `${baseURL}autoresponder-auth-url`,
            data: data,
            headers: { 'Content-Type': 'application/json', 'Authorization': auth.token }
        }).then((res) => {
            if (res.data.status === true) {
                let myWindow = window.open(res.data.data.outhUrl, "_blank", "width=700, height=500")
                let interval = setInterval(() => {
                    if (checkStatus) {
                        dispatch(checkResponse(randomString, "get_response", auth.token, interval, myWindow, setCheckStatus))
                    }
                }, 5000)
            } else {
                dispatch(setAlert(res.data.message, "danger"))
            }

            setLoader({
                ...loader,
                loader1: false,
                loader2: false,
                loader3: false,
                loader4: false

            })
        }).catch(err => {
            setLoader({
                ...loader,
                loader1: false,
                loader2: false,
                loader3: false,
                loader4: false
            })
            console.log(err);
        })
    }

    const handleModelPop = (type, name) => {
        setInputData({
            ...inputData,
            type: type,
            name: name,
        })
        handleShow()
    }


    return (
        <>
            <h2 className="titleBdr mt-5 mb-5 pt-3">My Auto Responder Integration</h2>

            <IntegrationAutoResponse
                data={intergation.autoResponse.getResponse ? intergation.autoResponse.getResponse : []}
                logo={getResponse_logo}
                name="GetResponse"
                type="gr"
                loader={loader.loader1}
                handleModel={handleClickModel}
            />

            <IntegrationAutoResponse
                data={intergation.autoResponse.aweber ? intergation.autoResponse.aweber : []}
                logo={awebar_logo}
                name="Aweber"
                type="aw"
                handleModel={handleClickModel}
                loader={loader.loader2}

            />

            <IntegrationAutoResponse
                data={intergation.autoResponse.mailChimp ? intergation.autoResponse.mailChimp : []}
                logo={mailChimp_logo}
                name="MailChimp"
                type="mc"
                handleModel={handleClickModel}
                loader={loader.loader3}

            />

            <IntegrationAutoResponse
                data={intergation.autoResponse.sendGrid ? intergation.autoResponse.sendGrid : []}
                logo={Sendgrid_logo}
                name="Sendgrid"
                type="sg"
                handleModel={handleModelPop}

            />

            <IntegrationAutoResponse
                data={intergation.autoResponse.sendLane ? intergation.autoResponse.sendLane : []}
                logo={Sendlane_logo}
                name="Sendlane"
                type="sl"
                handleModel={handleModelPop}

            />

            <IntegrationAutoResponse
                data={intergation.autoResponse.sendiio ? intergation.autoResponse.sendiio : []}
                logo={Sandiio_logo}
                name="Sendiio"
                type="si"
                handleModel={handleModelPop}

            />

            <IntegrationAutoResponse
                data={intergation.autoResponse.mailvio ? intergation.autoResponse.mailvio : []}
                logo={Mailvio_logo}
                name="Mailvio"
                type="mv"
                handleModel={handleModelPop}

            />

            <AutoResponseModel
                show={show}
                handleClose={handleClose}
                name={inputData.name}
                type={inputData.type}
            />
        </>
    );
}

export default AutoResponder;
