import React, { useState } from "react";
import HelpSubContent from "./HelpSubContent";

const HelpContent = ({ loadArticles }) => {
    const [searchKey, setsearchKey] = useState("")

    const onSearch = (e) => {
        setsearchKey(e.target.value)
    }

    return (
        <>

            <section className="siteWrap">
                <section className="help-banner">
                    <div className="container">
                        <div className="row align-items-center justify-content-center">
                            <div className="col-lg-8">
                                <div className="help-banner-in">
                                    <h2>What do you want to <span>know?</span></h2>
                                    <div className="helpSearch">
                                        <div className="helpSearch-left">
                                            <i class="fa fa-search" aria-hidden="true"></i>
                                            <input type="text"
                                                placeholder="Search"
                                                onChange={(e) => onSearch(e)}
                                            />
                                        </div>
                                        <div className="helpSearch-right">
                                            <button type="submit" className="demoLink">SUBMIT</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="impTopic">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-10">
                                <div className="impTopic-main">
                                    <h2>Important Topics</h2>
                                    <div className="row">
                                        {loadArticles.length > 0 ?
                                            loadArticles.filter((curElem) => {
                                                return curElem.tags.toLowerCase().indexOf(searchKey.toLowerCase()) > -1
                                            }).map((article, index) => {
                                                return (
                                                    <HelpSubContent
                                                        name={article.name}
                                                        id={article.id}
                                                        url={article.url}
                                                        key={index}
                                                    />
                                                )
                                            }) : ''}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </section>

        </>
    )
}

export default HelpContent;