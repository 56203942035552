import axios from "axios"
import { baseURL } from "../global/global"
import { setAlert } from "./alert"

export const setStore = (token, val, setUpdateLoader, handleClose2) => (dispatch) => {
    axios({
        method: 'POST',
        url: `${baseURL}store-add`,
        data: val,
        headers: { 'Content-Type': 'application/json', 'Authorization': token }
    }).then((res) => {
        if (res.data.status === true) {
            dispatch(setAlert(res.data.message, "success"))
            dispatch(getStore(token, false))
            if (handleClose2) {
                handleClose2()
            }
        } else {
            dispatch(setAlert(res.data.message, "danger"))
        }
        setUpdateLoader(false)
    }).catch((error) => {
        setUpdateLoader(false)
        console.log(error)
    })
}

export const getStore = (token, state, handleClose2, handleShow2) => (dispatch) => {
    axios({
        method: 'POST',
        url: `${baseURL}store-get`,
        data: {},
        headers: { 'Content-Type': 'application/json', 'Authorization': token }
    }).then((res) => {
        if (res.data.status === true) {
            if (state) {
                if (res.data.data.length > 0) {
                    handleClose2()
                }
                else {
                    handleShow2()
                }
            }
            dispatch({ type: "SET_BASE_CURRENCY", payload: res.data.data[0] })
        }
    }).catch((error) => {
        console.log(error)
    })
}

export const updateStore = (token, val, setUpdateLoader) => (dispatch) => {
    axios({
        method: 'POST',
        url: `${baseURL}store-update`,
        data: val,
        headers: { 'Content-Type': 'application/json', 'Authorization': token }
    }).then((res) => {
        if (res.data.status === true) {
            dispatch(setAlert(res.data.message, "success"))
            dispatch(getStore(token, false))
        } else {
            dispatch(setAlert(res.data.message, "danger"))
        }
        setUpdateLoader(false)
    }).catch((error) => {
        setUpdateLoader(false)
        console.log(error)
    })
}