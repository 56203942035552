import React, { useEffect, useState } from 'react';
import grapesjs from "grapesjs"
import 'grapesjs/dist/css/grapes.min.css';
import 'grapesjs-preset-webpage/dist/grapesjs-preset-webpage.min.css';
import 'grapesjs/dist/grapes.min';
import 'grapesjs-preset-webpage/dist/grapesjs-preset-webpage.min';
import { baseURL } from "../../../global/global";
import axios from "axios";
import queryString from "query-string";
import { useSelector } from 'react-redux';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { Tooltip } from 'react-bootstrap';
import modalCloseIcon from '../../../images/modal-close.png';
import { Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { setAlert } from '../../../actions/alert';
import Alert from '../../Alert';
import { handleProductSave, setTemplateCheck } from '../../../actions/productAction';



const TemplateCustomize = ({ location }) => {
    const auth = useSelector(state => state.auth)
    const id = queryString.parse(location.search).id;
    const productId = queryString.parse(location.search).productId;
    const tempType = queryString.parse(location.search).type;
    const type = queryString.parse(location.search).tempCustype;

    const productData = useSelector(state => state.product.data)

    const dispatch = useDispatch()
    const [checkSave, setCheckSave] = useState(false)
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [saveData, setSaveData] = useState({
        loader: false,
        name: ""
    })

    const templateId = id;
    const [templateData, setTemplateData] = useState({
        style: [],
        script: []
    });


    useEffect(() => {
        if (type === "blank") {
            dispatch(setTemplateCheck(1, "custom"))
            const newProduct = { ...productData }
            if (id && type) {
                newProduct["templateId"] = 1
                newProduct["templateType"] = "custom"
            }
            dispatch(handleProductSave(newProduct, false))
        }
    }, [type])


    const handleSubmit = (e) => {
        e.preventDefault()
        let data = {
            name: saveData.name,
            templateID: templateId,
            productId: productId,
            userId: auth.user.id
        }
        axios({
            method: 'POST',
            url: `${baseURL}template-saved`,
            data: data,
            headers: { 'Content-Type': 'application/json', 'Authorization': auth.token }
        }).then((res) => {
            if (res.data.status === true) {
                dispatch(setAlert(res.data.message, "success"))
                handleClose()
            }
            else {
                dispatch(setAlert(res.data.message, "danger"))
            }
        }).catch((error) => {
            console.log(error)
        })
    }

    useEffect(() => {
        axios.get(`${baseURL}product-templates-css/${templateId}`)
            .then(response => {
                // console.log(response.data.data);
                setTemplateData({
                    style: response.data.data.css,
                    script: response.data.data.script
                });
            })
            .catch(error => {
                console.log(error.response.data.error)
            })
    }, [])


    useEffect(() => {
        if (templateData.script.length > 0 && templateData.style.length > 0) {
            setCheckSave(true)
            const editor = grapesjs.init({
                container: '#gjs',
                style: '',
                plugins: ['gjs-preset-newsletter', 'gjs-blocks-basic'],
                pluginsOpts: {
                    'gjs-preset-newsletter': {
                        modalLabelImport: 'Paste all your code here below and click import',
                        modalLabelExport: 'Copy the code and use it wherever you want',
                        codeViewerTheme: 'material',
                        //defaultTemplate: templateImport,
                        importPlaceholder: '<table class="table"><tr><td class="cell">Hello world!</td></tr></table>',
                        cellStyle: {
                            'font-size': '12px',
                            'font-weight': 300,
                            'vertical-align': 'top',
                            color: 'rgb(111, 119, 125)',
                            margin: 0,
                            padding: 0,
                        }
                    }
                },
                canvas: {
                    styles: templateData.style,
                    scripts: templateData.script,
                },
                styleManager: {
                    // options
                },
                storageManager: {
                    type: 'remote',
                    autoload: true,
                    stepsBeforeSave: 1,
                    contentTypeJson: true,
                    credentials: 'omit',
                    urlStore: `${baseURL}product-template-save`,
                    urlLoad: `${baseURL}product-templates/${templateId}/${productId}/${auth.user.id}/${tempType}/${type}`,
                    params: {
                        templateID: templateId,
                        content: 'this is template customize',
                        userId: auth.user.id,
                        productId: productId,
                        templateType: tempType
                    },
                    storeComponents: true,
                    storeStyles: true,
                    storeHtml: true,
                    storeCss: true,
                }
            });

            editor.on('load', function () {
                const sM = editor.StyleManager;
                // let typographySector = styleManager.getSector('Typography');
                // const fontProperty = styleManager.getProperty('Typography', 'font-family');
                //  const lista = editor.StyleManager.getBuiltInAll();
                const lista = sM.getBuiltIn('font-family');
                const newList = { ...lista, options: lista.options.push({ id: "'Oswald', sans-serif", label: "Oswald" }) }
                console.log(sM.getSector('typography'));
                sM.addProperty('typography', newList);
                // var list = [];
                // fontProperty.set('list', list);
                // list = [
                //     fontProperty.addOption({value: "'Oswald', sans-serif", name: 'Oswald'}),
                //     fontProperty.addOption({value: "Helvetica Neue,Helvetica,Arial,sans-serif", name: 'Helvetica'}),
                //     fontProperty.addOption({value: "sans-serif", name: 'sans-serif'}),
                //     fontProperty.addOption({value: "Times New Roman", name: 'Times New Roman'}),
                //     fontProperty.addOption({value: "Arial Black", name: 'Arial Black'}),
                //     fontProperty.addOption({value: "Tahoma", name: 'Tahoma'}),
                //     fontProperty.addOption({value: "Verdana, Geneva, sans-serif", name: 'Verdana'}),
                //     fontProperty.addOption({value: "Courier New Courier, monospace", name: 'Courier New Courier'}),
                //     fontProperty.addOption({value: "'Lato', sans-serif", name: 'Lato'}),
                //     fontProperty.addOption({value: "'Open Sans', sans-serif", name: 'Open Sans'}),
                //     fontProperty.addOption({value: "'Montserrat', sans-serif", name: 'Montserrat'}),
                // ];
                // fontProperty.set('list', list);
                // styleManager.render();
            });
        }


    }, [templateData.script, templateData.style]);


    return (
        <div>
            <Alert />
            <div className='alert alert-warning text-center tempAlert'>
                All changes will be auto saved
                <OverlayTrigger placement="left" overlay={<Tooltip id="button-tooltip-2">Click Here to save this customization as a template</Tooltip>}>
                    <button
                        className='tempBtn'
                        onClick={checkSave ? handleShow : " "}
                    >Save as a Template</button>
                </OverlayTrigger>
            </div>
            <div id="gjs">

            </div>


            <Modal show={show} centered>
                <Modal.Body className="setname-modal">
                    <div onClick={handleClose} className="vidClose"><img src={modalCloseIcon} alt="" /></div>
                    <h5 className='text-center my-3'>Please enter a name to this Template</h5>
                    <form onSubmit={handleSubmit}>
                        <input
                            type="text"
                            name="name"
                            className='form-control'
                            placeholder='Enter Name'
                            onChange={e => setSaveData({
                                ...saveData,
                                name: e.target.value
                            })}
                            required
                        />
                        <div className="my-1 text-center text-white">
                            <button type='submit' className='btn btn-info'> Submit {saveData.loader ? <i className="fa fa-spinner fa-spin" /> : ''}</button>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>


        </div>
    );
}

export default TemplateCustomize;
