import axios from "axios";
import React, { useState, useEffect } from "react";
import { RiDeleteBinLine, RiErrorWarningLine } from 'react-icons/ri';
import { useDispatch, useSelector } from "react-redux";
import { baseURL } from "../../global/global";
import swal from "sweetalert";
import { setData, addShipping, updateShipping, deleteShipping } from "../../actions/productAction";
import { setAlert } from "../../actions/alert";
import { AiOutlineConsoleSql } from "react-icons/ai";
import { DefaultEditor } from 'react-simple-wysiwyg';
import { SiUfc } from "react-icons/si";



const ProductDetails = ({ id }) => {

    const auth = useSelector(state => state.auth)
    const dispatch = useDispatch()
    const productData = useSelector(state => state.product.data)

    const [pastProducts, setPastProducts] = useState([])
    const [loader, setLoader] = useState({
        pImageLoader: false,
        pImageName: '',
        orderBumpImageLoader: false,
        orderBumpImageName: ''
    })

    const [shipping, setShipping] = useState({
        countries: [],
        data: {
            country: '',
            shippingCost: ''
        }
    })

    let allowedExt = ['image/png', 'image/jpg', 'image/jpeg'];

    useEffect(() => {
        axios({
            method: 'POST',
            url: `${baseURL}product-fetchs`,
            data: {},
            headers: { 'Content-Type': 'application/json', 'Authorization': auth.token }
        }).then((res) => {
            if (res.data.status === true) {
                setPastProducts(res.data.data)
            }
        }).catch((error) => {
            console.log(error)
        })

        axios({
            method: 'POST',
            url: `${baseURL}fetch-country`,
            data: {},
            headers: { 'Content-Type': 'application/json', 'Authorization': auth.token }
        }).then((res) => {
            if (res.data.status === true) {
                setShipping({
                    ...shipping,
                    countries: res.data.data
                })
            }
        }).catch((error) => {
            console.log(error)
        })
    }, []);

    const handleAddLocation = () => {
        let data = {
            id: 1,
            shippingCost: 0
        }
        dispatch(addShipping(data))
    }

    const handleChange = (e) => {
        const { name, value } = e.target
        let val = value

        if (name === "orderbump_title") {
            val = val.match("^\\S*$")
            val = val[0]
            if (val.length > 80 || val.length === 80) {
                dispatch(setAlert("Not allow more than 80 characters", "danger"))
            }
        }

        if (name === "orderbump_description") {

            if (val.length > 500 || val.length === 500) {
                val = val.substr(0, 500);
                dispatch(setAlert("Not allow more than 500 characters", "danger"))
            }
        }

        // ankit***************change

        if (name === "ipnStatus" || name === "orderBumpsEnable") {
            dispatch(setData(e.target.name, e.target.checked))
        }
        else {
            if (name === "skuNumber") {
                val = val.match("^[a-zA-Z0-9]*")
                val = val[0]
            }
            if (name === "price") {
                if (val < 0) {
                    val = 0
                }
            }
            dispatch(setData(name, val))
        }
    }

    const handleShipping = (e, index) => {
        const { name, value } = e.target
        dispatch(updateShipping(name, value, index))
    }

    const handleDelete = (index) => {
        dispatch(deleteShipping(index))
    }


    const onInputFile = (e) => {
        const { name } = e.target
        const options = {
            headers: {
                'Authorization': auth.token
            }
        }

        if (e.target.files[0] !== undefined) {
            if (allowedExt.includes(e.target.files[0].type)) {
                let allowedSize = 100000000;

                if (e.target.files[0].size < allowedSize) {
                    const formData = new FormData();
                    formData.append('type', name);
                    formData.append('file', e.target.files[0])

                    if (name === "productImage") {
                        setLoader({
                            ...loader,
                            pImageLoader: true
                        })
                    }
                    if (name === "orderBumpImage") {
                        setLoader({
                            ...loader,
                            orderBumpImageLoader: true
                        })
                    }
                    axios.post(`${baseURL}upload-user-media`, formData, options)
                        .then(res => {
                            if (res.data.status === true) {
                                if (name === "productImage") {
                                    dispatch(setData(name, res.data.path))
                                    setLoader({
                                        ...loader,
                                        pImageLoader: false,
                                        pImageName: res.data.name
                                    })
                                }
                                if (name === "orderBumpImage") {
                                    dispatch(setData(name, res.data.path))
                                    setLoader({
                                        ...loader,
                                        orderBumpImageLoader: false,
                                        orderBumpImageName: res.data.name
                                    })
                                }
                            }
                            else {
                                dispatch(setAlert(res.data.message, "danger"))
                                setLoader({
                                    ...loader,
                                    pImageLoader: false,
                                    orderBumpImageLoader: false
                                })
                            }
                        })
                        .catch((error) => {
                            console.log(error)
                            setLoader({
                                ...loader,
                                pImageLoader: false,
                                orderBumpImageLoader: false
                            })
                        })
                }
                else {
                    swal("Oops!", "Max allowed size for image 100MB");
                }
            }
            else {
                swal("Oops!", "You have Selected Invalid File Type");
            }
        }
    }

    return (
        <>

            <div className="productBox">
                <h2 className="pageHeading">Product Details</h2>

                <div className="productBlock">
                    <div className="productBlock-left">
                        <label>Product Name</label>
                    </div>
                    <div className="productBlock-right">
                        <input
                            className="searchInp"
                            placeholder="Add Product Name"
                            type="text"
                            name="productName"
                            value={productData.productName}
                            onChange={(e) => handleChange(e)}
                            maxLength={200}

                        />
                    </div>
                </div>

                <div className="productBlock">
                    <div className="productBlock-left">
                        <label>SKU</label>
                    </div>
                    <div className="productBlock-right">
                        <input
                            className="searchInp"
                            placeholder="Add Product SKU"
                            type="text"
                            name="skuNumber"
                            value={productData.skuNumber}
                            onChange={(e) => handleChange(e)}
                        />
                        <span className="infoTxt">Add information about SKU</span>
                    </div>
                </div>

                <div className="productBlock">
                    <div className="productBlock-left">
                        <label>URL</label>
                    </div>
                    <div className="productBlock-right">

                        <span className="inpField"><a style={{ color: "black", textTransform: "lowercase" }} href={productData.productUrl} target="_blank">{productData.productUrl}</a></span>

                    </div>
                </div>
                <div className="productBlock align-items-start">
                    <div className="productBlock-left">
                        <label>Description</label>
                    </div>
                    <div className="productBlock-right">
                        <textarea
                            type="text"
                            maxLength={1000}
                            className="searchInp large"
                            placeholder="Add Product Description"
                            value={productData.productDescription}
                            name="productDescription"
                            onChange={(e) => handleChange(e)}
                        />
                    </div>
                </div>

                <div className="productBlock align-items-start">
                    <div className="productBlock-left">
                        <label>Price Type</label>
                    </div>
                    <div className="productBlock-right">
                        <div className="fileType">
                            <select
                                className="searchInp cursor-pointer"
                                name="paymentType"
                                value={productData.paymentType}
                                onChange={(e) => handleChange(e)}
                                required
                            >
                                <option value={1}>{'Fixed Payment'}</option>
                                <option value={2}>{'Pay As You Want'}</option>
                            </select>
                        </div>
                    </div>

                </div>

                <div className="productBlock align-items-start">
                    <div className="productBlock-left">
                        <label>{productData.paymentType === "2" ? 'Minimum' : ''} Price</label>
                    </div>
                    <div className="productBlock-right">
                        <div className="fileType">
                            <input
                                type="number"
                                className="searchInp"
                                placeholder="Enter Price"
                                name="price"
                                value={productData.price}
                                onChange={(e) => handleChange(e)}
                                required
                            />
                        </div>
                    </div>

                </div>


                <div className="productBlock align-items-start">
                    <div className="productBlock-left">
                        <label>Image</label>
                    </div>
                    <div className="productBlock-right">
                        <div className="fileType">
                            <button className="demoLink demoLink-bdr mt-0">Upload Product Image {loader.pImageLoader ? <i className="fa fa-spinner fa-spin mx-2" /> : ''}</button>
                            <input
                                type="file"
                                name="productImage"
                                onChange={(e) => onInputFile(e)}
                            />
                        </div>
                        <span className="infoTxt">Image should be smaller than 100MB and should be in JPG, PNG formate.</span>
                    </div>

                </div>
                <div className="edit-product-image mt-2">
                    {productData.image !== "" && productData.image !== null ?
                        <img src={productData.image} alt="" /> : ''}
                </div>

                <div className="productBlock align-items-start">
                    <div className="productBlock-left">
                        <label>Product Type</label>
                    </div>
                    <div className="productBlock-right d-flex">
                        <div>
                            <label class="custom-radio">Digital / Service
                                <input
                                    type="radio"
                                    name="productType"
                                    value={"digital"}
                                    checked={productData.productType === "digital" ? true : false}
                                    onChange={(e) => handleChange(e)}
                                />
                                <span class="checkmark"></span>
                            </label>
                            <span className="infoTxt">A digital file that buyers will download or a service</span>
                        </div>
                        <div className="pl-5">
                            <label class="custom-radio">Physical
                                <input
                                    type="radio"
                                    name="productType"
                                    value={"physical"}
                                    checked={productData.productType === "physical" ? true : false}
                                    onChange={(e) => handleChange(e)}
                                />
                                <span class="checkmark"></span>
                            </label>
                            <span className="infoTxt">A tangible item that you will ship to buyers.</span>
                        </div>
                    </div>
                </div>
                {productData.productType === "physical" ?
                    <div className="productBlock align-items-start">
                        <div className="productBlock-left">
                            <label>Shipping</label>
                        </div>
                        <div className="productBlock-right">
                            <div className="ships-block">
                                <div className="row">
                                    <div className="col-6">
                                        <div className="ships-block-txt">SHIPS TO</div>
                                    </div>
                                    <div className="col-6">
                                        <div className="ships-block-txt">SINGLE ITEM</div>
                                    </div>
                                </div>
                            </div>


                            <div className="ships-block">
                                {productData.shipping.length > 0 ?
                                    productData.shipping.map((ship, index) => {
                                        return (
                                            <div key={index} style={{ position: 'relative' }}>
                                                <div className="row mt-2">
                                                    <div className="col-6">
                                                        <div className="ships-block-txt">
                                                            <select
                                                                className="searchInp w-100"
                                                                name="country"
                                                                value={ship.id}
                                                                onChange={(e) => handleShipping(e, index)}
                                                            >
                                                                {shipping.countries.length > 0 ?
                                                                    shipping.countries.map((curElem) => {
                                                                        return (
                                                                            <option key={curElem.id} value={curElem.id}>{curElem.country_name}</option>
                                                                        )
                                                                    })
                                                                    : ''}

                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-6">
                                                        <div className="currencyInp">
                                                            <span>$</span>
                                                            <input
                                                                type="text"
                                                                placeholder="0.00"
                                                                name="shippingCost"
                                                                value={ship.shippingCost}
                                                                onChange={(e) => handleShipping(e, index)}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div
                                                    className="delBtn"
                                                    style={{ right: -60 }}
                                                    onClick={() => handleDelete(index)}
                                                ><RiDeleteBinLine /></div>
                                            </div>
                                        )
                                    })
                                    : ''}
                            </div>

                            <div className="ships-block mb-0">
                                <button className="demoLink mt-0" onClick={handleAddLocation}>+ Add Location</button>
                            </div>

                        </div>
                    </div>
                    : ''}

                <div className="productBlock align-items-start">
                    <div className="productBlock-left">
                        <label>Thank You Page URL</label>
                    </div>
                    <div className="productBlock-right">
                        <input
                            className="searchInp"
                            type="url"
                            name="thankyouPageUrl"
                            value={productData.thankyouPageUrl}
                            onChange={(e) => handleChange(e)}
                        />
                        <span className="infoTxt">This is the page where your customer will be redirected after purchasing the product.</span>
                    </div>
                </div>

                <div className="productBlock">
                    <div className="productBlock-left">
                        <label>IPN</label>
                    </div>
                    <div className="productBlock-right">
                        <div className="switchWrap">
                            <span>OFF</span>
                            <label class="switch">
                                <input
                                    type="checkbox"
                                    name="ipnStatus"
                                    checked={productData.ipn.enable}
                                    onChange={(e) => handleChange(e)}
                                />
                                <span class="slider round"></span>
                            </label>
                            <span>ON</span>
                        </div>
                    </div>
                </div>
                {productData.ipn.enable ?
                    <div className="productBlock">
                        <div className="productBlock-left">
                            <label>IPN URL</label>
                        </div>
                        <div className="productBlock-right">
                            <input
                                className="searchInp"
                                placeholder="Add IPN URL"
                                type="text"
                                name="ipnUrl"
                                value={productData.ipn.url}
                                onChange={(e) => handleChange(e)}
                            />
                            <span className="infoTxt">A URL where we send POST request after successful purchase</span>
                        </div>
                    </div>
                    : ''}

                <div className="productBlock">
                    <div className="productBlock-left">
                        <label>Order Bump</label>
                    </div>
                    <div className="productBlock-right">
                        <div className="switchWrap">
                            <span>OFF</span>
                            <label class="switch">
                                <input
                                    type="checkbox"
                                    name="orderBumpsEnable"
                                    checked={productData.orderBump.enable}
                                    onChange={(e) => handleChange(e)}
                                />
                                <span class="slider round"></span>
                            </label>
                            <span>ON</span>

                        </div>
                        {productData.orderBump.enable ?
                            pastProducts.length > 1 ? " " :
                                <p className="infoTxt" style={{ color: "crimson" }}>
                                    <RiErrorWarningLine
                                        size={20}
                                        style={{ marginBottom: '3px' }}
                                    /> Need at least two product to create order bumps</p> : ''}
                    </div>
                </div>

                {productData.orderBump.enable ?
                    pastProducts.length > 1 ?
                        <>
                            <div className="productBlock">
                                <div className="productBlock-left">
                                    <label>Title</label>
                                </div>
                                <div className="productBlock-right">
                                    <input
                                        className="searchInp"
                                        placeholder="Add Title for Order Bump"
                                        type="text"
                                        name="orderbump_title"
                                        value={productData.orderBump.title}
                                        onChange={(e) => handleChange(e)}
                                        maxLength={80}
                                    />
                                </div>
                            </div>

                            <div className="productBlock align-items-start">
                                <div className="productBlock-left">
                                    <label>Description</label>
                                </div>
                                <div className="productBlock-right">

                                    <DefaultEditor
                                        value={productData.orderBump.description}
                                        onChange={(e) => handleChange(e)}
                                        placeholder="Add Description for Order Bump"
                                        name="orderbump_description"
                                        className="searchInp large"
                                    />

                                </div>
                            </div>


                            <div className="productBlock align-items-start">
                                <div className="productBlock-left">
                                    <label>Image</label>
                                </div>
                                <div className="productBlock-right ">
                                    <div className="fileType ">
                                        <button className="demoLink demoLink-bdr mt-0">Upload {loader.orderBumpImageLoader ? <i className="fa fa-spinner fa-spin mx-2" /> : ''}</button>
                                        <input
                                            type="file"
                                            name="orderBumpImage"
                                            onChange={(e) => onInputFile(e)}
                                        />
                                    </div>
                                    <span className="infoTxt">Image should be smaller than 5MB, and should be in  JPG, PNG format.</span>
                                </div>
                            </div>

                            <div className="edit-product-image mt-2">
                                {productData.orderBump.image !== "" ?
                                    <img src={productData.orderBump.image} alt="" /> : ''}
                            </div>

                            <div className="productBlock">
                                <div className="productBlock-left">
                                    <label>Product</label>
                                </div>
                                <div className="productBlock-right">
                                    <select
                                        className="searchInp"
                                        name="orderbump_product"
                                        value={productData.orderBump.product}
                                        onChange={(e) => handleChange(e)}
                                    >
                                        <option value="">Select Product</option>
                                        {pastProducts.length > 0 ?
                                            pastProducts.map((curElem) => {
                                                if (id !== curElem.productId) {
                                                    return (
                                                        <option
                                                            key={curElem.productId}
                                                            value={curElem.productId}
                                                        >
                                                            {curElem.productName}
                                                        </option>
                                                    )
                                                }
                                            })
                                            : ''}

                                    </select>
                                    <span className="infoTxt">Select the product which you want to add in Order Bump</span>
                                </div>
                            </div>

                        </> : ""
                    : ''}

            </div>

        </>
    )
}

export default ProductDetails;