import React from 'react';
import { useDispatch } from 'react-redux';
import { updatePayment } from '../../actions/productAction';

const PaymentCard = (props) => {
    const dispatch = useDispatch()

    const handleChangePaymant = (e, index) => {
        const { name, value, checked } = e.target
        if (checked !== undefined) {
            dispatch(updatePayment(name, index, checked))
        }
        else {
            dispatch(updatePayment(name, index, value))
        }
    }
    return (
        <li key={props.index}>
            <div className="price-single">
                <div className="price-single-main">
                    <label className="customRadio sq">
                        <input
                            type="checkbox"
                            name="enable"
                            checked={props.data.enable}
                            onChange={(e) => handleChangePaymant(e, props.index)}
                        />
                        <span className="checkmark"></span>
                    </label>
                    <p>{props.data.name}</p>
                </div>
                <div className="price-single-img"><img src={props.icon} alt="" /></div>
            </div>
        </li>
    );
}

export default PaymentCard;
