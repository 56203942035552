import React, { useState, useEffect } from "react";
import Navbar from "../Navbar";
import Footer from "../Footer";
import TitleBar from "../TitleBar";

import { Tab, Row, Col, Nav } from "react-bootstrap";

import saveIcon from '../../images/save-icon.svg';
import renderIcon from '../../images/render-icon.svg';

import productDetailsIcon from '../../images/product-details-icon.svg';
import templatesIcon from '../../images/templates-icon.svg';
import paymentsIcon from '../../images/payments-icon.svg';
import seoIcon from '../../images/seo-icon.svg';
import settingsIcon from '../../images/settings-icon.svg';
import couponIcon from '../../images/coupon-icon.svg';

import ProductDetails from "./ProductDetails";
import Templates from "./Templates";
import Payments from "./Payments";
import Seo from "./Seo";
import Settings from "./Settings";
import Coupons from "./Coupons";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { baseURL } from "../../global/global";
import { setAlert } from "../../actions/alert";
import { addProduct, handleProductSave, setData } from "../../actions/productAction";
import queryString from "query-string";
import { useHistory } from "react-router-dom";
import Alert from "../Alert";
import { AiOutlineConsoleSql } from "react-icons/ai";


const EditProduct = ({ location }) => {

    const id = queryString.parse(location.search).id;
    const auth = useSelector(state => state.auth)
    const product = useSelector(state => state.product.data)
    const dispatch = useDispatch()
    const history = useHistory()

    const [productData, setProductData] = useState(false)
    const [saveLoader, setSaveLoader] = useState(false)
    const [loader, setLoader] = useState({
        saveLoader: false,
        mainLoader: true,
        liveModeLoader: false
    })

    useEffect(async () => {
        if (id !== undefined) {
            setLoader({
                ...loader,
                mainLoader: true
            })
            const data = { "productId": id }
            await axios({
                method: 'POST',
                url: `${baseURL}product-get`,
                data: data,
                headers: { 'Content-Type': 'application/json', 'Authorization': auth.token }
            }).then((res) => {
                if (res.data.status === true) {
                    let val = res.data.data
                    val = {
                        ...val,
                        productId: id,
                        productType: "digital",
                        shipping: [],
                        seo: {
                            title: "",
                            description: "",
                            image: "",
                            meta_tags: ""
                        },
                        setting: {
                            headerScript: "",
                            footerScript: "",
                            firePixel: ""
                        },
                    }
                    if (val.paymentMode === "") {
                        val.paymentMode = "sandbox"
                    }
                    dispatch(addProduct(val))

                }
                else {
                    dispatch(setAlert(`You don't have product id ${id}!`, "danger"))
                    history.push(`/edit-product?id=${product.productId}`)
                }
                setLoader({
                    ...loader,
                    mainLoader: false
                })
            }).catch((error) => {
                setLoader({
                    ...loader,
                    mainLoader: false
                })
                history.push('/dashboard')
                console.log(error)
            })
        }

    }, [id])

    useEffect(() => {
        if (product) {
            setProductData(product)
        }
    }, [product])



    const handleSave = () => {
        if (product.thankyouPageUrl) {
            dispatch(handleProductSave(product, true, setSaveLoader))
        }
        else {
            dispatch(setAlert("Please Enter Thank You Page URL", "danger"))
        }
    }

    const handleChange = async (e) => {
        let val = "sandbox"
        if (e.target.checked === true) {
            setLoader({
                ...loader,
                liveModeLoader: true
            })
            let temp = []
            productData.paymentData.map((curElem) => {
                if (curElem.enable) {
                    let obj = {
                        type: curElem.paymentType,
                        integrationId: curElem.integrationId
                    }
                    temp.push(obj)
                }
            })
            await axios({
                method: 'POST',
                url: `${baseURL}live-mode-check`,
                data: { "paymentGetway": temp },
                headers: { 'Content-Type': 'application/json', 'Authorization': auth.token }
            }).then((res) => {
                if (res.data.status === true) {
                    dispatch(setAlert(res.data.message, "success"))
                    val = "live"
                }
                else {
                    dispatch(setAlert(res.data.message, "danger"))
                }
                setLoader({
                    ...loader,
                    liveModeLoader: false
                })
            }).catch((error) => {
                setLoader({
                    ...loader,
                    liveModeLoader: false
                })
                console.log(error)
            })
        }
        dispatch(setData(e.target.name, val))
    }

    return (
        <>
            {loader.mainLoader ?
                <div className="loader-sec">
                    <div className="loader">
                    </div>
                </div> :
                <>
                    <TitleBar title="Edit Product" />
                    <Navbar />
                    <Alert />


                    <section className="siteWrap">
                        <div className="pageWrap alt">
                            <div className="pageBread">
                                <div className="container">
                                    <ul>
                                        <li><a href="">Dashboard</a></li>
                                        {/* <li><a href="">Create Product</a></li> */}
                                        <li><a href="">Edit Product</a></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="container">
                                <div className="pageTitle">
                                    <h2>Edit Product</h2>
                                </div>

                                <div>
                                    <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                                        <div className="tabTop">
                                            <div className="tabTop-in">
                                                <div className="campaignBarN">
                                                    <div className="row justify-content-end">
                                                        <div className="d-flex align-items-center justify-content-end">
                                                            <div className="pr-3">
                                                                <div className="switchWrap">
                                                                    <span>Sandbox</span>
                                                                    <label class="switch">
                                                                        <input
                                                                            type="checkbox"
                                                                            name="paymentMode"
                                                                            checked={productData ? productData.paymentMode === "sandbox" ? false : true : false}
                                                                            onChange={(e) => handleChange(e)}
                                                                        />
                                                                        <span class="slider round">
                                                                            {loader.liveModeLoader ? <i className="fa fa-spinner fa-spin  slider-loader-css" /> : ''}</span>
                                                                    </label>
                                                                    <span>Live</span>
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <button
                                                                    className="demoLink demoLink-dark mt-0 btn-block"
                                                                    onClick={handleSave}
                                                                ><img src={saveIcon} />
                                                                    {saveLoader ?
                                                                        <>
                                                                            Saving Changes  <i className="fa fa-spinner fa-spin" />
                                                                        </>
                                                                        :
                                                                        'Save Changes'
                                                                    }
                                                                </button>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                                <Nav variant="pills" className="tabNav">
                                                    <Nav.Item><Nav.Link eventKey="first">
                                                        <img src={productDetailsIcon} />
                                                        <span>Product Details</span>
                                                    </Nav.Link></Nav.Item>
                                                    <Nav.Item><Nav.Link eventKey="second">
                                                        <img src={templatesIcon} />
                                                        <span>Templates</span>
                                                    </Nav.Link></Nav.Item>
                                                    <Nav.Item><Nav.Link eventKey="third">
                                                        <img src={paymentsIcon} />
                                                        <span>Payments</span>
                                                    </Nav.Link></Nav.Item>
                                                    <Nav.Item><Nav.Link eventKey="fourth">
                                                        <img src={seoIcon} />
                                                        <span>SEO</span>
                                                    </Nav.Link></Nav.Item>
                                                    <Nav.Item><Nav.Link eventKey="fifth">
                                                        <img src={settingsIcon} />
                                                        <span>Settings</span>
                                                    </Nav.Link></Nav.Item>
                                                    <Nav.Item><Nav.Link eventKey="sixth">
                                                        <img src={couponIcon} />
                                                        <span>Coupons</span>
                                                    </Nav.Link></Nav.Item>
                                                </Nav>
                                            </div>
                                        </div>


                                        <Tab.Content>
                                            <Tab.Pane eventKey="first">
                                                <ProductDetails
                                                    id={id}
                                                />
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="second">
                                                <Templates
                                                    productData={productData}
                                                    handleSave={handleSave}
                                                    id={id}
                                                />
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="third">
                                                <Payments
                                                    productData={productData}
                                                />
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="fourth">
                                                <Seo
                                                    productData={productData}
                                                    id={id}
                                                />
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="fifth">
                                                <Settings
                                                    productData={productData}
                                                    id={id}
                                                />
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="sixth">
                                                <Coupons
                                                    productData={productData}
                                                    id={id}
                                                />
                                            </Tab.Pane>
                                        </Tab.Content>

                                    </Tab.Container>
                                </div>
                            </div>
                        </div>
                    </section >

                    <Footer />
                </>
            }
        </>
    )
}

export default EditProduct;