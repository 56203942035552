import React, { useState, useEffect } from "react";
import Navbar from "../Navbar";
import Footer from "../Footer";
import TitleBar from "../TitleBar";
import { FaSort } from 'react-icons/fa';
import Select from 'react-select';
import modalCloseIcon from '../../images/modal-close.png';
import ModernDatepicker from 'react-modern-datepicker';
import { baseURL } from "../../global/global";
import axios from "axios";
import { useSelector } from "react-redux";
import makeAnimated from 'react-select/animated';
import { FaChevronLeft, FaChevronRight } from "react-icons/fa"
import { Modal } from "react-bootstrap";
import { setAlert } from "../../actions/alert";
import { useDispatch } from "react-redux";
import Alert from "../Alert"


const animatedComponents = makeAnimated();
const CustomerReport = () => {
    const dispatch = useDispatch()
    const auth = useSelector(state => state.auth)
    const [status, setStatus] = useState(false)
    const [cusReport, setCusReport] = useState([])
    const [cusReportData, setCusReportData] = useState([])
    const [loader, setLoader] = useState(false)
    const [selectTwo, setSelectTwo] = useState([])
    const [products, setProducts] = useState([])
    const [toggleDate, setToggleDate] = useState({
        customeDate: false,
        searchBy: '',
        startDate: '',
        endDate: '',
        saleType: ''
    })
    const [pageNumber, setPageNumber] = useState([])
    const [pagination, setPagination] = useState({
        currentPage: 1,
        totalItemonPage: 20
    })
    const [refundOnData, setRefundOnData] = useState(false)

    const [submitObj, setSubmitObj] = useState({
        reason: "",
        currencyId: "",
        platformId: "",
        ledgerAccountId: ""
    })
    const [curr, setCurr] = useState({
        currencyData: [],
        plateform: []
    })

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = (data) => {
        setRefundOnData(data)
        let arr = ["Crypto", "CoinGate", "PayPal"]
        if (arr.includes(data.paymentMethod)) {
            setShow(true);
        }
    }

    const onChange = (e) => {
        const { name, value } = e.target
        setSubmitObj({
            ...submitObj,
            [name]: value
        })
    }



    useEffect(() => {
        if (show) {
            axios({
                method: "POST",
                url: `${baseURL}coingate-currencies`,
                data: {},
                headers: { 'Content-Type': 'application/json', 'Authorization': auth.token }
            }).then((res) => {
                if (res.data.status === true) {
                    setCurr({
                        ...curr,
                        currencyData: res.data.data.getCurrencies,
                        plateform: res.data.data.getPlatforms
                    })
                }
            }).catch((error) => {
                console.log(error)
            })
        }
    }, [show])

    const handleSort = (type) => {

        let data = [...cusReport]
        if (type === "sno") {
            if (status === true) {
                setStatus(false)
                data.sort((a, b) => {
                    if (parseInt(a.productSN) > parseInt(b.productSN)) {
                        return 1
                    }
                    else {
                        return -1
                    }
                }
                )
            }
            else {
                data.sort((a, b) => {
                    setStatus(true)
                    if (parseInt(a.productSN) < parseInt(b.productSN)) {
                        return 1
                    }
                    else {
                        return -1
                    }
                }
                )
            }
        }
        else if (type === "name") {
            if (status === true) {
                data.sort((a, b) => {
                    setStatus(false)
                    if (a.name.toLowerCase() > b.name.toLowerCase()) {
                        return 1
                    }
                    else {
                        return -1
                    }
                }
                )
            } else {
                data.sort((a, b) => {
                    setStatus(true)
                    if (a.name.toLowerCase() < b.name.toLowerCase()) {
                        return 1
                    }
                    else {
                        return -1
                    }
                }
                )
            }
        }
        else if (type === "email") {
            if (status) {
                setStatus(false)
                data.sort((a, b) => {
                    if (a.email.toLowerCase() > b.email.toLowerCase()) {
                        return 1
                    }
                    else {
                        return -1
                    }
                }
                )
            }
            else {
                setStatus(true)
                data.sort((a, b) => {
                    if (a.email.toLowerCase() < b.email.toLowerCase()) {
                        return 1
                    }
                    else {
                        return -1
                    }
                }
                )
            }
        }
        else if (type === "product") {
            if (status) {
                setStatus(false)
                data.sort((a, b) => {
                    if (a.productName.toLowerCase() > b.productName.toLowerCase()) {
                        return 1
                    }
                    else {
                        return -1
                    }
                }
                )
            } else {
                setStatus(true)
                data.sort((a, b) => {
                    if (a.productName.toLowerCase() < b.productName.toLowerCase()) {
                        return 1
                    }
                    else {
                        return -1
                    }
                }
                )
            }
        }
        else if (type === "price") {
            if (status) {
                setStatus(false)
                data.sort((a, b) => {
                    if (parseFloat(a.priceAmount) > parseFloat(b.priceAmount)) {
                        return 1
                    }
                    else {
                        return -1
                    }
                }
                )
            }
            else {
                setStatus(true)
                data.sort((a, b) => {
                    if (parseFloat(a.priceAmount) < parseFloat(b.priceAmount)) {
                        return 1
                    }
                    else {
                        return -1
                    }
                }
                )
            }
        }
        else if (type === "currency") {
            if (status) {
                setStatus(false)
                data.sort((a, b) => {
                    if (a.priceCurrency.toLowerCase() > b.priceCurrency.toLowerCase()) {
                        return 1
                    }
                    else {
                        return -1
                    }
                }
                )
            } else {
                setStatus(true)
                data.sort((a, b) => {
                    if (a.priceCurrency.toLowerCase() < b.priceCurrency.toLowerCase()) {
                        return 1
                    }
                    else {
                        return -1
                    }
                }
                )
            }
        }
        else if (type === "paymentGateway") {
            if (status) {
                setStatus(false)
                data.sort((a, b) => {
                    if (a.paymentMethod.toLowerCase() > b.paymentMethod.toLowerCase()) {
                        return 1
                    }
                    else {
                        return -1
                    }
                }
                )
            }
            else {
                setStatus(true)
                data.sort((a, b) => {
                    if (a.paymentMethod.toLowerCase() < b.paymentMethod.toLowerCase()) {
                        return 1
                    }
                    else {
                        return -1
                    }
                }
                )
            }
        }
        else if (type === "created") {
            if (status) {
                setStatus(false)
                data.sort((a, b) => {
                    if (a.created > b.created) {
                        return 1
                    }
                    else {
                        return -1
                    }
                }
                )
            } else {
                setStatus(true)
                data.sort((a, b) => {
                    if (a.created < b.created) {
                        return 1
                    }
                    else {
                        return -1
                    }
                })
            }
        }
        setCusReport(data)
    }


    useEffect(() => {
        setLoader(true)
        axios({
            method: 'POST',
            url: `${baseURL}customer-reports`,
            data: {},
            headers: { 'Content-Type': 'application/json', 'Authorization': auth.token }
        }).then((res) => {
            if (res.data.status === true) {
                setCusReport(res.data.data)
            }
            setLoader(false)
        }).catch((error) => {
            setLoader(false)
            console.log(error)
        })
    }, []);

    useEffect(() => {
        axios({
            method: 'POST',
            url: `${baseURL}product-reports`,
            data: {},
            headers: { 'Content-Type': 'application/json', 'Authorization': auth.token }
        }).then((res) => {
            if (res.data.status === true) {
                let val = res.data.data
                let objArr = []
                val.forEach((curElem) => {
                    let obj = { value: curElem.productSN, label: curElem.productName }
                    objArr.push(obj)
                })
                setProducts(objArr)
            }
        }).catch((error) => {
            console.log(error)
        })
    }, []);

    const handleGetDate = (type) => {
        const d = new Date();

        if (type === "week") {
            d.setDate(d.getDate() - 7);
        }
        else {
            d.setDate(d.getDate() - 30);
        }

        let startDate = d.toISOString().slice(0, 10)
        return startDate
    }

    const handleSearchBy = (e) => {
        let start = new Date().toISOString().slice(0, 10)
        if (e.target.value === "3") {
            setToggleDate({
                ...toggleDate,
                customeDate: true,
                searchBy: '',
                startDate: '',
                endDate: ''
            })
        }
        else {
            if (e.target.value === "0") {
                setToggleDate({
                    ...toggleDate,
                    searchBy: "today",
                    customeDate: false,
                    startDate: start,
                    endDate: ''
                })
            }
            else if (e.target.value === "1") {
                setToggleDate({
                    ...toggleDate,
                    searchBy: "week",
                    customeDate: false,
                    startDate: handleGetDate("week"),
                    endDate: start
                })
            }
            else if (e.target.value === "2") {
                setToggleDate({
                    ...toggleDate,
                    searchBy: "month",
                    customeDate: false,
                    startDate: handleGetDate("month"),
                    endDate: start
                })
            }
            else if (e.target.value === "4") {
                setToggleDate({
                    ...toggleDate,
                    searchBy: "",
                    customeDate: false,
                    startDate: '',
                    endDate: ''
                })
            }
        }
    }


    const handleDate = (date, type) => {
        if (type === "start") {
            setToggleDate({
                ...toggleDate,
                startDate: date
            })
        }
        else if (type === "end") {
            setToggleDate({
                ...toggleDate,
                endDate: date
            })
        }
    }

    const handleSaleType = (e) => {
        const { value } = e.target
        setToggleDate({
            ...toggleDate,
            saleType: value
        })
    }

    const handleSelectChange = (e) => {
        let arr = []
        e.forEach((curElem) => {
            arr.push(curElem.value)
        })
        setSelectTwo(arr)
    }

    useEffect(() => {
        if (cusReportData.length > 0) {
            let maxLength = cusReportData.length
            let data = []
            if (maxLength > 20) {
                let val = maxLength / 20
                if (val > parseInt(val)) {
                    val = val + 1
                }
                for (let i = 1; i <= val; i++) {
                    data.push(i)
                }
                setPageNumber(data)
            }
            else {
                setPageNumber([1])
            }
        }
    }, [cusReportData])

    const handleClick = (num) => {
        setPagination({
            ...pagination,
            currentPage: num
        })
    }
    const handleBackward = (num) => {
        if (pageNumber[0] < num) {
            setPagination({
                ...pagination,
                currentPage: num - 1
            })
        }
    }
    const handleForward = (num) => {
        if (pageNumber[pageNumber.length - 1] > num) {
            setPagination({
                ...pagination,
                currentPage: num + 1
            })
        }
    }

    const indexOfLastTodo = pagination.currentPage * pagination.totalItemonPage;
    const indexOfFirstTodo = indexOfLastTodo - pagination.totalItemonPage;
    const currentTodos = cusReportData.slice(indexOfFirstTodo, indexOfLastTodo);

    useEffect(() => {

        if (toggleDate.saleType === "" && toggleDate.customeDate === false && toggleDate.searchBy === "" && selectTwo.length === 0) {
            setCusReportData(cusReport)
        }
        else {
            const val = cusReport.filter((curElem) => {
                if (toggleDate.customeDate === false && toggleDate.searchBy === '') {
                    return curElem
                }
                else {
                    if (toggleDate.endDate !== "" && toggleDate.customeDate) {
                        let searchDate = curElem.created.split(" ")
                        let data = (searchDate[0] >= toggleDate.startDate && searchDate[0] <= toggleDate.endDate)
                        if (data) {
                            return curElem
                        }
                    }
                    else if (toggleDate.customeDate === false && toggleDate.searchBy === "today") {
                        let searchDate = curElem.created.split(" ")
                        let data = (toggleDate.startDate === searchDate[0])
                        if (data) {
                            return curElem
                        }
                    }
                    else if (toggleDate.customeDate === false && toggleDate.searchBy === "week") {
                        let searchDate = curElem.created.split(" ")
                        let data = (searchDate[0] >= toggleDate.startDate && searchDate[0] <= toggleDate.endDate)
                        if (data) {
                            return curElem
                        }
                    }
                    else if (toggleDate.customeDate === false && toggleDate.searchBy === "month") {
                        let searchDate = curElem.created.split(" ")
                        let data = (searchDate[0] >= toggleDate.startDate && searchDate[0] <= toggleDate.endDate)
                        if (data) {
                            return curElem
                        }
                    }
                }
            }).filter((curElem) => {
                if (toggleDate.saleType === "") {
                    return curElem
                }
                else if (toggleDate.saleType === "complete") {
                    let data = curElem.is_status === "4"
                    if (data) {
                        return curElem
                    }
                }
                else if (toggleDate.saleType === "incomplete") {
                    let data = curElem.is_status !== "4"
                    if (data) {
                        return curElem
                    }
                }
            }).filter((curElem) => {
                if (selectTwo.length > 0) {
                    let val = selectTwo.includes(curElem.productSN)
                    if (val) {
                        return curElem
                    }
                } else {
                    return curElem
                }
            })
            setCusReportData(val)
        }
    }, [toggleDate, selectTwo, cusReport])


    const handleSubmit = (e) => {
        e.preventDefault()
        let type = ""
        let data

        if (refundOnData.paymentMethod === "CoinGate") {
            type = "coingate"
            data = {
                id: refundOnData.orderId,
                reason: submitObj.reason,
                currencyId: submitObj.currencyId,
                platformId: submitObj.platformId,
                ledgerAccountId: submitObj.ledgerAccountId
            }
        } else if (refundOnData.paymentMethod === "Crypto") {
            type = "crypto"
            data = {
                id: refundOnData.orderId,
                reason: submitObj.reason
            }
        } else if (refundOnData.paymentMethod === "PayPal") {
            type = "paypal"
            data = {
                id: refundOnData.orderId,
                reason: submitObj.reason
            }
        }

        axios({
            method: 'POST',
            url: `${baseURL}${type}-refund-request`,
            data: data,
            headers: { 'Content-Type': 'application/json', 'Authorization': auth.token }
        }).then((res) => {
            if (res.data.status === true) {
                dispatch(setAlert(res.data.message, "success"))
            }
            else {
                dispatch(setAlert(res.data.message, "danger"))
            }
        }).catch((error) => {
            console.log(error)
        })
    }
    return (
        <>
            <TitleBar title="Reports" />
            <Navbar />
            <Alert />
            <section className="siteWrap">
                <div className="pageWrap">
                    <div className="container">
                        <div className="pageTitle">
                            <h2>Customer Report</h2>
                        </div>
                        <div className="productTable">
                            <div className="salesBy">
                                <div className="salesBy-left">
                                    <select
                                        className="searchInp"
                                        onChange={handleSearchBy}
                                    >
                                        <option value={4}>All time</option>
                                        <option value={0}>Today</option>
                                        <option value={1}>Week</option>
                                        <option value={2}>Month</option>
                                        <option value={3}>Custom Date</option>
                                    </select>
                                    <div style={{ display: "flex" }}>
                                        {toggleDate.customeDate ? <>
                                            <ModernDatepicker
                                                date={toggleDate.startDate}
                                                format={'YYYY-MM-DD'}
                                                showBorder
                                                className="date-picker-style ml-2"
                                                onChange={date => handleDate(date, "start")}
                                                primaryColor={'#400C46'}
                                                secondaryColor={'#fff6fe'}
                                                primaryTextColor={'#400C46'}
                                                secondaryTextColor={'#fff'}
                                                placeholder="From Date"
                                            />
                                            <ModernDatepicker

                                                date={toggleDate.endDate}
                                                format={'YYYY-MM-DD'}
                                                showBorder
                                                className="date-picker-style ml-2 "
                                                onChange={date => handleDate(date, "end")}
                                                primaryColor={'#400C46'}
                                                secondaryColor={'#fff6fe'}
                                                primaryTextColor={'#400C46'}
                                                secondaryTextColor={'#fff'}
                                                placeholder="To Date"
                                            />
                                        </> : ''}
                                      
                                    </div>
                                    <div className="mx-3">
                                        <Select
                                            closeMenuOnSelect={false}
                                            components={animatedComponents}
                                            defaultValue={[products[0]]}
                                            isMulti
                                            options={products}
                                            onChange={(e) => handleSelectChange(e)}
                                        />
                                    </div>
                                </div>
                                <div className="salesBy-right">
                                    <select
                                        className="searchInp ml-2"
                                        name="saleType"
                                        onChange={(e) => handleSaleType(e)}
                                    >
                                        <option value={""}>Select All</option>
                                        <option value={"complete"}>Complete Sales</option>
                                        <option value={"incomplete"}>Incomplete Sales</option>
                                    </select>
                                </div>
                            </div>
                            <div className="table-responsive">
                                <table className="table table-striped themeTable themeTable-alt hover-effect">
                                    <tr>
                                        <th title="SNo." onClick={() => handleSort("sno")}>SNo. <FaSort className="sortIcon" /> </th>
                                        <th title="Buyer Name" onClick={() => handleSort("name")}>Name <FaSort className="sortIcon" /></th>
                                        <th title="Buyer Email" onClick={() => handleSort("email")}>Email <FaSort className="sortIcon" /></th>
                                        <th title="Product bought" onClick={() => handleSort("product")}>Product <FaSort className="sortIcon" /></th>
                                        <th title="Price of the product" onClick={() => handleSort("price")}>Price <FaSort className="sortIcon" /></th>
                                        <th title="Currency used for payment" onClick={() => handleSort("currency")}>Currency <FaSort className="sortIcon" /></th>
                                        <th title="Payment method used for payment" onClick={() => handleSort("paymentGateway")}>Payment Method <FaSort className="sortIcon" /></th>
                                        <th title="Sale Type">Sale Type <FaSort className="sortIcon" /></th>
                                        <th title="Purchased date" onClick={() => handleSort("created")}>Created <FaSort className="sortIcon" /></th>
                                        <th title="Status">Status</th>
                                        <th title="Action">Action</th>
                                    </tr>
                                    {currentTodos.length > 0 ?
                                        currentTodos.map((curElem, index) => {
                                            console.log(curElem)
                                            return (
                                                <tr key={index}>
                                                    <td>{curElem.productSN}  </td>
                                                    <td style={{ textTransform: "capitalize" }}>{curElem.name}</td>
                                                    <td>{curElem.email} </td>
                                                    <td>{curElem.productName} </td>
                                                    <td>{curElem.priceAmount}</td>
                                                    <td>{curElem.priceCurrency} </td>
                                                    <td>{curElem.paymentMethod} </td>
                                                    <td>{curElem.is_status === "4" ? 'Complete' : 'Incomplete'}</td>
                                                    <td>{curElem.created} </td>
                                                    <td style={{ textTransform: "upperCase", fontWeight: "bold" }}>{curElem.status} </td>
                                                    <td>
                                                        {
                                                            curElem.paymentMode === "live" ?
                                                                curElem.is_status === "4" || curElem.is_status === "8" || curElem.is_status === "9" ?
                                                                    <button
                                                                        className="demoLink smBtn mt-0"
                                                                        style={curElem.is_status === "8" ? { background: "#2e2e2e" } : { background: "#A82F98" }}
                                                                        onClick={() => handleShow(curElem)}
                                                                        disabled={curElem.is_status === "8" || curElem.is_status === "9" ? true : false}
                                                                    >
                                                                        {curElem.is_status === "8" ? "Refunded" : curElem.is_status === "9" ? "Refunding" : "Refund"}
                                                                    </button> :
                                                                    '' : ''
                                                        }
                                                    </td>
                                                </tr>
                                            )
                                        }) :
                                        <td className="no-result report-loader-css py-5" colSpan="9"> {loader ?
                                            <i className="fa fa-spinner fa-spin" /> :
                                            <h5>No Results</h5>
                                        }</td>

                                    }
                                    <tr>
                                        <td className="expCv" colSpan="9">
                                            <a className="demoLink mt-0" href="">Export CSV File</a>
                                        </td>
                                    </tr>
                                </table>
                                <div className="tablepage mx-3">
                                    <ul>
                                        <li
                                            onClick={() => handleBackward(pagination.currentPage)}
                                            className="cursor-pointer"
                                        ><a><FaChevronLeft /></a></li>
                                        {pageNumber.length > 0 ?
                                            pageNumber.map((curElem, index) => {
                                                return (
                                                    <li
                                                        key={index}
                                                        id={curElem}
                                                        style={{ color: 'white' }}
                                                        onClick={() => handleClick(curElem)}
                                                        className="cursor-pointer"
                                                    >
                                                        <a style={curElem === pagination.currentPage ? { backgroundColor: 'white', color: '#400C46' } : {}}>{curElem}</a>
                                                    </li>
                                                )
                                            }) : ''}
                                        <li
                                            onClick={() => handleForward(pagination.currentPage)}
                                            className="cursor-pointer"
                                        ><a><FaChevronRight /></a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Modal className="VideoModal smModal" show={show} onHide={handleClose} centered>
                <Modal.Body>
                    <div onClick={handleClose} className="vidClose"><img src={modalCloseIcon} /></div>
                    <form className="modalForm" onSubmit={handleSubmit}>
                        <h2 className='text-capitalize'>{refundOnData.paymentMethod} Refund</h2>
                        {refundOnData.paymentMethod === "CoinGate" ?
                            <>
                                <div className="form-group form-group-alt">
                                    <label htmlFor=''>Enter Currency</label>
                                    <select
                                        className="form-control"
                                        placeholder="Currency"
                                        type="text"
                                        name="currencyId"
                                        value={submitObj.currencyId}
                                        onChange={(e) => onChange(e)}
                                        required
                                    >
                                        <option value={""}>Select Currency</option>
                                        {curr.currencyData.length > 0 ?
                                            curr.currencyData.map((curElem, index) => {
                                                return (
                                                    <option value={curElem.id} key={index}>{curElem.symbol}-{curElem.title}</option>
                                                )
                                            }) : ''}
                                    </select>
                                </div>
                                <div className="form-group form-group-alt">
                                    <label htmlFor=''>Enter Plateform</label>
                                    <select
                                        className="form-control"
                                        placeholder="Plateform"
                                        type="text"
                                        name="platformId"
                                        value={submitObj.platformId}
                                        onChange={(e) => onChange(e)}
                                        required
                                    >
                                        <option value={""}>Select Plateform</option>
                                        {curr.plateform.length > 0 ?
                                            curr.plateform.map((curElem, index) => {
                                                return (
                                                    <option value={curElem.id} key={index}>{curElem.title}</option>
                                                )
                                            }) : ''}
                                    </select>
                                </div>
                                <div className="form-group form-group-alt">
                                    <label htmlFor=''>Enter Ledger Account ID</label>
                                    <input
                                        className="form-control"
                                        placeholder="Ledger Account ID"
                                        type="text"
                                        name="ledgerAccountId"
                                        value={submitObj.ledgerAccountId}
                                        onChange={(e) => onChange(e)}
                                        required
                                    />
                                </div>
                            </>
                            : ''}
                        <div className="form-group form-group-alt">
                            <label htmlFor=''>Enter Your Reason</label>
                            <textarea
                                className="form-control"
                                placeholder="Enter your reason"
                                type="text"
                                name="reason"
                                value={submitObj.reason}
                                onChange={(e) => onChange(e)}
                                required
                            />
                        </div>
                        <div className="inp-wrap">
                            <button type='submit' className='btn btn-primary inpBtn'> Submit {loader ? <i className="fa fa-spinner fa-spin" /> : ''}</button>
                        </div>
                    </form>
                </Modal.Body>

            </Modal>


            <Footer />
        </>
    )
}

export default CustomerReport;