import { baseURL } from "../global/global"
import axios from "axios"
import { setAlert } from "./alert"
import { getElementsAtEvent } from "react-chartjs-2"


export const addProduct = (val) => (dispatch) => {
    dispatch({ type: 'ADD_PRODUCT', payload: val })
}

export const setData = (name, value) => (dispatch) => {
    if (name === "productName") {
        dispatch({ type: "PRODUCT_NAME", payload: value })
    }
    else if (name === "skuNumber") {
        dispatch({ type: "SKU_NUMBER", payload: value })
    }
    else if (name === "productUrl") {
        dispatch({ type: "PRODUCT_URL", payload: value })
    }
    else if (name === "productDescription") {
        dispatch({ type: "PRODUCT_DESCRIPTION", payload: value })
    }
    else if (name === "paymentType") {
        dispatch({ type: "PRODUCT_PAYMENT_TYPE", payload: value })
    }
    else if (name === "price") {
        dispatch({ type: "PRODUCT_PRICE", payload: value })
    }
    else if (name === "productImage") {
        dispatch({ type: "PRODUCT_IMAGE", payload: value })
    }
    else if (name === "orderBumpImage") {
        dispatch({ type: "ORDERBUMP_IMAGE", payload: value })
    }
    else if (name === "productType") {
        dispatch({ type: "PRODUCT_TYPE", payload: value })
    }
    else if (name === "thankyouPageUrl") {
        dispatch({ type: "THANKYOU_URL", payload: value })
    }
    else if (name === "ipnStatus") {
        dispatch({ type: "IPN_STATUS", payload: value })
    }
    else if (name === "ipnUrl") {
        dispatch({ type: "IPN_URL", payload: value })
    }
    else if (name === "orderBumpsEnable") {
        dispatch({ type: "ORDERBUMP_ENABLE", payload: value })
    }
    else if (name === "orderbump_title") {
        dispatch({ type: "ORDERBUMP_TITLE", payload: value })
    }
    else if (name === "orderbump_description") {
        dispatch({ type: "ORDERBUMP_DESC", payload: value })
    }
    else if (name === "orderbump_product") {
        dispatch({ type: "ORDERBUMP_PRODUCT", payload: value })
    }
    else if (name === "seo_title") {
        dispatch({ type: "SEO_TITLE", payload: value })
    }
    else if (name === "seo_description") {
        dispatch({ type: "SEO_DESCRIPTION", payload: value })
    }
    else if (name === "seo_image") {
        dispatch({ type: "SEO_IMAGE", payload: value })
    }
    else if (name === "seo_meta_tags") {
        dispatch({ type: "SEO_METATAG", payload: value })
    }
    else if (name === "headerScript") {
        dispatch({ type: "SETTING_HEADER_SCRIPT", payload: value })
    }
    else if (name === "footerScript") {
        dispatch({ type: "SETTING_FOOTER_SCRIPT", payload: value })
    }
    else if (name === "firePixel") {
        dispatch({ type: "SETTING_FIRE_PIXEL", payload: value })
    }
    else if (name === "checkoutDataEnable") {
        dispatch({ type: "CLOSE_CHECKOUT_ENABLE", payload: value })
    }
    else if (name === "enterNumber") {
        dispatch({ type: "CLOSE_CHECKOUT_NUMBER", payload: value })
    }
    else if (name === "closeCheckoutDate") {
        dispatch({ type: "CLOSE_CHECKOUT_DATE", payload: value })
    }
    else if (name === "applyCoupon") {
        dispatch({ type: "APPLY_COUPON", payload: value })
    }
    else if (name === "paymentMode") {
        dispatch({ type: "PAYMENT_MODE", payload: value })
    }
    else if (name === "footerText") {
        dispatch({ type: "FOOTER_TEXT", payload: value })
    }
}

export const setOldSeo = (data) => (dispatch) => {
    dispatch({ type: "SET_OLD_SEO", payload: data })
}
export const setOldSetting = (data) => (dispatch) => {
    dispatch({ type: "SET_OLD_SETTING", payload: data })
}
export const setOldCheckOut = (data) => (dispatch) => {
    dispatch({ type: "SET_OLD_CHECKOUT", payload: data })
}

export const addShipping = (data) => (dispatch) => {
    dispatch({ type: "ADD_LOCATION_OBJ", payload: data })
}

export const updateShipping = (name, value, index) => (dispatch) => {
    const data = { value, index }
    if (name === "country") {
        dispatch({ type: "UPDATE_SHIPPING_LOCATION", payload: data })
    }
    else if (name === "shippingCost") {
        dispatch({ type: "UPDATE_SHIPPING_COST", payload: data })
    }

}
export const deleteShipping = (index) => (dispatch) => {
    dispatch({ type: "DELETE_SHIPPING", payload: index })
}

export const setPaymentArr = (arr) => (dispatch) => {
    dispatch({ type: "ADD_PAYMENT_ARR", payload: arr })
}

export const updatePayment = (name, index, val) => (dispatch) => {
    const data = { index, val }
    if (name === "enable") {
        dispatch({ type: "UPDATE_PAYMENT_ENABLE", payload: data })
    }
    else {
        dispatch({ type: "UPDATE_PAYMENT_CURRENCY", payload: data })
    }

}

export const setTemplateCheck = (val, type) => (dispatch) => {
    const data = { val, type }
    dispatch({ type: "UPDATE_TEMPLATE_ID", payload: data })
}

export const addAutoResponders = (data) => (dispatch) => {
    dispatch({ type: "ADD_AUTORESPONDER_OBJ", payload: data })
}

export const setEnableAutoRes = (val, index) => (dispatch) => {
    const data = { val, index }
    dispatch({ type: "SET_ENABLE_AUTO", payload: data })
}
export const setListAutoRes = (val, index) => (dispatch) => {
    const data = { val, index }
    dispatch({ type: "SET_LIST_AUTO", payload: data })
}
export const handleProductSave = (product, showAlert = true, setLoader) => (dispatch, getState) => {

    if (product) {
        if (product.price === "0") {
            dispatch(setAlert("Price can't be 0", "danger"))
        } else {
            if (setLoader) {
                setLoader(true)
            }
            axios({
                method: 'POST',
                url: `${baseURL}product-saved`,
                data: product,
                headers: { 'Content-Type': 'application/json', 'Authorization': getState().auth.token }
            }).then((res) => {
                if (res.data.status) {
                    if (showAlert) {
                        dispatch(setAlert(res.data.message, "success"))
                    }
                }
                else {
                    dispatch(setAlert(res.data.message, "danger"))
                }
                if (setLoader) {
                    setLoader(false)
                }
            }).catch((error) => {
                setLoader(false)
                console.log(error)
            })
        }
    }
}


