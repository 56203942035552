import React, { useEffect, useState } from "react";
import { AiOutlineEye, AiOutlineCheck } from 'react-icons/ai';
import temp1 from '../../images/custome-temp.png';
import InfiniteScroll from "react-infinite-scroll-component";
import axios from "axios";
import { baseURL } from "../../global/global";
import { useDispatch, useSelector } from "react-redux";
import { handleProductSave, setTemplateCheck } from "../../actions/productAction";
import { Link } from "react-router-dom";
import { setAlert } from "../../actions/alert";

const Templates = ({ productData, id }) => {
    const auth = useSelector(state => state.auth)
    const dispatch = useDispatch()
    const [membership, setMembership] = useState([]);
    const [templates, setTemplates] = useState({
        inBuilt: [],
        inbuiltLength: 0,
        dummyArr: []
    })
    const [currentTemp, setCurrentTemp] = useState({
        type: "inbuilt",
        templateId: 1,
        templateImage: '',
        templatePreview: "",
        templateName: ''
    })

    const [savedTemp, setSavedTemp] = useState([])
    const [templateId, setTemplateId] = useState(-1)
    const [templateType, setTemplateType] = useState("inbuilt")


    useEffect(() => {
        if (productData) {
            let data
            let val = templates.inBuilt.find(({ id }) => id === productData.templateId)
            let savedData = savedTemp.find(({ id }) => id === productData.templateId)

            if (val && productData.templateType === "inbuilt") {
                data = val
            } else if (savedData && productData.templateType === "saved") {
                data = savedData

            } else {
                data = {
                    previewImage: temp1,
                    title: "Custom Template",
                    type: "custome"
                }
            }

            if (data) {
                setCurrentTemp({
                    ...currentTemp,
                    type: productData.templateType,
                    templateId: productData.templateId,
                    templateImage: data.previewImage,
                    templatePreview: data.perviewUrl,
                    templateName: data.title
                })
            }
            setTemplateId(productData.templateId)
            setTemplateType(productData.templateType)
        }
    }, [productData])

    useEffect(() => {
        if (auth.user) {
            if (auth.user.membership !== '' && auth.user.membership !== null) {
                setMembership(auth.user.membership.split('__'))
            }
        }
    }, [auth])

    const fetchSaved = () => {
        axios({
            method: "POST",
            url: `${baseURL}saved-template-list`,
            data: {},
            headers: { 'Content-Type': 'application/json', 'Authorization': auth.token }
        }).then((res) => {
            if (res.data.status === true) {
                setSavedTemp(res.data.data.reverse())
            }
        }).catch((error) => {
            console.log(error)
        })
    }

    const fetchCustomeTemp = () => {
        axios({
            method: "POST",
            url: `${baseURL}product-template-get`,
            data: { "productId": id },
            headers: { 'Content-Type': 'application/json', 'Authorization': auth.token }
        }).then((res) => {
            if (res.data.status === true) {
                let val = res.data.data
                dispatch(setTemplateCheck(val[0].id, "custom"))
                // handleCheck(val[0].id, "custom")
            }
        }).catch((error) => {
            console.log(error)
        })
    }
    const fetchProductTemp = () => {
        axios({
            method: "POST",
            url: `${baseURL}product-template-list`,
            data: {},
            headers: { 'Content-Type': 'application/json', 'Authorization': auth.token }
        }).then((res) => {
            if (res.data.status === true) {
                let data = [...res.data.data]
                setTemplates({
                    ...templates,
                    inBuilt: res.data.data,
                    inbuiltLength: res.data.data.length,
                    dummyArr: data.splice(0, 10)
                })
            }
        }).catch((error) => {
            console.log(error)
        })

    }
    useEffect(() => {
        fetchProductTemp()
        fetchSaved()
        if (productData.templateType === "custom") {
            fetchCustomeTemp()
        }
    }, [])


    const handlePreview = (url) => {
        window.open(url, "_blank")
    }


    const fetchMore = () => {
        let temp = templates.dummyArr.length
        if (temp < templates.inbuiltLength) {
            let data = [...templates.inBuilt]
            let length = templates.inbuiltLength
            if (membership.length > 0) {
                if (membership[membership.length - 1] === "commercial") {
                    data = data.slice(0, 20)
                    length = 20
                }
            }
            setTimeout(() => {
                setTemplates({
                    ...templates,
                    dummyArr: templates.dummyArr.concat(data.splice(temp, 10)),
                    inbuiltLength: length
                })
            }, 1000)
        }
    }

    const handleCheck = (id, type) => {
        dispatch(setTemplateCheck(id, type))
        dispatch(setAlert('Template selected successfully', 'success'))
        const newProduct = { ...productData }
        if (id && type) {
            newProduct["templateId"] = id
            newProduct["templateType"] = type
        }
        dispatch(handleProductSave(newProduct))
    }


    return (
        <>
            <div className="templateList">

                <div className="row">

                    <div className="col-md-6 col-sm-12 col-12">
                        <div className="temp-head mt-2 mb-2">
                            <h2 className="pageHeading withLine">Current Template</h2>
                        </div>
                        <div className="templateList-single text-center" style={{ width: '270px' }}>
                            <img className="templateImg" src={currentTemp.templateImage} />
                            <div className="templateList-hov">
                                <div className="activeTheme">
                                    {templateId === currentTemp.templateId && templateType === currentTemp.type ?
                                        <>
                                            <Link to={`/template-customize?id=${currentTemp.templateId}&productId=${id}&type=${currentTemp.type}&tempCustype=customized`} target="_blank"><span>Customize</span></Link>
                                            <span className="actColor">Active</span>
                                        </> : ''}
                                </div>
                                <h6>{currentTemp.templateName}</h6>
                                <div className="themeView">
                                    <span>
                                        {currentTemp.type === "custom" ? "" :
                                            <AiOutlineEye
                                                onClick={() => handlePreview(currentTemp.templatePreview)}
                                                className="cursor-pointer"
                                            />}
                                    </span>
                                    <span><AiOutlineCheck
                                        className="cursor-pointer"
                                        onClick={() => handleCheck(currentTemp.templateId, currentTemp.type)}
                                    /></span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-sm-12 col-12">
                        <div className="temp-head mt-2 mb-2">
                            <h2 className="pageHeading withLine">Create New Template</h2>
                        </div>
                        <div className="templateList-single text-center" style={{ width: '270px' }}>
                            <img className="templateImg" src={temp1} />
                            <div className="templateList-hov">
                                <div className="activeThemes mx-auto">
                                    <Link to={`/template-customize?id=${1}&productId=${id}&type=custom&tempCustype=blank`} target="_blank"><span>Customize</span></Link>
                                </div>

                            </div>
                        </div>
                    </div>

                </div>

                <h2 className="pageHeading withLine mt-5 pt-3">Your Saved Templates</h2>
                <div className="row">
                    {savedTemp.length > 0 ?
                        savedTemp.map((curElem, index) => {
                            return (
                                <div className="col-lg-3 col-md-6" key={index}>
                                    <div className="templateList-single">
                                        <img className="templateImg" src={curElem.previewImage} />
                                        <div className="templateList-hov">
                                            <div className="activeTheme">
                                                {templateId !== -1 ?
                                                    templateId === curElem.id && templateType === "saved" ?
                                                        <>
                                                            <Link to={`/template-customize?id=${curElem.id}&productId=${id}&type=saved&tempCustype=customized`} target="_blank"><span>Customize</span></Link>
                                                            <span className="actColor">Active</span>
                                                        </> : '' : ''}
                                            </div>
                                            <h6>{curElem.title}</h6>
                                            <div className="themeView">
                                                <span><AiOutlineEye
                                                    onClick={() => handlePreview(curElem.perviewUrl)}
                                                    className="cursor-pointer"
                                                /></span>
                                                <span><AiOutlineCheck
                                                    className="cursor-pointer"
                                                    onClick={() => handleCheck(curElem.id, "saved")}
                                                /></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                        :
                        <div className="col-md-12 text-center my-3" >
                            <h5>You don't have any saved templates</h5>
                        </div>
                    }
                </div>

                <h2 className="pageHeading withLine mt-5 pt-3">In-built Templates</h2>
                <div className="row">
                    <div id="scrollableTemp">
                        <InfiniteScroll
                            dataLength={templates.dummyArr.length}
                            next={() => fetchMore(0)}
                            hasMore={true}
                            height={600}
                            scrollableTarget="scrollableTemp"
                            loader={
                                templates.dummyArr.length !== templates.inbuiltLength ?
                                    <div className="infinite-loader col-md-12 text-center">
                                        <i className="fa fa-spinner fa-spin" />
                                    </div> :
                                    <div className="infinite-loader col-md-12 text-center">
                                        {/* <p style={{ fontSize: 18 }} > No Data Left</p> */}
                                    </div>}
                            className="infinitescroll"
                        >
                            {templates.dummyArr.length > 0 ?
                                templates.dummyArr.map((curElem, index) => {

                                    return (
                                        <div className="col-lg-3 col-md-6" key={index}>
                                            <div className="templateList-single">
                                                <img className="templateImg" src={curElem.previewImage} />
                                                <div className="templateList-hov">
                                                    <div className="activeTheme">
                                                        {templateId !== -1 ?
                                                            templateId === curElem.id && templateType === "inbuilt" ?
                                                                <>
                                                                    <Link to={`/template-customize?id=${curElem.id}&productId=${id}&type=inbuilt&tempCustype=customized`} target="_blank"><span>Customize</span></Link>
                                                                    <span className="actColor">Active</span>
                                                                </> : '' : ''
                                                        }
                                                    </div>
                                                    <h6>{curElem.title}</h6>
                                                    <div className="themeView">
                                                        <span>
                                                            <AiOutlineEye
                                                                onClick={() => handlePreview(curElem.perviewUrl)}
                                                                className="cursor-pointer"
                                                            />
                                                        </span>
                                                        <span><AiOutlineCheck
                                                            onClick={() => handleCheck(curElem.id, "inbuilt")}
                                                            className="cursor-pointer"
                                                        /></span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                                : ''}
                        </InfiniteScroll>
                    </div>
                </div>
            </div >
        </>
    )
}

export default Templates;