import React from "react";
import { useEffect } from "react";
import Navbar from "../Navbar";
import HelpContent from "./HelpContent";
import HelpVideos from "./HelpVideos";
import Footer from "../Footer";
import CustomerSupport from "./CustomerSupport";
import TitleBar from "../TitleBar";

const HelpSupport = () => {

    const loadArticles = [
        {
            id: "1",
            name: "Business Setup",
            url: "https://reeelapps-app.s3.us-west-2.amazonaws.com/Koinkart/tutorial_pdf/Business+Setup.pdf",
            tags: "business setup, store setup, currency setup"
        },
        {
            id: "2",
            url: "https://reeelapps-app.s3.us-west-2.amazonaws.com/Koinkart/tutorial_pdf/Koincart+-+Client+Accounts.pdf",
            name: "Client Account",
            tags: "client account"
        },
        {
            id: "3",
            url: "https://reeelapps-app.s3.us-west-2.amazonaws.com/Koinkart/tutorial_pdf/Koincart+-+Team+Member.pdf",
            name: "Team Member",
            tags: "team member, virtual account"
        },
        {
            id: "4",
            url: "https://reeelapps-app.s3.us-west-2.amazonaws.com/Koinkart/tutorial_pdf/Koincart+-+Privacy.pdf",
            name: "Privacy",
            tags: "gdpr, delete your account, download account data, privacy"
        },
        {
            id: "5",
            url: "https://reeelapps-app.s3.us-west-2.amazonaws.com/Koinkart/tutorial_pdf/Koincart+-+Products.pdf",
            name: "Products",
            tags: "create product, view product, delete product, products"
        },
        {
            id: "6",
            url: "https://reeelapps-app.s3.us-west-2.amazonaws.com/Koinkart/tutorial_pdf/Koincart+-+Reports.pdf",
            name: "Reports",
            tags: "view report, filter report, sales report, incomplete sales, reports"
        },
        {
            id: "7",
            url: "https://reeelapps-app.s3.us-west-2.amazonaws.com/Koinkart/tutorial_pdf/Koincart+-+Manage+your+account.pdf",
            name: "Manage Your Account",
            tags: "change password, manage account"
        },
        {
            id: "8",
            url: "https://reeelapps-app.s3.us-west-2.amazonaws.com/Koinkart/tutorial_pdf/Koincart+-+Managing+your+Profile+Info.pdf",
            name: "Manage Profile Info",
            tags: "manage profile, profile pic, change profile"
        },
        {
            id: "9",
            url: "https://reeelapps-app.s3.us-west-2.amazonaws.com/Koinkart/tutorial_pdf/Koincart+-+Integrations.pdf",
            name: "Integration ",
            tags: "payment integration, auto responder integration, zapier integration"
        },
        {
            id: "10",
            url: "https://reeelapps-app.s3.us-west-2.amazonaws.com/Koinkart/tutorial_pdf/Payment+Integrations.pdf",
            name: "Payment Integration",
            tags: "bitpay, coingate,crypto.com, confirmo, coinremitter, nowpayment, api key"
        },

    ]
    

   
    return (
        <>
            <TitleBar title="Help & Support" />
            <Navbar />
            <HelpContent loadArticles={loadArticles} />
            <HelpVideos />
            <CustomerSupport />

            <Footer />
        </>
    )
}

export default HelpSupport;