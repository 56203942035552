import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { setAlert } from '../../actions/alert';
import { fetchAutoResponder } from '../../actions/paymentAction';
import { baseURL } from '../../global/global';
import modalCloseIcon from '../../images/modal-close.png';
import Alert from '../Alert';

const AutoResponseModel = ({ show, handleClose, type, name }) => {

    const dispatch = useDispatch()
    const auth = useSelector(state => state.auth)
    const [loader, setLoader] = useState(false)

    const [data, setData] = useState({
        name: '',
        apiKey: '',
        hashKey: '',
        accountName: '',
        accountEmail: '',
        secret: ''
    })


    useEffect(() => {
        setData({
            ...data,
            name: '',
            apiKey: '',
            hashKey: '',
            accountName: '',
            accountEmail: '',
            secret: ''
        })
    }, [show]);

    const onChange = (e) => {
        const { name, value } = e.target
        setData({
            ...data,
            [name]: value
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        setLoader(true)

        let val

        if (type === "sg") {
            val = {
                type: 'sg',
                name: data.name,
                apiKey: data.apiKey,
            }
        } else if (type === "sl") {
            val = {
                type: "sl",
                send_lane_api_key: data.apiKey,
                send_lane_hash_key: data.hashKey,
                name: data.accountName,
                email: data.accountEmail
            }
        }
        else if (type === "si") {
            val = {
                type: "si",
                name: data.name,
                secret: data.secret,
                token: data.apiKey
            }
        }
        else if (type === "mv") {
            val = {
                type: "mv",
                name: data.name,
                token: data.apiKey
            }
        }


        axios({
            method: 'POST',
            url: `${baseURL}autoresponder-auth-url`,
            data: val,
            headers: { 'Content-Type': 'application/json', 'Authorization': auth.token }
        }).then((res) => {
            if (res.data.status === true) {
                dispatch(setAlert(res.data.message, "success"))
                dispatch(fetchAutoResponder(auth.token))

            } else {
                dispatch(setAlert(res.data.message, "danger"))
            }
            handleClose()
            setLoader(false)
        }).catch((error) => {
            setLoader(false)
            console.log(error)
        })
    }

    return (
        <>
            <Alert />
            <Modal className="VideoModal smModal" show={show} centered>
                <Modal.Body>
                    <div onClick={handleClose} className="vidClose"><img src={modalCloseIcon} alt="" /></div>
                    <form className="modalForm" onSubmit={handleSubmit}>
                        <h2 className='text-capitalize'>{name} Integration</h2>
                        {type === "sg" ?
                            <>
                                <div className="form-group form-group-alt">
                                    <label htmlFor=''>Name</label>
                                    <input
                                        className="form-control"
                                        placeholder="Name"
                                        type="text"
                                        name="name"
                                        value={data.name}
                                        onChange={(e) => onChange(e)}
                                        required
                                    ></input>
                                </div>
                                <div className="form-group form-group-alt">
                                    <label htmlFor=''>API Key</label>
                                    <input
                                        className="form-control"
                                        placeholder="API Key"
                                        type="text"
                                        name="apiKey"
                                        value={data.apiKey}
                                        onChange={(e) => onChange(e)}
                                        required
                                    ></input>
                                </div>
                            </>
                            : ""}
                        {type === "sl" ?
                            <>

                                <div className="form-group form-group-alt">
                                    <label htmlFor=''>Account Name</label>
                                    <input
                                        className="form-control"
                                        placeholder="Account Name"
                                        type="text"
                                        name="accountName"
                                        value={data.accountName}
                                        onChange={(e) => onChange(e)}
                                        required
                                    ></input>
                                </div>
                                <div className="form-group form-group-alt my-2">
                                    <label htmlFor=''>Account Email</label>
                                    <input
                                        className="form-control"
                                        placeholder="Account Email"
                                        type="email"
                                        name="accountEmail"
                                        value={data.accountEmail}
                                        onChange={(e) => onChange(e)}
                                        required
                                    ></input>
                                </div>
                                <div className="form-group form-group-alt my-2">
                                    <label htmlFor=''>API Key</label>
                                    <input
                                        className="form-control"
                                        placeholder="API Key"
                                        type="text"
                                        name="apiKey"
                                        value={data.apiKey}
                                        onChange={(e) => onChange(e)}
                                        required
                                    ></input>
                                </div>

                                <div className="form-group form-group-alt my-2">
                                    <label htmlFor=''>Hash Key</label>
                                    <input
                                        className="form-control"
                                        placeholder="Hash Key"
                                        type="text"
                                        name="hashKey"
                                        value={data.hashKey}
                                        onChange={(e) => onChange(e)}
                                        required
                                    ></input>
                                </div>
                            </> : ''
                        }

                        {type === "si" ?
                            <>
                                <div className="form-group form-group-alt">
                                    <label htmlFor=''>Name</label>
                                    <input
                                        className="form-control"
                                        placeholder="Name"
                                        type="text"
                                        name="name"
                                        value={data.name}
                                        onChange={(e) => onChange(e)}
                                        required
                                    ></input>
                                </div>
                                <div className="form-group form-group-alt">
                                    <label htmlFor=''>Token</label>
                                    <input
                                        className="form-control"
                                        placeholder="Token"
                                        type="text"
                                        name="apiKey"
                                        value={data.apiKey}
                                        onChange={(e) => onChange(e)}
                                        required
                                    ></input>
                                </div>
                                <div className="form-group form-group-alt">
                                    <label htmlFor=''>Secret Key</label>
                                    <input
                                        className="form-control"
                                        placeholder="Secret Key"
                                        type="text"
                                        name="secret"
                                        value={data.secret}
                                        onChange={(e) => onChange(e)}
                                        required
                                    ></input>
                                </div>
                            </>
                            : ''}

                        {type === "mv" ?
                            <>
                                <div className="form-group form-group-alt">
                                    <label htmlFor=''>Name</label>
                                    <input
                                        className="form-control"
                                        placeholder="Name"
                                        type="text"
                                        name="name"
                                        value={data.name}
                                        onChange={(e) => onChange(e)}
                                        required
                                    ></input>
                                </div>
                                <div className="form-group form-group-alt">
                                    <label htmlFor=''>Token</label>
                                    <input
                                        className="form-control"
                                        placeholder="Token"
                                        type="text"
                                        name="apiKey"
                                        value={data.apiKey}
                                        onChange={(e) => onChange(e)}
                                        required
                                    ></input>
                                </div>
                            </>

                            : ''}

                        <div className="inp-wrap">
                            <button type='submit' className='btn btn-primary inpBtn'> Submit {loader ? <i className="fa fa-spinner fa-spin" /> : ''}</button>
                            {/* <input className="btn btn-primary inpBtn" type="submit" value="Submit" /> */}
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default AutoResponseModel;
