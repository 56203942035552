import { combineReducers } from "redux";
import authReducer from "./authReducer";
import alertReducer from "./alertReducer";
import supportReducer from "./supportReducer";
import rebrandReducer from "./rebrandReducer";
import paymentReducer from "./paymentReducer";
import productReducer from "./productReducer";
import baseReducer from "./baseReducer";

const appReducer = combineReducers({
    alert: alertReducer,
    auth: authReducer,
    support: supportReducer,
    rebrand: rebrandReducer,
    payment: paymentReducer,
    product: productReducer,
    base : baseReducer
})

const rootReducers = (state, action) => {
    if (action.type === 'LOGOUT') {
        localStorage.clear();
        state = undefined
    }
    return appReducer(state, action)
}

export default rootReducers;