import React, { useState, useEffect } from "react";
import Navbar from "../Navbar";
import Footer from "../Footer";
import TitleBar from "../TitleBar";
import axios from "axios";
import { baseURL } from "../../global/global";
import { useDispatch, useSelector } from "react-redux";
import { setAlert } from "../../actions/alert";
import { Link, useHistory } from "react-router-dom";
import { RiErrorWarningLine } from "react-icons/ri"
import Alert from "../Alert";
import { AiOutlineConsoleSql } from "react-icons/ai";

const CreateProduct = () => {

    const auth = useSelector(state => state.auth)
    const symbol = useSelector(state => state.base.storeData)
    const dispatch = useDispatch()
    const history = useHistory()

    const [product, setProduct] = useState({
        productName: '',
        productDescription: '',
        paymentType: 1,
        price: ""
    })
    const [loader, setLoader] = useState(false)
    const [status, setStatus] = useState(false)

    const handleChange = (e) => {
        const { name, value } = e.target
        let val = value

        // ...................................write code down by ankit .......
        if (name === "productName") {
            if (val.length > 200 || val.length === 200) {
                dispatch(setAlert("Not allow more than 200 characters", "danger"))
            }
        }
        // ...................................write code above by ankit .......

        // ...................................write code down by ankit .......
        if (name === "productDescription") {
            if (val.length > 1000 || val.length === 1000) {
                dispatch(setAlert("Not allow more than 1000 characters", "danger"))
            }
        }
        // ...................................write code above by ankit .......

        if (name === "price") {
            if (value >= 0) {
                setProduct({
                    ...product,
                    [name]: value
                })
            }
            else {
                setProduct({
                    ...product,
                    [name]: ""
                })
                dispatch(setAlert("Price should be grater than 0", "warning"))
            }
        }
        else {
            setProduct({
                ...product,
                [name]: value
            })
        }

    }


    const handleSubmit = (e) => {
        e.preventDefault()
        setLoader(true)
        if (product.price === "0") {
            dispatch(setAlert("Price can not be 0", "danger"))
            setLoader(false)
        }
        else {
            axios({
                method: 'POST',
                url: `${baseURL}product-create`,
                data: product,
                headers: { 'Content-Type': 'application/json', 'Authorization': auth.token }
            }).then((res) => {
                if (res.data.status === true) {
                    history.push(`/edit-product?id=${res.data.data.campaignId}`)
                }
                else {
                    dispatch(setAlert(res.data.message, "danger"))
                    setLoader(false)
                }
            }).catch((error) => {
                console.log(error)
                setLoader(false)
            })
        }
    }

    useEffect(() => {
        axios({
            method: 'POST',
            url: `${baseURL}store-get`,
            data: {},
            headers: { 'Content-Type': 'application/json', 'Authorization': auth.token }
        }).then((res) => {
            if (res.data.status === true) {
                if (res.data.data.length === 0) {

                    setStatus(true)
                }
                else {
                    setStatus(false)
                }
            } else {
                dispatch(setAlert(res.data.message, "danger"))
            }
        }).catch((error) => {
            console.log(error)
        })
    }, [])


    return (
        <>
            <TitleBar title="Create Product" />
            <Navbar />
            <Alert />

            <section className="siteWrap">
                <div className="pageWrap alt">
                    <div className="pageBread">
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-lg-9">
                                    <ul>
                                        <li><a href="">Dashboard</a></li>
                                        <li><a href="">Create Product</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <form className="container" onSubmit={handleSubmit}>
                        <div className="row justify-content-center">
                            <div className="col-lg-9">
                                <div className="pageTitle">
                                    <h2>Create New Product</h2>
                                </div>
                                {status ?
                                    <div class="alert alert-warning" role="alert">
                                        <RiErrorWarningLine size={20} style={{ marginBottom: '3px' }} />  Please Select Your Base Currency and Store Name to create products
                                        <span className="mx-1">
                                            <Link to="/profile" >Click Here</Link>
                                        </span>
                                    </div>
                                    : ''}

                                <div className="proForm">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <span className="infoTxt alt mt-0 pt-0">Give your product a name.</span>
                                            <div className="form-group form-group-alt mt-0">
                                                <label>Product Name</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name="productName"
                                                    placeholder="Your Product Name"
                                                    value={product.productName}
                                                    // maxLength={15}
                                                    onChange={(e) => handleChange(e)}
                                                    maxLength={200}
                                                    required
                                                />
                                            </div>
                                            {/* <span className="infoTxt alt mt-0 pt-0 text-right">{product.productName.length}/15</span> */}

                                        </div>
                                    </div>

                                    <div>
                                        <span className="infoTxt alt">Give your product a Description.</span>
                                        <div className="form-group form-group-alt mt-0">
                                            <label>Product Description</label>

                                            <textarea
                                                type="text"
                                                className="form-control"
                                                name="productDescription"
                                                placeholder="Your Product Description"
                                                value={product.productDescription}
                                                onChange={(e) => handleChange(e)}
                                                required
                                                maxLength={1000}
                                            />
                                        </div>
                                        {/* <span className="infoTxt alt mt-0 pt-0 text-right">{product.productDescription.length}/1000</span> */}

                                    </div>

                                    <div className="row">
                                        <div className="col-lg-12">
                                            <span className="infoTxt alt">Select a Payment Type for your product.</span>
                                            <div className="form-group form-group-alt mt-0">
                                                <label>Payment Type</label>
                                                <select
                                                    className="form-control cursor-pointer"
                                                    name="paymentType"
                                                    defaultValue={product.paymentType}
                                                    onChange={(e) => handleChange(e)}
                                                    required
                                                >
                                                    <option value={1}>{'Fixed Payment'}</option>
                                                    <option value={2}>{'Pay As You Want'}</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <span className="infoTxt alt"> {product.paymentType === "2" ? 'Minimum' : ''} Price (should be grater than 0)</span>
                                            <div className="form-group form-group-alt mt-0">
                                                <label>{product.paymentType === "2" ? 'Minimum' : ''} Price  ({symbol ? symbol.symbol : ''})  </label>
                                                <input
                                                    type="number"
                                                    className="form-control"
                                                    placeholder="Enter Price"
                                                    name="price"
                                                    value={product.price}
                                                    onChange={(e) => handleChange(e)}
                                                    required
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group form-group-alt">
                                        <a>
                                            <button
                                                type="submit"
                                                className="demoLink large btn-block mt-0"
                                                disabled={status}
                                                style={status ? { background: '#cfc1cd' } : {}}
                                            >
                                                Create Product
                                                {loader ?
                                                    <i className="fa fa-spinner fa-spin mx-2" /> : ''}
                                            </button>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </section>

            <Footer />
        </>
    )
}

export default CreateProduct;