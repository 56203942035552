import './App.css';
import './responsive.css'
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Login from "./components/auth/Login";
import Dashboard from "./components/dashboard/Dashboard";
import { PrivateRoute } from "./components/PrivateRoute";
import ForgetPassword from "./components/auth/ForgetPassword";
import ResetPassword from "./components/auth/ResetPassword";
import Profile from "./components/user/Profile";
import Privacy from "./components/user/Privacy";
import AccountManagement from "./components/user/AccountManagement";
import Upgrades from "./components/user/Upgrades";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { loadUser } from "./actions/authAction";
import HelpSupport from "./components/support/HelpSupport";
import MasterLogin from './components/auth/MasterLogin';
import Integration from "./components/integration/Integration";
import Reseller from "./components/user/Reseller";
import CreateProduct from './components/products/CreateProduct';
import EditProduct from './components/products/EditProduct';
import { removeAlert } from './actions/alert';
import SalePage from './components/Public/SalePage';
import CheckoutPage from './components/Public/CheckoutPage';
import TemplateCustomize from './components/products/templateCustomize/TemplateCustomize';
import ProductList from './components/reports/ProductList';
import ProductsReport from './components/reports/ProductsReport';
import CustomerReport from './components/reports/CustomerReport';
import Business from './components/user/Business';


function App() {
    const alert = useSelector(state => state.alert)
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(loadUser())
    }, [])

    useEffect(() => {
        if (alert !== undefined) {
            if (alert.message !== "") {
                setTimeout(() => {
                    dispatch(removeAlert())
                }, 5000);
            }
        }
    }, [alert.message])

    return (
        <div className="App">
            <Router>
                <Switch>

                    <Route exact path="/" component={Login} />
                    <Route exact path="/login" component={Login} />
                    <Route exact path="/forget-password" component={ForgetPassword} />
                    <Route exact path="/reset-password" component={ResetPassword} />
                    <Route exact path="/master-login" component={MasterLogin} />
                    <Route exact path="/sale-page" component={SalePage} />
                    <Route exact path="/checkout-page" component={CheckoutPage} />

                    <PrivateRoute exact path="/dashboard" component={Dashboard} />
                    <PrivateRoute exact path="/profile" component={Profile} />
                    <PrivateRoute exact path="/privacy" component={Privacy} />
                    <PrivateRoute exact path="/account-management" component={AccountManagement} />
                    <PrivateRoute exact path="/reseller" component={Reseller} />
                    <PrivateRoute exact path="/upgrades" component={Upgrades} />
                    <PrivateRoute exact path="/help-and-support" component={HelpSupport} />
                    <PrivateRoute exact path="/integration" component={Integration} />
                    <PrivateRoute exact path="/product-list" component={ProductList} />
                    <PrivateRoute exact path="/customers-report" component={CustomerReport} />
                    <PrivateRoute exact path="/create-product" component={CreateProduct} />
                    <PrivateRoute exact path="/edit-product" component={EditProduct} />
                    <PrivateRoute exact path="/products-report" component={ProductsReport} />
                    <PrivateRoute exact path="/template-customize" component={TemplateCustomize} />
                    <PrivateRoute exact path="/business" component={Business} />
                    <Route path={"*"} component={Login} />
                </Switch>
            </Router>
        </div>
    );
}

export default App;
