import React, { useEffect, useState } from "react";
import ManageClient from "./ManageClient";
import ManageTeam from "./ManageTeam";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { baseURL } from "../../global/global";
import Alert from "../Alert";
import { setAlert } from "../../actions/alert";

const AddAccount = () => {

    const auth = useSelector(state => state.auth);
    const dispatch = useDispatch();

    const [clientData, setClientData] = useState([]);
    const [teamData, setTeamData] = useState([]);
    const [resellerData, setResellerData] = useState([]);

    const [loader, setLoader] = useState(false);
    const [userDetails, setUserDetails] = useState({
        type: 'team_member',
        email: '',
        name: '',
        password: ''
    });

    const [membership, setMembership] = useState([]);

    useEffect(() => {
        if (auth.user) {
            if (auth.user.membership !== '' && auth.user.membership !== null) {
                setMembership(auth.user.membership.split('__'))
            }
        }
    }, [auth])
    const onInputChange = (e) => {
        setUserDetails({ ...userDetails, [e.target.name]: e.target.value });
    }

    const fetchClientTeam = () => {
        axios({
            method: "POST",
            url: `${baseURL}fetch-clients`,
            data: {},
            headers: { 'Content-Type': 'application/json', 'Authorization': auth.token }
        }).then(res => {
            if (res.data.status === true) {
                setTeamData(res.data.data.team_members)
                setClientData(res.data.data.clients)
            }
        }).catch(error => {
            console.log(error)
        })
    }

    const addReseller = () => {
        axios({
            method: "POST",
            url: `${baseURL}reseller`,
            data: userDetails,
            headers: { 'Content-Type': 'application/json', 'Authorization': auth.token }
        }).then(res => {
            if (res.data.status === true) {
                dispatch(setAlert(res.data.message, 'success'));
                fetchReseller()
            } else {
                dispatch(setAlert(res.data.message, 'danger'));
            }
            setLoader(false);
        }).catch(error => {
            setLoader(false);
            console.log(error)
        })
    }

    const addClientTeam = () => {
        axios({
            method: "POST",
            url: `${baseURL}add-client`,
            data: userDetails,
            headers: { 'Content-Type': 'application/json', 'Authorization': auth.token }
        }).then(res => {
            if (res.data.status === true) {
                dispatch(setAlert(res.data.message, 'success'));
                fetchClientTeam()
            } else {
                dispatch(setAlert(res.data.message, 'danger'));
            }
            setLoader(false);
        }).catch(error => {
            setLoader(false);
        })
    }

    const fetchReseller = () => {
        axios({
            method: "POST",
            url: `${baseURL}fetch-reseller-accounts`,
            data: {},
            headers: { 'Content-Type': 'application/json', 'Authorization': auth.token }
        }).then(res => {
            if (res.data.status === true) {
                setResellerData(res.data.accounts)
            }
        }).catch(error => {
            console.log(error)
        })
    }

    const onFormSubmit = (e) => {
        e.preventDefault();
        setLoader(true);
        if (userDetails.type === 'reseller') {
            addReseller()
        }
        else {
            addClientTeam();
        }
    }

    useEffect(() => {
        fetchClientTeam();
        fetchReseller()
    }, []);


    return (
        <>
            <Alert />

            <div className="profileBlock-wrap">
                <h2 className="infoTitle">Create Account</h2>
                <div className="profileBlock mt-0">

                    <form className="" method="post" onSubmit={(e) => onFormSubmit(e)}>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group form-group-alt mt-0">
                                    <label className="form-text text-muted">Account Type</label>
                                    <select className="form-control" name="type" onChange={(e) => onInputChange(e)}>
                                        <option style={{ backgroundColor: "#fff" }} value="team_member">Team Member</option>
                                        <option style={{ backgroundColor: "#fff" }} value="client">Client Account</option>
                                        {membership[membership.length - 1] === "commercial" ? "" :
                                            <option style={{ backgroundColor: "#fff" }} value="reseller">Reseller Account</option>}
                                    </select>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group form-group-alt mt-0">
                                    <label className="form-text text-muted  ">Account Name</label>
                                    <input type="text" className="form-control"
                                        placeholder="Enter Account Name" required name="name"
                                        onChange={(e) => onInputChange(e)}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group form-group-alt">
                                    <label className="form-text text-muted">Account Email</label>
                                    <input type="email" className="form-control" placeholder="Enter Email"
                                        required name="email"
                                        onChange={(e) => onInputChange(e)}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group form-group-alt">
                                    <label className="form-text text-muted">Account Password</label>
                                    <input type="password" className="form-control"
                                        placeholder="********" required name="password"
                                        onChange={(e) => onInputChange(e)}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="form-group">
                            <button type="submit" className="fbtn btn-block demoLink" disabled={loader}>
                                {loader ? <i className="fa fa-spinner fa-spin mr-2" /> : ''} Create
                            </button>
                        </div>

                    </form>

                </div>

                <ManageClient users={clientData} fetchClientTeam={fetchClientTeam} type="Client" />
                <ManageTeam users={teamData} fetchClientTeam={fetchClientTeam} type="Team Member" />
                {
                    membership[membership.length - 1] === "commercial" ? "" :
                        <ManageTeam users={resellerData} fetchClientTeam={fetchReseller} type="Reseller" />
                }
            </div >

        </>
    )
}

export default AddAccount;