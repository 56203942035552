import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import mailChimp_logo from "../../images/mail-chimp.png";
import getResponse_logo from "../../images/get-response.png";
import awebar_logo from "../../images/AWeber.png";
import iContact_logo from "../../images/iContact.png";
import Sendgrid_logo from "../../images/Sendgrid.png";
import Sendlane_logo from "../../images/Sendlane.png";
import Sandiio_logo from "../../images/Sandiio_logo1.png";
import Mailvio_logo from "../../images/Mailvio_logo.png";
import { addAutoResponders, setEnableAutoRes, setListAutoRes } from '../../actions/productAction';
import { Link } from 'react-router-dom';
import { RiErrorWarningLine } from 'react-icons/ri';
import axios from 'axios';
import { baseURL } from '../../global/global';

const AutoResp = ({ autoResponders }) => {
    const dispatch = useDispatch()
    const auth = useSelector(state => state.auth)
    const payment = useSelector(state => state.payment.autoResponse)
    const [autoRes, setAutoRes] = useState({
        enable: false
    })
    const [listData, setListData] = useState({
        grList: [],
        awList: [],
        mcList: [],
        sgList: [],
        slList: [],
        siList: [],
        mvList: []
    })

    useEffect(() => {
        const findTrue = autoResponders.findIndex(({ enable }) => enable === true)
        if (findTrue !== -1)
            setAutoRes({
                ...autoRes,
                enable: true
            })
    }, [autoResponders])

    useEffect(() => {
        let getResponse = payment.getResponse
        let aweber = payment.aweber
        let mailChimp = payment.mailChimp
        let sendGrid = payment.sendGrid
        let sendLane = payment.sendLane
        let sendiio = payment.sendiio
        let mailvio = payment.mailvio

        let data, arr = []

        if (getResponse.length > 0) {
            getResponse.forEach((curElem) => {
                const checkingData = autoResponders.find(({ type }) => type === "gr")
                if (checkingData) {
                    data = {
                        enable: checkingData.enable,
                        list_id: checkingData.list_id,
                        auto_responder_id: checkingData.auto_responder_id,
                        type: checkingData.type
                    }
                } else {
                    data = {
                        enable: false,
                        list_id: "",
                        auto_responder_id: curElem.id,
                        type: "gr"
                    }
                }

                arr.push(data)
            })
        }
        if (aweber.length > 0) {
            aweber.forEach((curElem) => {
                const checkingData = autoResponders.find(({ type }) => type === "aw")
                if (checkingData) {
                    data = {
                        enable: checkingData.enable,
                        list_id: checkingData.list_id,
                        auto_responder_id: checkingData.auto_responder_id,
                        type: checkingData.type
                    }
                } else {
                    data = {
                        enable: false,
                        list_id: "",
                        auto_responder_id: curElem.id,
                        type: "aw"
                    }
                }
                arr.push(data)
            })
        }
        if (mailChimp.length > 0) {
            mailChimp.forEach((curElem) => {
                const checkingData = autoResponders.find(({ type }) => type === "mc")
                if (checkingData) {
                    data = {
                        enable: checkingData.enable,
                        list_id: checkingData.list_id,
                        auto_responder_id: checkingData.auto_responder_id,
                        type: checkingData.type
                    }
                } else {
                    data = {
                        enable: false,
                        list_id: "",
                        auto_responder_id: curElem.id,
                        type: "mc"
                    }
                }
                arr.push(data)
            })
        }
        if (sendGrid.length > 0) {
            sendGrid.forEach((curElem) => {
                const checkingData = autoResponders.find(({ type }) => type === "sg")
                if (checkingData) {
                    data = {
                        enable: checkingData.enable,
                        list_id: checkingData.list_id,
                        auto_responder_id: checkingData.auto_responder_id,
                        type: checkingData.type
                    }
                } else {
                    data = {
                        enable: false,
                        list_id: "",
                        auto_responder_id: curElem.id,
                        type: "sg"
                    }
                }
                arr.push(data)
            })
        }
        if (sendLane.length > 0) {
            sendLane.forEach((curElem) => {
                const checkingData = autoResponders.find(({ type }) => type === "sl")
                if (checkingData) {
                    data = {
                        enable: checkingData.enable,
                        list_id: checkingData.list_id,
                        auto_responder_id: checkingData.auto_responder_id,
                        type: checkingData.type
                    }
                } else {
                    data = {
                        enable: false,
                        list_id: "",
                        auto_responder_id: curElem.id,
                        type: "sl"
                    }
                }
                arr.push(data)
            })
        }
        if (sendiio.length > 0) {
            sendiio.forEach((curElem) => {
                const checkingData = autoResponders.find(({ type }) => type === "si")
                if (checkingData) {
                    data = {
                        enable: checkingData.enable,
                        list_id: checkingData.list_id,
                        auto_responder_id: checkingData.auto_responder_id,
                        type: checkingData.type
                    }
                } else {
                    data = {
                        enable: false,
                        list_id: "",
                        auto_responder_id: curElem.id,
                        type: "si"
                    }
                }
                arr.push(data)
            })
        }
        if (mailvio.length > 0) {
            mailvio.forEach((curElem) => {
                const checkingData = autoResponders.find(({ type }) => type === "mv")
                if (checkingData) {
                    data = {
                        enable: checkingData.enable,
                        list_id: checkingData.list_id,
                        auto_responder_id: checkingData.auto_responder_id,
                        type: checkingData.type
                    }
                } else {
                    data = {
                        enable: false,
                        list_id: "",
                        auto_responder_id: curElem.id,
                        type: "mv"
                    }
                }
                arr.push(data)
            })
        }
        dispatch(addAutoResponders(arr))

    }, [payment])

    const handleChangeResponse = (e, index) => {
        const { name, value, checked } = e.target
        if (name === "enable") {
            dispatch(setEnableAutoRes(checked, index))
        }
        else {
            dispatch(setListAutoRes(value, index))
        }
    }
    let arrVal
    const handleSetList = (data, type, intId) => {
        let val = {
            integrationId: intId,
            list: data
        }
        arrVal = { ...listData }
        if (type === "gr") {
            const findInte = arrVal.grList.findIndex(({ integrationId }) => integrationId === intId)
            if (findInte === -1) {

                arrVal.grList.push(val)
            }
        }
        else if (type === "aw") {
            const findInte = arrVal.awList.findIndex(({ integrationId }) => integrationId === intId)
            if (findInte === -1) {
                arrVal.awList.push(val)
            }
        }
        else if (type === "mc") {
            const findInte = arrVal.mcList.findIndex(({ integrationId }) => integrationId === intId)
            if (findInte === -1) {
                arrVal.mcList.push(val)
            }
        }
        else if (type === "sg") {
            const findInte = arrVal.sgList.findIndex(({ integrationId }) => integrationId === intId)
            if (findInte === -1) {
                arrVal.sgList.push(val)
            }
        }
        else if (type === "sl") {
            const findInte = arrVal.slList.findIndex(({ integrationId }) => integrationId === intId)
            if (findInte === -1) {
                arrVal.slList.push(val)
            }
        }
        else if (type === "si") {
            const findInte = arrVal.siList.findIndex(({ integrationId }) => integrationId === intId)
            if (findInte === -1) {
                arrVal.siList.push(val)
            }
        }
        else if (type === "mv") {
            const findInte = arrVal.mvList.findIndex(({ integrationId }) => integrationId === intId)
            if (findInte === -1) {
                arrVal.mvList.push(val)
            }
        }

        setListData(arrVal)
    }



    const fetchListData = (intId, type) => {
        axios({
            method: 'POST',
            url: `${baseURL}${type}-list`,
            data: { "id": intId },
            headers: { 'Content-Type': 'application/json', 'Authorization': auth.token }
        }).then((res) => {
            if (res.data.status === true) {
                handleSetList(res.data.data, type, intId)
            }
        }).catch((error) => {
            console.log(error)
        })
    }

    useEffect(() => {
        if (autoResponders.length > 0) {
            autoResponders.forEach((curElem) => {
                fetchListData(curElem.auto_responder_id, curElem.type)
            })
        }
    }, [autoResponders])




    return (

        <>
            <div className="productBlock full align-items-start">
                <div className="productBlock-left">
                    <label>Add Auto Responder</label>
                </div>
                <div className="productBlock-right">
                    <div className="switchWrap">
                        <span>OFF</span>
                        <label class="switch">
                            <input
                                type="checkbox"
                                name="enable"
                                checked={autoRes.enable}
                                onChange={(e) => setAutoRes({
                                    ...autoRes,
                                    enable: e.target.checked
                                })}
                            />
                            <span class="slider round"></span>
                        </label>
                        <span>ON</span>
                    </div>
                </div>
            </div>

            {autoRes.enable ?
                <div className='productBlock align-items-start mt-0'>
                    <div className='productBlock-left'></div>
                    <div className='productBlock-right'>

                        <ul className="pricing-opt">
                            {autoResponders.length > 0 ?
                                autoResponders.map((curElem, index) => {
                                    let icon, name, listArray = [], selectedInt
                                    if (curElem.type === "gr") {
                                        icon = getResponse_logo
                                        name = "GetResponse"
                                        selectedInt = listData.grList.find(({ integrationId }) => integrationId === curElem.auto_responder_id)
                                        if (!selectedInt) {
                                            listArray = []
                                        }
                                        else {
                                            listArray = selectedInt.list
                                        }
                                    }
                                    else if (curElem.type === "aw") {
                                        icon = awebar_logo
                                        name = "Aweber"

                                        selectedInt = listData.awList.find(({ integrationId }) => integrationId === curElem.auto_responder_id)
                                        if (!selectedInt) {
                                            listArray = []
                                        }
                                        else {
                                            listArray = selectedInt.list
                                        }
                                    }
                                    else if (curElem.type === "mc") {
                                        icon = mailChimp_logo
                                        name = "MailChimp"
                                        selectedInt = listData.mcList.find(({ integrationId }) => integrationId === curElem.auto_responder_id)
                                        if (!selectedInt) {
                                            listArray = []
                                        }
                                        else {
                                            listArray = selectedInt.list
                                        }
                                    }
                                    else if (curElem.type === "sg") {
                                        icon = Sendgrid_logo
                                        name = "Sendgrid"
                                        let selectedInt = listData.sgList.find(({ integrationId }) => integrationId === curElem.auto_responder_id)
                                        if (!selectedInt) {
                                            listArray = []
                                        }
                                        else {
                                            listArray = selectedInt.list
                                        }
                                    }
                                    else if (curElem.type === "sl") {
                                        icon = Sendlane_logo
                                        name = "Sendlane"
                                        let selectedInt = listData.slList.find(({ integrationId }) => integrationId === curElem.auto_responder_id)
                                        if (!selectedInt) {
                                            listArray = []
                                        }
                                        else {
                                            listArray = selectedInt.list
                                        }
                                    }
                                    else if (curElem.type === "si") {
                                        icon = Sandiio_logo
                                        name = "Sandiio"
                                        let selectedInt = listData.siList.find(({ integrationId }) => integrationId === curElem.auto_responder_id)
                                        if (!selectedInt) {
                                            listArray = []
                                        }
                                        else {
                                            listArray = selectedInt.list
                                        }
                                    }

                                    else if (curElem.type === "mv") {
                                        icon = Mailvio_logo
                                        name = "Mailvio"
                                        let selectedInt = listData.mvList.find(({ integrationId }) => integrationId === curElem.auto_responder_id)
                                        if (!selectedInt) {
                                            listArray = []
                                        }
                                        else {
                                            listArray = selectedInt.list
                                        }
                                    }
                                    return (
                                        <li key={index}>
                                            <div className="price-single">
                                                <div className="price-single-main">
                                                    <label className="customRadio">
                                                        <input
                                                            type="radio"
                                                            name="enable"
                                                            checked={curElem.enable}
                                                            onChange={(e) => handleChangeResponse(e, index)}
                                                        />
                                                        <span className="checkmark"></span>
                                                    </label>
                                                    <p>{name}</p>
                                                </div>
                                                <div className="price-single-img">
                                                    <img src={icon} alt="" />
                                                </div>
                                            </div>
                                            <div className='currencyOpt'>
                                                <select
                                                    className='searchInp full'
                                                    required
                                                    onChange={(e) => handleChangeResponse(e, index)}
                                                    value={curElem.list_id}
                                                >
                                                    <option value={""}>Select Type</option>
                                                    {listArray.length > 0 ?
                                                        listArray.map((curElem, index) => {
                                                            return (
                                                                <option key={index} value={curElem.campaignId}>
                                                                    {curElem.name}
                                                                </option>
                                                            )
                                                        }) : ''
                                                    }
                                                </select>
                                            </div>

                                        </li>
                                    )
                                }) : <p className="infoTxt" style={{ color: "crimson" }}>
                                    <RiErrorWarningLine
                                        size={20}
                                        style={{ marginBottom: '3px' }}
                                    />  You have not integrated any Auto Responder <Link to="/integration" style={{ color: "#0099ff" }}>Click Here</Link> to connect</p>
                            }
                        </ul>

                    </div>
                </div>
                : ''}
        </>
    )
}

export default AutoResp