const initialState = {
    bitpay: [],
    coingate: [],
    paypal: [],
    crypto: [],
    confirmo: [],
    alfacoins: [],
    gourl: [],
    spicepay: [],
    coinremitter: [],
    nowpayments: [],
    strike: [],
    autoResponse: {
        getResponse: [],
        aweber: [],
        mailChimp: [],
        iContact: [],
        sendGrid: [],
        sendLane: [],
        sendiio : [],
        mailvio : []
    },
    zapier: []
}

export default function (state = initialState, action) {
    switch (action.type) {

        case 'ADD_PAYMENT_ACCOUNTS':
            return {
                ...state,
                bitpay: action.payload.bitpay ? action.payload.bitpay : [],
                coingate: action.payload.coingate ? action.payload.coingate : [],
                paypal: action.payload.paypal ? action.payload.paypal : [],
                crypto: action.payload.crypto ? action.payload.crypto : [],
                confirmo: action.payload.confirmo ? action.payload.confirmo : [],
                alfacoins: action.payload.alfacoins ? action.payload.alfacoins : [],
                gourl: action.payload.gourl ? action.payload.gourl : [],
                spicepay: action.payload.spicepay ? action.payload.spicepay : [],
                coinremitter: action.payload.coinremitter ? action.payload.coinremitter : [],
                nowpayments: action.payload.nowpayments ? action.payload.nowpayments : [],
                strike: action.payload.strike ? action.payload.strike : []
            }


        case 'ADD_AUTORESPONSE':
            return {
                ...state,
                autoResponse: {
                    ...state.autoResponse,
                    getResponse: action.payload.getResponse ? action.payload.getResponse : [],
                    aweber: action.payload.aweber ? action.payload.aweber : [],
                    mailChimp: action.payload.mailChimp ? action.payload.mailChimp : [],
                    iContact: action.payload.iContact ? action.payload.iContact : [],
                    sendGrid: action.payload.sendGrid ? action.payload.sendGrid : [],
                    sendLane: action.payload.sendLane ? action.payload.sendLane : [],
                    sendiio: action.payload.sendiio ? action.payload.sendiio : [],
                    mailvio: action.payload.mailvio ? action.payload.mailvio : []
                }
            }

            case 'ADD_ZAPIER': 
            return {
                ...state,
                zapier : action.payload.zapier ? action.payload.zapier : []
            }
        default:
            return state
    }
}