import React, {useState} from "react";
import LoginFooter from "./LoginFooter";
// import logo from "../../images/LOGO.png"
import loginBg from "../../images/BG.png";
import {Link} from "react-router-dom";
import {useDispatch} from "react-redux";
import {forgetPassword} from "../../actions/authAction";
import Alert from "../Alert";
import {Helmet} from "react-helmet";

import logoWhite from '../../images/logo-white.svg';
import logoIcon from '../../images/Koincart_logo.png';

const ForgetPassword = ( ) => {

    const dispatch = useDispatch();

    const [loader, setLoader] = useState(false);
    const [emailDetails, setEmailDetails] = useState({
        email : ''
    })

    const onInputChange = (e) =>{
        setEmailDetails({...emailDetails, email: e.target.value});
    }

    const onFormSubmit = (e) => {
        e.preventDefault();
        setLoader(true);

        dispatch(forgetPassword(emailDetails, setLoader));
    }

    return(
        <>
            <Helmet>
                <title>Koincart | Forget Password </title>
            </Helmet>
            <Alert/>

            {/* <header className="loginHeader">
               <div className="container">
                   <div className="loginLogo">
                      <img src={logo} />
                   </div>
               </div>
            </header> */}

            <div className="login-container">
                <div className="container full-height-container">
                    <div className="row full-height-row align-items-center">
                        <div className="col-lg-6">
                            <div className="login-container-right">
                                <div className="login-container-right-main">
                                    <div className="login-logo">
                                       <img src={logoIcon} />
                                    </div> 
                                   <h2><span>Enter </span> Your Email Address</h2>
                                   <form method="post" onSubmit={(e)=>onFormSubmit(e)}>
                                    <div className="form-group">
                                        <label>Email Address</label>
                                        <input type="email" className="form-control" placeholder="Enter Email"
                                               required
                                               onChange={(e)=>onInputChange(e)}
                                        />
                                    </div>

                                    <div className="form-group">
                                        <button type="submit" className="btn btn-block btn-primary themeBtn" disabled={loader}>
                                            { loader ? <i className="fa fa-spinner fa-spin mr-2"/>  : '' } Submit
                                        </button>
                                    </div>

                                    <div className="form-group">
                                        <p className="forgotLink">Remember Login ? <Link to="/login" className="text-center "> Click here</Link></p>
                                    </div>

                                </form>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-6">
                            <div className="login-container-left">
                                <div className="logoWhite">
                                    <img src={logoWhite} />
                                </div>
                                <p>Introducing Koincart... The Digital Product eCommerce Platform You Need.</p>
                                <div className="logoIcon">
                                    <img src={logoIcon} style={{ width: '250px' }} />
                                </div>
                                <p>Instantly boost your conversions, triple your revenue and scale your business using Koincart's powerful A.I. that does all the work for you.
                                </p>
                                <div className="logVid">
                                    <iframe width="100%" height="350" src={"https://player.vimeo.com/video/728808597?"} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; " allowfullscreen></iframe>
                                </div>
                                {/* <p>Start Profiting Today — 100% auto-pilot! </p> */}
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <LoginFooter/>

        </>
    )
}

export default ForgetPassword;