import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import modalCloseIcon from '../../images/modal-close.png';
import axios from 'axios';
import { baseURL } from '../../global/global';
import { useDispatch, useSelector } from 'react-redux';
import { getStore, setStore } from '../../actions/baseActions';
import Select from 'react-select';
const BaseInfo = () => {

    const dispatch = useDispatch()
    const auth = useSelector(state => state.auth)
    const [show2, setShow2] = useState(false);
    const [loader, setLoader] = useState(false)
    const [baseCurrencyData, setBaseCurrencyData] = useState([])
    const handleClose2 = () => setShow2(false);
    const handleShow2 = () => setShow2(true);
    const [data, setData] = useState({
        storeName: "",
        baseCurrency: "",
        symbol: ''
    })


    const updateCurrency = (data) => {
        let temp = []
        if (data.length > 0) {
            temp = [...new Map(data.map(item => [item["code"], item])).values()];
        }
        let objArr = []
        temp.forEach((curElem, index) => {
            let obj = {id : index, value: curElem.code, label: `${curElem.code}-${curElem.symbol} `, name: "baseCurrency", symbol: curElem.symbol }
            objArr.push(obj)
        })
        setBaseCurrencyData(objArr)
    }

    useEffect(() => {
        dispatch(getStore(auth.token, true, handleClose2, handleShow2))
    }, [])

    useEffect(() => {
        axios({
            method: 'POST',
            url: `${baseURL}base-currency`,
            data: {},
            headers: { 'Content-Type': 'application/json', 'Authorization': auth.token }
        }).then((res) => {
            if (res.data.status === true) {
                updateCurrency(res.data.data)
            }
        }).catch((error) => {
            console.log(error)
        })
    }, [])

    const handleSet = (e) => {
        e.preventDefault()
        setLoader(true)
        dispatch(setStore(auth.token, data, setLoader, handleClose2))
    }

    const handleChange = (e) => {
        if (e.name === "baseCurrency") {
            setData({
                ...data,
                baseCurrency: e.value,
                symbol: e.symbol
            })
        }
        else {
            setData({
                ...data,
                storeName: e.target.value
            })
        }
    }

    return (

        <Modal className="VideoModal smModal" show={show2} centered>
            <Modal.Body>
                <form className="modalForm" onSubmit={handleSet}>
                    <div className="inp-wrap mt-0">
                        <label htmlFor='storeName'>Enter your Store Name</label>
                        <input
                            className="searchInp full"
                            placeholder="Store Name"
                            type="text"
                            name='storeName'
                            value={data.storeName}
                            onChange={(e) => handleChange(e)}
                            required
                        />
                    </div>
                    <div className="inp-wrap">
                        <label htmlFor="">Select a Base Currency</label>

                        <Select
                            className="react-select-container my-select-box"
                            closeMenuOnSelect={true}
                            defaultValue=""
                            name='baseCurrency'
                            placeholder="Select Currency"
                            options={baseCurrencyData}
                            required={true}
                            onChange={(e) => handleChange(e)}
                        />
                    </div>

                    <div className="inp-wrap">
                        <div class="alert alert-info text-center" role="alert">
                            Please note that: Base currency can be set only one time. Once you set it, you can't update it
                        </div>
                    </div>

                    <div className="inp-wrap">
                        <button className="btn btn-primary inpBtn" type="submit" >Submit {loader ? <i className="fa fa-spinner fa-spin mr-2" /> : ''}</button>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    );
}

export default BaseInfo;
