import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import LoginFooter from "./LoginFooter";
import { useDispatch, useSelector } from "react-redux";
import { loginUser } from "../../actions/authAction";
import Alert from "../Alert";
import { Helmet } from "react-helmet";

import logo from '../../images/Koincart_logo.png';
import logoWhite from '../../images/logo-white.svg';
import loginIcon from '../../images/Koincart_logo.png';



const Login = () => {

    const history = useHistory();
    const auth = useSelector(state => state.auth);
    const dispatch = useDispatch();

    if (auth.isAuthenticated) {
        history.push('/dashboard');
    }

    const [loader, setLoader] = useState(false);
    const [user, setUser] = useState({
        email: '',
        password: ''
    })

    const onInputChange = (e) => {
        setUser({ ...user, [e.target.name]: e.target.value });
    }

    const onFormSubmit = (e) => {
        e.preventDefault();
        setLoader(true);

        dispatch(loginUser(user, setLoader));
    }

    return (
        <>
            <Helmet>
                <title>Koincart | Login </title>
            </Helmet>
            <Alert />

            <div className="login-container">
                <div className="container full-height-container">
                    <div className="row full-height-row align-items-center">
                        <div className="col-lg-6">
                            <div className="login-container-right">
                                <div className="login-container-right-main">
                                    <div className="login-logo">
                                        <img src={logo} />
                                    </div>
                                    <form method="post" onSubmit={(e) => onFormSubmit(e)}>
                                        <div className="form-group">
                                            <label>Email Address</label>
                                            <input type="email" name="email" className="form-control"
                                                placeholder="Email Address" required
                                                onChange={(e) => onInputChange(e)}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label>Password</label>
                                            <input type="password" name="password" className="form-control"
                                                placeholder="************" required
                                                onChange={(e) => onInputChange(e)}
                                            />
                                        </div>

                                        {/* <div className="rememberpass">
                                        <label class="custom-check alt">Remember me for 2 weeks
                                            <input type="checkbox"/>
                                            <span class="checkmark"></span>
                                        </label>
                                        </div> */}

                                        <div className="form-group">
                                            <button type="submit" className="btn btn-block btn-primary themeBtn" disabled={loader}>
                                                {loader ? <i className="fa fa-spinner fa-spin mr-2" /> : ''} Sign in to Koincart
                                            </button>
                                        </div>

                                        <div className="rememberpass text-right">
                                            <a href="/forget-password" className="">Forgot your password?</a>
                                        </div>

                                        {/* <div className="form-group">
                                            <p className="forgotLink">New to us? <Link to="/forget-password" className="text-center "> Sign Up</Link></p>
                                        </div> */}

                                    </form>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-6">
                            <div className="login-container-left">
                                <div className="logoWhite">
                                    <img src={logoWhite} />
                                </div>
                                <p>Introducing Koincart... The Digital Product eCommerce Platform You Need.</p>
                                <div className="logoIcon">
                                    <img src={loginIcon} style={{ width: '250px' }} />
                                </div>
                                <p>Instantly boost your conversions, triple your revenue and scale your business using Koincart's powerful A.I. that does all the work for you.
                                </p>
                                <div className="logVid">
                                    <iframe width="100%" height="350" src={"https://player.vimeo.com/video/728808597?"} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; " allowfullscreen></iframe>
                                </div>
                                {/* <p>Start Profiting Today — 100% auto-pilot! </p> */}
                            </div>
                        </div>

                    </div>
                </div>
            </div>


            <LoginFooter />


        </>
    )
}

export default Login;