import axios from 'axios';
import React, { useState } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import { useDispatch, useSelector } from 'react-redux';
import { setAlert } from '../../actions/alert';
import { fetchAutoResponder } from '../../actions/paymentAction';
import { baseURL } from '../../global/global';


const IntegrationAutoResponse = (props) => {

    const auth = useSelector(state => state.auth)
    const dispatch = useDispatch()
    const [button, setButton] = useState('Delete');
    const [showDelete, setShowDelete] = useState(false);
    const [deleteData, setDeleteData] = useState({
        type: '',
        id: -1
    });


    const accountType = {
        client: auth.user.is_client_account === "0" ? false : true,
        team: auth.user.is_virtual_account === "0" ? false : true,
        reseller: auth.user.is_reseller_account === "0" ? false : true,
    }
    const handleDeleteIntegration = (integrationId) => {

        let type
        if (props.type === "gr") {
            type = "get_response"
        }
        else if (props.type === "aw") {
            type = "aweber"
        }
        else if (props.type === "mc") {
            type = "mail_chimp"
        }
        else if (props.type === "sg") {
            type = "send_grid"
        }
        else if (props.type === "sg") {
            type = "send_lane"
        }
        else if (props.type === "si") {
            type = "sendiio"
        }
        else if (props.type === "mv") {
            type = "mailvio"
        }

        const data = {
            type: type,
            id: integrationId
        }
        axios({
            method: 'POST',
            url: `${baseURL}delete-autoresponder-connection`,
            data: data,
            headers: { 'Content-Type': 'application/json', 'Authorization': auth.token }
        }).then((res) => {
            if (res.data.status === true) {
                dispatch(setAlert(res.data.message, "success"))
                dispatch(fetchAutoResponder(auth.token))
            } else {
                dispatch(setAlert(res.data.message, "danger"))
            }
            onCancel()
        }).catch((error) => {
            console.log(error)
        })
    }

    const handleSetDelete = (type, integrationId) => {
        setDeleteData({
            ...deleteData,
            type: type,
            id: integrationId
        })
        setShowDelete(true)
    }
    const onConfirm = () => {
        setButton('Deleting')
        handleDeleteIntegration(deleteData.type, deleteData.id)

    }
    const onCancel = () => {
        setShowDelete(false)
        setDeleteData({
            ...deleteData,
            type: '',
            id: -1
        })
    }
    return (
        <>
            <div className="connection-box mt-3">
                <div className="row">
                    <div className="col-md-1 d-flex justify-content-center">
                        <div className={`connection-img ${props.data.length > 0 ? '' : 'notConnected'}`}><img alt="bitpay" src={props.logo} /></div>
                    </div>
                    <div className="col-md-11">
                        {props.data.length > 0 ?
                            props.data.map((curElem, index) => {
                                return (
                                    <div key={index} className="youbtube-connection text-white d-flex align-items-center justify-content-between connect-list mb-2">
                                        <div className="youtube-content">
                                            <h6><strong>{props.name}</strong></h6>
                                            <p>{curElem.name} - Added On - {curElem.created}</p>
                                        </div>
                                        {accountType.client || accountType.team ? "" :
                                            <div className="facebook-connect-account">
                                                <button className="btn-change7" onClick={() => handleSetDelete(curElem.id)}>Delete</button>
                                            </div>}
                                    </div>
                                )
                            }) : <div className="youbtube-connection text-white d-flex align-items-center justify-content-between notConnected">
                                <div className="youtube-content">
                                    <h6><strong>{props.name}</strong></h6>
                                    <p>Not Connected</p>
                                </div>
                                <div className="facebook-connect-account">
                                    <button onClick={() => props.handleModel(props.type, props.name)} className="btn-change7"> Connect {props.loader ? <i className="fa fa-spinner fa-spin" /> : ''}</button>
                                </div>
                            </div>}

                        {props.data.length > 0 ?
                            <div className="facebook-connect-account d-flex justify-content-center mt-4">
                                <button onClick={() => props.handleModel(props.type, props.name
                                )} className="btn-change7">{props.data.length > 0 ? 'Connect New Accout ' : 'Connect'}</button>
                            </div> : ''}
                    </div>
                </div>
            </div>
            <SweetAlert
                warning
                showCancel
                confirmBtnText={button}
                confirmBtnBsStyle="danger"
                cancelBtnBsStyle="light"
                title="Are you sure?"
                onConfirm={(e) => onConfirm(e)}
                onCancel={(e) => onCancel(e)}
                focusCancelBtn
                show={showDelete}
            >
                Are you sure to disconnect this connection?
            </SweetAlert>
        </>
    );
}

export default IntegrationAutoResponse;
