import axios from "axios";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setData, setOldSetting } from "../../actions/productAction";
import { baseURL } from "../../global/global";
import ModernDatepicker from 'react-modern-datepicker';
import { setAlert } from "../../actions/alert";
import { AiOutlineCheck, AiOutlineConsoleSql } from "react-icons/ai";
import AutoResp from "./AutoResp";

const Settings = ({ id }) => {

    const auth = useSelector(state => state.auth)
    const productData = useSelector(state => state.product.data)
    const dispatch = useDispatch()
    const [domainToggle, setdomainToggle] = useState(false)
    const [checkDomain, setCheckDomain] = useState({
        url: '',
        active: false,
        loader: false
    })
   
    useEffect(() => {
        if (id) {
            axios({
                method: 'POST',
                url: `${baseURL}product-get-setting`,
                data: { "productId": Number(id) },
                headers: { 'Content-Type': 'application/json', 'Authorization': auth.token }
            }).then((res) => {
                if (res.data.status === true) {
                    dispatch(setOldSetting(res.data.data[0]))
                }
            }).catch((error) => {
                console.log(error)
            })
        }
    }, [])


    const handleChange = (e) => {
        const { name } = e.target
        if (name === "checkoutDataEnable") {
            dispatch(setData(name, e.target.checked))
        }
        else {
            dispatch(setData(name, e.target.value))
        }
    }

    const handleDate = (name, date) => {
        dispatch(setData(name, date))
    }

    const handleDomainToggle = () => {
        setdomainToggle(!domainToggle)
    }

    const handleCheckDomain = () => {
        if (checkDomain.url !== "") {
            setCheckDomain({
                ...checkDomain,
                loader: true
            })
            axios({
                method: "POST",
                url: `${baseURL}addon-domain-add`,
                data: {
                    "domainName": checkDomain.url,
                    "productID": id
                },
                headers: { 'Content-Type': 'application/json', 'Authorization': auth.token }
            }).then((res) => {
                if (res.data.status === true) {
                    setCheckDomain({
                        ...checkDomain,
                        active: true,
                        loader: false
                    })
                    dispatch(setAlert(res.data.message, "success"))
                }
                else {
                    setCheckDomain({
                        ...checkDomain,
                        active: false,
                        loader: false
                    })
                    dispatch(setAlert(res.data.message, "danger"))
                }
            }).catch((error) => {
                setCheckDomain({
                    ...checkDomain,
                    loader: false
                })
                console.log(error)
            })
        }
    }
    return (
        <>
            <div className="productBox">
                <h2 className="pageHeading">Settings</h2>

                <div className="productBlock mt-0 align-items-start">
                    <div className="productBlock-left">
                        <label>Header HTML/Script</label>
                    </div>
                    <div className="productBlock-right">
                        <textarea
                            type="text"
                            className="searchInp large"
                            placeholder="Add Header HTML/Script"
                            name="headerScript"
                            value={productData.setting.headerScript}
                            onChange={(e) => handleChange(e)}
                        />
                        <span className="infoTxt">Add header HTML/Script code which will show on the checkout page </span>
                    </div>
                </div>

                <div className="productBlock align-items-start">
                    <div className="productBlock-left">
                        <label>Footer HTML/Script</label>
                    </div>
                    <div className="productBlock-right">
                        <textarea
                            type="text"
                            className="searchInp large"
                            placeholder="Footer HTML/Script"
                            name="footerScript"
                            value={productData.setting.footerScript}
                            onChange={(e) => handleChange(e)}
                        />
                        <span className="infoTxt">Add footer HTML/Script code which will show on the checkout page</span>
                    </div>
                </div>

                <div className="productBlock align-items-start">
                    <div className="productBlock-left">
                        <label>Fire Pixel After purchase</label>
                    </div>
                    <div className="productBlock-right">
                        <textarea
                            type="text"
                            className="searchInp large"
                            placeholder="Add Fire Pixel After purchase"
                            name="firePixel"
                            value={productData.setting.firePixel}
                            onChange={(e) => handleChange(e)}
                        />
                        <span className="infoTxt">Enter Pixel code which will fire on successful purchase</span>
                    </div>
                </div>

                <div className="productBlock align-items-start">
                    <div className="productBlock-left">
                        <label>Footer Branding</label>
                    </div>
                    <div className="productBlock-right">
                        <textarea
                            type="text"
                            className="searchInp large"
                            placeholder="Add Footer Text"
                            name="footerText"
                            value={productData.footerText}
                            onChange={(e) => handleChange(e)}
                        />
                        <span className="infoTxt">Add footer text which will show on the checkout page</span>
                    </div>
                </div>

                <AutoResp
                    autoResponders={productData.autoResponders}
                />

                <div className="productBlock full align-items-start">
                    <div className="productBlock-left">
                        <label>Close Checkout After # of Purchases</label>
                    </div>
                    <div className="productBlock-right">
                        <div className="switchWrap">
                            <span>OFF</span>
                            <label class="switch">
                                <input
                                    type="checkbox"
                                    name="checkoutDataEnable"
                                    checked={productData.closeCheckout.enable}
                                    onChange={(e) => handleChange(e)}
                                />
                                <span class="slider round"></span>
                            </label>
                            <span>ON</span>
                        </div>
                    </div>
                </div>
                {productData.closeCheckout.enable ?
                    <>
                        <div className="productBlock align-items-start">
                            <div className="productBlock-left">
                                <label>Enter Number</label>
                            </div>
                            <div className="productBlock-right">
                                <input
                                    className="searchInp"
                                    type="number"
                                    placeholder="Enter Number"
                                    name="enterNumber"
                                    value={productData.closeCheckout.enterNumber}
                                    onChange={(e) => handleChange(e)}
                                />
                                <span className="infoTxt">Total number of purchases after which you want to close checkout</span>
                            </div>
                        </div>

                        <div className="productBlock align-items-start">
                            <div className="productBlock-left">
                                <label>Close Checkout After Date</label>
                            </div>
                            <div className="productBlock-right">
                                <ModernDatepicker
                                    date={productData.closeCheckout.closeCheckoutDate}
                                    format={'DD-MM-YYYY'}
                                    showBorder
                                    className="date-picker-style"
                                    onChange={date => handleDate("closeCheckoutDate", date)}
                                    placeholder={'Add Close Checkout After Date'}
                                    primaryColor={'#400C46'}
                                    secondaryColor={'#fff6fe'}
                                    primaryTextColor={'#400C46'}
                                    secondaryTextColor={'#fff'}
                                />
                                <span className="infoTxt">Select date after which you want to close checkout</span>
                            </div>
                        </div>
                    </> : ''}


                <div className="productBlock align-items-start">
                    <div className="productBlock-left">
                        <label>Custom Domain</label>
                    </div>
                    <div className="productBlock-right">
                        <input
                            className="searchInp"
                            type="text"
                            name="checkDomain"
                            placeholder="Enter Custom Domain"
                            value={checkDomain.url}
                            onChange={(e) => setCheckDomain({
                                ...checkDomain,
                                url: e.target.value
                            })}
                        />
                        <button
                            className="demoLink smBtn mt-0 ml-2"
                            style={checkDomain.url !== "" ? {} : { backgroundColor: "gray" }}
                            onClick={handleCheckDomain}
                        >Check & Add Custom Domain {checkDomain.loader ? <i className="fa fa-spinner fa-spin" /> : ''} </button>
                        {checkDomain.active ?
                            <AiOutlineCheck size={25} color="green" fontWeight={"bold"} className="mx-3 mb-2" /> : ''}
                        <span
                            className="infoTxt fw-bold cursor-pointer domain-hover-effect"
                            onClick={() => handleDomainToggle()}
                        >
                            How to Add a Custom Domain
                        </span>
                    </div>
                </div>
                {domainToggle ?
                    <div className="productBlock align-items-start">
                        <div className="productBlock-left"></div>
                        <div className="productBlock-right">
                            <div className="infoTxtGroup">
                                <ul>
                                    <li>Please use top-level domains only (sub-domains won’t work).</li>
                                    <li>Please enter your domain above without any www, http or https (domain.com for example).</li>
                                    <li>Enter your domain name for example "mydomain.com" (without the quotation marks) and click the "Add Domain" button.</li>
                                    <li>Copy this IP address for the A Record: 72.52.158.199</li>
                                    <li>Log into your domain registrar account and find the option to add Host Records, which can usually be found in the Advanced DNS section. If you're struggling to find this, perform a Google search for "[Domain Registrar Here] how to change host records".</li>
                                    <li>Select your type as "A Record".</li>
                                    <li>Set Host as "@".</li>
                                    <li>Paste the copied IP Address from step 4 into the value field.</li>
                                    <li>Leave TTL as Automatic or 14400.</li>
                                    <li>Click Save and you're done.</li>
                                </ul>
                                <p className="redTxt pt-3">Note: It can take up to 48-72 hours to reflect.</p>
                            </div>
                        </div>
                    </div> : ''}

            </div>
        </>
    )
}

export default Settings;