import React from "react";
import { MdOutlineBusinessCenter } from "react-icons/md";
import { Link } from "react-router-dom";
import questionLogo from '../../images/question.png';
import dummyBusiness from '../../images/logoKoin.png'
const HelpSubContent = (props) => {

    const handleOpenPdf = (url) => {
        window.open(url, "_blank")
    }
    return (
        <>
            <div className="col-xl-3 col-lg-4 col-sm-6">
                <div className="impTopic-single cursor-pointer" onClick={() => handleOpenPdf(props.url)} style={{ backgroundColor: "#400C46", height: "230px" }}>
                    <div className="text-center">
                        <img src={dummyBusiness} alt="" className="img-fluid"/>
                        <h3>{props.name}</h3>
                    </div>
                </div>
            </div>
        </>
    )
}

export default HelpSubContent;