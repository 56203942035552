
const initialState = {
    data: false
}

export default function (state = initialState, action) {
    switch (action.type) {
        case "ADD_PRODUCT":
            return {
                ...state,
                data: action.payload
            }

        case "PRODUCT_NAME":
            return {
                ...state,
                data: {
                    ...state.data,
                    productName: action.payload
                }
            }

        case "SKU_NUMBER":
            return {
                ...state,
                data: {
                    ...state.data,
                    skuNumber: action.payload
                }
            }

        case "PRODUCT_URL":
            return {
                ...state,
                data: {
                    ...state.data,
                    productUrl: action.payload
                }
            }

        case "PRODUCT_DESCRIPTION":
            return {
                ...state,
                data: {
                    ...state.data,
                    productDescription: action.payload
                }
            }
        case "PRODUCT_PAYMENT_TYPE":
            return {
                ...state,
                data: {
                    ...state.data,
                    paymentType: action.payload
                }
            }
        case "PRODUCT_PRICE":
            return {
                ...state,
                data: {
                    ...state.data,
                    price: action.payload
                }
            }

        case "PRODUCT_IMAGE":
            return {
                ...state,
                data: {
                    ...state.data,
                    image: action.payload
                }
            }

        case "ORDERBUMP_IMAGE":
            return {
                ...state,
                data: {
                    ...state.data,
                    orderBump: {
                        ...state.data.orderBump,
                        image: action.payload
                    }
                }
            }

        case "PRODUCT_TYPE":
            return {
                ...state,
                data: {
                    ...state.data,
                    productType: action.payload
                }
            }

        case "PRODUCT_TYPE":
            return {
                ...state,
                data: {
                    ...state.data,
                    productType: action.payload
                }
            }

        case "THANKYOU_URL":
            return {
                ...state,
                data: {
                    ...state.data,
                    thankyouPageUrl: action.payload
                }
            }

        case "IPN_STATUS":
            return {
                ...state,
                data: {
                    ...state.data,
                    ipn: {
                        ...state.data.ipn,
                        enable: action.payload
                    }
                }
            }

        case "IPN_URL":
            return {
                ...state,
                data: {
                    ...state.data,
                    ipn: {
                        ...state.data.ipn,
                        url: action.payload
                    }
                }
            }
        case "ORDERBUMP_ENABLE":
            return {
                ...state,
                data: {
                    ...state.data,
                    orderBump: {
                        ...state.data.orderBump,
                        enable: action.payload
                    }
                }
            }

        case "ORDERBUMP_TITLE":
            return {
                ...state,
                data: {
                    ...state.data,
                    orderBump: {
                        ...state.data.orderBump,
                        title: action.payload
                    }
                }
            }

        case "ORDERBUMP_DESC":
            return {
                ...state,
                data: {
                    ...state.data,
                    orderBump: {
                        ...state.data.orderBump,
                        description: action.payload
                    }
                }
            }

        case "ORDERBUMP_PRODUCT":
            return {
                ...state,
                data: {
                    ...state.data,
                    orderBump: {
                        ...state.data.orderBump,
                        product: action.payload
                    }
                }
            }
        case "SEO_TITLE":
            return {
                ...state,
                data: {
                    ...state.data,
                    seo: {
                        ...state.data.seo,
                        title: action.payload
                    }
                }
            }

        case "SEO_DESCRIPTION":
            return {
                ...state,
                data: {
                    ...state.data,
                    seo: {
                        ...state.data.seo,
                        description: action.payload
                    }
                }
            }

        case "SEO_IMAGE":
            return {
                ...state,
                data: {
                    ...state.data,
                    seo: {
                        ...state.data.seo,
                        image: action.payload
                    }
                }
            }

        case "SEO_METATAG":
            return {
                ...state,
                data: {
                    ...state.data,
                    seo: {
                        ...state.data.seo,
                        meta_tags: action.payload
                    }
                }
            }

        case "SETTING_HEADER_SCRIPT":
            return {
                ...state,
                data: {
                    ...state.data,
                    setting: {
                        ...state.data.setting,
                        headerScript: action.payload
                    }
                }
            }

        case "SETTING_FOOTER_SCRIPT":
            return {
                ...state,
                data: {
                    ...state.data,
                    setting: {
                        ...state.data.setting,
                        footerScript: action.payload
                    }
                }
            }

        case "SETTING_FIRE_PIXEL":
            return {
                ...state,
                data: {
                    ...state.data,
                    setting: {
                        ...state.data.setting,
                        firePixel: action.payload
                    }
                }
            }

        case "FOOTER_TEXT":
            return {
                ...state,
                data: {
                    ...state.data,
                    footerText: action.payload
                }
            }

        case "CLOSE_CHECKOUT_ENABLE":
            return {
                ...state,
                data: {
                    ...state.data,
                    closeCheckout: {
                        ...state.data.closeCheckout,
                        enable: action.payload
                    }
                }
            }

        case "CLOSE_CHECKOUT_NUMBER":
            return {
                ...state,
                data: {
                    ...state.data,
                    closeCheckout: {
                        ...state.data.closeCheckout,
                        enterNumber: action.payload
                    }
                }
            }



        case "CLOSE_CHECKOUT_DATE":
            return {
                ...state,
                data: {
                    ...state.data,
                    closeCheckout: {
                        ...state.data.closeCheckout,
                        closeCheckoutDate: action.payload
                    }
                }
            }

        case "APPLY_COUPON":
            return {
                ...state,
                data: {
                    ...state.data,
                    applyCoupon: action.payload
                }
            }

        case "PAYMENT_MODE":
            return {
                ...state,
                data: {
                    ...state.data,
                    paymentMode: action.payload
                }
            }

        case "ADD_LOCATION_OBJ":
            let temp = [...state.data.shipping]
            temp.push(action.payload)
            return {
                ...state,
                data: {
                    ...state.data,
                    shipping: temp
                }
            }

        case "UPDATE_SHIPPING_LOCATION":
            let temp1 = [...state.data.shipping]
            temp1[action.payload.index].id = action.payload.value
            return {
                ...state,
                data: {
                    ...state.data,
                    shipping: temp1
                }
            }
        case "UPDATE_SHIPPING_COST":
            let temp2 = [...state.data.shipping]
            temp2[action.payload.index].shippingCost = action.payload.value
            return {
                ...state,
                data: {
                    ...state.data,
                    shipping: temp2
                }
            }

        case "DELETE_SHIPPING":
            let temp3 = [...state.data.shipping]
            temp3.splice(action.payload, 1)
            return {
                ...state,
                data: {
                    ...state.data,
                    shipping: temp3
                }
            }

        case "SET_OLD_SEO":
            let val = { ...state.data.seo }
            val = {
                title: action.payload.title,
                description: action.payload.description,
                image: action.payload.image,
                meta_tags: action.payload.meta_tags
            }
            return {
                ...state,
                data: {
                    ...state.data,
                    seo: val
                }
            }

        case "SET_OLD_SETTING":
            let val1 = { ...state.data.setting }
            val1 = {
                headerScript: action.payload.header_script,
                footerScript: action.payload.footer_script,
                firePixel: action.payload.fire_pixel
            }
            return {
                ...state,
                data: {
                    ...state.data,
                    setting: val1
                }
            }

        case "ADD_PAYMENT_ARR":
            return {
                ...state,
                data: {
                    ...state.data,
                    paymentData: action.payload
                }
            }

        case "UPDATE_PAYMENT_ENABLE":

            let data1 = [...state.data.paymentData]
            data1[action.payload.index].enable = action.payload.val
            return {
                ...state,
                data: {
                    ...state.data,
                    paymentData: data1
                }
            }

        case "UPDATE_PAYMENT_CURRENCY":

            let data2 = [...state.data.paymentData]
            data2[action.payload.index].currencyId = action.payload.val

            return {
                ...state,
                data: {
                    ...state.data,
                    paymentData: data2
                }
            }

        case "UPDATE_TEMPLATE_ID":
            return {
                ...state,
                data: {
                    ...state.data,
                    templateId: action.payload.val,
                    templateType: action.payload.type
                }
            }

        case "ADD_AUTORESPONDER_OBJ":
            return {
                ...state,
                data: {
                    ...state.data,
                    autoResponders: action.payload
                }
            }

        case "SET_ENABLE_AUTO":
            let res1 = [...state.data.autoResponders]
            res1.forEach((curElem, index) => {
                if (action.payload.index === index) {
                    curElem.enable = true
                }
                else {
                    curElem.enable = false
                }
            })

            return {
                ...state,
                data: {
                    ...state.data,
                    autoResponders: res1
                }
            }

        case "SET_LIST_AUTO":
            let res2 = [...state.data.autoResponders]
            res2[action.payload.index].list_id = action.payload.val
            return {
                ...state,
                data: {
                    ...state.data,
                    autoResponders: res2
                }
            }

        default:
            return {
                ...state
            }
    }
} 