import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setAlert } from "../../actions/alert";
import { setData, setOldSeo } from "../../actions/productAction";
import { baseURL } from "../../global/global";
import axios from "axios";
import swal from "sweetalert";


const Seo = ({ id }) => {
    const auth = useSelector(state => state.auth)
    const productData = useSelector(state => state.product.data)
    const dispatch = useDispatch()

    const [loader, setLoader] = useState(false)
   
    let allowedExt = ['image/png', 'image/jpg', 'image/jpeg'];


    useEffect(() => {
        if (id) {
            axios({
                method: 'POST',
                url: `${baseURL}product-get-seo`,
                data: { "productId": Number(id) },
                headers: { 'Content-Type': 'application/json', 'Authorization': auth.token }
            }).then((res) => {
                if (res.data.status === true) {
                    dispatch(setOldSeo(res.data.data[0]))
                }
            }).catch((error) => {
                console.log(error)
            })
        }
    }, [])



    const handleChange = (e) => {
        const { name, value } = e.target
        let val = value
        
        if (name === "seo_title") {
            val = val.match("^[a-zA-Z0-9]*")
            val = val[0]
        }

        dispatch(setData(name, val))
    }


    const onInputFile = (e) => {
        const { name } = e.target
        const options = {
            headers: {
                'Authorization': auth.token
            }
        }
        if (e.target.files[0] !== undefined) {
            if (allowedExt.includes(e.target.files[0].type)) {
                let allowedSize = 2000000;

                if (e.target.files[0].size < allowedSize) {
                    const formData = new FormData();
                    formData.append('type', name);
                    formData.append('file', e.target.files[0])
                    setLoader(true)
                    axios.post(`${baseURL}upload-user-media`, formData, options)
                        .then(res => {
                            if (res.data.status === true) {
                                dispatch(setData(name, res.data.path))
                            }
                            else {
                                dispatch(setAlert(res.data.message, "danger"))

                            }
                            setLoader(false)
                        })
                        .catch((error) => {
                            console.log(error)
                            setLoader(false)
                        })

                }
                else {
                    swal("Oops!", "Max allowed size for image 2MB");
                }
            }
            else {
                swal("Oops!", "You have Selected Invalid File Type");
            }
        }
    }

    return (
        <>
            <div className="productBox">
                <h2 className="pageHeading">SEO</h2>

                <div className="productBlock mt-0">
                    <div className="productBlock-left">
                        <label>Title</label>
                    </div>
                    <div className="productBlock-right">
                        <input
                            className="searchInp"
                            placeholder="Add SEO Title"
                            type="text"
                            name="seo_title"
                            value={productData.seo.title}
                            onChange={(e) => handleChange(e)}
                            maxLength={70}
                        />
                        <span className="infoTxt">Add SEO title which you want on the checkout page</span>
                    </div>
                </div>

                <div className="productBlock align-items-start">
                    <div className="productBlock-left">
                        <label>Description</label>
                    </div>
                    <div className="productBlock-right">
                        <textarea
                            className="searchInp large"
                            placeholder="Add SEO Description"
                            name="seo_description"
                            value={productData.seo.description}
                            onChange={(e) => handleChange(e)}
                            maxLength={120}
                        />
                        <span className="infoTxt">Add SEO description which will go on the checkout page</span>
                    </div>
                </div>

                <div className="productBlock align-items-start">
                    <div className="productBlock-left">
                        <label>Feature Image</label>
                    </div>
                    <div className="productBlock-right">
                        <div className="fileType">
                            <button className="demoLink demoLink-bdr mt-0">Upload {loader ? <i className="fa fa-spinner fa-spin mx-2" /> : ''}</button>
                            <input
                                type="file"
                                name="seo_image"
                                onChange={(e) => onInputFile(e)}
                            />
                        </div>
                        <span className="infoTxt">Image should be smaller than 2MB, 250x 250 pixels in size, and in either JPG, PNG, or GIF format.</span>
                    </div>
                </div>

                <div className="edit-product-image mt-2">
                    {productData.seo.image !== "" ?
                        <img src={productData.seo.image} alt="" /> : ''}
                </div>

                <div className="productBlock">
                    <div className="productBlock-left">
                        <label>Meta Tags</label>
                    </div>
                    <div className="productBlock-right">
                        <input
                            className="searchInp"
                            type="text"
                            placeholder="Add Meta Tags"
                            name="seo_meta_tags"
                            value={productData.seo.meta_tags}
                            onChange={(e) => handleChange(e)}
                        />
                        <span className="infoTxt">Add meta tag which will go on the checkout page </span>
                    </div>
                </div>

            </div>
        </>
    )
}

export default Seo;