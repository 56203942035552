const initialState = {
    storeData: false
}

export default function (state = initialState, action) {
    switch (action.type) {
        case "SET_BASE_CURRENCY": {
            return {
                ...state,
                storeData: {
                    id:action.payload.id,
                    storeName: action.payload.storeName,
                    baseCurrency: action.payload.baseCurrency,
                    symbol: action.payload.symbol,
                }
            }
        }

        default: {
            return state
        }
    }
}