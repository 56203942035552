import React, { useState } from 'react';
import axios from 'axios';
import { baseURL } from '../../global/global';
import { useDispatch, useSelector } from 'react-redux';
import { setAlert } from '../../actions/alert';
import Alert from '../Alert';

const SalePage = () => {
    const auth = useSelector(state => state.auth)
    const dispatch = useDispatch()
    const [details, setDetais] = useState({
        name: '',
        email: '',
        product_id: 0,
        price_amount: "",
        price_currency: "",
        receive_currency: "",
        title: "",
        description: ""
    })
    const [paymntType, setPaymentType] = useState("coingate")


    const handleChange = (e) => {
        const { name, value } = e.target
        setDetais({
            ...details,
            [name]: value
        })

    }

    const handleSubmit = () => {
        if (paymntType === "coingate") {
            axios({
                method: "POST",
                url: `${baseURL}create-order-coingate`,
                data: details,
                headers: { 'Content-Type': 'application/json', 'Authorization': auth.token }
            }).then((res) => {
                if (res.data.status === true) {
                    dispatch(setAlert(res.data.message, "success"))
                    window.open(`${res.data.data.payment_url}`, '_blank');

                } else {
                    dispatch(setAlert(res.data.message, "danger"))

                }
            }).catch((error) => {
                console.log(error)
            })
        }
        else {
            axios({
                method: "POST",
                url: `${baseURL}create-invoice`,
                data: details,
                headers: { 'Content-Type': 'application/json', 'Authorization': auth.token }
            }).then((res) => {
                if (res.data.status === true) {
                    dispatch(setAlert(res.data.message, "success"))
                    window.open(`${res.data.data.payment_url}`, '_blank');
                } else {
                    dispatch(setAlert(res.data.message, "danger"))
                }
            }).catch((error) => {
                console.log(error)
            })
        }
    }
    return (
        <>
            <Alert />
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-md-12'>
                        <h1>Welcome to Sales Page</h1>
                    </div>
                    <div className='col-md-6'>
                        <input
                            type='text'
                            name="name"
                            placeholder='Name'
                            className='form-control mt-3'
                            onChange={(e) => handleChange(e)}
                        />
                        <input
                            type='text'
                            placeholder='Product Id'
                            name="product_id"
                            onChange={(e) => handleChange(e)}
                            className='form-control mt-3'
                        />
                        <input
                            type='text'
                            placeholder='Price Currency'
                            name="price_currency"
                            onChange={(e) => handleChange(e)}
                            className='form-control mt-3'
                        />
                        <input
                            type='text'
                            placeholder='Title'
                            name="title"
                            onChange={(e) => handleChange(e)}
                            className='form-control mt-3'
                        />
                        <select
                            placeholder='Payment Method'
                            className='form-control mt-3'
                            onChange={(e) => setPaymentType(e.target.value)}
                        >
                            <option value={"coingate"}>coingate</option>
                            <option value={"bitpay"}>bitpay</option>
                        </select>

                    </div>
                    <div className='col-md-6'>
                        <input
                            type='email'
                            placeholder='Email'
                            name="email"
                            onChange={(e) => handleChange(e)}
                            className='form-control mt-3'
                        />
                        <input
                            type='text'
                            placeholder='Price Amount'
                            name="price_amount"
                            onChange={(e) => handleChange(e)}
                            className='form-control mt-3'
                        />
                        <input
                            type='text'
                            placeholder='Receive Currency'
                            name="receive_currency"
                            onChange={(e) => handleChange(e)}
                            className='form-control mt-3'
                        />
                        <input
                            type='text'
                            placeholder='Description'
                            name="description"
                            onChange={(e) => handleChange(e)}
                            className='form-control mt-3'
                        />
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-12 mt-2'>
                        <button
                            onClick={handleSubmit}
                            className='btn btn-outline-success'
                        >Buy Now</button>
                    </div>

                </div>

            </div>
        </>
    );
}

export default SalePage;
