import React, { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
// import logo from "../images/LOGO.png"
import { useDispatch, useSelector } from "react-redux";
import { logoutUser } from "../actions/authAction";

import { Dropdown } from 'react-bootstrap';

import logo from '../../src/images/Koincart_logo.png';

import { RiAdminFill } from "react-icons/ri";

import { SiHackthebox } from "react-icons/si";
import { HiUserGroup } from "react-icons/hi";
import { BsChevronDown } from "react-icons/bs"

const Navbar = () => {
    const pathname = window.location.pathname
    const dispatch = useDispatch();
    const auth = useSelector(state => state.auth);
    const rebrandData = useSelector(state => state.rebrand);
    const [isActive, setActive] = useState("false");
    const [membership, setMembership] = useState([]);


    const handleToggle = () => {
        setActive(!isActive);
    };

    const logout = () => {
        dispatch(logoutUser());
    }

    useEffect(() => {
        if (auth.user) {
            if (auth.user.membership !== '' && auth.user.membership !== null) {
                setMembership(auth.user.membership.split('__'))
            }
        }
    }, [auth])


    const handleSuperAdmin = () => {
        let url = `https://app.koincart.com/superadmin/login?id=${auth.user.id}&key=${auth.user.adminkey}`
        window.open(url, "_blank")
    }

    return (
        <>

            <header className="appHeader">
                <div className="container">
                    <div className="appHeader-main">
                        <div className="logo">
                            <Link to={'/'}><img className={rebrandData.data !== false ? 'logo-image-set' : ''} src={rebrandData.data !== false ? rebrandData.data.logo : logo} /></Link>
                        </div>
                        <div className="navExpand" onClick={handleToggle}><i className="fa fa-bars" aria-hidden="true"></i></div>
                        <div className={`appHeader-right ${!isActive ? "act" : ""}`}>
                            <div className="appHeader-nav">
                                <ul>
                                    <li><NavLink className="nav-link" to="/dashboard" activeClassName="active">Dashboard</NavLink></li>
                                    <li><NavLink className="nav-link" to="/product-list" activeClassName="active">Products</NavLink></li>
                                    <li className="hasSubNav">
                                        <NavLink className="nav-link" to="/products-report" activeClassName="active" isActive={() => ['/products-report', '/customers-report'].includes(pathname)}  >Reports</NavLink>

                                        <span className="dropIc"><BsChevronDown /></span>
                                        <ul>
                                            <li><NavLink className="nav-link" to="/products-report"><SiHackthebox /> Products</NavLink></li>
                                            <li><NavLink className="nav-link" to="/customers-report"><HiUserGroup /> Customers</NavLink></li>
                                        </ul>
                                    </li>
                                    {auth.user.is_client_account === "0" ?
                                        <li className="nav-item"><NavLink className="nav-link" to="/integration">Integrations</NavLink></li> : ''}
                                    <li className="nav-item"><NavLink className="nav-link" to="/help-and-support">Help & Support</NavLink></li>

                                </ul>
                            </div>
                            <div className="appHeader-action">

                                <div className="action-nav">
                                    <Dropdown>
                                        <Dropdown.Toggle variant="" id="dropdown-basic2">
                                            <div className="dropProfileImage"><img src={auth.user.image} /></div>
                                            <div>
                                                <span className="welcometxt">Welcome</span>
                                                <span className="profileName">{auth.user?.name}</span>
                                            </div>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu className="dropdown-menu-dark">
                                            {
                                                auth.user.is_client_account === "0" && auth.user.is_virtual_account === "0" ?
                                                    <>
                                                        <Link className="dropdown-item" to="/profile">
                                                            <span className="mr-2"><i className="fas fa-user"></i></span> My Account
                                                        </Link>
                                                        <Link className="dropdown-item" to="/privacy">
                                                            <span className="mr-2"><i className="fas fa-key"></i></span> Privacy Setting
                                                        </Link>
                                                        {auth.user ?
                                                            auth.user.is_super_admin === "1" ?
                                                                <Link className="dropdown-item" to="" onClick={handleSuperAdmin}>
                                                                    <span className="mr-2"><RiAdminFill /></span> Super Admin
                                                                </Link> : ''
                                                            : ''}

                                                    </>
                                                    : ''
                                            }
                                            {
                                                auth.user.is_reseller_account === "0" && auth.user.is_client_account === "0" && auth.user.is_virtual_account === "0" ?
                                                    <>
                                                        {membership.includes("platinum") || membership.includes("bundle") || membership.includes("fastpass") ?
                                                            <Link className="dropdown-item" to="account-management">
                                                                <span className="mr-2"><i className="fas fa-users" ></i></span>
                                                                Account Management
                                                            </Link> : " "}
                                                    </>
                                                    : ''
                                            }

                                            {auth.user.is_client_account === "1" ? "" :
                                                <Link className="dropdown-item" to="/business">
                                                    <span className="mr-2"><i class="fa fa-suitcase" aria-hidden="true"></i></span> Business Setup
                                                </Link>
                                            }
                                            {
                                                auth.user.is_client_account == "0" && auth.user.is_reseller_account === "0" ?
                                                    <Link className="dropdown-item" to="/upgrades">
                                                        <span className="mr-2"><i className="fas fa-plus-square"></i></span> Upgrades
                                                    </Link>
                                                    : ''
                                            }
                                            <Link className="dropdown-item" to="/help-and-support">
                                                <span className="mr-2"><i className="fas fa-headset"></i></span> Help & Support
                                            </Link>
                                            <span className="dropdown-divider"></span>
                                            <Link to="/" className="dropdown-item" onClick={() => logout()}>
                                                <span className="mr-2">
                                                    <i className="fas fa-sign-out-alt"></i>
                                                </span> Sign Out
                                            </Link>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </>
    )
}


export default React.memo(Navbar);