import React, { useState } from "react";
import LoginFooter from "./LoginFooter";
import { Link, useHistory } from "react-router-dom";
// import logo from "../../images/LOGO.png";
import loginBg from "../../images/BG.png";
import { useDispatch } from "react-redux";
import queryString from "query-string";
import { resetPassword } from "../../actions/authAction";
import Alert from "../Alert";
import { Helmet } from "react-helmet";

import logoWhite from '../../images/logo-white.svg';
import loginIcon from '../../images/Koincart_logo.png';

const ResetPassword = ({ location }) => {

    const dispatch = useDispatch();
    const history = useHistory();

    const [loader, setLoader] = useState(false);
    const [password, setPassword] = useState({
        password: null,
        confirm_password: null,
        password_token: queryString.parse(location.search).token
    })

    const onInputChange = (e) => {
        setPassword({ ...password, [e.target.name]: e.target.value });
    }

    const onFormSubmit = (e) => {
        e.preventDefault();
        setLoader(true);

        dispatch(resetPassword(password, setLoader, history));
    }

    return (
        <>
            <Helmet>
                <title>Koincart | Reset Password </title>
            </Helmet>

            <Alert />

            {/* <header className="loginHeader">
               <div className="container">
                   <div className="loginLogo">
                      <img src={logo} alt="logo" />
                   </div>
               </div>
            </header> */}

            <div className="login-container">
                <div className="container full-height-container">
                    <div className="row full-height-row align-items-center">
                        <div className="col-lg-6">
                            <div className="login-container-right">
                                <div className="login-container-right-main">
                                    <div className="login-logo">
                                        <img src={loginIcon} />
                                    </div>
                                    <h2><span>Reset</span> Password</h2>
                                    <form method="post" onSubmit={(e) => onFormSubmit(e)}>
                                        <div className="form-group">
                                            <label>New Password</label>
                                            <input type="password" name="password" className="form-control"
                                                placeholder="************"
                                                required onChange={(e) => onInputChange(e)}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label>Confirm Password</label>
                                            <input type="password" name="confirm_password" className="form-control"
                                                placeholder="************" required
                                                onChange={(e) => onInputChange(e)}
                                            />
                                        </div>

                                        <div className="form-group">
                                            <button type="submit" className="btn btn-block btn-primary themeBtn" disabled={loader}>
                                                {loader ? <i className="fa fa-spinner fa-spin mr-2" /> : ''} Reset Password
                                            </button>
                                        </div>

                                        <div className="form-group">
                                            <p className="forgotLink">Login ? <Link to="/login" > Click here</Link></p>
                                        </div>

                                    </form>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-6">
                            <div className="login-container-left">
                                <div className="logoWhite">
                                    <img src={logoWhite} />
                                </div>
                                <p>Introducing Koincart... The Digital Product eCommerce Platform You Need.</p>
                                <div className="logoIcon">
                                    <img src={loginIcon} style={{ width: '250px' }} />
                                </div>
                                <p>Instantly boost your conversions, triple your revenue and scale your business using Koincart's powerful A.I. that does all the work for you.
                                </p>
                                <div className="logVid">
                                    <iframe width="100%" height="350" src={"https://player.vimeo.com/video/728808597?"} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; " allowfullscreen></iframe>
                                </div>
                                {/* <p>Start Profiting Today — 100% auto-pilot! </p> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <LoginFooter />

        </>
    )
}


export default ResetPassword;