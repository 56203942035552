import React, { useState, useEffect } from "react";
import dashImg1 from "../../images/dashImg1.png"
import { Bar } from 'react-chartjs-2';
import descImg from '../../images/dashImg3.png'
import desktop200 from '../../images/dashImg2.png'
import { AiFillStar } from 'react-icons/ai';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import axios from "axios";
import { baseURL } from "../../global/global";
import { useSelector } from "react-redux";

// import iMac from '../../images/imac.png';

const DashboardAbout = () => {
    const auth = useSelector(state => state.auth)
    const baseSymbol = useSelector(state => state.base.storeData)
    ChartJS.register(
        CategoryScale,
        LinearScale,
        BarElement,
        Title,
        Tooltip,
        Legend,
    );

    const [symbol, setSymbol] = useState("")
    const [saleData, setSaleData] = useState({
        avrageSale: "",
        grossSale: "",
        rateConversion: "",
        refoundAmount: 0,
        soldOrder: 0,
        unslodOrder: 0
    });

    const [graphData, setGraphData] = useState([])

    // const labels = 
    const data = {
        labels: ['Jan', 'Feb', 'March', 'Apr', 'May', 'June', 'Jul', 'Aug', "Sep", "Oct", "Nov", "Dec"],
        datasets: [
            {
                label: 'Sales',
                data: graphData,
                backgroundColor: '#764370',
            }
        ],
    };

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
        }
    };

    const updateGraphData = (data) => {
        let val = []
        val[0] = data.January
        val[1] = data.February
        val[2] = data.March
        val[3] = data.April
        val[4] = data.May
        val[5] = data.June
        val[6] = data.July
        val[7] = data.August
        val[8] = data.September
        val[9] = data.October
        val[10] = data.November
        val[11] = data.December
        setGraphData(val)
    }


    useEffect(() => {
        axios({
            method: "POST",
            url: `${baseURL}sale-summrary`,
            data: {},
            headers: { 'Content-Type': 'application/json', 'Authorization': auth.token }
        }).then((res) => {
            if (res.data.status === true) {
                setSaleData(res.data.data)
            }
        }).catch((error) => {
            console.log(error)
        })
    }, []);

    useEffect(() => {
        axios({
            method: "POST",
            url: `${baseURL}sale-graph`,
            data: {},
            headers: { 'Content-Type': 'application/json', 'Authorization': auth.token }
        }).then((res) => {
            if (res.data.status === true) {
                updateGraphData(res.data.data)
            }
        }).catch((error) => {
            console.log(error)
        })

    }, []);

    useEffect(() => {
        if (baseSymbol) {
            setSymbol(baseSymbol.symbol)
        }
    }, [baseSymbol])

    console.log()
    return (
        <>

            <section className="graphSec">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="graphSec-single">
                                <Bar options={options} data={data} />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="graphSec-single right">
                                <h2><AiFillStar /> Sales Summary</h2>
                                <div className="row pt-5">
                                    <div className="col-6 text-center">
                                        <h3>{saleData.soldOrder}</h3>
                                        <h4>Total Units Sold</h4>
                                    </div>
                                    <div className="col-6 text-center">
                                        <h3 className="red">{0}</h3>
                                        <h4>Units Refunded</h4>
                                    </div>
                                </div>
                                <ul className="revStat">
                                    <li>
                                        <span>Gross Sales</span>
                                        <span>({symbol}) {saleData.grossSale}</span>
                                    </li>
                                    <li>
                                        <span>Refunded Amount</span>
                                        <span>({symbol}) {saleData.refoundAmount}</span>
                                    </li>
                                    <li>
                                        <span>Product Conversion Rate</span>
                                        <span>({symbol}) {saleData.rateConversion}</span>
                                    </li>
                                    <li>
                                        <span>Average Sales</span>
                                        <span>({symbol}) {saleData.avrageSale}</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="featureSec">
                <div className="container">
                    <div className="featureSec-wrap">
                        <div className="featureSec-single">
                            <div className="row align-items-center">
                                <div className="col-lg-6">
                                    <div className="featureSec-left">
                                        <h2>Revolutionary Cryptocurrency Payments Widget</h2>
                                        <p>
                                            Allow businesses anywhere to quickly accept cryptocurrency in a variety of easy for their products and services.
                                        </p>
                                        <p>
                                            Our checkout widget allows them to pay in crypto in a click hassle-free and can be installed anywhere on any page fast and easily.
                                        </p>
                                        {/* <ul className="dash-list">
                                            <li>Drag & Drop Page Builder</li>
                                            <li>Unlimited Products</li>
                                            <li>100% Customization</li>
                                            <li>Library of Readymade Templates</li>
                                            <li>100% Mobile Responsive</li>
                                            <li>Custom Domains</li>
                                            <li>Accepts Payments via Digital Wallets and Cryptocurrency</li>
                                        </ul> */}
                                        {/* <div className="textL">See how you can use CourseReel to <span>create your first course</span></div> */}
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="featureSec-right"><img src={dashImg1} /></div>
                                </div>
                            </div>
                        </div>
                        <div className="featureSec-single">
                            <div className="row align-items-center">
                                <div className="col-lg-6">
                                    <div className="featureSec-left">

                                        <h2>Accept Cryptocurrency, StableCoins, and Fiat in the Most Ways Possible</h2>
                                        <p>Connect multiple crypto payment providers and choose which ones you want to use to accept cryptocurrency as payment.
                                        </p>
                                        <p>Allow customers to pay in crypto many different ways for the highest conversions possible. They can pay via wallet address, or through a centeralized service like PayPal crypto checkout.</p>
                                        <p>Plus, we have integration to allow all the most popular coins like Bitcoin and Ethereum, plus ‘stablecoins’ like USDT and even regular fiat USD.
                                        </p>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="featureSec-right"><img src={desktop200} /></div>
                                </div>
                            </div>
                        </div>
                        <div className="featureSec-single">
                            <div className="row align-items-center">
                                <div className="col-lg-6">
                                    <div className="featureSec-left">
                                        <h2>'Web3-Style' DFY Pages Templates</h2>
                                        <p>Create modern web3-style product order pages for small businesses from our huge selection of DFY templates in all the most popular niches most likely to accept cryptocurrency.</p>
                                        <p>Help businesses like yoga studios, pet supply businesses, info-marketers, and more quickly sell their products with crypto online with high converting DFY sites needed for better conversions today.</p>
                                        
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="featureSec-right"><img src={descImg} /></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default DashboardAbout;