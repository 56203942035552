import React from 'react'
import { Termstyle } from './TermsStyle'

const Terms = () => {

    return (
        <Termstyle>
            <section className='terms'>
                <div className="header">
                    <h1 className="text-center">Terms of Service</h1>

                    <div className="text-wrapper">
                        <p>THIS DOCUMENT CONTAINS IMPORTANT INFORMATION THAT WILL ALLOW YOU TO KNOW THE SCOPE OF OUR SERVICES AS WELL AS THE TERMS AND CONDITIONS TO USE THIS PLATFORM. PLEASE TAKE A FEW MINUTES TO READ THIS AGREEMENT BEFORE USING OUR PLATFORM AND SERVICES. </p>
                    </div>
                </div>

                <div className="container">
                    <div className="group">
                        <div className="title">
                            <h2>1. General</h2>
                        </div>

                        <div className="text-wrapper">
                            <p>This document describes the legal conditions applicable to you, when you access and use our Platform and Services ("Terms of Service").</p>

                            <p>These Terms of Service are a legally binding contract between you and VineaSX Solutions LLC.</p>

                            <p>The Platform and the Services provided, including the Website <span className="h">Koincart.io and Koincart.com</span> and any mobile application or software provided by us, belong to VineaSX Solutions LLC (hereinafter jointly referred to as "Koincart”, “We”, and/or “Us”).</p>
                        </div>

                        <div className="in-group">
                            <p className="mb-3">About Us:</p>

                            <ul className="list" type="none">
                                <li>- Product name: Koincart.</li>
                                <li>- Business/Owner: VineaSX Solutions LLC.</li>
                                <li>- Address: Office 10, Level 1 Sharjah Media City, Sharjah, United Arab Emirates.</li>
                                <li>- Email address: <a href="mailto:support@vineasx.com">support@vineasx.com</a></li>
                            </ul>
                        </div>

                        <div className="text-wrapper mb-3">
                            <p>Koincart offers the Services described below subject to the Terms of Service described herein.</p>

                            <p>Koincart is protected by all Copyrights and Intellectual Property Rights under the laws of the United Arab Emirates, as well as all international copyright treaties and regulations.</p>

                            <p>Please read the definitions applicable to these Terms of Service in Section 29 set out below.</p>
                        </div>

                        <div className="disclaimer">
                            <p>BY REGISTERING ON THIS PLATFORM AND USING OUR SERVICES YOU ARE EXPRESSLY ACCEPTING AND ELECTRONICALLY GIVING YOUR CONSENT TO ALL THE TERMS OF SERVICE SET FORTH HEREIN, <span className="h">AND ACKNOWLEDGE THAT YOU MUST COMPLY WITH ALL APPLICABLE LAWS AND REGULATIONS, INCLUDING ANY APPLICABLE LOCAL LAW OR REGULATION.</span>  IF YOU DO NOT AGREE TO THESE TERMS OF SERVICE, PLEASE LEAVE OUR PLATFORM IMMEDIATELY AND DO NOT USE OUR SERVICES.</p>
                        </div>
                    </div>

                    <div className="group">
                        <div className="title">
                            <h2>2. User Eligibility</h2>
                        </div>

                        <div className="text-wrapper">
                            <p>The use of this Platform is strictly available to persons who can enter into legally binding contracts under applicable law, as may be nationally and/or internationally prescribed.</p>

                            <p>Only individuals who are above the legal age as prescribed by applicable laws, are eligible to use this Platform and Services.</p>

                            <p>Individuals who are below legal age as prescribed by applicable laws (“minors”)<u className="dul">, and</u>/or individuals who are legally restricted from entering into binding contracts under law, are not eligible to use this Platform and our Services.</p>

                            <p>By accessing and using this Platform and Services you represent that you hold the legal age of majority, and hold full legal capacity to use this Platform and our Services in your jurisdiction.</p>
                        </div>
                    </div>

                    <div className="group">
                        <div className="title">
                            <h2>3. Services</h2>
                        </div>

                        <div className="text-wrapper">
                            <p>Koincart is a platform that allows users to process payments in cryptocurrencies when purchasing and/or selling products within a digital, comfortable and secure environment (“Services”).</p>

                            <p>Through our Platform you will find different features and functionalities when buying and/or selling products, making cryptocurrency transactions <u className="dul">quick and secure.</u></p>
                        </div>
                    </div>

                    <div className="group">
                        <div className="title">
                            <h2>4. User Account</h2>
                        </div>

                        <div className="text-wrapper">
                            <p>Users who wish to use our Services must register through our Website <span className="h">Koincart.io and Koincart.com.</span> During the registration process, Koincart will require the User to provide the following information:</p>
                        </div>

                        <div className="in-group">
                            <ul className="list" type="none">
                                <li>- Name.</li>
                                <li>- Last name.</li>
                                <li>- Email Address.</li>
                                <li>- <span className="h">Address.</span></li>
                            </ul>
                        </div>

                        <div className="text-wrapper">
                            <p>You will be exclusively responsible for maintaining the confidentiality of your User Account and password and for restricting its access to any third party.</p>
                            <p>You must be responsible for all activities carried out under your User Account login and the use of your password.</p>
                            <p>You agree to immediately notify Koincart if any unauthorized use of your password or User Account occurs, as any other breach of security, and ensure to logout from your User Account at the end of each session.</p>
                            <p>Koincart will take all commercially reasonable measures to protect your information against accidental or unlawful destruction, loss, unauthorized access, including measures to encrypt such information.</p>
                        </div>
                    </div>

                    <div className="group">
                        <div className="title">
                            <h2>5. Service Fees</h2>
                        </div>

                        <div className="text-wrapper">
                            <p>The use of the Platform may result in charges for the Services and/or payments received through the Platform ("Service Fees") as described in this Section. Fees will include applicable taxes, as required by law. <br />
                                Services Fees may apply, at any time, up to <b>one percent (1%)</b> per transaction, <span className="h">over the amount of each transaction</span> made through the Platform. Any Service Fees will be automatically charged by Koincart at the time the payment is processed.
                            </p>

                            <p>You expressly accept that the Service Fee set out in this Section is final and non-refundable, unless Koincart , in its sole discretion, determines otherwise.</p>

                            <p>You expressly agree that Koincart may modify the Service Fees from time to time, which will be notified to you in advance by us.</p>
                        </div>
                    </div>

                    <div className="group">
                        <div className="title">
                            <h2>6. License of Use</h2>
                        </div>

                        <div className="text-wrapper">
                            <p>Koincart grants you a non-exclusive and revocable license of use, while this agreement is in force, for the use of the Platform and Services, <u className="dul">including any</u> <span className="h">material,</span> software and/or update provided by us, provided that you accept these Terms of Service</p>
                            <p className="h">This license is granted to temporarily download one copy of the materials (information or software) on our Website for personal, non-commercial transitory viewing only. This is the grant of a license, not a transfer of title, and under this license you may not:</p>
                        </div>

                        <div className="in-group">
                            <ol className="list" type="a">
                                <li><span className="h">Modify or copy the materials;</span></li>
                                <li><span className="h">Use the materials for any commercial purpose, or for any public display (commercial or non-commercial);</span></li>
                                <li><span className="h">Attempt to decompile or reverse engineer any software contained on our Website;</span></li>
                                <li><span className="h">Remove any copyright or other proprietary notations from the materials; or</span></li>
                                <li><span className="h">Transfer the materials to another person or "mirror" the materials on any other server.</span></li>
                            </ol>
                        </div>

                        <div className="text-wrapper">
                            <p>You agree the license of use granted herein is provided on a temporary basis and may be <span className="h">terminated</span> by Koincart at any time. All rights not expressly granted are reserved. </p>

                            <p>This license of use shall automatically terminate if you violate any provision of these Terms of Service.</p>
                        </div>
                    </div>

                    <div className="group">
                        <div className="title">
                            <h2>7. Updates</h2>
                        </div>

                        <div className="text-wrapper">
                            <p>Koincart, may from time to time and without separate permission or consent from you, deploy an upgrade or update of, or replacement for, any Service (“Update”), and as a result of any such deployment you may not be able to use the applicable Service until any such Update is fully installed or activated. Each Update will be deemed as part of the “Services” for all purposes under this agreement.</p>

                            <p>Updates may include both additions to, and removals from, any particular features or functionality offered by a Service or may entirely replace it, and Koincart will determine the content, features and functionality of the updated Services in its sole discretion. Koincart is not required to offer you the option to decline or delay Updates but, in any event, you may need to download and allow any installation or activation of all available Updates to obtain the best benefit with the Services. Koincart may stop providing Customer Support until you have accepted and installed or activated all Updates.</p>

                            <p>Koincart in its sole discretion will determine when and if Updates are appropriate and will have no obligation to make any Updates available to you. Koincart in its sole discretion may stop providing Updates for any version of the Services other than the most current version, or Updates supporting use of the Services in connection with any device and/or versions of operating systems, browsers and any software with which the Services is designed to operate.</p>
                        </div>
                    </div>

                    <div className="group">
                        <div className="title">
                            <h2>8. Platform Maintenance</h2>
                        </div>

                        <div className="text-wrapper">
                            <p>Koincart will make its best efforts to provide and/or make the Platform and its Services, available in an uninterrupted manner to you. However, the <u className="dul">Platform</u> and its Services may be subject to downtime and/or may not be available temporarily due to scheduled maintenance activity initiated by Koincart and such scheduled maintenance activity may be notified to you through email by Koincart. In addition, the Platform and its Services may be unavailable due to unscheduled emergencies and/or for other causes beyond Koincart´s reasonable control and for such unscheduled emergencies prior notice shall not be given by Koincart.</p>
                        </div>
                    </div>

                    <div className="group">
                        <div className="title">
                            <h2>9. Acceptable Use and Restrictions </h2>
                        </div>

                        <div className="text-wrapper">
                            <p>The use of this Platform and our Services are subject to the following rules and restrictions:</p>
                        </div>

                        <div className="in-group">
                            <ol className="list" type="a">
                                <li>You may use our Platform and Services only in accordance with these Terms of Service.</li>
                                <li>You must not use our Platform and Services for any harmful, illegal, or nefarious purpose.</li>
                                <li>You must not use the Platform and Services in violation of any laws, regulations, ordinances or regulations.</li>
                                <li>You must not misrepresent your identity, age, or any other personal information.</li>
                                <li>You should treat other Users in a courteous and respectful manner.</li>
                                <li>You must not harass, bully, stalk, intimidate, assault, defame, harm or otherwise mistreat any User and/or individual through our Platform and its Services.</li>
                                <li>You agree not to express or imply that the products and/or services for which you solicit or receive any payment through the Platform are endorsed by Koincart.</li>
                                <li>You agree to comply with these Terms of Service, and check our Website, from time to time, to ensure you are aware of any change.</li>
                                <li>You undertake to maintain a strong password and take reasonable measures to protect the security of your login information.</li>
                                <li>You must not use any other User’s Account.</li>
                                <li>You must not breach the license of use granted herein.</li>
                                <li><b>It is not permitted and is strictly prohibited to use this Platform and Services to: </b>
                                    <ol className="list" type="i">
                                        <li>Offer, promote, solicit, or engage Users and/or any third party for ilegal and/or prohibite activity, task and/or service, including but not limited to drug trafficking; sex trafficking; pornography; slavery and/or prostitution; trafficking, exploits or abuse of child; human trafficking; escort services; arms trafficking; gambling or betting; money laundering and/or any kind of activities related with any organization designated as a terrorist or terrorist organization by the government of any country and/or by any international organization <b>("Prohibited Activities")</b></li>

                                        <li>Offer, promote, solicit, sell, and/or purchase any illegal and/or prohibited goods, items and/or products <b>("Prohibited Products”);</b> and/or</li>

                                        <li>Publish, upload, share, send, display and/or make available to any User and/or third party in any way any information, content and/or material related to Prohibited Activities and/or Prohibited <u className="dul">Products, and</u>/or that <b>("Prohibited Products”):</b>
                                            <ol className="list" type="1">
                                                <li>Is fraudulent, false, misleading (directly or by omission or failure to update information) or deceptive.</li>
                                                <li>Is defamatory, libelous, obscene, vulgar or offensive.</li>
                                                <li>Promotes discrimination, bigotry, racism, hatred, harassment or harm against any individual or group.</li>
                                                <li>Is violent or threatening or promotes violence or actions that are threatening to any other person or animal.</li>
                                                <li>Promotes harmful substances or products.</li>
                                                <li>Infringes the intellectual property rights or other rights of any third party.</li>
                                                <li>Discloses or publishes copies of private communications between private individuals without the explicit consent of all parties to the communication, including but not limited to (a) private or obscure personal information but also the aggregation of publicly available information to target, shame, blackmail, harass, intimidate, threaten, or endangered; and/or (b) sensitive or confidential information such as credit card numbers, social security numbers, or other non-public information; and/or (c) intimate or explicit images taken or posted without the subject's express consent.</li>
                                                <li>Violates these Terms of Service and/or any applicable local, national or international law or regulation.</li>
                                            </ol>
                                        </li>
                                    </ol>
                                </li>
                                <li>This Platform contains features that allow you to communicate with other Users and/or upload, post, share and/or make available to other Users any content.You expressly agree and acknowledge that you must not use this Platform and/or Services to:
                                    <ol className="list" type="i">
                                        <li>Contact any <u className="dul">User to</u> obtain and/or provide resources and/or services and/or contract, or employ, or for any other purposes not related to the use of this Platform and/or its Services as set forth herein.</li>
                                        <li>Contact Users and/or individual and/or entity to obtain and/or provide and/or encourage and/or promote any Prohibited Activities and/or Prohibited Products.</li>
                                        <li>Disclose personal, sensitive, private and/or proprietary information to which you do not hold the right to disclose.</li>
                                        <li>Upload, post, share and/or make available in any way any Prohibited Content.</li>
                                    </ol>
                                </li>
                                <li>You must not copy or reproduce our Platform and/or Services in any manner of any kind and must not use and/or allow:
                                    <ol className="list" type="i">
                                        <li>The use of any authorization code, license number, username/password, its combination or other activation code or number supplied by Koincart in connection with the Platform.</li>
                                        <li>Reverse engineer, disassemble, decompile, translate, reconstruct, transform or extract or any portion of the Platform and our Services, including any related malware signatures and malware detection routines.</li>
                                        <li>Change, modify or otherwise alter the Platform and/or our Services.</li>
                                        <li>Use any robot, crawler, site search/retrieval application, proxy or other manual or automatic device, method or process to access, retrieve, index, “data mine,” or in any way reproduce or circumvent the navigational structure or presentation of our Services or its contents;</li>
                                        <li>Use the Platform and Services to provide or build a product or service that competes with Koincart.</li>
                                    </ol>
                                </li>
                                <li>You must not use or attempt to use the Platform and/or its Services, to:
                                    <ol className="list" type="i">
                                        <li>Forge headers or otherwise manipulate identifiers in order to disguise the origin of any content broadcasted through the use of the Services.</li>
                                        <li>Upload, post, email or otherwise broadcast any unsolicited or unauthorized advertising, promotional materials, “junk mail,” “spam,” “chain letters,” or “pyramid schemes”.</li>
                                        <li>“Frame” or “mirror” any part of our Services without our prior written authorization.</li>
                                        <li>Use meta tags or code or other devices containing any reference to Koincart <u className="dul">or our</u> Platform (or any trademark, trade name, service mark, logo or slogan of Koincart) to direct any person to any other platform or website for any purpose;</li>
                                        <li>Collect or store personal and/or sensitive data without the knowledge and express consent of the owner of said information.</li>
                                        <li>Access any information and/or data and/or content not intended for you, or log into an Account that you are not authorized to access into.</li>
                                        <li>Solicit passwords for any purpose, or personal identifying information for commercial or unlawful purposes from other Users or disseminate another individual’s personal <u className="dul">data without</u> his or her permission.</li>
                                        <li>Damage, disable or impair the operation of, or gain or attempt to gain unauthorized access to the Platform and Services or any property, devices, software, services, networks or data connected to, or inter-operating with the Platform and Services or to any content or data stored, accessed or delivered through the Platform, by any means, including by hacking, phishing, spoofing or seeking to circumvent or defeat any firewalls, password protection or other information security protections or controls of whatever nature.</li>
                                        <li>Interfere with, disrupt or negatively affect this Platform, the servers, or our Services’ networks.</li>
                                        <li>Upload viruses or other malicious code or otherwise compromise the security of our Platform and its Services;</li>
                                        <li>Defeat or circumvent, attempt to defeat, or authorize or assist any third party in defeating or circumventing controls on the use of copies of the Platform and our Services.</li>
                                        <li>Use or develop any third-party applications that interact with our Platform and/or Services and/or any content and/or information without our written consent.</li>
                                        <li>Use, access, or publish the Koincart application programming interface without our written consent; </li>
                                        <li>Probe, scan or test the vulnerability of our Platform and/or its Services or any system or network.</li>
                                    </ol>
                                </li>
                                <li>Please note that to protect our security and integrity, as well as that of our Users, we reserve the right at any time, in our sole discretion, to block any User related to certain IP addresses and/or for any other reason, from accessing our Platform and Services.</li>
                                <li>Any illegal and/or prohibited and/or unauthorized uses of this Platform and Services, and/or breach of these Terms of Service, may be investigated, and appropriate legal action may be taken by us, including without limitation, civil, criminal, and injunctive redress. Koincart may investigate and take any available legal action in response to illegal and/or prohibited and/or unauthorized uses of this Platform and Services, as well as to the breach of these Terms of Service, including referring activities Koincart deems suspicious to governmental authorities.</li>
                            </ol>
                        </div>
                    </div>

                    <div className="group">
                        <div className="title">
                            <h2>10. Disclaimers, Warranties and Limitation of Liability</h2>
                        </div>

                        <div className="in-group">
                            <ol className="list" type="A">
                                <li>THIS PLATFORM AND SERVICES, <span className="h">INCLUDING ANY MATERIAL AVAILABLE ON THE PLATFORM,</span> IS PROVIDED AND AVAILABLE IN AN "AS IS" BASIS WITH THE EXCLUSIVE PURPOSE OF ENABLING OUR USERS TO MAKE PAYMENTS WITH CRYPTOCURRENCIES, AS DESCRIBED IN SECTION 3 OF THIS AGREEMENT.</li>
                                <li>KOINCART DOES NOT PROVIDE WARRANTIES OF ANY KIND, EITHER EXPRESS, IMPLIED, STATUTORY OR OTHERWISE WITH RESPECT TO THIS PLATFORM AND SERVICES INCLUDING (WITHOUT LIMITATION) ANY IMPLIED WARRANTIES OF SATISFACTORY QUALITY, MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, EXPECTATIONS OF PRIVACY, OR NON-INFRINGEMENT.</li>
                                <li>KOINCART DOES NOT WARRANT AND DISCLAIMS THAT OUR PLATFORM AND SERVICES WILL BE AVAILABLE AND/OR PROVIDED IN A TIMELY, SECURED MANNER, UNINTERRUPTED, FREE FROM ANY ERRORS, INCLUDING BUT NOT LIMITED TO VIRUSES OR OTHER MALICIOUS SOFTWARE AND/OR THAT THEY WILL WORK PROPERLY ON ANY GIVEN DEVICE OR WITH ANY PARTICULAR CONFIGURATION OF HARDWARE AND/OR SOFTWARE, OR THAT THEY WILL PROVIDE COMPLETE PROTECTION FOR THE INTEGRITY OF SELECTED DATA, INFORMATION OR CONTENT STORED AND/OR SHARED AND/OR BROADCASTED VIA THE INTERNET.</li>
                                <li>YOU EXPRESSLY ACCEPT AND ACKNOWLEDGE THAT KOINCART IS A PAYMENT MIDDLE-MAN PLATFORM, AND THAT KOINCART IS NOT A PARTY TO ANY AGREEMENT BETWEEN ANY BUYER OR SELLER, NOR DOES IT PARTICIPATE, NOR IS IT LIABLE FOR THE COMMERCIAL TRANSACTIONS AND AGREEMENTS AGREED BETWEEN USERS, NOR FOR THE PRODUCTS OFFERED, PURCHASED AND /OR SOLD BETWEEN USERS; NOR FOR ANY SERVICE CONTRACTED BETWEEN USERS.</li>
                                <li>YOU EXPRESSLY AGREE AND ACKNOWLEDGE THAT YOU ARE SOLELY RESPONSIBLE FOR ANY PAYMENT YOU MAKE THROUGH THIS PLATFORM AND KOINCART IS NOT LIABLE, DIRECTLY OR INDIRECTLY, FOR:
                                    <ol className="list" type="I">
                                        <li>ANY ERROR INCURRED BY YOU AND/OR ANY OTHER USER IN MAKING ANY PAYMENT THROUGH THIS PLATFORM;</li>
                                        <li>ANY UNDERPAYMENT OR OVERPAYMENT MADE BY YOU AND/OR ANY OTHER USER;</li>
                                        <li>ANY LOSS OR LACK OF FUNDS AT THE TIME OF MAKING ANY PAYMENT;</li>
                                        <li><span className="h">LOSS OF FUNDS DUE TO ERRORS IN THE SOFTWARE OR PLATFORM.</span></li>
                                        <li>ANY NON-PAYMENT OR FAILURE TO PAY FOR ANY PRODUCTS AND/OR SERVICES PURCHASED OR SOLD BY YOU AND/OR ANY OTHER USER; AND/OR</li>
                                        <li>ANY REFUND REQUESTED OR CLAIMED BY YOU AND/OR ANY OTHER USER FOR ANY DEFECTIVE OR ERRONEOUS PRODUCT AND/OR SERVICE SOLD OR PURCHASED BY YOU AND/OR ANY OTHER USER.</li>
                                    </ol>
                                </li>
                                <li>YOU EXPRESSLY AGREE AND ACKNOWLEDGE THAT ALL USERS REGISTERED ON THIS PLATFORM ARE INDEPENDENT BUSINESS OWNERS AND/OR INDEPENDENT CONTRACTORS AND NOT EMPLOYEES, PARTNERS, REPRESENTATIVES, AGENTS, AND/OR FRANCHISEES OF KOINCART. </li>
                                <li>KOINCART DOES NOT USE INDIVIDUALS TO SELL AND/OR BUY PRODUCTS AND/OR SERVICES OF ANY KIND THROUGH THIS PLATFORM. KOINCART EXCLUSIVELY PROVIDES PAYMENT PROCESSING SERVICES FOR THE PURPOSE OF CONNECTING PEOPLE WHO WISH TO MAKE CRYPTOCURRENCY PAYMENTS TO BUY OR SELL ITS PRODUCTS AND/OR PURCHASE ITS SERVICES AS DESCRIBED IN SECTION 3 ABOVE.</li>
                                <li>YOU EXPRESSLY ACKNOWLEDGE THAT KOINCART DOES NOT SUPERVISE, CONTROL, MONITOR, ENDORSE OR GUARANTEE THE PRODUCTS AND/OR SERVICES OFFERED BY OUR USERS AND EXPRESSLY DISCLAIMS ANY RESPONSIBILITY AND LIABILITY FOR ANY PRODUCT AND/OR SERVICE OFFERED, PROMOTED, PURCHASED, SOLD AND/OR HIRED BY ANY USER THROUGH THIS PLATFORM, INCLUDING BUT NOT LIMITED TO A WARRANTY OR CONDITION OF GOOD AND WORKMANLIKE PRODUCTS AND/OR SERVICES, WARRANTY OR CONDITION OF QUALITY OR FITNESS FOR A PARTICULAR PURPOSE, OR COMPLIANCE WITH ANY LAW, STATUTE, ORDINANCE, OR REGULATION.</li>
                                <li>KOINCART CANNOT GUARANTEE AND DOES NOT PROMISE ANY SPECIFIC RESULTS FROM THE USE OF THE PLATFORM AND ITS SERVICES.</li>
                                <li>YOU EXPRESSLY AGREE THAT: (I) ANY INFORMATION AND/OR CONTENT ABOUT OUR USERS DISPLAYED ON OUR PLATFORM IS ONLY INTENDED TO ALLOW USERS TO INTERACT WITH EACH OTHER AND CARRY OUT PAYMENT TRANSACTIONS BETWEEN USERS; (II) THE USE FOR ANY PURPOSE WHATSOEVER OF ANY INFORMATION AND/OR CONTENT SHARED, SUBMITTED, PUBLISHED, DISPLAYED, MADE AVAILABLE,  AND/OR UPLOADED, IN ANY WAY THROUGH OUR PLATFORM BY ANY USE IS SOLE AND EXCLUSIVE RESPONSIBILITY OF SUCH USER; (III) KOINCART WILL NOT BE RESPONSIBLE FOR ANY ACTION, DECISION, PURCHASE, SALE AND/OR ANY KIND OF TRANSACTION MADE BY YOU AND/OR ANY USER THROUGH THIS PLATFORM.</li>
                                <li>YOU EXPRESSLY AGREE THAT KOINCART WILL NOT BE RESPONSIBLE UNDER ANY CIRCUMSTANCES, DIRECTLY OR INDIRECTLY, AND HEREBY EXEMPT KOINCART FROM ANY RESPONSIBILITY FOR ANY UNLAWFUL OR PROHIBITED USE OF THIS PLATFORM AND OUR SERVICES, INCURRED BY YOU AND/O ANY OTHER USER; AND/OR FOR ANY PROHIBITED ACTIVITY (AS DESCRIBED IN PARAGRAPH “L” OF SECTION 9 ABOVE) OFFERED, PROMOTED, INCURRED AND/OR PERFORMED BY YOU AND/OR ANY OTHER USER; AND/OR BY ANY PROHIBITED PRODUCT (AS DESCRIBED IN PARAGRAPH “L” OF SECTION 9 ABOVE) OFFERED, PROMOTED, BOUGHT, ACQUIRED, SOLD, PAID BY YOU AND/OR ANY OTHER USER; AND/OR ANY PROHIBITED CONTENT (AS DESCRIBED IN PARAGRAPH “L” OF SECTION 9 ABOVE) SHARED, SUBMITTED, PUBLISHED, DISPLAYED, MADE AVAILABLE, UPLOADED IN ANY WAY ON THIS PLATFORM BY  YOUR AND/OR ANY OTHER USER.</li>
                                <li>YOU EXPRESSLY AGREE THAT KOINCART IS STRICTLY AN INTERMEDIARY USED TO CONNECT USERS REGISTERED ON THE PLATFORM AND PROCESS PAYMENTS RELATED TO THEIR PRODUCTS AND SERVICES AS DESCRIBED IN THESE TERMS OF SERVICE; AND FOR THIS REASON, KOINCART WILL NOT BE RESPONSIBLE UNDER ANY CIRCUMSTANCES, DIRECTLY OR INDIRECTLY, FOR ANY INCIDENT AND/OR MISUNDERSTANDING, AND/OR FRAUD AND/OR DAMAGE AND/OR LOSS AND/OR HARM AND/OR THEFT AND/OR INJURY CAUSED TO YOU BY YOU AND/OR ANY OTHER USER WHILE USING THIS PLATFORM AND ITS SERVICES.</li>
                                <li><span className="h">IN NO EVENT SHALL KOINCART OR ITS SUPPLIERS BE LIABLE FOR ANY DAMAGES (INCLUDING, WITHOUT LIMITATION, DAMAGES FOR LOSS OF DATA OR PROFIT, OR DUE TO BUSINESS INTERRUPTION) ARISING OUT OF THE USE OR INABILITY TO USE THE PLATFORM AND OUR SERVICES, INCLUDING ANY MATERIAL  AVAILABLE ON THE PLATFORM, EVEN IF KOINCART OR A KOINCART  AUTHORIZED REPRESENTATIVE HAS BEEN NOTIFIED ORALLY OR IN WRITING OF THE POSSIBILITY OF SUCH DAMAGE. BECAUSE SOME JURISDICTIONS DO NOT ALLOW LIMITATIONS ON IMPLIED WARRANTIES, OR LIMITATIONS OF LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES, THESE LIMITATIONS MAY NOT APPLY TO YOU.</span></li>
                                <li>THIS PLATFORM AND ITS SERVICES ARE STRICTLY <u className="dul">OFFERED TO</u> ENABLE PROCESSING OF PAYMENTS BETWEEN USERS, ANYTHING BEYOND THAT IS NOT IN OUR CONTROL AND IS DONE SO AT THE USERS’ OWN RISK. YOU MUST USE REASONABLE JUDGMENT ABOUT THE INFORMATION YOU REVEAL TO OTHER USERS. IT IS YOUR RESPONSIBILITY TO ENSURE YOUR SAFETY AND TO INVESTIGATE USERS AND VERIFY IF THEY ARE NOT POSSIBLE SCAMMERS AND/OR ARE OFFERING, FAKE, ILLEGAL AND/OR PROHIBITED PRODUCTS AND SERVICES. PLEASE NOTE THAT THERE ARE MANY DIFFERENT FRAUDS, SCHEMES, AND DECEPTIONS ON THE INTERNET, AND WE STRONGLY WARN YOU TO BE SKEPTICAL OF ANY USER UNTIL YOU LEARN MORE ABOUT SUCH INDIVIDUAL, AND VERIFY ITS INFORMATION AND BACKGROUND.</li>
                                <li>KOINCART IS NOT RESPONSIBLE FOR THE CONDUCT, WHETHER ONLINE OR OFFLINE, OF ANY USER ON THIS PLATFORM. YOU ASSUME ALL RISKS WHEN USING OUR PLATFORM AND SERVICES, INCLUDING BUT NOT LIMITED TO, ALL OF THE RISKS ASSOCIATED <u className="dul">WITH  ANY</u> SALE, PURCHASE, HIRING AND/OR ANY TYPE OF TRANSACTION AGREED INTO WITH ANY USER ON THIS PLATFORM. YOU AGREE TO TAKE ALL NECESSARY PRECAUTIONS BEFORE BUYING, SELLING, CONTRACTING AND/OR MAKING ANY PAYMENT TO ANOTHER USER THROUGH THIS PLATFORM.</li>
                                <li>YOU ACCEPT AND ACKNOWLEDGE THAT KOINCART IS UNDER NO CIRCUMSTANCES OBLIGATED TO PARTICIPATE IN AND/OR RESOLVE ANY DISPUTE BETWEEN USERS. ACCORDINGLY, IN CONNECTION WITH ANY DISPUTES, YOU AGREE TO HOLD KOINCART HARMLESS FROM CLAIMS, CLAIMS AND DAMAGES, ACTUAL AND CONSEQUENTIAL, OF EVERY KIND AND NATURE, KNOWN AND UNKNOWN, WITH RESPECT TO SUCH DISPUTES.</li>
                                <li>YOU EXPRESSLY <u className="dul">AGREE  THAT</u>  ANY PAYMENT OR TRANSACTION AGREED UPON BETWEEN USERS SHALL BE PERFORMED AS INDEPENDENT CONTRACTORS AND KOINCART DOES NOT HAVE AND SHALL NOT HAVE, DIRECTLY OR INDIRECTLY, ANY LIABILITY FOR SUCH PAYMENTS OR TRANSACTIONS.</li>
                                <li>YOU EXPRESSLY AGREE THAT KOINCART IS STRICTLY SUBJECT TO PROCESSING CRYPTOCURRENCY PAYMENTS. ACCORDINGLY, YOU ACCEPT AND ACKNOWLEDGE THE FOLLOWING:
                                    <ol className="list" type="I">
                                        <li>CRYPTOCURRENCIES ARE UNIQUE ASSETS, BACKED BY A SPECIALIZED TECHNOLOGY AND BASED ON TRUST, UNLIKE MOST CURRENCIES, WHICH ARE BACKED BY GOVERNMENTS, CENTRAL BANKS OR OTHER AUTHORITIES AND THAT FOR THIS REASON, THERE IS NO CENTRAL BANK THAT WILL PROTECT, GUARANTEE OR THAT MAY TAKE CORRECTIVE MEASURES TO  PROTECT THE VALUE OF THE CRYPTOCURRENCIES.</li>
                                        <li>CRYPTOCURRENCIES ARE UNREGULATED ASSETS, SO ANY EVENTUAL LOSS YOU MAY SUFFER WHEN MAKING OR RECEIVING PAYMENTS IN CRYPTOCURRENCIES WILL NOT BE COMPENSATED BY KOINCART AND/OR ANY GOVERNMENT OR AUTHORITY.</li>
                                        <li>CRYPTOCURRENCIES ISSUERS OR THIRD PARTIES MAY MAKE NEWS ANNOUNCEMENTS AND/OR RUMORS MAY ARISE THAT MAY IMPACT THE PRICE OF CRYPTOCURRENCIES, AND THESE ANNOUNCEMENTS OR RUMORS MAY OCCUR DURING THE PURCHASE OR SALE OF  ANY PRODUCT OR SERVICE PAID FOR WITH CRYPTOCURRENCIES , AND IT MAY SUDDENLY CAUSE AN UNEXPECTED POSITIVE OR NEGATIVE MOVEMENT IN  IN THE VALUE OF THE RELEVANT CRYPTOCURRENCY AND CAUSE LOSSES TO THE RELEVANT BUYER OR THE SELLER.</li>
                                    </ol>
                                </li>
                                <li>YOU EXPRESSLY AGREE AND ACKNOWLEDGE THAT KOINCART DOES NOT PROVIDE ADVICE ON CRYPTOCURRENCIES. FOR SUCH REASON, IF YOU ARE INEXPERIENCED OR DO NOT HAVE ADEQUATE KNOWLEDGE IN THIS AREA, WE STRONGLY RECOMMEND THAT YOU CONSULT A PROFESSIONAL IN THIS AREA, BEFORE MAKING OR RECEIVING ANY PAYMENTS IN CRYPTOCURRENCIES.</li>
                                <li>YOU HEREBY EXPRESSLY STATE THAT YOU ARE AWARE OF ALL THE RISKS INHERENT IN TRANSACTIONS WITH CRYPTOCURRENCIES AND DECLARE THAT ANY PAYMENT YOU MAKE OR RECEIVE IN CRYPTOCURRENCY THROUGH THIS PLATFORM AND SERVICES, YOU WILL DO SO AT YOUR OWN WILL AND AT YOUR OWN RISK.</li>
                                <li>TO THE FULL EXTENT PERMITTED BY LAW, IN NO EVENT WILL KOINCART AND/OR ITS AFFILIATES, PARTNERS, OFFICERS, EMPLOYEES, STAFF, AGENTS, DIRECTORS, LICENSORS OR ANY OTHER PERSON ASSOCIATED (“RELATED PERSONS”), BE LIABLE TO YOU AND/OR  ANY OTHER USER AND/OR ANY THIRD PARTY FOR ANY  DAMAGE AND/OR SCAM AND/OR INJURY AND/OR LOSS, INCLUDING DEATH, CAUSED BY: (I) YOU AND/OR ANY OTHER USER REGISTERED ON THIS PLATFORM; AND/OR  (II) ANY PROHIBITED ACTIVITIES PERFORMED BY YOU AND/OR ANY OTHER USER; AND/OR ANY PROHIBITED PRODUCT OFFERED, PROMOTED, SOLD AND/OR BOUGHT BY YOU AND/OR ANY OTHER USER; AND/OR (III) THE VIOLATION OF ANY PROVISION OF THESE TERMS OF SERVICE AND/OR ANY APPLICABLE LAW AND/OR REGULATION BY YOU AND/OR  ANY OTHER USER.</li>
                                <li>THE EXCLUSIONS AND LIMITATIONS OF LIABILITY OF KOINCART AND ITS RELATED PERSONS CONTAINED IN THESE TERM OF SERVICE WILL NOT LIMIT OR EXCLUDE THEIR POTENTIAL LIABILITY FOR ANY MATTER THAT MAY NOT OTHERWISE BE LIMITED OR EXCLUDED BY APPLICABLE LAWS.</li>
                            </ol>
                        </div>
                    </div>

                    <div className="group">
                        <div className="title">
                            <h2>11.	Our Emails</h2>
                        </div>

                        <div className="text-wrapper">
                            <p>Koincart will send emails to you, from time to time, to let them know about the service offers and important improvements and updates to our Platform and Services. You will be able to opt out of these emails at any time by clicking the unsubscribe option. However, other emails will be sent to you as system notifications for security reasons (passwords resets, logins from new devices, etc.) for which you may not deactivate this option.</p>
                        </div>
                    </div>

                    <div className="group">
                        <div className="title">
                            <h2 >12. Links</h2>
                        </div>

                        <div className="text-wrapper">
                            <p className="h">Koincart has not reviewed all of the sites linked to the Website of the Platform and is not responsible for the contents of any such linked sites. The inclusion of any link does not imply endorsement by Koincart of such sites. Use of any such linked web site is at the user's own risk.</p>
                        </div>
                    </div>

                    <div className="group">
                        <div className="title">
                            <h2>13. Revisions and Errata</h2>
                        </div>

                        <div className="text-wrapper">
                            <p className="h">The materials and/or content appearing on Platform could include technical, typographical, or photographic errors. Koincart does not warrant that any of the materials on its Platform are accurate, complete, or current. Koincart  may make changes to the materials and/or content available on the Platform at any time without notice. Koincart does not, however, make any commitment to update such materials and/or content.</p>
                        </div>
                    </div>

                    <div className="group">
                        <div className="title">
                            <h2>14.	User Support</h2>
                        </div>

                        <div className="text-wrapper">
                            <p>Koincart allows you to access support features available on the Platform through our Website.</p>
                            <p>By using the Platform and Services, you accept the modality by which Koincart offers to provide technical support.</p>
                            <p>You expressly agree that Koincart may choose to change technical support features at any time and without prior notice.</p>
                        </div>
                    </div>

                    <div className="group">
                        <div className="title">
                            <h2>15.	Ownership Rights</h2>
                        </div>

                        <div className="text-wrapper">
                            <p>Each and every intellectual property right and copyright held by Koincart in relation to the Platform and its Services; as any inventions; ideas; designs; patents; trademarks; logos; business secrets; domain names; source codes; software;  and know-how, among others, that currently exist and/or that have existed in the past and/or that may exist in the future with respect to Koincart, including any website and mobile application ("Intellectual Property"), are the exclusive property of, and belong solely to VineaSX Solutions LLC. Likewise, you accept that all moral and patrimonial rights to Intellectual Property belong solely and exclusively <u className="dul">to VineaSX</u> Solutions LLC.</p>
                            <p>You must not reproduce and/or disclose and/or use in its own benefit or for the benefit of any third parties, in its original or modified, altered, derived form, or in any other way, whether directly or indirectly, any Intellectual Property belonging <u className="dul">to VineaSX</u> Solutions LLC.</p>
                            <p>You agree that the use of the Platform and/or our Services, including the creation of your User Account, is exclusively limited to the provisions established in these Terms of Service, and that you have no right to claim any right or interest of any kind on the Platform and/or  Services and/or the Intellectual Property and/or any other right associated, derived,  or arising from such Intellectual Property.</p>
                        </div>
                    </div>

                    <div className="group">
                        <div className="title">
                            <h2>16. Privacy Policy</h2>
                        </div>

                        <div className="text-wrapper">
                            <p className="h">Your privacy is very important to us. Accordingly, we have developed this Privacy Policy Section in order for you to understand how we collect, use, communicate and disclose and make use of your personal information.</p>
                            <p className="h">The following outlines our Privacy Policy:</p>
                        </div>

                        <div className="in-group">
                            <ol className="list" type="a">
                                <li><span className="h">Before or at the time of collecting any personal information, we will identify the purposes for which such information is being collected.</span></li>
                                <li><span className="h">We will collect and use the personal information of our Users solely with the objective of fulfilling those purposes specified by us and for other compatible purposes, unless we obtain the consent of the User concerned or as required by law.</span></li>
                                <li><span className="h">We will only retain your personal information as long as necessary for the fulfillment of those purposes.</span></li>
                                <li><span className="h">We will collect personal information from each User by lawful and fair means and, where appropriate, with the knowledge or consent of the User concerned.</span></li>
                                <li><span className="h">Personal information should be relevant to the purposes for which it is to be used, and, to the extent necessary for those purposes, should be accurate, complete, and up-to-date.</span></li>
                                <li><span className="h">We will protect your personal information by reasonable security safeguards against loss or theft, as well as unauthorized access, disclosure, copying, use or modification.</span></li>
                                <li><span className="h">We will make readily available to Users information about our policies and practices relating to the management of personal information.</span></li>
                                <li><span className="h">We are committed to conducting our business in accordance with these principles in order to ensure that the confidentiality of  our Users' personal information is protected and maintained.</span></li>
                            </ol>
                        </div>
                    </div>

                    <div className="group">
                        <div className="title">
                            <h2>17.	Changes to these Terms of Service</h2>
                        </div>

                        <div className="text-wrapper">
                            <p>Koincart reserves the right to modify all or part of these Terms of Service at any time. The modified Terms of Service will be published on the Website and will come effective five (5) days following such publication. In the event that you do not agree to such changes, it must indicate so by sending an email to: <a href="mailto:support@vineasx.com" className="h">support@vineasx.com</a> within one (1) days following said publication, in which case you must leave the Platform immediately and also suspend the use of our Services and you should delete or destroy all copies of any software provided by us.</p>

                            <p>Upon expiration of the term above, it will be understood that you accepted all modifications made to these Terms of Service.</p>

                            <p className="h">By using this Platform and Services you are agreeing to be bound by the then current version of these Terms of Service.</p>
                        </div>
                    </div>

                    <div className="group">
                        <div className="title">
                            <h2>18.	Termination</h2>
                        </div>

                        <div className="text-wrapper">
                            <p>These Terms of Service will terminate immediately upon breach by you of any of the obligations herein, which may result in the suspension or termination of the User Account. Koincart reserves the right to any other remedy available under law in the event that you breach any of your obligations under these Terms of Service.</p>
                            <p><u className="dul">Koincart shall</u> not be liable to you or any third party, for the suspension, cancellation, termination or discontinuation of any of our Platform Services. Koincart reserves the right to modify, <u className="dul">restrict or</u> discontinue all or part of the Services, either temporarily or permanently, without notice, for any or no reason whatsoever.</p>
                        </div>
                    </div>

                    <div className="group">
                        <div className="title">
                            <h2>19.	Indemnity</h2>
                        </div>

                        <div className="text-wrapper">
                            <p>You declare that the use of the Platform and Service is intended for lawful purposes only and complies with all applicable laws and regulations.</p>
                            <p>You shall indemnify and hold Koincart, its partners, subsidiaries, affiliates, third-parties and their respective officers, directors, agents, and employees harmless against any and all claims brought by a third party for actions arising from or related to the unauthorized use of the Platform and  its Services, including but not limited to the misuse or operation of the Platform and/or its Services in combination or modification with other hardware or software belonging to a third party alleging infringement and/or misappropriation of its intellectual property rights.</p>
                        </div>
                    </div>

                    <div className="group">
                        <div className="title">
                            <h2>20.	Force Majeure</h2>
                        </div>

                        <div className="text-wrapper">
                            <p>If Koincart is prevented from carrying out its obligations under these Terms of Service as a result of any cause beyond its reasonable control, including but not limited to unavailability, interruptions, loss or malfunctions of internet services and/or any communication system; virus attack, breach, sabotage in our technological systems;  acts of God; acts of war or terrorism, civil or military disturbances; natural catastrophes; strikes; floods, failure of service providers; failures or malfunctions of devices and/or software and/or hardware; epidemics or pandemics, lockdown or quarantine measures; acts of government; and/or asimilar events, then in such an event, Koincart shall be relieved from its obligations and liabilities under these Terms of Service as such fulfillment of its obligations is prevented.</p>
                        </div>
                    </div>

                    <div className="group">
                        <div className="title">
                            <h2>21.	No Waiver</h2>
                        </div>

                        <div className="text-wrapper">
                            <p>Koincart's failure to insist upon strict performance of any provision of these Terms of Service shall not be deemed to be a waiver of its rights or remedies in respect of any present or future default of you in performance or compliance with any obligation on this agreement.</p>
                        </div>
                    </div>

                    <div className="group">
                        <div className="title">
                            <h2>22.	Entirety</h2>
                        </div>

                        <div className="text-wrapper">
                            <p>These Terms of Service constitute the entire agreement between Koincart and you, and supersedes and replaces all prior or contemporaneous understandings or agreements, written or oral, regarding such subject matter. These Terms of Service supersedes and replaces any other agreement that you have previously entered into with respect to our Services.</p>
                        </div>
                    </div>

                    <div className="group">
                        <div className="title">
                            <h2>23.	Severability</h2>
                        </div>

                        <div className="text-wrapper">
                            <p>In the event that a court of competent jurisdiction holds any provision of these Terms of Service to be invalid, such invalid provision shall be severed and have no effect on the remaining provisions of these Terms of Service, and they shall continue in full force and effect.</p>
                        </div>
                    </div>

                    <div className="group">
                        <div className="title">
                            <h2>24.	No Assignment</h2>
                        </div>

                        <div className="text-wrapper">
                            <p>You may not assign their rights or obligations under these Terms of <u className="dul">Service without</u> the prior written consent of Koincart.</p>
                        </div>
                    </div>

                    <div className="group">
                        <div className="title">
                            <h2>25.	Interpretation</h2>
                        </div>

                        <div className="text-wrapper">
                            <p>The headings in these Terms of Service do not affect its interpretation. The use of any gender includes all genders. The singular includes the plural and vice-versa. In the event that an ambiguity or question of intent or interpretation arises, in any judicial proceeding or otherwise, the rights and obligations set forth in these Terms of Service will be construed as having been drafted jointly by the parties, and no presumption or burden of proof will arise favoring or disfavoring any party by virtue of the authorship of any provisions of these Terms of Service.</p>
                        </div>
                    </div>

                    <div className="group">
                        <div className="title">
                            <h2>26.	Dispute Resolution </h2>
                        </div>

                        <div className="in-group">
                            <ol className="list" type="a">
                                <li>Between Users.

                                    <div className="text-wrapper">
                                        <p>Your interactions with other Users on our Platform and any <u className="dul">agreement, payments,</u> conditions, warranties or representations associated with any transaction, buy and sell,  and/or services agreed with any User  are the responsibility are made exclusively between you and such User. For this <u className="dul">reason</u> Koincart cannot be involved in any dispute between Users.</p>

                                        <p>If there is any dispute between you and any User of any kind, you agree to make its best efforts as to the resolution of such disagreements in good faith and by mutual agreement.</p>

                                        <p>If no such resolution is reached then you and the other User shall submit such dispute to the competent Courts in accordance with the laws applicable to both parties.</p>
                                    </div>
                                </li>

                                <li>Between Users and Koincart.

                                    <div className="text-wrapper">
                                        <p>In the event of any dispute  arising from or relating to your use of our Platform and our Services, you must use your best efforts to settle the dispute and resolve the issue in good faith, acknowledging mutual interests of both parties, in attempting to reach a just and equitable solution that may result satisfactory to you and Koincart before initiating any legal proceeding. If such resolution is not reached within a period of thirty (30) days, then, upon notice by either party to the other, such dispute shall be submitted to the exclusive jurisdiction of the Courts of <span className="h">the United Arab Emirates.</span></p>
                                    </div>
                                </li>
                            </ol>
                        </div>
                    </div>

                    <div className="group">
                        <div className="title">
                            <h2>27.	Governing Law and Jurisdiction </h2>
                        </div>

                        <div className="text-wrapper">
                            <p>These Terms of Use shall be governed by and construed in accordance with the laws in force in the <span className="h">Emirates of Sharjah and with the applicable federal laws in the United Arab <u className="dul">Emirates</u>,</span>  without regard to conflict of <u className="dul">laws</u> provisions. In the event of any dispute between you and Koincart that cannot be resolved in accordance with the provisions of Section above, you hereby agree to submit to the exclusive jurisdiction of the Courts <u className="dul">of <span className="h">the</span></u> <span className="h">Emirates of <b style={{ color: "#0000ff" }}>Sharjah</b>, United Arab Emirates.</span></p>
                        </div>
                    </div>

                    <div className="group">
                        <div className="title">
                            <h2>28.	Contact Us</h2>
                        </div>

                        <div className="text-wrapper">
                            <p>For any questions, comments, or complaints regarding these Terms of Service, you may address to Koincart by sending an email to support@vineasx.com and that email receipt must be acknowledged by Koincart, and in the event that delivery fails, notice can be sent by mail to the address mentioned below:</p>
                        </div>

                        <div className="in-group">
                            <ul className="list" type="none">
                                <li>Koincart - VineaSX Solutions LLC. <br />
                                    Office 10, Level 1 Sharjah Media City, Sharjah, <br />
                                    United Arab Emirates.
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div className="group">
                        <div className="title">
                            <h2>29.	Definitions</h2>
                        </div>

                        <div className="in-group">
                            <ul className="list">
                                <li><b>“Device”</b> means smartphones, tablets, laptops, desktops and any other similar digital or electronic equipment.</li>
                                <li><b>“Koincart" and/or “We” and/or “Us”</b> <u className="dul">means collectively</u> this Platform owned and operated by VineaSX Solutions LLC, with main address at Office 10, Level 1 Sharjah Media City, Sharjah, United Arab Emirates.</li>
                                <li><b>"Platform"</b> means Koincart digital platform owned and operated by VineaSX Solutions LLC, including our Website, and any software or mobile application provided by us.</li>
                                <li><b>“Prohibited Activities”</b> means any activity or service that breaches Section 9 of these Terms of Service.</li>
                                <li><b>“Prohibited Content”</b> means any information, content and/or material that breaches Section 9 of these Terms of Service.</li>
                                <li><b>“Prohibited Product”</b> means any good or item that breaches Section 9 of these Terms of Service.</li>
                                <li><b>“Services”</b> means services offered by Koincart, as described in Section 3 to these Terms of Service.</li>
                                <li><b>“Service Fees”</b> means any fees or charges <u className="dul">that may be</u> applied to Users by Koincart for the use of the Services, as described in Section 5 above.</li>
                                <li><b>“Terms of Service”</b> <u>means this</u>  document that governs the access and  use of the Platform and our Services.</li>
                                <li><b>“User” and/or “You”</b> means any registered individual on this Platform, who has access to, and uses the Services.</li>
                                <li><b>“User Account”</b> means the account that each User must create when registering in our Platform for the use of our Services.</li>
                                <li><b>“Website”</b> means <span className="h" style={{ color: "#0000ff" }}>[Include Website].</span></li>
                            </ul>
                        </div>
                    </div>
                </div>

                <p style={{ fontSize: "12px" }}>Last Updated: <span style={{ color: "#0000ff" }}>2nd August 2022.</span></p>
            </section>
        </Termstyle>
    )
}

export default Terms