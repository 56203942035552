import React, { useState } from "react";

import wordpress from "../../images/round-icon-wordpress.png";
import download from "../../images/round-icon-download.png";
import importIcon from "../../images/round-icon-import.png"
import customize from "../../images/round-icon-customize.png";
import Rebranding from "./Rebranding";
import youtubeimg from '../../images/youtubeimg.jpg';

import modalCloseIcon from '../../images/modal-close.png';


const UpgradesContent = () => {

    const [playing, setPlaying] = useState(false);
    const [frameUrl, setFrameUrl] = useState();

    const addFrameUrl = () => {
        setPlaying(true);
        setFrameUrl('https://player.vimeo.com/video/514577280?');
    }

    const closePop = () => {
        setPlaying(false);
        setFrameUrl('');
    }

    return (
        <>
            <div className="col-lg-8 tabRight">
                <div className="tabCont-right">
                    <div className="tab-content" id="v-pills-tabContent">

                        <div className="tab-pane fade show active">
                            <div className="tabepaneIn">
                                <h2 className="tabTitle">Welcome</h2>
                                <div className="tabepaneIn-main">
                                    <div className="tabepaneIn-cont">
                                        <h3>Welcome</h3>
                                        <p>To access your upgrades, click any of the available options on the left side menu</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="tab-pane fade" id="v-pills-wlrebranding" role="tabpanel" aria-labelledby="v-pills-wlrebranding-tab" >
                            <div className="tabepaneIn">
                                <h2 className="tabTitle">Rebranding</h2>
                                <div className="tabepaneIn-main">
                                    <div className="tabepaneIn-cont">
                                        <Rebranding />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="tab-pane fade" id="v-pills-fbtemplate" role="tabpanel" aria-labelledby="v-pills-fbtemplate-tab">
                            <div className="tabepaneIn">
                                <h2 className="tabTitle">FB Templates</h2>
                                <div className="tabepaneIn-main">
                                    <div className="tabepaneIn-cont">
                                        <h3>Facebook Ads Templates</h3>
                                        <div className="upgrade-para">
                                            <p className="text-center ">
                                                Download this done-for-you Facebook and Instagram ad creatives
                                                and ad copy templates that can easily be customized to be used
                                                for any niche
                                            </p>
                                        </div>
                                    </div>


                                    <div className="tabepaneIn-cont mt-4">
                                        <h3>Download your Facebook Ads Templates here</h3>
                                        <a
                                            href="https://s3-us-west-2.amazonaws.com/adsviser3/library/adpack1.zip"
                                            target="_blank"
                                        >
                                            <button className="demoLink btn-block mt-0">Download the Templates</button>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div
                            className="tab-pane fade"
                            id="v-pills-vidoeflix"
                            role="tabpanel"
                            aria-labelledby="v-pills-vidoeflix-tab"
                        >
                            <h5 className="text-white">Video Flix</h5>
                            <div className="upgrade-content">
                                <div className="row">
                                    <div className="col-md-8 mx-auto">
                                        <div className="upgrade-box-content  text-white  ">
                                            <div className="upgrade-heading">
                                                <h6>VideoFLIX - Netflix Style Video Site Builder</h6>
                                            </div>
                                            <div className="upgrade-para">
                                                <p className="text-center ">
                                                    VideoFLIX is a subscription-based video content management app
                                                    built on cloud. Using VideoFLIX you will be able to create
                                                    your own video content sharing website similar to NetFlix,
                                                    HBO-Go, DisneyPLUS etc; sharing your own or others unique
                                                    video content as channels or series. You will be able to
                                                    create free, one-time or monthly payment plans for your
                                                    customers to access your video content and build your video
                                                    content subscription business!
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row  mt-5">
                                    <div className="col-md-6">
                                        <div className="upgrade-box-content text-center p-3 text-white">
                                            <a
                                                href="https://abhibonus.s3-us-west-2.amazonaws.com/VideoFlix/VideoFlix_2.2.zip"
                                                target="_blank"
                                            >
                                                <button className="btn-change7">Download VideoFlix</button>
                                            </a>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="upgrade-box-content text-center p-3 text-white">
                                            <a
                                                href="https://abhibonus.s3-us-west-2.amazonaws.com/VideoFlix/VideoFlix+Training+Docs+updated.zip"
                                                target="_blank"
                                            >
                                                <button className="btn-change7">Download Tutorial Docs</button>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="tab-pane fade" id="v-pills-udamy" role="tabpanel" aria-labelledby="v-pills-udamy-tab">
                            <div className="tabepaneIn">
                                <h2 className="tabTitle">Acadeable</h2>
                                <div className="tabepaneIn-main">
                                    <div className="tabepaneIn-cont">
                                        <h3>Acadeable</h3>
                                        <div className="upgrade-para">
                                            <p className="text-center ">
                                                Download Udemy Style Website to showcase your courses. Instant
                                                Access to Udemy Style Website Builder to Sell Courses
                                            </p>
                                        </div>
                                        <a
                                            href="https://acadeable.s3.amazonaws.com/Acadeable_files.zip"
                                            target="_blank"
                                        >
                                            <button className="demoLink btn-block">Download Setup</button>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div
                            className="tab-pane fade"
                            id="v-pills-dfycourse"
                            role="tabpanel"
                            aria-labelledby="v-pills-dfycourse-tab"
                        >
                            <h5 className="text-white">DFY Course</h5>
                            <div className="upgrade-content">
                                <div className="row">
                                    <div className="col-md-8 mx-auto">
                                        <div className="upgrade-box-content  text-white  ">
                                            <div className="upgrade-heading">
                                                <h6 className="m-0">DFY Courses</h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt-5">
                                    <div className="col-md-6">
                                        <div className="upgrade-box-content text-center p-3 text-white">
                                            <a
                                                href="https://coursereel-app.s3-us-west-2.amazonaws.com/CourseEngagementHacks.zip"
                                                target="_blank"
                                            >
                                                <button className="btn-change7">Download DFY Course 1</button>
                                            </a>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="upgrade-box-content text-center p-3 text-white">
                                            <a
                                                href="https://acadeable.s3.amazonaws.com/dfycoursex196/HealthyBoundaries_GOLD.zip"
                                                target="_blank"
                                            >
                                                <button className="btn-change7">Download DFY Course 11</button>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt-5">
                                    <div className="col-md-6">
                                        <div className="upgrade-box-content text-center p-3 text-white">
                                            <a
                                                href="https://coursereel-app.s3-us-west-2.amazonaws.com/EcommerceWithWoocommerce.zip"
                                                target="_blank"
                                            >
                                                <button className="btn-change7">Download DFY Course 2</button>
                                            </a>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="upgrade-box-content text-center p-3 text-white">
                                            <a
                                                href="https://acadeable.s3.amazonaws.com/dfycoursex196/GOLD-WorkFromHome.zip"
                                                target="_blank"
                                            >
                                                <button className="btn-change7">Download DFY Course 12</button>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt-5">
                                    <div className="col-md-6">
                                        <div className="upgrade-box-content text-center p-3 text-white">
                                            <a
                                                href="https://coursereel-app.s3-us-west-2.amazonaws.com/GoogleAdsMastery.zip"
                                                target="_blank"
                                            >
                                                <button className="btn-change7">Download DFY Course 3</button>
                                            </a>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="upgrade-box-content text-center p-3 text-white">
                                            <a
                                                href="https://acadeable.s3.amazonaws.com/dfycoursex196/GOLD-PersonalBrandingBlueprint.zip"
                                                target="_blank"
                                            >
                                                <button className="btn-change7">Download DFY Course 13</button>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt-5">
                                    <div className="col-md-6">
                                        <div className="upgrade-box-content text-center p-3 text-white">
                                            <a
                                                href="https://coursereel-app.s3-us-west-2.amazonaws.com/HowToUseWebinarsForYourBusiness.zip"
                                                target="_blank"
                                            >
                                                <button className="btn-change7">Download DFY Course 4</button>
                                            </a>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="upgrade-box-content text-center p-3 text-white">
                                            <a
                                                href="https://acadeable.s3.amazonaws.com/dfycoursex196/FacebookAdSecrets.zip"
                                                target="_blank"
                                            >
                                                <button className="btn-change7">Download DFY Course 14</button>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt-5">
                                    <div className="col-md-6">
                                        <div className="upgrade-box-content text-center p-3 text-white">
                                            <a
                                                href="https://coursereel-app.s3-us-west-2.amazonaws.com/IMForNewbiesDeluxe.zip"
                                                target="_blank"
                                            >
                                                <button className="btn-change7">Download DFY Course 5</button>
                                            </a>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="upgrade-box-content text-center p-3 text-white">
                                            <a
                                                href="https://acadeable.s3.amazonaws.com/dfycoursex196/EmailMarketingBasicsVideo.zip"
                                                target="_blank"
                                            >
                                                <button className="btn-change7">Download DFY Course 15</button>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt-5">
                                    <div className="col-md-6">
                                        <div className="upgrade-box-content text-center p-3 text-white">
                                            <a
                                                href="https://coursereel-app.s3-us-west-2.amazonaws.com/ModernPodcastingUp.zip"
                                                target="_blank"
                                            >
                                                <button className="btn-change7">Download DFY Course 6</button>
                                            </a>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="upgrade-box-content text-center p-3 text-white">
                                            <a
                                                href="https://acadeable.s3.amazonaws.com/dfycoursex196/EcommerceWithWoocommerce.zip"
                                                target="_blank"
                                            >
                                                <button className="btn-change7">Download DFY Course 16</button>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt-5">
                                    <div className="col-md-6">
                                        <div className="upgrade-box-content text-center p-3 text-white">
                                            <a
                                                href="https://coursereel-app.s3-us-west-2.amazonaws.com/SimpleSocialMediaContent.zip"
                                                target="_blank"
                                            >
                                                {" "}
                                                <button className="btn-change7">Download DFY Course 7</button>
                                            </a>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="upgrade-box-content text-center p-3 text-white">
                                            <a
                                                href="https://acadeable.s3.amazonaws.com/dfycoursex196/DealClosingSecrets.zip"
                                                target="_blank"
                                            >
                                                {" "}
                                                <button className="btn-change7">Download DFY Course 17</button>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt-5">
                                    <div className="col-md-6">
                                        <div className="upgrade-box-content text-center p-3 text-white">
                                            <a
                                                href="https://coursereel-app.s3-us-west-2.amazonaws.com/SimpleSocialMediaContent.zip"
                                                target="_blank"
                                            >
                                                {" "}
                                                <button className="btn-change7">Download DFY Course 8</button>
                                            </a>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="upgrade-box-content text-center p-3 text-white">
                                            <a
                                                href="https://acadeable.s3.amazonaws.com/dfycoursex196/CourseEngagementHacks.zip"
                                                target="_blank"
                                            >
                                                <button className="btn-change7">Download DFY Course 18</button>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt-5">
                                    <div className="col-md-6">
                                        <div className="upgrade-box-content text-center p-3 text-white">
                                            <a
                                                href="https://coursereel-app.s3-us-west-2.amazonaws.com/TIKTOKADSMASTERY.zip"
                                                target="_blank"
                                            >
                                                <button className="btn-change7">Download DFY Course 9</button>
                                            </a>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="upgrade-box-content text-center p-3 text-white">
                                            <a
                                                href="https://acadeable.s3.amazonaws.com/dfycoursex196/ClicksAndTraffic.zip"
                                                target="_blank"
                                            >
                                                <button className="btn-change7">Download DFY Course 19</button>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt-5">
                                    <div className="col-md-6">
                                        <div className="upgrade-box-content text-center p-3 text-white">
                                            <a
                                                href="https://coursereel-app.s3-us-west-2.amazonaws.com/UdemyForRecurringIncome.zip"
                                                target="_blank"
                                            >
                                                <button className="btn-change7">Download DFY Course 10</button>
                                            </a>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="upgrade-box-content text-center p-3 text-white">
                                            <a
                                                href="https://acadeable.s3.amazonaws.com/dfycoursex196/AntiAnexietyFormulaVideoUp.zip"
                                                target="_blank"
                                            >
                                                <button className="btn-change7">Download DFY Course 20</button>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="tab-pane fade" id="v-pills-client" role="tabpanel" aria-labelledby="v-pills-client-tab">

                            <div className="tabepaneIn">
                                <h2 className="tabTitle">Client Contract</h2>
                                <div className="tabepaneIn-main">
                                    <div className="tabepaneIn-cont">
                                        <h3>Client Contract</h3>
                                        <div className="upgrade-para">
                                            <p className="text-center ">
                                                Download our "Client Contract" template. This is a docx file,
                                                works best with MS Word. Edit the doc and add your business
                                                name, address and other details. Fill the empty blank space
                                                with your service, eg Video Marketing, Local Reputation
                                                Management, Social Media Marketing, Design &amp; Creatives,
                                                Ads Management and so on.
                                            </p>
                                        </div>
                                    </div>

                                    <div className="tabepaneIn-cont-alt">
                                        <div className="upgrade-content">
                                            <div className="row">
                                                <div className="col-xl-4 mb-2">
                                                    <div className="upgrade-box-content text-center  text-white">
                                                        <div className="upgrade-heading">
                                                            <h6>Brief Pitch Template</h6>
                                                        </div>
                                                        <div className="upgrade-para p-3">
                                                            <a
                                                                href="https://misctrainingvideos.s3.amazonaws.com/Client+Contract/Client-Brief-Form.doc"
                                                                target="_blank"
                                                            >
                                                                <button className="demoLink btn-block mt-0">Download</button>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xl-4  mb-2">
                                                    <div className="upgrade-box-content text-center  text-white">
                                                        <div className="upgrade-heading">
                                                            <h6>Service Agreement</h6>
                                                        </div>
                                                        <div className="upgrade-para p-3">
                                                            <a
                                                                href="https://misctrainingvideos.s3.amazonaws.com/Client+Contract/Services+Agreement.doc"
                                                                target="_blank"
                                                            >
                                                                <button className="demoLink btn-block mt-0">Download</button>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xl-4  mb-2">
                                                    <div className="upgrade-box-content text-center  text-white">
                                                        <div className="upgrade-heading">
                                                            <h6>Meeting Setup Template</h6>
                                                        </div>
                                                        <div className="upgrade-para p-3">
                                                            <a
                                                                href="https://misctrainingvideos.s3.amazonaws.com/Client+Contract/Meeting-Doc.doc"
                                                                target="_blank"
                                                            >
                                                                <button className="demoLink btn-block mt-0">Download</button>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="tab-pane fade" id="v-pills-reseller" role="tabpanel" aria-labelledby="v-pills-reseller-tab">

                            <div className="tabepaneIn">
                                <h2 className="tabTitle">Reseller License</h2>
                                <div className="tabepaneIn-main">
                                    <div className="tabepaneIn-cont">
                                        <h3>Reseller License</h3>
                                        <div className="upgrade-para">
                                            <p className="text-center ">
                                                Go to JVZoo Dashboard and request your affiliate link. Once
                                                you've requested, submit a ticket with your KoinCart Business
                                                transaction ID, affiliate ID and email address for our team to
                                                activate your reseller license. Please allow upto 2 working
                                                days.
                                            </p>
                                        </div>
                                        <div className="row   mt-5">
                                            <div className="col-md-6">
                                                <div className="upgrade-box-content text-center  text-white">
                                                    <div className="">
                                                        <a
                                                            href="https://www.jvzoo.com/affiliate/affiliateinfonew/index/383555"
                                                            target="_blank"
                                                        >
                                                            <button className="demoLink btn-block mt-0">
                                                                Request Affiliate Link
                                                            </button>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="upgrade-box-content text-center  text-white">
                                                    <div className="">
                                                        <a href="https://support.vineasx.com/" target="_blank">
                                                            <button className="demoLink btn-block mt-0">Raise Support Ticket</button>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="tab-pane fade" id="v-pills-webhosting" role="tabpanel" aria-labelledby="v-pills-webhosting-tab">

                            <div className="tabepaneIn">
                                <h2 className="tabTitle">Web Hosting</h2>
                                <div className="tabepaneIn-main">
                                    <div className="tabepaneIn-cont">
                                        <h3>Web Hosting</h3>
                                        <div className="upgrade-para">
                                            <p className="text-center ">
                                                Please raise a support ticket with your KoinCart purchase
                                                transaction ID, your domain name that you currently own and
                                                wish to use with the hosting, email address, your full name
                                                and a note about how you would be using the webhosting for
                                                your business.
                                            </p>
                                        </div>
                                        <a href="https://support.vineasx.com/" target="_blank">
                                            <button className="demoLink btn-block">Raise a Ticket</button>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="tab-pane fade" id="v-pills-videocommercial" role="tabpanel" aria-labelledby="v-pills-videocommercial-tab">

                            <div className="tabepaneIn">
                                <h2 className="tabTitle">Video Commercial</h2>
                                <div className="tabepaneIn-main">
                                    <div className="tabepaneIn-cont">
                                        <h3>Video Commercial</h3>
                                        <div className="upgrade-para">
                                            <p className="text-center ">
                                                6X Stunning &amp; Compelling Video Commercials
                                            </p>
                                        </div>
                                        <a
                                            href="https://abhibonus.s3-us-west-2.amazonaws.com/6X+Stunning+%26+Compelling+Video+Commercials+.zip"
                                            target="_blank"
                                        >
                                            <button className="demoLink btn-block">Download Videos</button>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="tab-pane fade" id="v-pills-videopromo" role="tabpanel" aria-labelledby="v-pills-videopromo-tab">

                            <div className="tabepaneIn">
                                <h2 className="tabTitle">Video Promos</h2>
                                <div className="tabepaneIn-main">
                                    <div className="tabepaneIn-cont">
                                        <h3>Video Promos</h3>
                                        <div className="upgrade-para">
                                            <p className="text-center ">
                                                Open a ticket with the following details per video:
                                            </p>
                                            <ol>
                                                <li>Your KoinCart credentials</li>
                                                <li>Permission to access your account</li>
                                                <li>
                                                    What is the purpose of this video that you wish to create?
                                                </li>
                                                <li>Name of the template you wish to use</li>
                                                <li>Number of the template you wish to use</li>
                                                <li>Screenshot of the template you wish to use</li>
                                                <li>
                                                    Content for the video. Please click on the EDIT option for
                                                    the template you wish to use and share with us all the text
                                                    content, logo, music file or Text-to-speech content along
                                                    with images/video clips you wish to use in your videos.
                                                </li>
                                            </ol>
                                        </div>
                                        <a href="https://support.vineasx.com/" target="_blank">
                                            {" "}
                                            <button className="demoLink btn-block">Raise a Ticket</button>
                                        </a>
                                    </div>
                                    <div className="tabepaneIn-cont mt-4">
                                        <h3>Note</h3>
                                        <div className="upgrade-para">
                                            <p className="text-center ">
                                                we have the rights to deny a request if it does not match with
                                                the video template requirements. Video creation can take up to
                                                5-6 working days. The video will be available inside your
                                                KoinCart Account.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>



                        <div className="tab-pane fade" id="v-pills-dfylead" role="tabpanel" aria-labelledby="v-pills-dfylead-tab">
                            <div className="tabepaneIn">
                                <h2 className="tabTitle">DFY Lead Magnets</h2>
                                <div className="tabepaneIn-main">
                                    <div className="tabepaneIn-cont">
                                        <h3 className="text-white">DFY Lead Magnets</h3>
                                        <a
                                            href="https://s3-us-west-2.amazonaws.com/virtualpioneer.net/Sendiio/100.zip"
                                            target="_blank"
                                        >
                                            <button className="demoLink btn-block mt-0" type="button">
                                                Download
                                            </button>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="tab-pane fade" id="v-pills-agency" role="tabpanel" aria-labelledby="v-pills-agency-tab">

                            <div className="tabepaneIn">
                                <h2 className="tabTitle">Agency</h2>
                                <div className="tabepaneIn-main">
                                    <div className="tabepaneIn-cont">
                                        <h3>Agency</h3>
                                        <div className="row">
                                            <div className="col-md-12 mx-auto">
                                                <div className="upgrade-box-content p-2 text-white  ">
                                                    <div className="row">
                                                        <div className="col-md-2 pr-0 text-center ">
                                                            <img src={wordpress} alt="wprdpress" />
                                                        </div>
                                                        <div className="col-md-10">
                                                            <div className="upgrade">
                                                                <p className="m-0">
                                                                    Fresh Install and configure wordpress at your server. If
                                                                    you need help in installing and configuring wordpress,
                                                                    Click here to find the tutorial.
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="upgrade-box-content mt-3 p-2 text-white  ">
                                                    <div className="row">
                                                        <div className="col-md-2 pr-0 text-center ">
                                                            <img src={download} alt="download" />
                                                        </div>
                                                        <div className="col-md-10">
                                                            <div className="upgrade">
                                                                <p className="m-0">
                                                                    Once wordpress is installed at your server. Download the
                                                                    site content by clicking the "Download site button". This
                                                                    will download a wpress file which you will be needing to
                                                                    import in your wordpress site.
                                                                </p>
                                                                <div className="row  d-flex justify-content-center">
                                                                    <div className="col-md-12">
                                                                        <div className="upgrade-box-content text-center  text-white">
                                                                            <div className="upgrade-para p-3">
                                                                                <a
                                                                                    href="https://misctrainingvideos.s3.amazonaws.com/Agency+Website/AgencyWebsite_new.wpress"
                                                                                    target="_blank"
                                                                                >
                                                                                    <button className="demoLink btn-block mt-0">
                                                                                        Download the site
                                                                                    </button>
                                                                                </a>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="upgrade-box-content mt-3 p-2 text-white  ">
                                                    <div className="row">
                                                        <div className="col-md-2 pr-0 text-center ">
                                                            <img src={importIcon} alt="import" />
                                                        </div>
                                                        <div className="col-md-10">
                                                            <div className="upgrade">
                                                                <p className="m-0">
                                                                    Import the xml file in to your wordpress site. If you need
                                                                    help importing the xml file in wordpress, Click here to
                                                                    find the tutorial.
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="upgrade-box-content mt-3 p-2 text-white  ">
                                                    <div className="row">
                                                        <div className="col-md-2 pr-0 text-center ">
                                                            <img src={customize} alt="customize" />
                                                        </div>
                                                        <div className="col-md-10">
                                                            <div className="upgrade">
                                                                <p className="m-0">
                                                                    Customize the site as according to your need. If you need
                                                                    help customizing the site. Click here to find the tutorial
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="tabepaneIn-cont mt-4">
                                        <h3>Tutorial</h3>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="upload-box-content">
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <div className="tutorial-content">
                                                                <div className="row">
                                                                    <div className="col-md-2 text-center">
                                                                        <img src={wordpress} alt="wordpress" />
                                                                    </div>
                                                                    <div className="col-md-10">
                                                                        <div className="upgrade ml-lg-4">
                                                                            <a className="btn-block"
                                                                                href="https://misctrainingvideos.s3.amazonaws.com/Agency+Website/Install+Wordpress+in+cPanel.pdf"
                                                                                target="_blank"
                                                                            >
                                                                                <button
                                                                                    title="Install WordPress From cPanel"
                                                                                    className="demoLink btn-block mt-0"
                                                                                >
                                                                                    Install WordPress From cPanel
                                                                                </button>
                                                                            </a>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row mt-4">
                                                                    <div className="col-md-2 text-center">
                                                                        <img src={importIcon} />
                                                                    </div>
                                                                    <div className="col-md-10">
                                                                        <div className="upgrade ml-lg-4">
                                                                            <a
                                                                                href="https://misctrainingvideos.s3.amazonaws.com/Agency+Website/Import+Agency+Data.pdf"
                                                                                target="_blank"
                                                                            >
                                                                                <button className="demoLink btn-block mt-0">
                                                                                    Import Website
                                                                                </button>
                                                                            </a>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row mt-4">
                                                                    <div className="col-md-2 text-center">
                                                                        <img src={customize} />
                                                                    </div>
                                                                    <div className="col-md-10">
                                                                        <div className="upgrade ml-lg-4">
                                                                            <a
                                                                                href="https://misctrainingvideos.s3.amazonaws.com/Agency+Website/Customize+Agency+Website.pdf"
                                                                                target="_blank"
                                                                            >
                                                                                <button className="demoLink btn-block mt-0">
                                                                                    Customize website
                                                                                </button>
                                                                            </a>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row mt-4">
                                                                    <div className="col-md-2 text-center">
                                                                        <img src={download} />
                                                                    </div>
                                                                    <div className="col-md-10">
                                                                        <div className="upgrade ml-lg-4">
                                                                            <a
                                                                                href="https://misctrainingvideos.s3.amazonaws.com/Agency+Website/Upload+Video+and+Usage+in+Agency+Website.pdf"
                                                                                target="_blank"
                                                                            >
                                                                                <button
                                                                                    className="demoLink btn-block mt-0"
                                                                                    title="Upload Video and Usage in Website"
                                                                                >
                                                                                    Upload Video and Usage in Website
                                                                                </button>
                                                                            </a>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    <div className="tabepaneIn-cont mt-4">
                                        <h3>How to Install the Agency Website</h3>
                                        <div className="row justify-content-center">
                                            <div className="col-md-6">
                                                <div className="card fb-card border-0">
                                                    <div
                                                        className="card-header text-center"
                                                        style={{ background: "rgb(0, 123, 255)" }}
                                                    >
                                                        <h6 className="text-white Account-Management-headings">
                                                            How to Install the Agency Website
                                                        </h6>
                                                    </div>
                                                    <div className="card-body p-0">
                                                        <img src={youtubeimg} alt="" className="img-fluid" />
                                                        <span className="youtubeicon" onClick={(e) => addFrameUrl()} style={{ cursor: "pointer", color: '#007bff' }} data-toggle="modal" data-target="#MyModal" ><i class="fas fa-play-circle"></i></span>
                                                        {/* <div className="vid-wrapper">
                                                <div className="plyr__video-embed" id="player">
                                                    <iframe
                                                        src="https://player.vimeo.com/video/514577280?"
                                                        frameBorder={0}
                                                        allow="autoplay"
                                                        allowFullScreen
                                                        width="100%"
                                                        height="100%"
                                                    />
                                                </div>
                                            </div> */}
                                                    </div>
                                                    <div >

                                                    </div>
                                                </div>

                                                <div className={`modal VideoModal ${playing ? 'show-modal' : ''} `}>
                                                    <div className="modal-dialog modal-dialog-centered" role="document">
                                                        <div className="modal-content">
                                                            {/* <div className="modal-header">
                                                <h5 className="modal-title"></h5>
                                                <button  type="button"  onClick={(e)=>closePop('')}  className="close" data-dismiss="modal"
                                                        aria-label="Close">
                                                    <span aria-hidden="true">
                                                        <i className="fas fa-times-circle" style={{color: "#ffffff"}} ></i></span>
                                                </button>
                                            </div> */}
                                                            <div className="modal-body">
                                                                <div onClick={(e) => closePop('')} data-dismiss="modal" className="vidClose"><img src={modalCloseIcon} /></div>
                                                                <div className="embed-responsive embed-responsive-16by9">
                                                                    <iframe id="frameSrc" className="embed-responsive-item" src={frameUrl} allowFullScreen=""></iframe>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>


                        <div className="tab-pane fade" id="v-pills-social" role="tabpanel" aria-labelledby="v-pills-social-tab">

                            <div className="tabepaneIn">
                                <h2 className="tabTitle">Social Media Assets</h2>
                                <div className="tabepaneIn-main">
                                    <div className="tabepaneIn-cont">
                                        <h3>Social Media Assets</h3>
                                        <div className="upgrade-para">
                                            <p className="text-center ">
                                                Download your plug &amp; play social media assets below
                                            </p>
                                        </div>

                                        <div className="row  d-flex justify-content-center  mt-5">
                                            <div className="col-xl-6">
                                                <div className="upgrade-box-content text-center  text-white">
                                                    <div className="upgrade-para p-3">
                                                        <a
                                                            href="https://abhibonus.s3-us-west-2.amazonaws.com/upgradebonus/Visual+Assets/24+Month+Social+Media+Calendar.xlsx"
                                                            target="_blank"
                                                        >
                                                            <button className="demoLink btn-block mt-0">Social Media Calendar</button>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-xl-6">
                                                <div className="upgrade-box-content text-center  text-white">
                                                    <div className="upgrade-para p-3">
                                                        <a
                                                            href="https://abhibonus.s3-us-west-2.amazonaws.com/upgradebonus/Visual+Assets/800+Motivational+Videos.zip"
                                                            target="_blank"
                                                        >
                                                            <button className="demoLink btn-block mt-0">Social Media Captions</button>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row  d-flex justify-content-center  mt-5">
                                            <div className="col-xl-6">
                                                <div className="upgrade-box-content text-center  text-white">
                                                    <div className="upgrade-para p-3">
                                                        <a
                                                            href="https://abhibonus.s3-us-west-2.amazonaws.com/upgradebonus/Visual+Assets/6900%2B+Facts+Images.zip"
                                                            target="_blank"
                                                        >
                                                            <button className="demoLink btn-block mt-0">
                                                                Social Media Video Posts
                                                            </button>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-xl-6">
                                                <div className="upgrade-box-content text-center  text-white">
                                                    <div className="upgrade-para p-3">
                                                        <a
                                                            href="https://abhibonus.s3-us-west-2.amazonaws.com/upgradebonus/Visual+Assets/Captions.xlsx"
                                                            target="_blank"
                                                        >
                                                            <button className="demoLink btn-block mt-0">
                                                                Inspirational, Motivational Quotes Images
                                                            </button>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row  d-flex justify-content-center  mt-5">
                                            <div className="col-xl-6">
                                                <div className="upgrade-box-content text-center  text-white">
                                                    <div className="upgrade-para p-3">
                                                        <a
                                                            href="https://abhibonus.s3-us-west-2.amazonaws.com/upgradebonus/Visual+Assets/Captions.xlsx"
                                                            target="_blank"
                                                        >
                                                            {" "}
                                                            <button className="demoLink btn-block mt-0">
                                                                Social Media Image Posts
                                                            </button>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-xl-6">
                                                <div className="upgrade-box-content text-center  text-white">
                                                    <div className="upgrade-para p-3">
                                                        <a
                                                            href="https://abhibonus.s3-us-west-2.amazonaws.com/upgradebonus/Visual+Assets/Captions.xlsx"
                                                            target="_blank"
                                                        >
                                                            {" "}
                                                            <button className="demoLink btn-block mt-0">
                                                                Library of Quotes for social Media{" "}
                                                            </button>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>



                        <div className="tab-pane fade" id="v-pills-dfyarticles" role="tabpanel" aria-labelledby="v-pills-dfyarticles-tab">

                            <div className="tabepaneIn">
                                <h2 className="tabTitle">DFY Articles</h2>
                                <div className="tabepaneIn-main">
                                    <div className="tabepaneIn-cont">
                                        <h3>DFY Articles</h3>

                                        <div className="row  d-flex justify-content-center  mt-5">
                                            <div className="col-xl-6">
                                                <div className="upgrade-box-content text-center  text-white">
                                                    <div className="upgrade-para p-3">
                                                        <a
                                                            href="https://abhibonus.s3-us-west-2.amazonaws.com/upgradebonus/1_mil_arti/pack1.zip"
                                                            target="_blank"
                                                        >
                                                            {" "}
                                                            <button className="demoLink btn-block mt-0">Artical Pack 1</button>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-xl-6">
                                                <div className="upgrade-box-content text-center  text-white">
                                                    <div className="upgrade-para p-3">
                                                        <a
                                                            href="https://abhibonus.s3-us-west-2.amazonaws.com/upgradebonus/1_mil_arti/pack6.zip"
                                                            target="_blank"
                                                        >
                                                            {" "}
                                                            <button className="demoLink btn-block mt-0">Artical Pack 6</button>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="row  d-flex justify-content-center  mt-5">
                                            <div className="col-xl-6">
                                                <div className="upgrade-box-content text-center  text-white">
                                                    <div className="upgrade-para p-3">
                                                        <a
                                                            href="https://abhibonus.s3-us-west-2.amazonaws.com/upgradebonus/1_mil_arti/pack2.zip"
                                                            target="_blank"
                                                        >
                                                            <button className="demoLink btn-block mt-0">Artical Pack 2</button>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-xl-6">
                                                <div className="upgrade-box-content text-center  text-white">
                                                    <div className="upgrade-para p-3">
                                                        <a
                                                            href="https://abhibonus.s3-us-west-2.amazonaws.com/upgradebonus/1_mil_arti/pack7.zip"
                                                            target="_blank"
                                                        >
                                                            <button className="demoLink btn-block mt-0">Artical Pack 7</button>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row  d-flex justify-content-center  mt-5">
                                            <div className="col-xl-6">
                                                <div className="upgrade-box-content text-center  text-white">
                                                    <div className="upgrade-para p-3">
                                                        <a
                                                            href="https://abhibonus.s3-us-west-2.amazonaws.com/upgradebonus/1_mil_arti/pack2.zip"
                                                            target="_blank"
                                                        >
                                                            <button className="demoLink btn-block mt-0">Artical Pack 3</button>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-xl-6">
                                                <div className="upgrade-box-content text-center  text-white">
                                                    <div className="upgrade-para p-3">
                                                        <a
                                                            href="https://abhibonus.s3-us-west-2.amazonaws.com/upgradebonus/1_mil_arti/pack8.zip"
                                                            target="_blank"
                                                        >
                                                            <button className="demoLink btn-block mt-0">Artical Pack 8 </button>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row  d-flex justify-content-center  mt-5">
                                            <div className="col-xl-6">
                                                <div className="upgrade-box-content text-center  text-white">
                                                    <div className="upgrade-para p-3">
                                                        <a
                                                            href="https://abhibonus.s3-us-west-2.amazonaws.com/upgradebonus/1_mil_arti/pack4.zip"
                                                            target="_blank"
                                                        >
                                                            {" "}
                                                            <button className="demoLink btn-block mt-0">Artical Pack 4</button>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-xl-6">
                                                <div className="upgrade-box-content text-center  text-white">
                                                    <div className="upgrade-para p-3">
                                                        <a
                                                            href="https://abhibonus.s3-us-west-2.amazonaws.com/upgradebonus/1_mil_arti/pack8.zip"
                                                            target="_blank"
                                                        >
                                                            {" "}
                                                            <button className="demoLink btn-block mt-0">Artical Pack 8 </button>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row  d-flex justify-content-center  mt-5">
                                            <div className="col-xl-6">
                                                <div className="upgrade-box-content text-center  text-white">
                                                    <div className="upgrade-para p-3">
                                                        <a
                                                            href="https://abhibonus.s3-us-west-2.amazonaws.com/upgradebonus/1_mil_arti/pack5.zip"
                                                            target="_blank"
                                                        >
                                                            <button className="demoLink btn-block mt-0">Artical Pack 5</button>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-xl-6">
                                                <div className="upgrade-box-content text-center  text-white">
                                                    <div className="upgrade-para p-3">
                                                        <a
                                                            href="https://abhibonus.s3-us-west-2.amazonaws.com/upgradebonus/1_mil_arti/pack10.zip"
                                                            target="_blank"
                                                        >
                                                            <button className="demoLink btn-block mt-0">Artical Pack 10 </button>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                </div>
                            </div>

                        </div>


                        <div className="tab-pane fade" id="v-pills-goldvip" role="tabpanel" aria-labelledby="v-pills-goldvip-tab">

                            <div className="tabepaneIn">
                                <h2 className="tabTitle">Gold VIP Bonus</h2>
                                <div className="tabepaneIn-main">

                                    <div className="tabepaneIn-cont-alt">
                                        <div className="upgrade-content gold-vip-wrapper">
                                            <div className="row me-1">
                                                <div className="col-xl-4 mb-4">
                                                    <div className="upgrade-box-content text-center  text-white">
                                                        <div className="upgrade-heading">
                                                            <h6>Agency Client Booking System</h6>
                                                        </div>
                                                        <div className="upgrade-para p-3">
                                                            <a
                                                                href="https://s3-us-west-2.amazonaws.com/virtualpioneer.net/Advertsuite/codecanyon-mq6B9tz4-booked-appointments-appointment-booking-for-wordpress.zip"
                                                            >
                                                                <button className="demoLink btn-block mt-0">Download</button>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xl-4  mb-4">
                                                    <div className="upgrade-box-content text-center  text-white">
                                                        <div className="upgrade-heading">
                                                            <h6>CryptoAgency VIP Webinar Ticket</h6>
                                                        </div>
                                                        <div className="upgrade-para p-3">
                                                            <a
                                                                href="https://misctrainingvideos.s3.amazonaws.com/Client+Contract/Services+Agreement.doc"
                                                                target="_blank"
                                                            >
                                                                <button className="demoLink btn-block mt-0">Download</button>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xl-4  mb-4">
                                                    <div className="upgrade-box-content text-center  text-white">
                                                        <div className="upgrade-heading">
                                                            <h6>Reseller Rights to Amazing Agency Flyers</h6>
                                                        </div>
                                                        <div className="upgrade-para p-3">
                                                            <a
                                                                href="https://s3.us-west-2.amazonaws.com/virtualpioneer.net/localio/Creative-Design-Agency-Flyers.zip"
                                                                target="_blank"
                                                            >
                                                                <button className="demoLink btn-block mt-0">Download</button>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xl-12  mb-4">

                                                    <div className="upgrade-box-content text-center  text-white">
                                                        <div className="upgrade-heading">
                                                            <h6>DFY Local Video SpokesPeople</h6>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-xl-4 ">
                                                                <div className="upgrade-para p-3 ">
                                                                    <a
                                                                        href="https://s3.us-west-2.amazonaws.com/virtualpioneer.net/ChatterPal/90+Niches+2.zip"
                                                                        target="_blank"
                                                                    >
                                                                        <button className="demoLink btn-block mt-0">Download Part 1</button>
                                                                    </a>
                                                                </div>
                                                            </div>


                                                            <div className="col-xl-4 ">
                                                                <div className="upgrade-para p-3">
                                                                    <a
                                                                        href="
                                                                        https://s3.us-west-2.amazonaws.com/virtualpioneer.net/ChatterPal/90+Niches+3.zip"
                                                                        target="_blank"
                                                                    >
                                                                        <button className="demoLink btn-block mt-0">Download Part 2</button>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                            <div className="col-xl-4 ">
                                                                <div className="upgrade-para p-3">
                                                                    <a
                                                                        href="https://s3.us-west-2.amazonaws.com/virtualpioneer.net/ChatterPal/90+Niches+4.zip"
                                                                        target="_blank"
                                                                    >
                                                                        <button className="demoLink btn-block mt-0">Download Part 3</button>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                            <div className="col-xl-4 ">
                                                                <div className="upgrade-para p-3">
                                                                    <a
                                                                        href="

                                                                        https://s3.us-west-2.amazonaws.com/virtualpioneer.net/ChatterPal/90+Niches.zip"
                                                                    >
                                                                        <button className="demoLink btn-block mt-0">Download Part 4</button>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                            <div className="col-xl-4 ">
                                                                <div className="upgrade-para p-3">
                                                                    <a
                                                                        href="https://bonus-one.s3-us-west-2.amazonaws.com/38+Whitelabel+HD+Promo+Videos.zip"
                                                                        target="_blank"
                                                                    >
                                                                        <button className="demoLink btn-block mt-0">Download Part 5</button>
                                                                    </a>
                                                                </div>
                                                            </div>

                                                        </div>

                                                    </div>
                                                </div>


                                                <div className="col-xl-12  mb-4">

                                                    <div className="upgrade-box-content text-center  text-white">
                                                        <div className="upgrade-heading">
                                                            <h6>Reseller Rights to 2000+ Jingle Music Tracks</h6>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-xl-4 ">
                                                                <div className="upgrade-para p-3 ">
                                                                    <a
                                                                        href="https://s3-us-west-2.amazonaws.com/virtualpioneer.net/+Designo+Pro+2/Stock+Audio+Firesale+pt.+1.2.zip"
                                                                        target="_blank"

                                                                    >
                                                                        <button className="demoLink btn-block mt-0">Download Part 1</button>
                                                                    </a>
                                                                </div>
                                                            </div>


                                                            <div className="col-xl-4 ">
                                                                <div className="upgrade-para p-3">
                                                                    <a
                                                                        href="  https://s3-us-west-2.amazonaws.com/virtualpioneer.net/+Designo+Pro+2/Stock+Audio+Firesale+pt.+2.2.zip"
                                                                        target="_blank"

                                                                    >
                                                                        <button className="demoLink btn-block mt-0">Download Part 2</button>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                            <div className="col-xl-4 ">
                                                                <div className="upgrade-para p-3">
                                                                    <a
                                                                        href="https://s3-us-west-2.amazonaws.com/virtualpioneer.net/+Designo+Pro+2/Stock+Audio+Firesale+pt.+3.2.zip"
                                                                        target="_blank"

                                                                    >
                                                                        <button className="demoLink btn-block mt-0">Download Part 3</button>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                            <div className="col-xl-4 ">
                                                                <div className="upgrade-para p-3">
                                                                    <a
                                                                        href="https://s3-us-west-2.amazonaws.com/virtualpioneer.net/+Designo+Pro+2/Stock+Audio+Firesale+pt.+4.2.zip"
                                                                        target="_blank"
                                                                    >
                                                                        <button className="demoLink btn-block mt-0">Download Part 4</button>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                            <div className="col-xl-4 ">
                                                                <div className="upgrade-para p-3">
                                                                    <a
                                                                        href="https://s3-us-west-2.amazonaws.com/virtualpioneer.net/+Designo+Pro+2/Stock+Audio+Firesale+pt.+5.2.zip"
                                                                        target="_blank"

                                                                    >
                                                                        <button className="demoLink btn-block mt-0">Download Part 5</button>
                                                                    </a>
                                                                </div>
                                                            </div>

                                                        </div>

                                                    </div>
                                                </div>


                                                <div className="col-xl-4  mb-4">
                                                    <div className="upgrade-box-content text-center  text-white">
                                                        <div className="upgrade-heading">
                                                            <h6>Resell Rights to Local eCom Site Maker</h6>
                                                        </div>
                                                        <div className="upgrade-para p-3">
                                                            <a
                                                                href="https://s3.us-west-2.amazonaws.com/virtualpioneer.net/faceswap/themeforest-sUSRhifw-zigcy-modern-multiconcept-woocommerce-theme.zip"
                                                                target="_blank"
                                                            >
                                                                <button className="demoLink btn-block mt-0">Download</button>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xl-4  mb-4">
                                                    <div className="upgrade-box-content text-center  text-white">
                                                        <div className="upgrade-heading">
                                                            <h6>Agency Rights to Elite Video Player 2.0e</h6>
                                                        </div>
                                                        <div className="upgrade-para p-3">
                                                            <a
                                                                href="https://s3-us-west-2.amazonaws.com/virtualpioneer.net/Vidyz/codecanyon-17621210-video-gallery-and-player-pro.zip"
                                                                target="_blank"
                                                            >
                                                                <button className="demoLink btn-block mt-0">Download</button>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xl-4  mb-4">
                                                    <div className="upgrade-box-content text-center  text-white">
                                                        <div className="upgrade-heading">
                                                            <h6>Whitelabel Rights to 800k DFY Content Articles</h6>
                                                        </div>
                                                        <div className="upgrade-para p-3">
                                                            <a
                                                                href="https://s3.amazonaws.com/virtualpioneerbonuses/Order+_FO36D0EA93F2+(1).pdf"
                                                                target="_blank"
                                                            >
                                                                <button className="demoLink btn-block mt-0">Download</button>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-xl-4  mb-4">
                                                    <div className="upgrade-box-content text-center  text-white">
                                                        <div className="upgrade-heading">
                                                            <h6>Transparent Images Collection with Reseller Rights</h6>
                                                        </div>
                                                        <div className="upgrade-para p-3">
                                                            <a
                                                                href="https://s3-us-west-2.amazonaws.com/virtualpioneer.net/Reviewify/D03-500PeopleStockPhotos.zip"
                                                                target="_blank"
                                                            >
                                                                <button className="demoLink btn-block mt-0">Download</button>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xl-4  mb-3">
                                                    <div className="upgrade-box-content text-center  text-white">
                                                        <div className="upgrade-heading">
                                                            <h6>Mammoth graphics pack</h6>
                                                        </div>
                                                        <div className="upgrade-para p-3">
                                                            <a
                                                                href="https://s3.us-west-2.amazonaws.com/virtualpioneer.net/Bonus+Reupload/mammoth/Mammoth+Local+Graphics+Collection.zip"
                                                                target="_blank"
                                                            >
                                                                <button className="demoLink btn-block mt-0">Download</button>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xl-4  mb-3">
                                                    <div className="upgrade-box-content text-center  text-white">
                                                        <div className="upgrade-heading">
                                                            <h6>DeFi Easily Explained</h6>
                                                        </div>
                                                        <div className="upgrade-para p-3">
                                                            <a
                                                                href="https://s3.us-west-2.amazonaws.com/virtualpioneer.net/thecryptounderworld/DeFi-ExplainedPLRpack.zip"
                                                                target="_blank"
                                                            >
                                                                <button className="demoLink btn-block mt-0">Download</button>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div className="tab-pane fade" id="v-pills-goldlite" role="tabpanel" aria-labelledby="v-pills-goldlite-tab">

                            <div className="tabepaneIn">
                                <h2 className="tabTitle">Gold Lite Bonus</h2>
                                <div className="tabepaneIn-main">

                                    <div className="tabepaneIn-cont-alt">
                                        <div className="upgrade-content gold-vip-wrapper">
                                            <div className="row me-1">
                                                <div className="col-xl-4 mb-4">
                                                    <div className="upgrade-box-content text-center  text-white">
                                                        <div className="upgrade-heading">
                                                            <h6>Agency Client Booking System</h6>
                                                        </div>
                                                        <div className="upgrade-para p-3">
                                                            <a
                                                                href="https://s3-us-west-2.amazonaws.com/virtualpioneer.net/Advertsuite/codecanyon-mq6B9tz4-booked-appointments-appointment-booking-for-wordpress.zip"
                                                            >
                                                                <button className="demoLink btn-block mt-0">Download</button>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xl-4  mb-4">
                                                    <div className="upgrade-box-content text-center  text-white">
                                                        <div className="upgrade-heading">
                                                            <h6>CryptoAgency VIP Webinar Ticket</h6>
                                                        </div>
                                                        <div className="upgrade-para p-3">
                                                            <a
                                                                href=""
                                                                target="_blank"
                                                            >
                                                                <button className="demoLink btn-block mt-0">Download</button>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xl-4  mb-4">
                                                    <div className="upgrade-box-content text-center  text-white">
                                                        <div className="upgrade-heading">
                                                            <h6>Reseller Rights to Amazing Agency Flyers</h6>
                                                        </div>
                                                        <div className="upgrade-para p-3">
                                                            <a
                                                                href="https://s3.us-west-2.amazonaws.com/virtualpioneer.net/localio/Creative-Design-Agency-Flyers.zip"
                                                                target="_blank"
                                                            >
                                                                <button className="demoLink btn-block mt-0">Download</button>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xl-12  mb-4">

                                                    <div className="upgrade-box-content text-center  text-white">
                                                        <div className="upgrade-heading">
                                                            <h6>DFY Local Video SpokesPeople</h6>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-xl-4 ">
                                                                <div className="upgrade-para p-3 ">
                                                                    <a
                                                                        href="https://s3.us-west-2.amazonaws.com/virtualpioneer.net/ChatterPal/90+Niches+2.zip"

                                                                        target="_blank"
                                                                    >
                                                                        <button className="demoLink btn-block mt-0">Download Part 1</button>
                                                                    </a>
                                                                </div>
                                                            </div>


                                                            <div className="col-xl-4 ">
                                                                <div className="upgrade-para p-3">
                                                                    <a
                                                                        href="https://s3.us-west-2.amazonaws.com/virtualpioneer.net/ChatterPal/90+Niches+3.zip"
                                                                        target="_blank"
                                                                    >
                                                                        <button className="demoLink btn-block mt-0">Download Part 2</button>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                            <div className="col-xl-4 ">
                                                                <div className="upgrade-para p-3">
                                                                    <a
                                                                        href="https://s3.us-west-2.amazonaws.com/virtualpioneer.net/ChatterPal/90+Niches+4.zip"
                                                                        target="_blank"
                                                                    >
                                                                        <button className="demoLink btn-block mt-0">Download Part 3</button>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                            <div className="col-xl-4 ">
                                                                <div className="upgrade-para p-3">
                                                                    <a
                                                                        href="https://s3.us-west-2.amazonaws.com/virtualpioneer.net/ChatterPal/90+Niches.zip"
                                                                    >
                                                                        <button className="demoLink btn-block mt-0">Download Part 4</button>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                            <div className="col-xl-4 ">
                                                                <div className="upgrade-para p-3">
                                                                    <a
                                                                        href="https://bonus-one.s3-us-west-2.amazonaws.com/38+Whitelabel+HD+Promo+Videos.zip"
                                                                        target="_blank"
                                                                    >
                                                                        <button className="demoLink btn-block mt-0">Download Part 5</button>
                                                                    </a>
                                                                </div>
                                                            </div>

                                                        </div>

                                                    </div>
                                                </div>


                                                <div className="col-xl-12  mb-4">

                                                    <div className="upgrade-box-content text-center  text-white">
                                                        <div className="upgrade-heading">
                                                            <h6>Reseller Rights to 2000+ Jingle Music Tracks</h6>
                                                        </div>

                                                        <div className="row">
                                                            <div className="col-xl-4 ">
                                                                <div className="upgrade-para p-3 ">
                                                                    <a
                                                                        href="https://s3-us-west-2.amazonaws.com/virtualpioneer.net/+Designo+Pro+2/Stock+Audio+Firesale+pt.+1.2.zip
                                                                        "
                                                                        target="_blank"

                                                                    >
                                                                        <button className="demoLink btn-block mt-0">Download Part 1</button>
                                                                    </a>
                                                                </div>
                                                            </div>


                                                            <div className="col-xl-4 ">
                                                                <div className="upgrade-para p-3">
                                                                    <a
                                                                        href="https://s3-us-west-2.amazonaws.com/virtualpioneer.net/+Designo+Pro+2/Stock+Audio+Firesale+pt.+2.2.zip "
                                                                        target="_blank"

                                                                    >
                                                                        <button className="demoLink btn-block mt-0">Download Part 2</button>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                            <div className="col-xl-4 ">
                                                                <div className="upgrade-para p-3">
                                                                    <a
                                                                        href="https://s3-us-west-2.amazonaws.com/virtualpioneer.net/+Designo+Pro+2/Stock+Audio+Firesale+pt.+3.2.zip"
                                                                        target="_blank"

                                                                    >
                                                                        <button className="demoLink btn-block mt-0">Download Part 3</button>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                            <div className="col-xl-4 ">
                                                                <div className="upgrade-para p-3">
                                                                    <a
                                                                        href="https://s3-us-west-2.amazonaws.com/virtualpioneer.net/+Designo+Pro+2/Stock+Audio+Firesale+pt.+4.2.zip"
                                                                        target="_blank"
                                                                    >
                                                                        <button className="demoLink btn-block mt-0">Download Part 4</button>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                            <div className="col-xl-4 ">
                                                                <div className="upgrade-para p-3">
                                                                    <a
                                                                        href="https://s3-us-west-2.amazonaws.com/virtualpioneer.net/+Designo+Pro+2/Stock+Audio+Firesale+pt.+5.2.zip"
                                                                        target="_blank"

                                                                    >
                                                                        <button className="demoLink btn-block mt-0">Download Part 5</button>
                                                                    </a>
                                                                </div>
                                                            </div>

                                                        </div>

                                                    </div>
                                                </div>


                                                <div className="col-xl-4  mb-4">
                                                    <div className="upgrade-box-content text-center  text-white">
                                                        <div className="upgrade-heading">
                                                            <h6>Resell Rights to Local eCom Site Maker</h6>
                                                        </div>
                                                        <div className="upgrade-para p-3">
                                                            <a
                                                                href="https://s3.us-west-2.amazonaws.com/virtualpioneer.net/faceswap/themeforest-sUSRhifw-zigcy-modern-multiconcept-woocommerce-theme.zip"
                                                                target="_blank"
                                                            >
                                                                <button className="demoLink btn-block mt-0">Download</button>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xl-4  mb-4">
                                                    <div className="upgrade-box-content text-center  text-white">
                                                        <div className="upgrade-heading">
                                                            <h6>Agency Rights to Elite Video Player 2.0e</h6>
                                                        </div>
                                                        <div className="upgrade-para p-3">
                                                            <a
                                                                href="https://s3-us-west-2.amazonaws.com/virtualpioneer.net/Vidyz/codecanyon-17621210-video-gallery-and-player-pro.zip"
                                                                target="_blank"
                                                            >
                                                                <button className="demoLink btn-block mt-0">Download</button>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xl-4  mb-4">
                                                    <div className="upgrade-box-content text-center  text-white">
                                                        <div className="upgrade-heading">
                                                            <h6>Whitelabel Rights to 800k DFY Content Articles</h6>
                                                        </div>
                                                        <div className="upgrade-para p-3">
                                                            <a
                                                                href="https://s3.us-west-2.amazonaws.com/virtualpioneer.net/localio/Creative-Design-Agency-Flyers.zip"
                                                                target="_blank"
                                                            >
                                                                <button className="demoLink btn-block mt-0">Download</button>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-xl-4  mb-4">
                                                    <div className="upgrade-box-content text-center  text-white">
                                                        <div className="upgrade-heading">
                                                            <h6>Transparent Images Collection with Reseller Rights</h6>
                                                        </div>
                                                        <div className="upgrade-para p-3">
                                                            <a
                                                                href="https://s3-us-west-2.amazonaws.com/virtualpioneer.net/Reviewify/D03-500PeopleStockPhotos.zip"
                                                                target="_blank"
                                                            >
                                                                <button className="demoLink btn-block mt-0">Download</button>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xl-4  mb-3">
                                                    <div className="upgrade-box-content text-center  text-white">
                                                        <div className="upgrade-heading">
                                                            <h6>YouTube Thumbnails Pack </h6>
                                                        </div>
                                                        <div className="upgrade-para p-3">
                                                            <a
                                                                href="https://s3-us-west-2.amazonaws.com/virtualpioneer.net/VideoCreator/YouTube-Thumbnails-Pack-V2.zip"
                                                                target="_blank"
                                                            >
                                                                <button className="demoLink btn-block mt-0">Download</button>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xl-4  mb-3">
                                                    <div className="upgrade-box-content text-center  text-white">
                                                        <div className="upgrade-heading">
                                                            <h6>YouTube Channel Art Multipurpose Banners</h6>
                                                        </div>
                                                        <div className="upgrade-para p-3">
                                                            <a
                                                                href="https://s3-us-west-2.amazonaws.com/virtualpioneer.net/Thumbnail+Blaster/Creative-MultiPurpose-YouTube-Banner.zip"
                                                                target="_blank"
                                                            >
                                                                <button className="demoLink btn-block mt-0">Download</button>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xl-4  mb-3">
                                                    <div className="upgrade-box-content text-center  text-white">
                                                        <div className="upgrade-heading">

                                                            <h6>  Adwords Mastery</h6>
                                                        </div>
                                                        <div className="upgrade-para p-3">
                                                            <a
                                                                href="https://s3.us-west-2.amazonaws.com/virtualpioneer.net/localio/GoogleAdsMasteryVideos_pu.zip"
                                                                target="_blank"
                                                            >
                                                                <button className="demoLink btn-block mt-0">Download</button>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="tab-pane fade" id="v-pills-Training" role="tabpanel" aria-labelledby="v-pills-Training-tab">

                            <div className="tabepaneIn">
                                <h2 className="tabTitle">Video Training</h2>
                                <div className="tabepaneIn-main">

                                    <div className="tabepaneIn-cont-alt">
                                        <div className="upgrade-content gold-vip-wrapper">
                                            <div className="row me-1">
                                                <div className="col-xl-12 mb-4">
                                                    <div className="upgrade-box-content text-center  text-white">
                                                        <div className="upgrade-heading">
                                                            <h6>Simple Social Media Content</h6>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-xl-6">
                                                                <div className="upgrade-para p-3">
                                                                    <iframe width="100%" height="300" id="frameSrc" class="embed-responsive-item" src="//player.vimeo.com/video/515257548" allowfullscreen=""></iframe>
                                                                </div>
                                                            </div>
                                                            <div className="col-xl-6">
                                                                <div className="upgrade-para p-3">
                                                                    <iframe width="100%" height="300" id="frameSrc" class="embed-responsive-item" src="//player.vimeo.com/video/515257602" allowfullscreen=""></iframe>
                                                                </div>
                                                            </div>
                                                            <div className="col-xl-6">
                                                                <div className="upgrade-para p-3">
                                                                    <iframe width="100%" height="300" id="frameSrc" class="embed-responsive-item" src="//player.vimeo.com/video/515257666" allowfullscreen=""></iframe>
                                                                </div>
                                                            </div>
                                                            <div className="col-xl-6">
                                                                <div className="upgrade-para p-3">
                                                                    <iframe width="100%" height="300" id="frameSrc" class="embed-responsive-item" src="//player.vimeo.com/video/515257736" allowfullscreen=""></iframe>
                                                                </div>
                                                            </div>
                                                            <div className="col-xl-6">
                                                                <div className="upgrade-para p-3">
                                                                    <iframe width="100%" height="300" id="frameSrc" class="embed-responsive-item" src="//player.vimeo.com/video/515257822" allowfullscreen=""></iframe>
                                                                </div>
                                                            </div>
                                                            <div className="col-xl-6">
                                                                <div className="upgrade-para p-3">
                                                                    <iframe width="100%" height="300" id="frameSrc" class="embed-responsive-item" src="//player.vimeo.com/video/515258503" allowfullscreen=""></iframe>
                                                                </div>
                                                            </div>
                                                            <div className="col-xl-6">
                                                                <div className="upgrade-para p-3">
                                                                    <iframe width="100%" height="300" id="frameSrc" class="embed-responsive-item" src="//player.vimeo.com/video/515258577" allowfullscreen=""></iframe>
                                                                </div>
                                                            </div>
                                                            <div className="col-xl-6">
                                                                <div className="upgrade-para p-3">
                                                                    <iframe width="100%" height="300" id="frameSrc" class="embed-responsive-item" src="//player.vimeo.com/video/515258629" allowfullscreen=""></iframe>
                                                                </div>
                                                            </div>
                                                            <div className="col-xl-6">
                                                                <div className="upgrade-para p-3">
                                                                    <iframe width="100%" height="300" id="frameSrc" class="embed-responsive-item" src="//player.vimeo.com/video/515258686" allowfullscreen=""></iframe>
                                                                </div>
                                                            </div>
                                                           
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xl-12  mb-4">
                                                    <div className="upgrade-box-content text-center  text-white">
                                                        <div className="upgrade-heading">
                                                            <h6>Facebook Ads 101 </h6>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-xl-6">
                                                                <div className="upgrade-para p-3">
                                                                    <iframe width="100%" height="300" id="frameSrc" class="embed-responsive-item" src="//player.vimeo.com/video/515252448" allowfullscreen=""></iframe>
                                                                </div>
                                                            </div>
                                                            <div className="col-xl-6">
                                                                <div className="upgrade-para p-3">
                                                                    <iframe width="100%" height="300" id="frameSrc" class="embed-responsive-item" src="//player.vimeo.com/video/515252549" allowfullscreen=""></iframe>
                                                                </div>
                                                            </div>
                                                            <div className="col-xl-6">
                                                                <div className="upgrade-para p-3">
                                                                    <iframe width="100%" height="300" id="frameSrc" class="embed-responsive-item" src="//player.vimeo.com/video/515252627" allowfullscreen=""></iframe>
                                                                </div>
                                                            </div>
                                                            <div className="col-xl-6">
                                                                <div className="upgrade-para p-3">
                                                                    <iframe width="100%" height="300" id="frameSrc" class="embed-responsive-item" src="//player.vimeo.com/video/515252677" allowfullscreen=""></iframe>
                                                                </div>
                                                            </div>
                                                            <div className="col-xl-6">
                                                                <div className="upgrade-para p-3">
                                                                    <iframe width="100%" height="300" id="frameSrc" class="embed-responsive-item" src="//player.vimeo.com/video/515252770" allowfullscreen=""></iframe>
                                                                </div>
                                                            </div>
                                                            <div className="col-xl-6">
                                                                <div className="upgrade-para p-3">
                                                                    <iframe width="100%" height="300" id="frameSrc" class="embed-responsive-item" src="//player.vimeo.com/video/515253016" allowfullscreen=""></iframe>
                                                                </div>
                                                            </div>
                                                            <div className="col-xl-6">
                                                                <div className="upgrade-para p-3">
                                                                    <iframe width="100%" height="300" id="frameSrc" class="embed-responsive-item" src="//player.vimeo.com/video/515253133" allowfullscreen=""></iframe>
                                                                </div>
                                                            </div>
                                                            <div className="col-xl-6">
                                                                <div className="upgrade-para p-3">
                                                                    <iframe width="100%" height="300" id="frameSrc" class="embed-responsive-item" src="//player.vimeo.com/video/515253354" allowfullscreen=""></iframe>
                                                                </div>
                                                            </div>
                                                            <div className="col-xl-6">
                                                                <div className="upgrade-para p-3">
                                                                    <iframe width="100%" height="300" id="frameSrc" class="embed-responsive-item" src="//player.vimeo.com/video/515253402" allowfullscreen=""></iframe>
                                                                </div>
                                                            </div>
                                                            <div className="col-xl-6">
                                                                <div className="upgrade-para p-3">
                                                                    <iframe width="100%" height="300" id="frameSrc" class="embed-responsive-item" src="//player.vimeo.com/video/515253483" allowfullscreen=""></iframe>
                                                                </div>
                                                            </div>
                                                           
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xl-12  mb-4">
                                                    <div className="upgrade-box-content text-center  text-white">
                                                        <div className="upgrade-heading">
                                                            <h6>Video Teaser Blueprint</h6>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-xl-6">
                                                                <div className="upgrade-para p-3">
                                                                    <iframe width="100%" height="300" id="frameSrc" class="embed-responsive-item" src="//player.vimeo.com/video/515260019" allowfullscreen=""></iframe>
                                                                </div>
                                                            </div>
                                                            <div className="col-xl-6">
                                                                <div className="upgrade-para p-3">
                                                                    <iframe width="100%" height="300" id="frameSrc" class="embed-responsive-item" src="//player.vimeo.com/video/515260098" allowfullscreen=""></iframe>
                                                                </div>
                                                            </div>
                                                            <div className="col-xl-6">
                                                                <div className="upgrade-para p-3">
                                                                    <iframe width="100%" height="300" id="frameSrc" class="embed-responsive-item" src="//player.vimeo.com/video/515260153" allowfullscreen=""></iframe>
                                                                </div>
                                                            </div>
                                                            <div className="col-xl-6">
                                                                <div className="upgrade-para p-3">
                                                                    <iframe width="100%" height="300" id="frameSrc" class="embed-responsive-item" src="//player.vimeo.com/video/515260228" allowfullscreen=""></iframe>
                                                                </div>
                                                            </div>
                                                            <div className="col-xl-6">
                                                                <div className="upgrade-para p-3">
                                                                    <iframe width="100%" height="300" id="frameSrc" class="embed-responsive-item" src="//player.vimeo.com/video/515260298" allowfullscreen=""></iframe>
                                                                </div>
                                                            </div>
                                                            <div className="col-xl-6">
                                                                <div className="upgrade-para p-3">
                                                                    <iframe width="100%" height="300" id="frameSrc" class="embed-responsive-item" src="//player.vimeo.com/video/515260341" allowfullscreen=""></iframe>
                                                                </div>
                                                            </div>
                                                           
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xl-12  mb-4">

                                                    <div className="upgrade-box-content text-center  text-white">
                                                        <div className="upgrade-heading">
                                                            <h6>YouTube Channel Growth</h6>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-xl-6">
                                                                <div className="upgrade-para p-3">
                                                                    <iframe width="100%" height="300" id="frameSrc" class="embed-responsive-item" src="//player.vimeo.com/video/515260428" allowfullscreen=""></iframe>
                                                                </div>
                                                            </div>
                                                            <div className="col-xl-6">
                                                                <div className="upgrade-para p-3">
                                                                    <iframe width="100%" height="300" id="frameSrc" class="embed-responsive-item" src="//player.vimeo.com/video/515260486" allowfullscreen=""></iframe>
                                                                </div>
                                                            </div>
                                                            <div className="col-xl-6">
                                                                <div className="upgrade-para p-3">
                                                                    <iframe width="100%" height="300" id="frameSrc" class="embed-responsive-item" src="//player.vimeo.com/video/515260598" allowfullscreen=""></iframe>
                                                                </div>
                                                            </div>
                                                            <div className="col-xl-6">
                                                                <div className="upgrade-para p-3">
                                                                    <iframe width="100%" height="300" id="frameSrc" class="embed-responsive-item" src="//player.vimeo.com/video/515260678" allowfullscreen=""></iframe>
                                                                </div>
                                                            </div>
                                                            <div className="col-xl-6">
                                                                <div className="upgrade-para p-3">
                                                                    <iframe width="100%" height="300" id="frameSrc" class="embed-responsive-item" src="//player.vimeo.com/video/515260764" allowfullscreen=""></iframe>
                                                                </div>
                                                            </div>
                                                            <div className="col-xl-6">
                                                                <div className="upgrade-para p-3">
                                                                    <iframe width="100%" height="300" id="frameSrc" class="embed-responsive-item" src="//player.vimeo.com/video/515260836" allowfullscreen=""></iframe>
                                                                </div>
                                                            </div>
                                                            <div className="col-xl-6">
                                                                <div className="upgrade-para p-3">
                                                                    <iframe width="100%" height="300" id="frameSrc" class="embed-responsive-item" src="//player.vimeo.com/video/515260928" allowfullscreen=""></iframe>
                                                                </div>
                                                            </div>
                                                            <div className="col-xl-6">
                                                                <div className="upgrade-para p-3">
                                                                    <iframe width="100%" height="300" id="frameSrc" class="embed-responsive-item" src="//player.vimeo.com/video/515260984" allowfullscreen=""></iframe>
                                                                </div>
                                                            </div>
                                                           
                                                        </div>

                                                    </div>
                                                </div>


                                                <div className="col-xl-12  mb-4">

                                                    <div className="upgrade-box-content text-center  text-white">
                                                        <div className="upgrade-heading">
                                                            <h6>Video Launch Method</h6>
                                                        </div>

                                                        <div className="row">
                                                            <div className="col-xl-6">
                                                                <div className="upgrade-para p-3">
                                                                    <iframe width="100%" height="300" id="frameSrc" class="embed-responsive-item" src="//player.vimeo.com/video/515259417" allowfullscreen=""></iframe>
                                                                </div>
                                                            </div>
                                                            <div className="col-xl-6">
                                                                <div className="upgrade-para p-3">
                                                                    <iframe width="100%" height="300" id="frameSrc" class="embed-responsive-item" src="//player.vimeo.com/video/515259357" allowfullscreen=""></iframe>
                                                                </div>
                                                            </div>
                                                            <div className="col-xl-6">
                                                                <div className="upgrade-para p-3">
                                                                    <iframe width="100%" height="300" id="frameSrc" class="embed-responsive-item" src="//player.vimeo.com/video/515259483" allowfullscreen=""></iframe>
                                                                </div>
                                                            </div>
                                                            <div className="col-xl-6">
                                                                <div className="upgrade-para p-3">
                                                                    <iframe width="100%" height="300" id="frameSrc" class="embed-responsive-item" src="//player.vimeo.com/video/515259558" allowfullscreen=""></iframe>
                                                                </div>
                                                            </div>
                                                            <div className="col-xl-6">
                                                                <div className="upgrade-para p-3">
                                                                    <iframe width="100%" height="300" id="frameSrc" class="embed-responsive-item" src="//player.vimeo.com/video/515259630" allowfullscreen=""></iframe>
                                                                </div>
                                                            </div>
                                                            <div className="col-xl-6">
                                                                <div className="upgrade-para p-3">
                                                                    <iframe width="100%" height="300" id="frameSrc" class="embed-responsive-item" src="//player.vimeo.com/video/515259728" allowfullscreen=""></iframe>
                                                                </div>
                                                            </div>
                                                            <div className="col-xl-6">
                                                                <div className="upgrade-para p-3">
                                                                    <iframe width="100%" height="300" id="frameSrc" class="embed-responsive-item" src="//player.vimeo.com/video/515259801" allowfullscreen=""></iframe>
                                                                </div>
                                                            </div>
                                                            <div className="col-xl-6">
                                                                <div className="upgrade-para p-3">
                                                                    <iframe width="100%" height="300" id="frameSrc" class="embed-responsive-item" src="//player.vimeo.com/video/515259872" allowfullscreen=""></iframe>
                                                                </div>
                                                            </div>
                                                            <div className="col-xl-6">
                                                                <div className="upgrade-para p-3">
                                                                    <iframe width="100%" height="300" id="frameSrc" class="embed-responsive-item" src="//player.vimeo.com/video/515259935" allowfullscreen=""></iframe>
                                                                </div>
                                                            </div>
                                                           
                                                        </div>

                                                    </div>
                                                </div>


                                                <div className="col-xl-12  mb-4">
                                                    <div className="upgrade-box-content text-center  text-white">
                                                        <div className="upgrade-heading">
                                                            <h6>YouTube Channel SEO</h6>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-xl-6">
                                                                <div className="upgrade-para p-3">
                                                                    <iframe width="100%" height="300" id="frameSrc" class="embed-responsive-item" src="//player.vimeo.com/video/515261047" allowfullscreen=""></iframe>
                                                                </div>
                                                            </div>
                                                            <div className="col-xl-6">
                                                                <div className="upgrade-para p-3">
                                                                    <iframe width="100%" height="300" id="frameSrc" class="embed-responsive-item" src="//player.vimeo.com/video/515261110" allowfullscreen=""></iframe>
                                                                </div>
                                                            </div>
                                                            <div className="col-xl-6">
                                                                <div className="upgrade-para p-3">
                                                                    <iframe width="100%" height="300" id="frameSrc" class="embed-responsive-item" src="//player.vimeo.com/video/515261168" allowfullscreen=""></iframe>
                                                                </div>
                                                            </div>
                                                            <div className="col-xl-6">
                                                                <div className="upgrade-para p-3">
                                                                    <iframe width="100%" height="300" id="frameSrc" class="embed-responsive-item" src="//player.vimeo.com/video/515261250" allowfullscreen=""></iframe>
                                                                </div>
                                                            </div>
                                                            <div className="col-xl-6">
                                                                <div className="upgrade-para p-3">
                                                                    <iframe width="100%" height="300" id="frameSrc" class="embed-responsive-item" src="//player.vimeo.com/video/515261301" allowfullscreen=""></iframe>
                                                                </div>
                                                            </div>
                                                            <div className="col-xl-6">
                                                                <div className="upgrade-para p-3">
                                                                    <iframe width="100%" height="300" id="frameSrc" class="embed-responsive-item" src="//player.vimeo.com/video/515261361" allowfullscreen=""></iframe>
                                                                </div>
                                                            </div>
                                                            <div className="col-xl-6">
                                                                <div className="upgrade-para p-3">
                                                                    <iframe width="100%" height="300" id="frameSrc" class="embed-responsive-item" src="//player.vimeo.com/video/515261428" allowfullscreen=""></iframe>
                                                                </div>
                                                            </div>
                                                            <div className="col-xl-6">
                                                                <div className="upgrade-para p-3">
                                                                    <iframe width="100%" height="300" id="frameSrc" class="embed-responsive-item" src="//player.vimeo.com/video/515261497" allowfullscreen=""></iframe>
                                                                </div>
                                                            </div>
                                                            
                                                           
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xl-12  mb-4">
                                                    <div className="upgrade-box-content text-center  text-white">
                                                        <div className="upgrade-heading">
                                                            <h6>Image Search Secrets</h6>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-xl-6">
                                                                <div className="upgrade-para p-3">
                                                                    <iframe width="100%" height="300" id="frameSrc" class="embed-responsive-item" src="//player.vimeo.com/video/515254221" allowfullscreen=""></iframe>
                                                                </div>
                                                            </div>
                                                            <div className="col-xl-6">
                                                                <div className="upgrade-para p-3">
                                                                    <iframe width="100%" height="300" id="frameSrc" class="embed-responsive-item" src="//player.vimeo.com/video/515254267" allowfullscreen=""></iframe>
                                                                </div>
                                                            </div>
                                                            <div className="col-xl-6">
                                                                <div className="upgrade-para p-3">
                                                                    <iframe width="100%" height="300" id="frameSrc" class="embed-responsive-item" src="//player.vimeo.com/video/515254725" allowfullscreen=""></iframe>
                                                                </div>
                                                            </div>
                                                            <div className="col-xl-6">
                                                                <div className="upgrade-para p-3">
                                                                    <iframe width="100%" height="300" id="frameSrc" class="embed-responsive-item" src="//player.vimeo.com/video/515254872" allowfullscreen=""></iframe>
                                                                </div>
                                                            </div>
                                                            <div className="col-xl-6">
                                                                <div className="upgrade-para p-3">
                                                                    <iframe width="100%" height="300" id="frameSrc" class="embed-responsive-item" src="//player.vimeo.com/video/515255200" allowfullscreen=""></iframe>
                                                                </div>
                                                            </div>
                                                            <div className="col-xl-6">
                                                                <div className="upgrade-para p-3">
                                                                    <iframe width="100%" height="300" id="frameSrc" class="embed-responsive-item" src="//player.vimeo.com/video/515255558" allowfullscreen=""></iframe>
                                                                </div>
                                                            </div>
                                                            <div className="col-xl-6">
                                                                <div className="upgrade-para p-3">
                                                                    <iframe width="100%" height="300" id="frameSrc" class="embed-responsive-item" src="//player.vimeo.com/video/515256008" allowfullscreen=""></iframe>
                                                                </div>
                                                            </div>
                                                            <div className="col-xl-6">
                                                                <div className="upgrade-para p-3">
                                                                    <iframe width="100%" height="300" id="frameSrc" class="embed-responsive-item" src="//player.vimeo.com/video/515257388" allowfullscreen=""></iframe>
                                                                </div>
                                                            </div>
                                                           
                                                            <div className="col-xl-6">
                                                                <div className="upgrade-para p-3">
                                                                    <iframe width="100%" height="300" id="frameSrc" class="embed-responsive-item" src="//player.vimeo.com/video/515257479" allowfullscreen=""></iframe>
                                                                </div>
                                                            </div>
                                                            
                                                           
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xl-12  mb-4">
                                                    <div className="upgrade-box-content text-center  text-white">
                                                        <div className="upgrade-heading">
                                                            <h6>Social Media Automation</h6>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-xl-6">
                                                                <div className="upgrade-para p-3">
                                                                    <iframe width="100%" height="300" id="frameSrc" class="embed-responsive-item" src="//player.vimeo.com/video/515258759" allowfullscreen=""></iframe>
                                                                </div>
                                                            </div>
                                                            <div className="col-xl-6">
                                                                <div className="upgrade-para p-3">
                                                                    <iframe width="100%" height="300" id="frameSrc" class="embed-responsive-item" src="//player.vimeo.com/video/515258845" allowfullscreen=""></iframe>
                                                                </div>
                                                            </div>
                                                            <div className="col-xl-6">
                                                                <div className="upgrade-para p-3">
                                                                    <iframe width="100%" height="300" id="frameSrc" class="embed-responsive-item" src="//player.vimeo.com/video/515258916" allowfullscreen=""></iframe>
                                                                </div>
                                                            </div>
                                                            <div className="col-xl-6">
                                                                <div className="upgrade-para p-3">
                                                                    <iframe width="100%" height="300" id="frameSrc" class="embed-responsive-item" src="//player.vimeo.com/video/515259041" allowfullscreen=""></iframe>
                                                                </div>
                                                            </div>
                                                            <div className="col-xl-6">
                                                                <div className="upgrade-para p-3">
                                                                    <iframe width="100%" height="300" id="frameSrc" class="embed-responsive-item" src="//player.vimeo.com/video/515259105" allowfullscreen=""></iframe>
                                                                </div>
                                                            </div>
                                                            <div className="col-xl-6">
                                                                <div className="upgrade-para p-3">
                                                                    <iframe width="100%" height="300" id="frameSrc" class="embed-responsive-item" src="//player.vimeo.com/video/515259209" allowfullscreen=""></iframe>
                                                                </div>
                                                            </div>
                                                            <div className="col-xl-6">
                                                                <div className="upgrade-para p-3">
                                                                    <iframe width="100%" height="300" id="frameSrc" class="embed-responsive-item" src="//player.vimeo.com/video/515259279" allowfullscreen=""></iframe>
                                                                </div>
                                                            </div>
                                                           
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-xl-12  mb-4">
                                                    <div className="upgrade-box-content text-center  text-white">
                                                        <div className="upgrade-heading">
                                                            <h6>Course Engagement Hacks</h6>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-xl-6">
                                                                <div className="upgrade-para p-3">
                                                                    <iframe width="100%" height="300" id="frameSrc" class="embed-responsive-item" src="//player.vimeo.com/video/515251128" allowfullscreen=""></iframe>
                                                                </div>
                                                            </div>
                                                            <div className="col-xl-6">
                                                                <div className="upgrade-para p-3">
                                                                    <iframe width="100%" height="300" id="frameSrc" class="embed-responsive-item" src="//player.vimeo.com/video/515251165" allowfullscreen=""></iframe>
                                                                </div>
                                                            </div>
                                                            <div className="col-xl-6">
                                                                <div className="upgrade-para p-3">
                                                                    <iframe width="100%" height="300" id="frameSrc" class="embed-responsive-item" src="//player.vimeo.com/video/515251334" allowfullscreen=""></iframe>
                                                                </div>
                                                            </div>
                                                            <div className="col-xl-6">
                                                                <div className="upgrade-para p-3">
                                                                    <iframe width="100%" height="300" id="frameSrc" class="embed-responsive-item" src="//player.vimeo.com/video/515251610" allowfullscreen=""></iframe>
                                                                </div>
                                                            </div>
                                                            <div className="col-xl-6">
                                                                <div className="upgrade-para p-3">
                                                                    <iframe width="100%" height="300" id="frameSrc" class="embed-responsive-item" src="//player.vimeo.com/video/515251705" allowfullscreen=""></iframe>
                                                                </div>
                                                            </div>
                                                            <div className="col-xl-6">
                                                                <div className="upgrade-para p-3">
                                                                    <iframe width="100%" height="300" id="frameSrc" class="embed-responsive-item" src="//player.vimeo.com/video/515251839" allowfullscreen=""></iframe>
                                                                </div>
                                                            </div>
                                                            <div className="col-xl-6">
                                                                <div className="upgrade-para p-3">
                                                                    <iframe width="100%" height="300" id="frameSrc" class="embed-responsive-item" src="//player.vimeo.com/video/515251995" allowfullscreen=""></iframe>
                                                                </div>
                                                            </div>
                                                            <div className="col-xl-6">
                                                                <div className="upgrade-para p-3">
                                                                    <iframe width="100%" height="300" id="frameSrc" class="embed-responsive-item" src="//player.vimeo.com/video/515252385" allowfullscreen=""></iframe>
                                                                </div>
                                                            </div>
                                                           
                                                           
                                                        </div>
                                                    </div>
                                                </div>
                                                

                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>




                        <div className="tab-pane fade" id="v-pills-dfyassets" role="tabpanel" aria-labelledby="v-pills-dfyassets-tab">

                            <div className="tabepaneIn">
                                <h2 className="tabTitle">DFY Assets</h2>
                                <div className="tabepaneIn-main">
                                    <div className="tabepaneIn-cont">
                                        <h3>How to add checkout in the DFY products sales pages</h3>
                                        <div className="upgrade-para">
                                            <div className="col-md-12">
                                                <div className="dfy-video text-center">
                                                    <iframe width="100%" height="360" id="frameSrc" class="embed-responsive-item" src="https://player.vimeo.com/video/738238394?h=15a0728884" allowfullscreen=""></iframe>

                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                    <div className="tabepaneIn-cont-alt">
                                        <div className="upgrade-content">
                                            <div className="row">
                                                <div className="col-xl-4 mb-2">
                                                    <div className="upgrade-box-content text-center  text-white">
                                                        <div className="upgrade-heading">
                                                            <h6>DFY products</h6>
                                                        </div>
                                                        <div className="upgrade-para p-3">
                                                            <a
                                                                href="https://reeelapps-app.s3.us-west-2.amazonaws.com/Koinkart/DFY+Product/DFY+Products.zip"
                                                                target="_blank"
                                                            >
                                                                <button className="demoLink btn-block mt-0">Download</button>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="tab-pane fade" id="v-pills-dfylightassets" role="tabpanel" aria-labelledby="v-pills-dfylightassets-tab">

                            <div className="tabepaneIn">
                                <h2 className="tabTitle">DFY Light Assets</h2>
                                <div className="tabepaneIn-main">
                                    <div className="tabepaneIn-cont">
                                        <h3>DFY Light Assets</h3>
                                        <div className="upgrade-para">
                                            <p className="text-center ">
                                                We are adding DFY Light Assets soon.
                                            </p>
                                        </div>
                                        <div className="row   mt-5">

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="tab-pane fade" id="v-pills-agencyvip" role="tabpanel" aria-labelledby="v-pills-agencyvip-tab">

                            <div className="tabepaneIn">
                                <h2 className="tabTitle">Agency VIP Bonus</h2>
                                <div className="tabepaneIn-main">

                                    <div className="tabepaneIn-cont-alt">
                                        <div className="upgrade-content gold-vip-wrapper">
                                            <div className="row me-1">
                                                <div className="col-xl-4 mb-4">
                                                    <div className="upgrade-box-content text-center  text-white">
                                                        <div className="upgrade-heading">
                                                            <h6>Agency Client Booking System</h6>
                                                        </div>
                                                        <div className="upgrade-para p-3">
                                                            <a
                                                                href="https://s3-us-west-2.amazonaws.com/virtualpioneer.net/Advertsuite/codecanyon-mq6B9tz4-booked-appointments-appointment-booking-for-wordpress.zip"
                                                            >
                                                                <button className="demoLink btn-block mt-0">Download</button>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xl-4  mb-4">
                                                    <div className="upgrade-box-content text-center  text-white">
                                                        <div className="upgrade-heading">
                                                            <h6>CryptoAgency VIP Webinar Ticket</h6>
                                                        </div>
                                                        <div className="upgrade-para p-3">
                                                            <a
                                                                href=""
                                                                target="_blank"
                                                            >
                                                                <button className="demoLink btn-block mt-0">Download</button>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xl-4  mb-4">
                                                    <div className="upgrade-box-content text-center  text-white">
                                                        <div className="upgrade-heading">
                                                            <h6>Reseller Rights to Amazing Agency Flyers</h6>
                                                        </div>
                                                        <div className="upgrade-para p-3">
                                                            <a
                                                                href="https://s3.us-west-2.amazonaws.com/virtualpioneer.net/localio/Creative-Design-Agency-Flyers.zip"
                                                                target="_blank"
                                                            >
                                                                <button className="demoLink btn-block mt-0">Download</button>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xl-12  mb-4">
                                                    Download your purchase here:





                                                    <div className="upgrade-box-content text-center  text-white">
                                                        <div className="upgrade-heading">
                                                            <h6>DFY Local Video SpokesPeople</h6>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-xl-4 ">
                                                                <div className="upgrade-para p-3 ">
                                                                    <a
                                                                        href="https://s3.us-west-2.amazonaws.com/virtualpioneer.net/ChatterPal/90+Niches+2.zip "

                                                                        target="_blank"
                                                                    >
                                                                        <button className="demoLink btn-block mt-0">Download Part 1</button>
                                                                    </a>
                                                                </div>
                                                            </div>


                                                            <div className="col-xl-4 ">
                                                                <div className="upgrade-para p-3">
                                                                    <a
                                                                        href="https://s3.us-west-2.amazonaws.com/virtualpioneer.net/ChatterPal/90+Niches+3.zip"
                                                                        target="_blank"
                                                                    >
                                                                        <button className="demoLink btn-block mt-0">Download Part 2</button>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                            <div className="col-xl-4 ">
                                                                <div className="upgrade-para p-3">
                                                                    <a
                                                                        href="https://s3.us-west-2.amazonaws.com/virtualpioneer.net/ChatterPal/90+Niches+4.zip"
                                                                        target="_blank"
                                                                    >
                                                                        <button className="demoLink btn-block mt-0">Download Part 3</button>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                            <div className="col-xl-4 ">
                                                                <div className="upgrade-para p-3">
                                                                    <a
                                                                        href="https://s3.us-west-2.amazonaws.com/virtualpioneer.net/ChatterPal/90+Niches.zip"
                                                                    >
                                                                        <button className="demoLink btn-block mt-0">Download Part 4</button>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                            <div className="col-xl-4 ">
                                                                <div className="upgrade-para p-3">
                                                                    <a
                                                                        href="https://bonus-one.s3-us-west-2.amazonaws.com/38+Whitelabel+HD+Promo+Videos.zip"
                                                                        target="_blank"
                                                                    >
                                                                        <button className="demoLink btn-block mt-0">Download Part 5</button>
                                                                    </a>
                                                                </div>
                                                            </div>

                                                        </div>

                                                    </div>
                                                </div>


                                                <div className="col-xl-12  mb-4">

                                                    <div className="upgrade-box-content text-center  text-white">
                                                        <div className="upgrade-heading">
                                                            <h6>Reseller Rights to 2000+ Jingle Music Tracks</h6>
                                                        </div>

                                                        <div className="row">
                                                            <div className="col-xl-4 ">
                                                                <div className="upgrade-para p-3 ">
                                                                    <a
                                                                        href="https://s3-us-west-2.amazonaws.com/virtualpioneer.net/+Designo+Pro+2/Stock+Audio+Firesale+pt.+1.2.zip"
                                                                        target="_blank"

                                                                    >
                                                                        <button className="demoLink btn-block mt-0">Download Part 1</button>
                                                                    </a>
                                                                </div>
                                                            </div>


                                                            <div className="col-xl-4 ">
                                                                <div className="upgrade-para p-3">
                                                                    <a
                                                                        href="https://s3-us-west-2.amazonaws.com/virtualpioneer.net/+Designo+Pro+2/Stock+Audio+Firesale+pt.+2.2.zip"
                                                                        target="_blank"

                                                                    >
                                                                        <button className="demoLink btn-block mt-0">Download Part 2</button>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                            <div className="col-xl-4 ">
                                                                <div className="upgrade-para p-3">
                                                                    <a
                                                                        href="https://s3-us-west-2.amazonaws.com/virtualpioneer.net/+Designo+Pro+2/Stock+Audio+Firesale+pt.+3.2.zip"
                                                                        target="_blank"

                                                                    >
                                                                        <button className="demoLink btn-block mt-0">Download Part 3</button>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                            <div className="col-xl-4 ">
                                                                <div className="upgrade-para p-3">
                                                                    <a
                                                                        href="https://s3-us-west-2.amazonaws.com/virtualpioneer.net/+Designo+Pro+2/Stock+Audio+Firesale+pt.+4.2.zip"
                                                                        target="_blank"
                                                                    >
                                                                        <button className="demoLink btn-block mt-0">Download Part 4</button>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                            <div className="col-xl-4 ">
                                                                <div className="upgrade-para p-3">
                                                                    <a
                                                                        href="https://s3-us-west-2.amazonaws.com/virtualpioneer.net/+Designo+Pro+2/Stock+Audio+Firesale+pt.+5.2.zip "
                                                                        target="_blank"

                                                                    >
                                                                        <button className="demoLink btn-block mt-0">Download Part 5</button>
                                                                    </a>
                                                                </div>
                                                            </div>

                                                        </div>

                                                    </div>
                                                </div>


                                                <div className="col-xl-4  mb-4">
                                                    <div className="upgrade-box-content text-center  text-white">
                                                        <div className="upgrade-heading">
                                                            <h6>Resell Rights to Local eCom Site Maker</h6>
                                                        </div>
                                                        <div className="upgrade-para p-3">
                                                            <a
                                                                href="https://s3.us-west-2.amazonaws.com/virtualpioneer.net/faceswap/themeforest-sUSRhifw-zigcy-modern-multiconcept-woocommerce-theme.zip"
                                                                target="_blank"
                                                            >
                                                                <button className="demoLink btn-block mt-0">Download</button>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xl-4  mb-4">
                                                    <div className="upgrade-box-content text-center  text-white">
                                                        <div className="upgrade-heading">
                                                            <h6>Agency Rights to Elite Video Player 2.0e</h6>
                                                        </div>
                                                        <div className="upgrade-para p-3">
                                                            <a
                                                                href="https://s3-us-west-2.amazonaws.com/virtualpioneer.net/Vidyz/codecanyon-17621210-video-gallery-and-player-pro.zip"
                                                                target="_blank"
                                                            >
                                                                <button className="demoLink btn-block mt-0">Download</button>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xl-4  mb-4">
                                                    <div className="upgrade-box-content text-center  text-white">
                                                        <div className="upgrade-heading">
                                                            <h6>Whitelabel Rights to 800k DFY Content Articles</h6>
                                                        </div>
                                                        <div className="upgrade-para p-3">
                                                            <a
                                                                href="https://s3.amazonaws.com/virtualpioneerbonuses/Order+_FO36D0EA93F2+(1).pdf"
                                                                target="_blank"
                                                            >
                                                                <button className="demoLink btn-block mt-0">Download</button>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-xl-4  mb-4">
                                                    <div className="upgrade-box-content text-center  text-white">
                                                        <div className="upgrade-heading">
                                                            <h6>Transparent Images Collection with Reseller Rights</h6>
                                                        </div>
                                                        <div className="upgrade-para p-3">
                                                            <a
                                                                href="https://s3-us-west-2.amazonaws.com/virtualpioneer.net/Reviewify/D03-500PeopleStockPhotos.zip"
                                                                target="_blank"
                                                            >
                                                                <button className="demoLink btn-block mt-0">Download</button>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xl-4  mb-3">
                                                    <div className="upgrade-box-content text-center  text-white">
                                                        <div className="upgrade-heading">
                                                            <h6>Crypto-Agency Scaling Materials</h6>
                                                        </div>
                                                        <div className="upgrade-para p-3">
                                                            <a
                                                                href="https://bonus-one.s3.us-west-2.amazonaws.com/CryptoAgencyScalingMaterials.zip"
                                                                target="_blank"
                                                            >
                                                                <button className="demoLink btn-block mt-0">Download</button>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xl-4  mb-3">
                                                    <div className="upgrade-box-content text-center  text-white">
                                                        <div className="upgrade-heading">
                                                            <h6>Virtual tour maker</h6>
                                                        </div>
                                                        <div className="upgrade-para p-3">
                                                            <a
                                                                href="https://s3.us-west-2.amazonaws.com/virtualpioneer.net/localio/wpvr.7.3.5.zip"
                                                                target="_blank"
                                                            >
                                                                <button className="demoLink btn-block mt-0">Download</button>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xl-4  mb-3">
                                                    <div className="upgrade-box-content text-center  text-white">
                                                        <div className="upgrade-heading">
                                                            <h6>Easy Infographics Builder</h6>
                                                        </div>
                                                        <div className="upgrade-para p-3">
                                                            <a
                                                                href="https://s3.us-west-2.amazonaws.com/virtualpioneer.net/easy+infographics+builder/infographic-and-list-builder-ilist.4.3.8.zip "
                                                                target="_blank"
                                                            >
                                                                <button className="demoLink btn-block mt-0">Download</button>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xl-4  mb-3">
                                                    <div className="upgrade-box-content text-center  text-white">
                                                        <div className="upgrade-heading">
                                                            <h6> Autopilot Outsourcer Training and Resources:  </h6>
                                                        </div>
                                                        <div className="upgrade-para p-3">
                                                            <a
                                                                href="https://fruitphul.teachable.com/p/vault/"
                                                                target="_blank"
                                                            >
                                                                <button className="demoLink btn-block mt-0">Download</button>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xl-4  mb-3">
                                                    <div className="upgrade-box-content text-center  text-white">
                                                        <div className="upgrade-heading">

                                                            <h6>   Reseller Materials:  </h6>
                                                        </div>
                                                        <div className="upgrade-para p-3">
                                                            <a
                                                                href="https://bonus-one.s3.us-west-2.amazonaws.com/KoincartReseller.zip"
                                                                target="_blank"
                                                            >
                                                                <button className="demoLink btn-block mt-0">Download</button>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>



                        <div className="tab-pane fade" id="v-pills-business" role="tabpanel" aria-labelledby="v-pills-business-tab">
                            <div className="tabepaneIn">
                                <h2 className="tabTitle">Business Finder</h2>
                                <div className="tabepaneIn-main">
                                    <div className="tabepaneIn-cont">
                                        <h3>Business Finder</h3>
                                        <iframe
                                            src="https://resources.vega6.info/business-finder?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzUxMiJ9.eyJpYXQiOjE2MDYxMjUyMjcsImp0aSI6IldZY3gwQW42cnFHcHdhRUluYlNWTWwiLCJpc3MiOiJodHRwczpcL1wvcmVzb3VyY2VzLnZlZ2E2LmluZm9cLyIsIm5iZiI6MTYwNjEyNTIzNywiZGF0YSI6eyJ1c2VyX2lkIjoiMSIsImFwcF91cmwiOiJodHRwczpcL1wvdjJzZXJ2ZXIudmlkZW9yZWVsLmlvXC8ifX0.fN5JpYG7hFfXgprbTLyGbtbBE-T4-8SG25xtn4ckOkGmwevJEANb2UjngHIaUiAVNNmzVIeLotsVRZb9aB9g_Q"
                                            style={{ width: "100%", height: "100vh" }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </div >

        </>
    )
}

export default UpgradesContent;