import React, { useState, useEffect, useRef } from "react";
import { FiEdit, FiTrash2 } from "react-icons/fi";
import axios from 'axios'
import { baseURL } from '../../global/global'
import { useSelector, useDispatch } from "react-redux";
import { setAlert } from "../../actions/alert";
import Alert from "../Alert";
import SweetAlert from "react-bootstrap-sweetalert";
import { BsFillCircleFill } from 'react-icons/bs'
import ModernDatepicker from 'react-modern-datepicker';
import { DefaultEditor } from 'react-simple-wysiwyg';



const Coupons = ({ productData, id }) => {

    const auth = useSelector(state => state.auth)
    const base = useSelector(state => state.base.storeData)
    const dispatch = useDispatch()
    const [couponData, setCouponData] = useState([])
    const [loader, setLoader] = useState(false)
    const [button, setButton] = useState('Delete');
    const [couponId, setCouponId] = useState(-1)
    const [showDelete, setShowDelete] = useState(false);
    const [couponToggle, setCouponToggle] = useState(false)
    const [buttonEdit, setButtonEdit] = useState(false)
    const [coupon, setCoupon] = useState({
        couponStart: "",
        couponTo: "",
        couponType: "fixed",
        couponAmount: 0,
        couponCode: "",
        couponDescription: "description",
        deactivateCouppon: false
    })
    const [apply, setApply] = useState({
        applyCoupon: false,
        deactivateCouppon: false
    })

    const [activeCoupons, setActiveCoupons] = useState([])

    useEffect(() => {
        if (productData.applyCoupon) {
            let data = productData.applyCoupon
            data = data.split(",")
            setActiveCoupons(data)
        }
    }, [])

    // useEffect(() => {
    //     if(coupon.couponAmount > 0){}
    //     setCoupon({
    //         ...coupon,
    //         couponAmount : 0
    //     })
    // },[coupon.couponType])

    const handleChange = (e) => {
        const { name, value } = e.target
        setApply({
            ...apply,
            applyCoupon: false
        })
        let val = value
        if (name === "couponAmount") {
            val = value.match('^[0-9.]*$')
            val = val[0]
            if (coupon.couponType === "percent") {
                if (val <= 100) {
                    setCoupon({
                        ...coupon,
                        [name]: val
                    })
                }
                else {
                    dispatch(setAlert("Discount should be less than 100%", "danger"))
                }
            }
            else {
                setCoupon({
                    ...coupon,
                    [name]: val
                })
            }
        }
        else if (name === "couponType") {
            setCoupon({
                ...coupon,
                couponAmount: 0,
                couponType: val
            })
        }
        else {
            setCoupon({
                ...coupon,
                [name]: val
            })
        }

    }
    const handleCouponChange = (e) => {
        const { name, checked } = e.target
        if (name === "applyCoupon") {
            if (checked) {
                if (coupon.couponType === "fixed") {
                    if (parseInt(productData.price) < parseInt(coupon.couponAmount)) {
                        dispatch(setAlert("You can not apply coupon on this product because discount is more than the product price", "danger"))
                    }
                    else {
                        setApply({
                            ...apply,
                            applyCoupon: checked,
                            deactivateCouppon: !checked
                        })
                        setCoupon({
                            ...coupon,
                            deactivateCouppon: false
                        })
                    }
                }
                else {
                    setApply({
                        ...apply,
                        applyCoupon: checked,
                        deactivateCouppon: !checked
                    })
                    setCoupon({
                        ...coupon,
                        deactivateCouppon: false
                    })
                }
            } else {
                setApply({
                    ...apply,
                    applyCoupon: checked,
                    deactivateCouppon: checked
                })
            }

        }
        else {
            if (checked) {
                // ankit..down
                setShowDelete(true)
                setButton('Conform')
                // ankit...above
                setApply({
                    ...apply,
                    applyCoupon: !checked,
                    deactivateCouppon: checked
                })
                setCoupon({
                    ...coupon,
                    deactivateCouppon: true
                })

            } else {
                setApply({
                    ...apply,
                    applyCoupon: checked,
                    deactivateCouppon: checked
                })
            }
        }
    }

    const handleDate = (date, type) => {
        setApply({
            ...apply,
            applyCoupon: false
        })
        if (type === "start") {
            setCoupon({
                ...coupon,
                couponStart: date
            })
        }
        else if (type === "end" && coupon.couponStart !== date) {
            setCoupon({
                ...coupon,
                couponTo: date
            })
        }
        else {
            dispatch(setAlert("Should'nt choose same date", "danger"))
        }

    }

    const fetchCoupons = () => {
        axios({
            method: 'POST',
            url: `${baseURL}coupon-get`,
            data: {},
            headers: { 'Content-Type': 'application/json', 'Authorization': auth.token }
        }).then((res) => {
            if (res.data.status === true) {
                setCouponData(res.data.data)
            }
            else {
                setCouponData([])
            }
        }).catch((error) => {
            console.log(error)
        })
    }
    useEffect(() => {
        fetchCoupons()
    }, [])

    useEffect(() => {
        if (productData !== false && productData !== undefined) {
            // setApplyCoupon(productData.applyCoupon)
        }
    }, [productData])



    const handleSave = (e) => {
        e.preventDefault()
        setLoader(true)
        let data = { ...coupon }
        if (apply.applyCoupon) {
            data.productId = id
        }
        axios({
            method: 'POST',
            url: `${baseURL}coupon-save`,
            data: data,
            headers: { 'Content-Type': 'application/json', 'Authorization': auth.token }
        }).then((res) => {
            if (res.data.status === true) {
                dispatch(setAlert(res.data.message, "success"))
                fetchCoupons()
                fetchProduct()
            }
            else {
                dispatch(setAlert(res.data.message, "danger"))
            }
            setLoader(false)
        }).catch((error) => {
            setLoader(false)
            console.log(error)
        })
    }

    const handleSaveEditCoupon = (e) => {
        e.preventDefault()
        setLoader(true)
        let data = { ...coupon }

        if (apply.applyCoupon) {
            data.productId = id
        }
        data.couponId = couponId
        axios({
            method: 'POST',
            url: `${baseURL}coupon-edit`,
            data: data,
            headers: { 'Content-Type': 'application/json', 'Authorization': auth.token }
        }).then((res) => {
            if (res.data.status === true) {
                dispatch(setAlert(res.data.message, "success"))
                fetchCoupons()
                fetchProduct()
            }
            else {
                dispatch(setAlert(res.data.message, "danger"))
            }
            setLoader(false)
        }).catch((error) => {
            setLoader(false)
            console.log(error)
        })
    }

    const handleEdit = (id) => {
        setCouponId(id)
        const couponEditData = couponData.find(({ couponId }) => couponId === id)
        if (couponEditData) {
            setCoupon({
                ...coupon,
                couponStart: couponEditData.couponStart,
                couponTo: couponEditData.couponTo,
                couponType: couponEditData.couponType,
                couponAmount: couponEditData.couponAmount,
                couponCode: couponEditData.couponCode,
            })
            if (activeCoupons.includes(id)) {
                setApply({
                    ...apply,
                    applyCoupon: true
                })
            }
            else {
                setApply({
                    ...apply,
                    applyCoupon: false,
                    deactivateCouppon: false
                })
            }

            setButtonEdit(true)
            setCouponToggle(true)
        }

    }

    const handleDelete = (id) => {
        setShowDelete(true)
        setCouponId(id)
    }

    const onConfirm = () => {
        // ankit ....start
        if (coupon.deactivateCouppon) {
            setCoupon({ ...coupon, deactivateCouppon: true })
            setShowDelete(false)
            setButton('Delete')
        }
        // ankit...edit else part
        else {
            setButton('Deleting...');
            axios({
                method: 'POST',
                url: `${baseURL}coupon-delete`,
                data: { "couponId": couponId },
                headers: { 'Content-Type': 'application/json', 'Authorization': auth.token }
            }).then((res) => {
                if (res.data.status === true) {
                    dispatch(setAlert(res.data.message, "success"))
                    fetchCoupons()
                }
                else {
                    dispatch(setAlert(res.data.message, "danger"))
                }
                setShowDelete(false)
            }).catch((error) => {
                setShowDelete(false)
                console.log(error)
            })
        }
    }
    const onCancel = () => {
        setShowDelete(false)
        setCouponId(-1)
        if (coupon.deactivateCouppon) {
            setButton('Delete')
            setCoupon({ ...coupon, deactivateCouppon: false })
            setApply({
                ...apply,
                deactivateCouppon: false
            })
        }
    }

    const handleCoupon = () => {
        setCouponToggle(!couponToggle)
        if (buttonEdit) {
            setButtonEdit(false)
            setCoupon({
                ...coupon,
                couponStart: "",
                couponTo: "",
                couponType: "fixed",
                couponAmount: 0,
                couponCode: "",
                couponDescription: "description",
                deactivateCouppon: false
            })
        }
    }

    const fetchProduct = () => {
        axios({
            method: 'POST',
            url: `${baseURL}product-get`,
            data: { "productId": id },
            headers: { 'Content-Type': 'application/json', 'Authorization': auth.token }
        }).then((res) => {
            if (res.data.status === true) {
                let data = res.data.data.applyCoupon
                if (data) {
                    data = data.split(",")
                    setActiveCoupons(data)
                } else {
                    setActiveCoupons([])
                }
            }
        }).catch((error) => {
            console.log(error)
        })
    }

    return (
        <>
            <Alert />
            <div className="productBox">
                <div className="titleBtn">
                    <h2 className="pageHeading">Coupons</h2>
                    <div className="productBlock mt-0">
                        <button className="demoLink mt-0" onClick={() => handleCoupon()}>Create Coupon</button>
                    </div>
                </div>


                {couponToggle ?
                    <>
                        <div className="productBlock">
                            <div className="productBlock-left">
                                <label>Coupon Code</label>
                            </div>
                            <div className="productBlock-right">
                                <input
                                    className="searchInp searchInp2"
                                    placeholder="Add Coupon Code"
                                    type="text"
                                    name="couponCode"
                                    value={coupon.couponCode}
                                    onChange={(e) => handleChange(e)}
                                    maxLength={16}
                                />
                                <span className="infoTxt">Add coupon code</span>
                            </div>
                        </div>

                        <div className="productBlock">
                            <div className="productBlock-left">
                                <label>Coupon start from</label>
                            </div>
                            <div className="productBlock-right">


                                <ModernDatepicker
                                    date={coupon.couponStart}
                                    format={'YYYY-MM-DD'}
                                    showBorder
                                    className="date-picker-style"
                                    onChange={date => handleDate(date, "start")}
                                    primaryColor={'#400C46'}
                                    secondaryColor={'#fff6fe'}
                                    primaryTextColor={'#400C46'}
                                    secondaryTextColor={'#fff'}
                                // minDate={coupon.couponStart}
                                />
                                <span className="infoTxt">Starting date for this coupon</span>
                            </div>
                        </div>

                        <div className="productBlock">
                            <div className="productBlock-left">
                                <label>Coupon ends on</label>
                            </div>
                            <div className="productBlock-right">

                                <ModernDatepicker
                                    date={coupon.couponTo}
                                    format={'YYYY-MM-DD'}
                                    showBorder
                                    className="date-picker-style"
                                    onChange={date => handleDate(date, "end")}
                                    primaryColor={'#400C46'}
                                    secondaryColor={'#fff6fe'}
                                    primaryTextColor={'#400C46'}
                                    secondaryTextColor={'#fff'}
                                    minDate={coupon.couponStart}
                                />
                                <span className="infoTxt">Ending date for this coupon</span>
                            </div>
                        </div>


                        <div className="productBlock align-items-start">
                            <div className="productBlock-left">
                                <label>Coupon Type</label>
                            </div>
                            <div className="productBlock-right d-flex">
                                <div>
                                    <label class="custom-radio">Percentage
                                        <input
                                            type="radio"
                                            name="couponType"
                                            value="percent"
                                            checked={coupon.couponType === "percent" ? true : false}
                                            onChange={(e) => handleChange(e)}
                                        />
                                        <span class="checkmark"></span>
                                    </label>
                                </div>
                                <div className="pl-5">
                                    <label class="custom-radio">Flat Rate
                                        <input
                                            type="radio"
                                            name="couponType"
                                            value="fixed"
                                            checked={coupon.couponType === "fixed" ? true : false}
                                            onChange={(e) => handleChange(e)}
                                        />
                                        <span class="checkmark"></span>
                                    </label>
                                </div>
                            </div>
                        </div>

                        <div className="productBlock">
                            <div className="productBlock-left">
                                <label>{coupon.couponType === "percent" ? 'Discount Percentage' : 'Discount Amount'}</label>
                            </div>
                            <div className="productBlock-right" style={{ width: "380px", position: "relative" }}>
                                <input
                                    className="searchInp searchInp2"
                                    placeholder="Add Coupon Amount"
                                    type="text"
                                    name="couponAmount"
                                    value={coupon.couponAmount}
                                    onChange={(e) => handleChange(e)}
                                />
                                {coupon.couponType === "fixed" ?
                                    <span className="base-symbol">{base.symbol}</span> : ''}
                                <span className="infoTxt">Enter the value in which you want to give discount</span>
                            </div>
                        </div>

                        <div className="productBlock">
                            <div className="productBlock-left">
                                <label>Apply to this product</label>
                            </div>
                            <div className="productBlock-right">
                                <label className="customRadio sq">
                                    <input
                                        type="checkbox"
                                        name="applyCoupon"
                                        checked={apply.applyCoupon}
                                        onChange={(e) => handleCouponChange(e)}
                                    />
                                    <span class="checkmark"></span>
                                </label>

                            </div>
                        </div>
                        <div className="productBlock">
                            <div className="productBlock-left">
                                <label>Deactivate for all products</label>
                            </div>
                            <div className="productBlock-right">
                                <label className="customRadio sq">
                                    <input
                                        type="checkbox"
                                        name="deactivateCouppon"
                                        checked={apply.deactivateCouppon}
                                        onChange={(e) => handleCouponChange(e)}
                                    />
                                    <span class="checkmark"></span>
                                </label>
                            </div>
                        </div>
                        <div className="productBlock mb-5">
                            {buttonEdit ?
                                <button className="demoLink mt-0" onClick={handleSaveEditCoupon}>Update Coupon {loader ? <i className="fa fa-spinner fa-spin mx-2" /> : ''}</button> :
                                <button className="demoLink mt-0" onClick={handleSave}>Save Coupon {loader ? <i className="fa fa-spinner fa-spin mx-2" /> : ''}</button>}

                        </div>
                    </> : ''}

                <div className="table-responsive">
                    <table className="table">
                        <tr>
                            <th>Code</th>
                            <th>Start From</th>
                            <th>Ends On</th>
                            <th>Type</th>
                            <th>Amount</th>
                            <th>Active</th>
                            <th>Status</th>
                            <th>Action</th>
                        </tr>
                        {couponData.length > 0 ?
                            couponData.map((curElem) => {
                                return (
                                    <tr key={curElem.couponId}>
                                        <td>{curElem.couponCode}</td>
                                        <td>{curElem.couponStart}</td>
                                        <td>{curElem.couponTo}</td>
                                        <td>{curElem.couponType}</td>
                                        <td>{curElem.couponAmount}</td>
                                        <td><BsFillCircleFill
                                            color={activeCoupons.length > 0 ? activeCoupons.includes(curElem.couponId) ? "#bbff99" : "#f2738c" : '#f2738c'} /></td>
                                        <td><BsFillCircleFill
                                            color={curElem.status === "1" ? "#bbff99" : "#f2738c"}
                                        /></td>
                                        <td>
                                            <a><FiEdit
                                                className="smIcon cursor-pointer"
                                                onClick={() => handleEdit(curElem.couponId)}
                                            /></a>
                                            <a><FiTrash2
                                                className="smIcon cursor-pointer"
                                                onClick={() => handleDelete(curElem.couponId)}
                                            /></a>
                                        </td>
                                    </tr>
                                )
                            }) :
                            <tr className="text-center" style={{ color: "#701f66" }}>
                                <td colspan="8" style={{ fontSize: "20px" }}>
                                    You have not created any coupons yet
                                </td>
                            </tr>}
                    </table>
                </div>

                {/* <div className="productBlock mt-4 button-center">
                    <button className="demoLink mt-0" onClick={() => handleCoupon()}>Create New Coupon</button>
                </div> */}


                <SweetAlert
                    warning
                    showCancel
                    confirmBtnText={button}
                    confirmBtnBsStyle="danger"
                    cancelBtnBsStyle="light"
                    title="Are you sure?"
                    onConfirm={(e) => onConfirm(e)}
                    onCancel={(e) => onCancel(e)}
                    focusCancelBtn
                    show={showDelete}
                >
                    {coupon.deactivateCouppon ? 'do you want to deactivate this coupon?' : ' do you want to delete this coupon?'}
                </SweetAlert>
            </div>
        </>
    )
}

export default Coupons;