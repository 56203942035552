import React from 'react'
import { Helmet } from 'react-helmet'
import { useSelector } from 'react-redux'
const TitleBar = (props) => {
    const rebrand = useSelector(state => state.rebrand.data.name)
    const isAuth = useSelector(state => state.auth.isAuthenticated)

    return (
        <Helmet>
            <title>{isAuth ? rebrand ? rebrand : 'Koincart' : 'Koincart'} | {props.title} </title>
        </Helmet>
    )
}

export default React.memo(TitleBar)