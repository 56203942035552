import React, { useState, useEffect } from "react";
import paypal from "../../images/paypal.png";
import bitpay from "../../images/bitpay.png";
import coingate from "../../images/coingate.png";
import crypto from "../../images/Crypto.png";
import confirmo from "../../images/ConFirmo.png";
import coinremitter from "../../images/CoinRemitter.png";
import nowpayments from "../../images/NowPayments.png";
import { useDispatch, useSelector } from "react-redux";
import { setPaymentArr } from "../../actions/productAction";
import PaymentCard from "./PaymentCard";
import { Link } from "react-router-dom";

const Payments = ({ productData }) => {
    const dispatch = useDispatch()
    const payment = useSelector(state => state.payment)
    const [integrationData, setIntegrationData] = useState({
        bitpay: [],
        coingate: [],
        paypal: [],
        crypto: [],
        confirmo: [],
        coinremitter: [],
        nowpayments : []

    })
    const [paymentData, setPaymentData] = useState([])

    useEffect(() => {
        if (productData) {
            setPaymentData(productData.paymentData)
        }
    }, [productData])



    useEffect(() => {
        let bitpay = integrationData.bitpay
        let coingate = integrationData.coingate
        let paypal = integrationData.paypal
        let crypto = integrationData.crypto
        let confirmo = integrationData.confirmo
        let coinremitter = integrationData.coinremitter
        let nowpayments = integrationData.nowpayments

        let arr = []
        if (bitpay.length > 0) {
            bitpay.forEach((curElem) => {
                let obj = {
                    enable: false,
                    integrationId: curElem.id,
                    paymentType: "bitpay",
                    currencyId: '',
                    name: curElem.name
                }

                if (paymentData.length > 0) {
                    paymentData.forEach((pay) => {

                        if (pay.paymentType === "bitpay") {
                            if (pay.integrationId === curElem.id && pay.enable) {
                                {
                                    obj.enable = true
                                }
                            }
                        }
                    })
                }

                arr.push(obj)
            })
        }

        if (coingate.length > 0) {
            coingate.forEach((curElem) => {
                let obj = {
                    enable: false,
                    integrationId: curElem.id,
                    paymentType: "coingate",
                    currencyId: '',
                    name: curElem.name
                }

                if (paymentData.length > 0) {
                    paymentData.forEach((pay) => {
                        if (pay.paymentType === "coingate") {
                            if (pay.integrationId === curElem.id && pay.enable) {
                                {
                                    obj.enable = true
                                }
                            }
                        }
                    })
                }

                arr.push(obj)
            })
        }

        if (paypal.length > 0) {
            paypal.forEach((curElem) => {
                let obj = {
                    enable: false,
                    integrationId: curElem.id,
                    paymentType: "paypal",
                    currencyId: '',
                    name: curElem.name
                }

                if (paymentData.length > 0) {
                    paymentData.forEach((pay) => {
                        if (pay.paymentType === "paypal") {
                            if (pay.integrationId === curElem.id && pay.enable) {
                                {
                                    obj.enable = true
                                }
                            }
                        }
                    })
                }

                arr.push(obj)
            })
        }
        if (crypto.length > 0) {
            crypto.forEach((curElem) => {
                let obj = {
                    enable: false,
                    integrationId: curElem.id,
                    paymentType: "crypto",
                    currencyId: '',
                    name: curElem.name
                }

                if (paymentData.length > 0) {
                    paymentData.forEach((pay) => {
                        if (pay.paymentType === "crypto") {
                            if (pay.integrationId === curElem.id && pay.enable) {
                                {
                                    obj.enable = true
                                }
                            }
                        }
                    })
                }

                arr.push(obj)

            })
        }
        if (confirmo.length > 0) {
            confirmo.forEach((curElem) => {

                let obj = {
                    enable: false,
                    integrationId: curElem.id,
                    paymentType: "confirmo",
                    currencyId: '',
                    name: curElem.name
                }

                if (paymentData.length > 0) {
                    paymentData.forEach((pay) => {
                        if (pay.paymentType === "confirmo") {
                            if (pay.integrationId === curElem.id && pay.enable) {
                                {
                                    obj.enable = true
                                }
                            }
                        }
                    })
                }

                arr.push(obj)
            })
        }
        if (coinremitter.length > 0) {
            coinremitter.forEach((curElem) => {

                let obj = {
                    enable: false,
                    integrationId: curElem.id,
                    paymentType: "coinremitter",
                    currencyId: '',
                    name: curElem.name
                }

                if (paymentData.length > 0) {
                    paymentData.forEach((pay) => {
                        if (pay.paymentType === "coinremitter") {
                            if (pay.integrationId === curElem.id && pay.enable) {
                                {
                                    obj.enable = true
                                }
                            }
                        }
                    })
                }

                arr.push(obj)
            })
        }
        if (nowpayments.length > 0) {
            nowpayments.forEach((curElem) => {

                let obj = {
                    enable: false,
                    integrationId: curElem.id,
                    paymentType: "nowpayments",
                    currencyId: '',
                    name: curElem.name
                }

                if (paymentData.length > 0) {
                    paymentData.forEach((pay) => {
                        if (pay.paymentType === "nowpayments") {
                            if (pay.integrationId === curElem.id && pay.enable) {
                                {
                                    obj.enable = true
                                }
                            }
                        }
                    })
                }

                arr.push(obj)
            })
        }
        dispatch(setPaymentArr(arr))
    }, [integrationData])



    const fetchIntegration = () => {
        setIntegrationData({
            ...integrationData,
            bitpay: payment.bitpay,
            coingate: payment.coingate,
            paypal: payment.paypal,
            crypto: payment.crypto,
            confirmo: payment.confirmo,
            alfacoins: payment.alfacoins,
            gourl: payment.gourl,
            spicepay: payment.spicepay,
            coinremitter: payment.coinremitter,
            nowpayments: payment.nowpayments,
            strike: payment.strike
        })
    }

    useEffect(() => {
        fetchIntegration()
    }, [payment]);



    return (
        <>
            <div classNameName="pymentsTab">
                <div className="checkout-sec">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="checkout-left">
                                <div className="pricing payment-heading">
                                    <h2>Payment Methods</h2>
                                    <ul className="pricing-opt">
                                        {paymentData.length > 0 ?
                                            paymentData.map((curElem, index) => {
                                                let icon
                                                if (curElem.paymentType === 'bitpay') {
                                                    icon = bitpay
                                                } else if (curElem.paymentType === 'coingate') {
                                                    icon = coingate
                                                }
                                                else if (curElem.paymentType === "paypal") {
                                                    icon = paypal
                                                }
                                                else if (curElem.paymentType === "confirmo") {
                                                    icon = confirmo
                                                }
                                                else if (curElem.paymentType === "crypto") {
                                                    icon = crypto
                                                }
                                                else if (curElem.paymentType === "coinremmiter") {
                                                    icon = coinremitter
                                                }
                                                else if (curElem.paymentType === "nowpayments") {
                                                    icon = nowpayments
                                                }

                                                return (
                                                    <PaymentCard
                                                        index={index}
                                                        data={curElem}
                                                        icon={icon}
                                                    />
                                                )
                                            }) :

                                            <div className="col-sm-12 text-center my-3">
                                                <p>
                                                    You have not integrated any payment method <Link to="/integration">Click Here</Link> to connect
                                                </p>
                                            </div>
                                        }

                                    </ul>

                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default Payments;