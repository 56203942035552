import React,{useState, useEffect} from 'react'
import { useSelector } from 'react-redux';
import helpDeskImg from '../../images/help-desk.png';

function CustomerSupport() {
    const rebrandData = useSelector(state => state.rebrand.data);

    const [rebrand, setRebrand] = useState(rebrandData);

    useEffect(() => {
        setRebrand(rebrandData);
    }, [rebrandData])
    return (
        <section className="helpDesk">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6">
                        <div className="helpDesk-left">
                        <img src={helpDeskImg} />
                        </div>
                    </div>
                    <div className="col-lg-6">
                    <div className="helpDesk-right">
                        <h6>customer support</h6>
                        <h2>Can't find your answer?</h2>
                        <p>We're here to help! From general communication to specifc {rebrand ? rebrand.name :  'Koincart' } platform questions, please reach out so we can assist.</p>
                        <a target="_blank" className="demoLink" href="https://support.vineasx.com/">Contact Our Helpdesk</a>
                    </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default CustomerSupport
