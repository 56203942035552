import React, { useState, useEffect } from "react";
import Navbar from "../Navbar";
import Footer from "../Footer";
import TitleBar from "../TitleBar";
import dummyProduct from '../../images/dummyProduct.jpg'
import { BsPlusLg } from 'react-icons/bs';
import dots from '../../images/dots.png';
import { FiTrash2 } from "react-icons/fi";
import { ImEmbed2 } from "react-icons/im";
import { Dropdown } from "react-bootstrap";
import axios from "axios";
import { baseURL, currentURL } from "../../global/global";
import { useSelector, useDispatch } from "react-redux";
import SweetAlert from "react-bootstrap-sweetalert";
import { setAlert } from "../../actions/alert";

import { GoLinkExternal } from "react-icons/go";
import { GrShareOption } from "react-icons/gr";
import { VscCopy } from "react-icons/vsc";
import { AiOutlineCodeSandbox, AiOutlinePlus, AiOutlineSearch } from "react-icons/ai";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa"
import { Link, useHistory } from "react-router-dom";
import { AiTwotoneEdit } from "react-icons/ai"
import { Modal } from 'react-bootstrap';
import modalCloseIcon from '../../images/modal-close.png';
import Alert from "../Alert";
import {
    EmailIcon,
    EmailShareButton, FacebookIcon,
    FacebookShareButton, HatenaIcon,
    HatenaShareButton, InstapaperIcon,
    InstapaperShareButton,
    LineShareButton, LinkedinIcon,
    LinkedinShareButton, LivejournalIcon,
    LivejournalShareButton, MailruIcon,
    MailruShareButton, OKIcon,
    OKShareButton,
    PinterestShareButton, PocketIcon,
    PocketShareButton, RedditIcon,
    RedditShareButton, TelegramIcon,
    TelegramShareButton, TumblrIcon,
    TumblrShareButton, TwitterIcon,
    TwitterShareButton, ViberIcon,
    ViberShareButton,
    VKShareButton, WhatsappIcon,
    WhatsappShareButton, WorkplaceIcon,
    WorkplaceShareButton
} from "react-share";

// const shareUrl = 'https://koincart.com';
const title = 'Koincart';

const ProductList = () => {
    const auth = useSelector(state => state.auth)
    const base = useSelector(state => state.base.storeData)

    const dispatch = useDispatch()
    const [shareUrl, setShareUrl] = useState({
        share: "",
        title: "",
        copied: false
    })
    const [products, setProducts] = useState([])
    const [productData, setProductData] = useState([])

    const [button, setButton] = useState('Delete');
    const [showDelete, setShowDelete] = useState(false);
    const [productId, setProductId] = useState(-1)
    const [searchKey, setSearchKey] = useState("")
    const [status, setStatus] = useState(false)
    const [pagination, setPagination] = useState({
        currentPage: 1,
        totalItemonPage: 20
    })
    const [loader, setLoader] = useState(false)
    const [pageNumber, setPageNumber] = useState([])
    const [mode, setMode] = useState("")
    const [embed, setEmbed] = useState({
        header: '',
        headerStatus: false,
        checkout: '',
        checkoutStatus: false,

    })

    const [show2, setShow2] = useState(false);
    const handleClose2 = () => setShow2(false);
    const handleShow2 = (id) => {
        setEmbed({
            ...embed,
            header: `<script type="text/Javascript" id="kc_widget" src="https://app.koincart.com/front-end/widget.js?product_id=${id}"></script>`,
            checkout: `<div id="koincart_checkout_section"></div>`
        })
        setShow2(true);
    }
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);

    const handleShow = (url, productName) => {
        setShareUrl({
            ...shareUrl,
            share: url,
            title: productName,
            copied: false
        })
        setShow(true);
    }


    const fetchProducts = () => {
        setLoader(true)
        axios({
            method: 'POST',
            url: `${baseURL}product-fetchs`,
            data: {},
            headers: { 'Content-Type': 'application/json', 'Authorization': auth.token }
        }).then((res) => {
            if (res.data.status === true) {
                setLoader(false)
                setProducts(res.data.data)
            }
            else {
                setLoader(false)
            }
        }).catch((error) => {
            setLoader(false)
            console.log(error)
        })
    }

    useEffect(() => {
        fetchProducts()
    }, []);




    const onConfirm = () => {
        setButton('Deleting...');
        axios({
            method: 'POST',
            url: `${baseURL}product-delete`,
            data: { "productId": productId },
            headers: { 'Content-Type': 'application/json', 'Authorization': auth.token }
        }).then((res) => {
            if (res.data.status === true) {
                dispatch(setAlert(res.data.message, "success"))
                fetchProducts()
            }
            else {
                dispatch(setAlert(res.data.message, "danger"))
            }
            setShowDelete(false)
        }).catch((error) => {
            setShowDelete(false)
            console.log(error)
        })
    }
    const onCancel = () => {
        setShowDelete(false)
        setProductId(-1)
    }


    const handleProductDelete = (id) => {
        setProductId(id)
        setShowDelete(true)
    }
    const handleSearch = (e) => {
        setSearchKey(e.target.value)
    }

    const handleSelect = (e) => {
        const { value } = e.target
        if (value === "0") {
            setMode("")
        }
        else if (value === "1") {
            setMode("sandbox")
        }
        else if (value === "2") {
            setMode("live")
        }
    }

    useEffect(() => {
        if (productData.length > 0) {
            let maxLength = productData.length
            let data = []
            if (maxLength > 20) {
                let val = maxLength / 20
                if (val > parseInt(val)) {
                    val = val + 1
                }
                for (let i = 1; i <= val; i++) {
                    data.push(i)
                }
                setPageNumber(data)
            }
            else {
                setPageNumber([1])
            }
        }
    }, [productData])

    const handleClick = (num) => {
        setPagination({
            ...pagination,
            currentPage: num
        })
    }
    const handleBackward = (num) => {
        if (pageNumber[0] < num) {
            setPagination({
                ...pagination,
                currentPage: num - 1
            })
        }
    }
    const handleForward = (num) => {
        if (pageNumber[pageNumber.length - 1] > num) {
            setPagination({
                ...pagination,
                currentPage: num + 1
            })
        }
    }

    const indexOfLastTodo = pagination.currentPage * pagination.totalItemonPage;
    const indexOfFirstTodo = indexOfLastTodo - pagination.totalItemonPage;
    const currentTodos = productData.slice(indexOfFirstTodo, indexOfLastTodo);

    useEffect(() => {
        if (mode === "" && searchKey === "") {
            setProductData(products)
        }
        else {
            const val = products.filter((curElem) => {
                if (mode === "") {
                    return curElem
                }
                else {
                    let data = (curElem.paymentMode === mode)
                    return data
                }
            }).filter((curElem) => {
                let data = (curElem.productName.toLowerCase().includes(searchKey.toLowerCase())
                    || curElem.productSlug.toLowerCase().includes(searchKey.toLowerCase()))
                if (data) {
                    return curElem
                }
            })
            setProductData(val)
        }
    }, [mode, searchKey, products])


    const handleDuplicate = (id) => {
        axios({
            method: "POST",
            url: `${baseURL}product-duplicate`,
            data: { "productId": id },
            headers: { 'Content-Type': 'application/json', 'Authorization': auth.token }
        }).then((res) => {
            if (res.data.status === true) {
                dispatch(setAlert(res.data.message, "success"))
                fetchProducts()
            }
            else {
                dispatch(setAlert(res.data.message, "danger"))
            }
        }).catch((error) => {
            console.log(error)
        })
    }

    const handleSort = (type) => {
        let data = [...productData]
        if (type === "product") {
            if (status === true) {
                setStatus(false)
                data.sort((a, b) => {
                    if (a.productName.toLowerCase() > b.productName.toLowerCase()) {
                        return 1
                    }
                    else {
                        return -1
                    }
                }
                )
            }
            else {
                data.sort((a, b) => {
                    setStatus(true)
                    if (a.productName.toLowerCase() < b.productName.toLowerCase()) {
                        return 1
                    }
                    else {
                        return -1
                    }
                }
                )
            }
        }
        else if (type === "price") {
            if (status === true) {
                setStatus(false)
                data.sort((a, b) => {
                    if (parseFloat(a.price) > parseFloat(b.price)) {
                        return 1
                    }
                    else {
                        return -1
                    }
                }
                )
            }
            else {
                data.sort((a, b) => {
                    setStatus(true)
                    if (parseFloat(a.price) < parseFloat(b.price)) {
                        return 1
                    }
                    else {
                        return -1
                    }
                }
                )
            }
        }
        else if (type === "date") {
            if (status === true) {
                setStatus(false)
                data.sort((a, b) => {
                    if ((a.updated) > (b.updated)) {
                        return 1
                    }
                    else {
                        return -1
                    }
                }
                )
            }
            else {
                data.sort((a, b) => {
                    setStatus(true)
                    if ((a.updated) < (b.updated)) {
                        return 1
                    }
                    else {
                        return -1
                    }
                }
                )
            }
        }
        setProductData(data)
    }

    const copyFunction = (type) => {
        var copyText
        if (type === "share") {
            copyText = document.getElementById("copyUrltoCopy");
            setShareUrl({
                ...shareUrl,
                copied: true
            })
        }
        else if (type === "header") {
            copyText = document.getElementById("headerCode");
            setEmbed({
                ...embed,
                headerStatus: true,
                checkoutStatus: false
            })
        }
        else if (type === "checkout") {
            copyText = document.getElementById("checkoutCode");
            setEmbed({
                ...embed,
                headerStatus: false,
                checkoutStatus: true
            })
        }
        copyText.select();
        copyText.setSelectionRange(0, 99999);
        navigator.clipboard.writeText(copyText.value);
    }



    return (
        <>
            <TitleBar title="Products" />
            <Navbar />
            <Alert />

            <section className="siteWrap">
                <div className="pageWrap">
                    <div className="container">
                        <div className="pageTitle withBtn">
                            <h2>Your Products</h2>
                            {auth.user.is_client_account === "1" ? "" :
                                <Link to={'/create-product'}>
                                    <button className="demoLink mt-0"><BsPlusLg size={20} style={{ marginRight: "5px", marginBottom: "3px" }} /> Create Product</button>
                                </Link>
                            }
                        </div>
                        <div className="productTable">
                            <div className="productSort">
                                <div className="productSort-single">
                                    <div className="proSearch">
                                        <span><AiOutlineSearch /></span>
                                        <input
                                            type="text"
                                            placeholder="Search a product by name"
                                            onChange={handleSearch}
                                        />
                                    </div>
                                </div>
                                <div className="productSort-single">
                                    <select onChange={(e) => handleSelect(e)}>
                                        <option value={0}>All</option>
                                        <option value={1}>Sandbox</option>
                                        <option value={2}>Live</option>
                                    </select>
                                </div>

                            </div>
                            <div className="table-responsive">
                                <table className="table table-striped themeTable">
                                    <tr>
                                        <th></th>
                                        <th className="cursor-pointer" onClick={() => handleSort("product")}>Name</th>
                                        <th>Product ID</th>
                                        <th>Mode</th>
                                        <th className="cursor-pointer" onClick={() => handleSort("price")}>Price</th>
                                        <th className="cursor-pointer" onClick={() => handleSort("date")}>Last Update</th>
                                        <th></th>
                                    </tr>
                                    {currentTodos.length > 0 ?
                                        currentTodos.reverse().map((curElem) => {
                                            return (
                                                <tr
                                                    className="cursor-pointer"
                                                    key={curElem.productId}
                                                >
                                                    <td>
                                                        <div className="proBox"><img src={curElem.image ? curElem.image : dummyProduct} /></div>
                                                    </td>

                                                    <td><strong>{curElem.productName}</strong> <br /> <span className="payMode">{curElem.productType}</span> </td>
                                                    <td>{curElem.productNumber}</td>

                                                    <td><span className="mode" style={curElem.paymentMode === "live" ? { backgroundColor: '#90EE90' } : { backgroundColor: '#f2738c' }}>{curElem.paymentMode}</span></td>

                                                    <td>{curElem.price} <br /><span className="payMode">{curElem.paymentType === "1" ? "Fixed Payment" : "Pay As You Want"}</span> </td>

                                                    <td>{curElem.updated}</td>
                                                    <td>

                                                        <span
                                                            className="roundBox"
                                                            style={{ position: 'relative', cursor: 'pointer' }}
                                                            onClick={() => window.open(curElem.productUrl)}
                                                        >
                                                            <GoLinkExternal size={20} className="align-icons" />
                                                        </span>
                                                        {auth.user.is_client_account === "1" ? "" :
                                                            <>
                                                                <span
                                                                    className="roundBox"
                                                                    style={{ position: 'relative', cursor: 'pointer' }}
                                                                    title="Edit"
                                                                >
                                                                    <Link to={`edit-product?id=${curElem.productId}`}><AiTwotoneEdit
                                                                        size={20}
                                                                        className="align-icons"
                                                                    /></Link>
                                                                </span>
                                                                <span
                                                                    className="roundBox"
                                                                    style={{ position: 'relative', cursor: 'pointer', color: 'red' }}
                                                                    title="Delete"
                                                                    onClick={(e) => handleProductDelete(curElem.productId)}
                                                                >
                                                                    <FiTrash2 size={20} className="align-icons" />
                                                                </span>
                                                            </>
                                                        }
                                                        <Dropdown className="tableDropdown">
                                                            <Dropdown.Toggle variant="nn" id="dropdown-basic">
                                                                <img src={dots} alt="" />
                                                            </Dropdown.Toggle>

                                                            <Dropdown.Menu>
                                                                <Dropdown.Item onClick={() => handleShow2(curElem.productId)}><ImEmbed2 size={16} /> Embed Code</Dropdown.Item>
                                                                <Dropdown.Item onClick={() => handleShow(curElem.productUrl, curElem.productName)}><GrShareOption size={16} /> Share</Dropdown.Item>
                                                                {auth.user.is_client_account === "1" ? "" :
                                                                    <>
                                                                        <Dropdown.Item onClick={() => handleDuplicate(curElem.productId)}><VscCopy size={16} /> Duplicate</Dropdown.Item>

                                                                        <Dropdown.Item
                                                                            onClick={() => window.open(`${currentURL}template-customize?id=${curElem.templateId}&productId=${curElem.productId}&type=inbuilt&tempCustype=customized`, "_blank",)}
                                                                        >
                                                                            <AiOutlineCodeSandbox size={16} /> Page Design
                                                                        </Dropdown.Item>
                                                                    </>}
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    </td>
                                                </tr>
                                            )
                                        }) :
                                        <div className="spin-center">
                                            {loader ?
                                                <i className="fa fa-spinner fa-spin" /> :
                                                <h5>Product data not found</h5>
                                            }
                                        </div>

                                    }
                                </table>

                                <div className="tablepage mx-3">
                                    <ul>
                                        <li
                                            onClick={() => handleBackward(pagination.currentPage)}
                                            className="cursor-pointer"
                                        ><a><FaChevronLeft /></a></li>
                                        {pageNumber.length > 0 ?
                                            pageNumber.map((curElem, index) => {
                                                return (
                                                    <li
                                                        key={index}
                                                        id={curElem}
                                                        style={{ color: 'white' }}
                                                        onClick={() => handleClick(curElem)}
                                                        className="cursor-pointer"
                                                    >
                                                        <a style={curElem === pagination.currentPage ? { backgroundColor: 'white', color: '#400C46' } : {}}>{curElem}</a>
                                                    </li>
                                                )
                                            }) : ''}
                                        <li
                                            onClick={() => handleForward(pagination.currentPage)}
                                            className="cursor-pointer"
                                        ><a><FaChevronRight /></a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <SweetAlert
                    warning
                    showCancel
                    confirmBtnText={button}
                    confirmBtnBsStyle="danger"
                    cancelBtnBsStyle="light"
                    title="Are you sure?"
                    onConfirm={(e) => onConfirm(e)}
                    onCancel={(e) => onCancel(e)}
                    focusCancelBtn
                    show={showDelete}
                >
                    Are you sure to delete this Product?
                </SweetAlert>
            </section>

            <Footer />


            <Modal className="VideoModal smModal" show={show} centered>
                <Modal.Body>
                    <div onClick={handleClose} className="vidClose"><img src={modalCloseIcon} /></div>
                    <div className="modalForm">
                        <h2>Share this Product</h2>
                        <div className="socialIcon">
                            <div className="shareLink">
                                <input
                                    class="searchInp full"
                                    type="text"
                                    id="copyUrltoCopy"
                                    value={shareUrl.share}
                                />
                                <button
                                    className="demoLink smBtn mt-0 ml-2"
                                    onClick={() => copyFunction()}
                                >
                                    {shareUrl.copied ? "Copied" :
                                        'Copy'} </button>
                            </div>
                            <div className="Demo__container">
                                <div className="Demo__some-network">
                                    <ul className="pt-5">
                                        <li><FacebookShareButton
                                            url={shareUrl.share}
                                            quote={shareUrl.title}
                                            className="Demo__some-network__share-button"
                                        >
                                            <FacebookIcon round />
                                        </FacebookShareButton></li>

                                        <li>
                                            <TwitterShareButton
                                                url={shareUrl.share}
                                                title={shareUrl.title}
                                                className="Demo__some-network__share-button"
                                            >
                                                <TwitterIcon round />
                                            </TwitterShareButton>
                                        </li>
                                        <li>
                                            <TelegramShareButton
                                                url={shareUrl.share}
                                                title={shareUrl.title}
                                                className="Demo__some-network__share-button"
                                            >
                                                <TelegramIcon round />
                                            </TelegramShareButton>
                                        </li>
                                        <li>
                                            <WhatsappShareButton
                                                url={shareUrl.share}
                                                title={shareUrl.title}
                                                separator=":: "
                                                className="Demo__some-network__share-button"
                                            >
                                                <WhatsappIcon round />
                                            </WhatsappShareButton>
                                        </li>
                                        <li>
                                            <LinkedinShareButton url={shareUrl.share} className="Demo__some-network__share-button">
                                                <LinkedinIcon round />
                                            </LinkedinShareButton>
                                        </li>
                                        <li>
                                            <RedditShareButton
                                                url={shareUrl.share}
                                                title={shareUrl.title}
                                                windowWidth={660}
                                                windowHeight={460}
                                                className="Demo__some-network__share-button"
                                            >
                                                <RedditIcon round />
                                            </RedditShareButton>
                                        </li>
                                        <li>
                                            <TumblrShareButton
                                                url={shareUrl.share}
                                                title={shareUrl.title}
                                                className="Demo__some-network__share-button"
                                            >
                                                <TumblrIcon round />
                                            </TumblrShareButton>
                                        </li>
                                        <li>
                                            <LivejournalShareButton
                                                url={shareUrl.share}
                                                title={shareUrl.title}
                                                description={shareUrl.share}
                                                className="Demo__some-network__share-button"
                                            >
                                                <LivejournalIcon round />
                                            </LivejournalShareButton>
                                        </li>
                                        <li>
                                            <MailruShareButton
                                                url={shareUrl.share}
                                                title={shareUrl.title}
                                                className="Demo__some-network__share-button"
                                            >
                                                <MailruIcon round />
                                            </MailruShareButton>
                                        </li>
                                        <li>
                                            <EmailShareButton
                                                url={shareUrl.share}
                                                subject={shareUrl.title}
                                                body="body"
                                                className="Demo__some-network__share-button"
                                            >
                                                <EmailIcon round />
                                            </EmailShareButton>
                                        </li>
                                        <li>
                                            <ViberShareButton
                                                url={shareUrl.share}
                                                title={shareUrl.title}
                                                className="Demo__some-network__share-button"
                                            >
                                                <ViberIcon round />
                                            </ViberShareButton>
                                        </li>
                                        <li>
                                            <WorkplaceShareButton
                                                url={shareUrl.share}
                                                quote={shareUrl.title}
                                                className="Demo__some-network__share-button"
                                            >
                                                <WorkplaceIcon round />
                                            </WorkplaceShareButton>
                                        </li>
                                        <li>
                                            <PocketShareButton
                                                url={shareUrl.share}
                                                title={shareUrl.title}
                                                className="Demo__some-network__share-button"
                                            >
                                                <PocketIcon round />
                                            </PocketShareButton>
                                        </li>
                                        <li>
                                            <InstapaperShareButton
                                                url={shareUrl.share}
                                                title={shareUrl.title}
                                                className="Demo__some-network__share-button"
                                            >
                                                <InstapaperIcon round />
                                            </InstapaperShareButton>
                                        </li>
                                        <li>
                                            <HatenaShareButton
                                                url={shareUrl.share}
                                                title={shareUrl.title}
                                                windowWidth={660}
                                                windowHeight={460}
                                                className="Demo__some-network__share-button"
                                            >
                                                <HatenaIcon round />
                                            </HatenaShareButton>
                                        </li>
                                    </ul>
                                </div>


                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>


            <Modal className="VideoModal smModal" show={show2} centered>
                <Modal.Body>
                    <div onClick={handleClose2} className="vidClose"><img src={modalCloseIcon} /></div>
                    <div className="modalForm">
                        <h2 className='text-capitalize'>Copy Paste Embed Code</h2>
                        <span className="infoTxt mt-3">Copy paste this code in {'<Header></Header>'} of your HTML page </span>
                        <div className="form-group form-group-alt mt-1">
                            <label htmlFor=''>Header Code</label>
                            <textarea
                                className="form-control"
                                placeholder="Header Code"
                                type="text"
                                name="header"
                                id="headerCode"
                                value={embed.header}
                                readOnly={true}
                            ></textarea>
                            <button
                                className="copy-button"
                                onClick={() => copyFunction("header")}
                            >
                                {embed.headerStatus ?
                                    'Copied' : 'Copy'}
                            </button>
                        </div>

                        <span className="infoTxt mt-5">Copy paste this code in body section of your HTML page</span>
                        <div className="form-group form-group-alt mt-1">
                            <label htmlFor=''>Checkout Code</label>
                            <textarea
                                className="form-control"
                                placeholder="Checkout Code"
                                type="text"
                                id="checkoutCode"
                                name="checkout"
                                value={embed.checkout}
                                readOnly={true}
                            ></textarea>
                            <button
                                className="copy-button mb-3"
                                onClick={() => copyFunction("checkout")}

                            >
                                {embed.checkoutStatus ?
                                    'Copied' : 'Copy'}

                            </button>
                        </div>

                    </div>
                </Modal.Body>
            </Modal>

        </>
    )
}

export default ProductList;