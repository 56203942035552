import React, { useEffect, useState } from 'react'
import webimg from '../../images/webimg.png'
import lessonImg from '../../images/lesson-img.png';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

function Webinar() {
    const rebrandData = useSelector(state => state.rebrand.data);

    const [rebrand, setRebrand] = useState(rebrandData);

    useEffect(() => {
        setRebrand(rebrandData);
    }, [rebrandData])

    const fnc=() => {
        setTimeout( window.scrollTo(400, 2200),5000);
     };
    
    return (
        <>
            <section className="lessonLink">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6">
                            <div className="lessonLink-right">
                                <h6><span>{rebrand ? rebrand.name : 'Koincart'}</span> LESSON #1</h6>
                                <h2>Join Us for our LIVE Webinar.</h2>
                                <p>
                                    Join us for a private training that will walk you through how to use {rebrand ? rebrand.name : 'Koincart'} to help you create and sell videos for maximum engagement, views and profits. This training or upgrade will not be made available afterwards.</p>
                                <Link to="/help-and-support" className="demoLink" onClick={fnc}><i class="fa fa-play" aria-hidden="true"></i>Watch Replay</Link>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="lessonLink-left"><img src={lessonImg} /></div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default Webinar
