import axios from "axios";
import { baseURL } from "../global/global";
import { setAlert } from "./alert";
import { fetchRebrandData } from "./rebrandAction";
import { fetchAutoResponder, getIntegration } from "./paymentAction";

export const loadUser = () => async (dispatch, getState) => {

    let state = getState('state')
    if (state.auth.token) {
        let headers = {
            'Content-Type': 'application/json',
            'Authorization': state.auth.token
        }

        axios({
            method: 'POST',
            url: `${baseURL}fetch-user`,
            data: {},
            headers: headers
        }).then(res => {
            if (res.data.status === true) {
                dispatch({ type: 'LOAD_USER_DATA', payload: res.data.data });
                dispatch(getIntegration(state.auth.token))
                dispatch(fetchAutoResponder(state.auth.token))
                dispatch(fetchRebrandData(state.auth.token))
            }
        }).catch(error => {
            console.log(error)
        })
    }


}

export const masterLoginUser = (user, setLoader, history) => (dispatch) => {
    axios({
        method: 'POST',
        url: `${baseURL}master-login`,
        data: user,
        headers: { 'Content-Type': 'application/json' }
    }).then(res => {
        if (res.data.status === true) {
            dispatch({ type: 'LOGIN_USER', payload: res.data.data });
            history.push('/dashboard');

        } else {
            dispatch(setAlert(res.data.message, "danger"));
        }
        setLoader(false)
    }).catch(error => {
        dispatch(setAlert('Getting Internal Server Error', "danger"));
    })
}


export const loginUser = (data, setLoader) => (dispatch) => {

    axios({
        method: 'POST',
        url: `${baseURL}login`,
        data: data,
        headers: { 'Content-Type': 'application/json' }
    }).then(res => {

        if (res.data.status === true) {
            dispatch(setAlert(res.data.message, "success"));
            dispatch({ type: 'LOGIN_USER', payload: res.data.data });

        } else {
            dispatch(setAlert(res.data.message, "danger"));
        }

        setLoader(false);

    }).catch(error => {
        dispatch(setAlert('Getting Internal Server Error', "danger"));
        setLoader(false);
    })

}


export const logoutUser = () => (dispatch, getState) => {
    axios({
        method: 'POST',
        url: `${baseURL}logout`,
        data: {},
        headers: { 'Content-Type': 'application/json', 'Authorization': getState().auth.token }
    }).then(res => {
        if (res.data.status === true) {
            dispatch(setAlert(res.data.message, "success"));
            dispatch({ type: 'LOGOUT' });
        } else {
            dispatch(setAlert(res.data.message, "danger"));
        }
    }).catch(error => {
        console.log(error);
    })
}


export const forgetPassword = (data, setLoader) => (dispatch) => {

    axios({
        method: 'POST',
        url: `${baseURL}forgot-password`,
        data: data,
        headers: { 'Content-Type': 'application/json' }
    }).then(res => {

        if (res.data.status === true) {
            dispatch(setAlert(res.data.message, "success"));
        } else {
            dispatch(setAlert(res.data.message, "danger"));
        }

        setLoader(false);

    }).catch(error => {
        setLoader(false);
    })
}

export const resetPassword = (data, setLoader, history) => (dispatch) => {

    axios({
        method: 'POST',
        url: `${baseURL}reset-password`,
        data: data,
        headers: { 'Content-Type': 'application/json' }
    }).then(res => {

        if (res.data.status === true) {
            dispatch(setAlert(res.data.message, "success"));
            history.push('/');
        } else {
            dispatch(setAlert(res.data.message, "danger"));
        }

        setLoader(false);

    }).catch(error => {

        setLoader(false);
    })
}


export const changeProfile = (data, setProfileStatus) => (dispatch, getState) => {

    axios({
        method: 'POST',
        url: `${baseURL}update-profile-image`,
        data: data,
        headers: { 'Content-Type': 'application/json', 'Authorization': getState().auth.token }
    }).then(res => {

        if (res.data.status === true) {
            dispatch(setAlert(res.data.message, "success"));
            dispatch({ type: 'CHANGE_USER_IMAGE', payload: res.data });
        } else {
            dispatch(setAlert(res.data.message, "danger"));
        }
        setProfileStatus(false);

    }).catch(error => {
        setProfileStatus(false);
        console.log(error)
    })
}


export const updateName = (data, setLoader) => (dispatch, getState) => {

    axios({
        method: 'POST',
        url: `${baseURL}update-profile`,
        data: data,
        headers: { 'Content-Type': 'application/json', 'Authorization': getState().auth.token }
    }).then(res => {

        if (res.data.status === true) {
            dispatch(setAlert(res.data.message, "success"));
            dispatch({ type: 'CHANGE_USER_NAME', action: data.name });
        } else {
            dispatch(setAlert(res.data.message, "danger"));
        }
        setLoader(false);

    }).catch(error => {
        setLoader(false);
    })

}


export const updatePassword = (data, setLoader) => (dispatch, getState) => {
    axios({
        method: 'POST',
        url: `${baseURL}update-profile`,
        data: data,
        headers: { 'Content-Type': 'application/json', 'Authorization': getState().auth.token }
    }).then(res => {
        if (res.data.status === true) {
            dispatch(setAlert(res.data.message, "success"));
            dispatch(logoutUser())

        } else {
            dispatch(setAlert(res.data.message, "danger"));
        }
        setLoader(false);

    }).catch(error => {
        setLoader(false);
    })
}


export const changeEmail = (data, setLoader) => (dispatch, getState) => {

    axios({
        method: 'POST',
        url: `${baseURL}update-email`,
        data: data,
        headers: { 'Content-Type': 'application/json', 'Authorization': getState().auth.token }
    }).then(res => {
        if (res.data.status === true) {
            dispatch(setAlert(res.data.message, "success"));
            dispatch({ type: 'CHANGE_USER_EMAIL', action: data.email });
        } else {
            dispatch(setAlert(res.data.message, "danger"));
        }
        setLoader(false);

    }).catch(error => {
        setLoader(false);
    })

}

export const gdprAction = (data, type, setShowDelete) => (dispatch, getState) => {

    axios({
        method: 'POST',
        url: `${baseURL}${type}`,
        data: data,
        headers: { 'Content-Type': 'application/json', 'Authorization': getState().auth.token }
    }).then(res => {

        if (res.data.status === true) {

            if (type === 'download-user-data') {
                window.location.href = res.data.file;
            } else {
                dispatch(logoutUser());
            }

            dispatch(setAlert(res.data.message, "success"));
        } else {
            dispatch(setAlert(res.data.message, "danger"));
        }
        setShowDelete(false);

    }).catch(error => {
        dispatch(setAlert('Getting Internal Server Error', "danger"));
        setShowDelete(false);
    })
}

export const updateTerms = (acceptTerm, setLoader, loader, handleClose2) => (dispatch, getState) => {
    axios({
        method: "POST",
        url: `${baseURL}accept-tram`,
        data: { "tram": acceptTerm },
        headers: { 'Content-Type': 'application/json', 'Authorization': getState().auth.token }
    }).then((res) => {
        if (res.data.status === true) {
            handleClose2()
            setLoader({
                ...loader,
                baseModal: true,
                acceptLoader: false
            })
            dispatch({ type: "UPDATE_TERM_STATUS", payload: "1" })
        } else {
            dispatch(setAlert(res.data.message, "danger"))
            setLoader({
                ...loader,
                acceptLoader: false
            })
        }

    }).catch((error) => {
        setLoader({
            ...loader,
            acceptLoader: false
        })
        console.log(error)
    })
}

export const checkTerms = (handleClose2, handleShow2, setLoader, loader) => (dispatch, getState) => {
    axios({
        method: "POST",
        url: `${baseURL}get-tram`,
        data: {},
        headers: { 'Content-Type': 'application/json', 'Authorization': getState().auth.token }
    }).then((res) => {
        if (res.data.status === true) {
            handleClose2()
            setLoader({
                ...loader,
                baseModal: true
            })
        } else {
            handleShow2()
        }

    }).catch((error) => {
        console.log(error)
    })
}