import React, { useState, useEffect } from "react";
import Navbar from "../Navbar";
import Footer from "../Footer";
import TitleBar from "../TitleBar";
import { FaSort } from 'react-icons/fa';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import ModernDatepicker from 'react-modern-datepicker';
import axios from "axios";
import { baseURL } from "../../global/global";
import { useSelector } from "react-redux";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa"


const animatedComponents = makeAnimated();
const ProductsReport = () => {

    const auth = useSelector(state => state.auth)
    const baseData = useSelector(state => state.base)
    let baseCurrency = baseData.storeData.baseCurrency
    const [status, setStatus] = useState(false)

    const [pageNumber, setPageNumber] = useState([])
    const [pagination, setPagination] = useState({
        currentPage: 1,
        totalItemonPage: 20
    })

    const [proReport, setProReport] = useState([])
    const [proReportData, setProReportData] = useState([])
    const [products, setProducts] = useState([])
    const [selectTwo, setSelectTwo] = useState([])
    const [loader, setLoader] = useState(false)
    const [toggleDate, setToggleDate] = useState({
        customeDate: false,
        searchBy: '',
        startDate: '',
        endDate: ''
    })



    const handleSort = (type) => {
        let data = [...proReport]
        if (type === "sno") {
            if (status === true) {
                setStatus(false)
                data.sort((a, b) => {
                    if (parseInt(a.productSN) > parseInt(b.productSN)) {
                        return 1
                    }
                    else {
                        return -1
                    }
                }
                )
            }
            else {
                data.sort((a, b) => {
                    setStatus(true)
                    if (parseInt(a.productSN) < parseInt(b.productSN)) {
                        return 1
                    }
                    else {
                        return -1
                    }
                }
                )
            }
        }
        else if (type === "product") {
            if (status === true) {
                data.sort((a, b) => {
                    setStatus(false)
                    if (a.productName.toLowerCase() > b.productName.toLowerCase()) {
                        return 1
                    }
                    else {
                        return -1
                    }
                }
                )
            } else {
                data.sort((a, b) => {
                    setStatus(true)
                    if (a.productName.toLowerCase() < b.productName.toLowerCase()) {
                        return 1
                    }
                    else {
                        return -1
                    }
                }
                )
            }
        }
        else if (type === "price") {
            if (status) {
                setStatus(false)
                data.sort((a, b) => {
                    if (parseInt(a.productPrice) > parseInt(b.productPrice)) {
                        return 1
                    }
                    else {
                        return -1
                    }
                }
                )
            }
            else {
                setStatus(true)
                data.sort((a, b) => {
                    if (parseInt(a.productPrice) < parseInt(b.productPrice)) {
                        return 1
                    }
                    else {
                        return -1
                    }
                }
                )
            }
        }
        else if (type === "visitors") {
            if (status) {
                setStatus(false)
                data.sort((a, b) => {
                    if (parseInt(a.productVisitor) > parseInt(b.productVisitor)) {
                        return 1
                    }
                    else {
                        return -1
                    }
                }
                )
            } else {
                setStatus(true)
                data.sort((a, b) => {
                    if (parseInt(a.productVisitor) < parseInt(b.productVisitor)) {
                        return 1
                    }
                    else {
                        return -1
                    }
                }
                )
            }
        }
        else if (type === "sales") {
            if (status) {
                setStatus(false)
                data.sort((a, b) => {
                    if (parseInt(a.productSalesComplete) > parseInt(b.productSalesComplete)) {
                        return 1
                    }
                    else {
                        return -1
                    }
                }
                )
            }
            else {
                setStatus(true)
                data.sort((a, b) => {
                    if (parseInt(a.productSalesComplete) < parseInt(b.productSalesComplete)) {
                        return 1
                    }
                    else {
                        return -1
                    }
                }
                )
            }
        }
        else if (type === "conversion") {
            if (status) {
                setStatus(false)
                data.sort((a, b) => {
                    if (parseFloat(a.productConversion) > parseFloat(b.productConversion)) {
                        return 1
                    }
                    else {
                        return -1
                    }
                }
                )
            } else {
                setStatus(true)
                data.sort((a, b) => {
                    if (parseFloat(a.productConversion) < parseFloat(b.productConversion)) {
                        return 1
                    }
                    else {
                        return -1
                    }
                }
                )
            }
        }
        else if (type === "totalSalesPrice") {
            if (status) {
                setStatus(false)
                data.sort((a, b) => {
                    if (parseFloat(a.totalSalePrice) > parseFloat(b.totalSalePrice)) {
                        return 1
                    }
                    else {
                        return -1
                    }
                }
                )
            }
            else {
                setStatus(true)
                data.sort((a, b) => {
                    if (parseFloat(a.totalSalePrice) < parseFloat(b.totalSalePrice)) {
                        return 1
                    }
                    else {
                        return -1
                    }
                }
                )
            }
        }
        else if (type === "dollarPerVisitor") {
            if (status) {
                setStatus(false)
                data.sort((a, b) => {
                    if (parseFloat(a.dollerPerVistor) > parseFloat(b.dollerPerVistor)) {
                        return 1
                    }
                    else {
                        return -1
                    }
                }
                )
            } else {
                setStatus(true)
                data.sort((a, b) => {
                    if (parseFloat(a.dollerPerVistor) < parseFloat(b.dollerPerVistor)) {
                        return 1
                    }
                    else {
                        return -1
                    }
                })
            }
        }
        else if (type === "incompeleteSales") {
            if (status) {
                setStatus(false)
                data.sort((a, b) => {
                    if (parseInt(a.productSalesIncomplete) > parseInt(b.productSalesIncomplete)) {
                        return 1
                    }
                    else {
                        return -1
                    }
                })
            }
            else {
                setStatus(true)
                data.sort((a, b) => {
                    if (parseInt(a.productSalesIncomplete) < parseInt(b.productSalesIncomplete)) {
                        return 1
                    }
                    else {
                        return -1
                    }
                })
            }
        }
        else if (type === "dollarPerSale") {
            if (status) {
                setStatus(false)
                data.sort((a, b) => {
                    if (parseFloat(a.dollerPerSale) > parseFloat(b.dollerPerSale)) {
                        return 1
                    }
                    else {
                        return -1
                    }
                })
            }
            else {
                setStatus(true)
                data.sort((a, b) => {
                    if (parseFloat(a.dollerPerSale) < parseFloat(b.dollerPerSale)) {
                        return 1
                    }
                    else {
                        return -1
                    }
                })
            }
        }
        setProReport(data)
    }

    const handleGetDate = (type) => {
        const d = new Date();

        if (type === "week") {
            d.setDate(d.getDate() - 7);
        }
        else {
            d.setDate(d.getDate() - 30);
        }

        let startDate = d.toISOString().slice(0, 10)
        return startDate
    }
    const handleSearchBy = (e) => {
        let start = new Date().toISOString().slice(0, 10)
        if (e.target.value === "3") {
            setToggleDate({
                ...toggleDate,
                customeDate: true,
                searchBy: '',
                startDate: '',
                endDate: ''
            })
        }
        else {
            if (e.target.value === "0") {
                setToggleDate({
                    ...toggleDate,
                    searchBy: "today",
                    customeDate: false,
                    startDate: new Date().toISOString().slice(0, 10),
                    endDate: ''
                })
            }
            else if (e.target.value === "1") {

                setToggleDate({
                    ...toggleDate,
                    searchBy: "week",
                    customeDate: false,
                    startDate: handleGetDate("week"),
                    endDate: start
                })
            }
            else if (e.target.value === "2") {
                setToggleDate({
                    ...toggleDate,
                    searchBy: "month",
                    customeDate: false,
                    startDate: handleGetDate("month"),
                    endDate: start
                })
            }
            else if (e.target.value === "4") {
                setToggleDate({
                    ...toggleDate,
                    searchBy: "",
                    customeDate: false,
                    startDate: '',
                    endDate: ''
                })
            }
        }
    }

    useEffect(() => {
        setLoader(true)
        axios({
            method: 'POST',
            url: `${baseURL}product-reports`,
            data: {},
            headers: { 'Content-Type': 'application/json', 'Authorization': auth.token }
        }).then((res) => {
            if (res.data.status === true) {
                setProReport(res.data.data)
                let val = res.data.data
                let objArr = []
                val.forEach((curElem) => {
                    let obj = { value: curElem.productSN, label: curElem.productName }
                    objArr.push(obj)
                })
                setProducts(objArr)
            }
            setLoader(false)
        }).catch((error) => {
            setLoader(false)
            console.log(error)
        })
    }, []);


    const handleDate = (date, type) => {
        if (type === "start") {
            setToggleDate({
                ...toggleDate,
                startDate: date
            })
        }
        else if (type === "end") {
            setToggleDate({
                ...toggleDate,
                endDate: date
            })
        }
    }

    const handleSelectChange = (e) => {
        let arr = []
        e.forEach((curElem) => {
            arr.push(curElem.value)
        })
        setSelectTwo(arr)
    }

    useEffect(() => {
        if (proReportData.length > 0) {
            let maxLength = proReportData.length
            let data = []
            if (maxLength > 20) {
                let val = maxLength / 20
                if (val > parseInt(val)) {
                    val = val + 1
                }
                for (let i = 1; i <= val; i++) {
                    data.push(i)
                }
                setPageNumber(data)
            }
            else {
                setPageNumber([1])
            }
        }
    }, [proReportData])

    const handleClick = (num) => {
        setPagination({
            ...pagination,
            currentPage: num
        })
    }
    const handleBackward = (num) => {
        if (pageNumber[0] < num) {
            setPagination({
                ...pagination,
                currentPage: num - 1
            })
        }
    }
    const handleForward = (num) => {
        if (pageNumber[pageNumber.length - 1] > num) {
            setPagination({
                ...pagination,
                currentPage: num + 1
            })
        }
    }

    const indexOfLastTodo = pagination.currentPage * pagination.totalItemonPage;
    const indexOfFirstTodo = indexOfLastTodo - pagination.totalItemonPage;
    const currentTodos = proReportData.slice(indexOfFirstTodo, indexOfLastTodo);


    useEffect(() => {
        if (toggleDate.customeDate === false && toggleDate.searchBy === "" && selectTwo.length === 0) {
            setProReportData(proReport)
        }
        else {
            let val = proReport.filter((curElem) => {
                if (toggleDate.customeDate === false && toggleDate.searchBy === '') {
                    return curElem
                }
                else {
                    if (toggleDate.endDate !== "" && toggleDate.customeDate) {
                        let searchDate = curElem.updated.split(" ")
                        let data = (searchDate[0] >= toggleDate.startDate && searchDate[0] <= toggleDate.endDate)

                        if (data) {
                            return curElem
                        }
                    }
                    else if (toggleDate.customeDate === false && toggleDate.searchBy === "today") {
                        let searchDate = curElem.updated.split(" ")
                        let data = (toggleDate.startDate === searchDate[0])
                        if (data) {
                            return curElem
                        }
                    }
                    else if (toggleDate.customeDate === false && toggleDate.searchBy === "week") {
                        let searchDate = curElem.updated.split(" ")
                        let data = (searchDate[0] >= toggleDate.startDate && searchDate[0] <= toggleDate.endDate)
                        if (data) {
                            return curElem
                        }
                    }
                    else if (toggleDate.customeDate === false && toggleDate.searchBy === "month") {
                        let searchDate = curElem.updated.split(" ")
                        let data = (searchDate[0] >= toggleDate.startDate && searchDate[0] <= toggleDate.endDate)
                        if (data) {
                            return curElem
                        }
                    }
                }
            }).filter((curElem) => {
                if (selectTwo.length > 0) {
                    let val = selectTwo.includes(curElem.productSN)
                    if (val) {
                        return curElem
                    }
                } else {
                    return curElem
                }
            })

            setProReportData(val)
        }

    }, [toggleDate, proReport, selectTwo])


    return (
        <>
            <TitleBar title="Reports" />
            <Navbar />

            <section className="siteWrap">
                <div className="pageWrap">
                    <div className="container">
                        <div className="pageTitle">
                            <h2>Product Report</h2>
                        </div>
                        <div className="productTable">
                            <div className="salesBy">
                                <div className="salesBy-left">
                                    <select
                                        className="searchInp"
                                        onChange={handleSearchBy}
                                    >
                                        <option value={4}>All time</option>
                                        <option value={0}>Today</option>
                                        <option value={1}>Week</option>
                                        <option value={2}>Month</option>
                                        <option value={3}>Custom Date</option>

                                    </select>
                                    {toggleDate.customeDate ?
                                        <div style={{ display: "flex" }}>
                                            <ModernDatepicker
                                                date={toggleDate.startDate}
                                                format={'YYYY-MM-DD'}
                                                showBorder
                                                className="date-picker-style ml-2"
                                                onChange={date => handleDate(date, "start")}
                                                primaryColor={'#400C46'}
                                                secondaryColor={'#fff6fe'}
                                                primaryTextColor={'#400C46'}
                                                secondaryTextColor={'#fff'}
                                                placeholder="From Date"
                                            />
                                            <ModernDatepicker
                                                date={toggleDate.endDate}
                                                format={'YYYY-MM-DD'}
                                                showBorder
                                                className="date-picker-style ml-2 "
                                                onChange={date => handleDate(date, "end")}
                                                primaryColor={'#400C46'}
                                                secondaryColor={'#fff6fe'}
                                                primaryTextColor={'#400C46'}
                                                secondaryTextColor={'#fff'}
                                                placeholder="To Date"
                                            />
                                        </div> : ''}
                                </div>
                                <div className="salesBy-right">
                                    <Select
                                        className="react-select-container"
                                        closeMenuOnSelect={false}
                                        components={animatedComponents}
                                        defaultValue={[products[0]]}
                                        isMulti
                                        options={products}
                                        onChange={(e) => handleSelectChange(e)}
                                    />
                                </div>
                            </div>
                            <div className="table-responsive">
                                <table className="table table-striped themeTable themeTable-alt hover-effect">
                                    <tr>
                                        <th title="SNo." onClick={() => handleSort("sno")}>SNo. <FaSort className="sortIcon" /> </th>
                                        <th
                                            title="Name of the product"
                                            onClick={() => handleSort("product")}
                                        >
                                            Product <FaSort className="sortIcon" />
                                        </th>
                                        <th
                                            title="Price of the product"
                                            onClick={() => handleSort("price")}
                                        >
                                            Price <FaSort className="sortIcon" />
                                        </th>
                                        <th
                                            title="Total number of people landed on the checkout page"
                                            onClick={() => handleSort("visitors")}
                                        >
                                            Visitors <FaSort className="sortIcon" />
                                        </th>
                                        <th
                                            title="Total number of people purchased the product"
                                            onClick={() => handleSort("sales")}
                                        >
                                            Sales <FaSort className="sortIcon" />
                                        </th>
                                        <th
                                            title="Total number of people who filled the checkout form but didn’t completed the purchase"
                                            onClick={() => handleSort("incompeleteSales")}
                                        >
                                            Incomplete Sales <FaSort className="sortIcon" />
                                        </th>
                                        <th
                                            title="Total conversion"
                                            onClick={() => handleSort("conversion")}
                                        >
                                            Conversion (%) <FaSort className="sortIcon" />
                                        </th>
                                        <th
                                            title="Cumulative Sales price of the product"
                                            onClick={() => handleSort("totalSalesPrice")}
                                        >
                                            Total Sales Price <FaSort className="sortIcon" />
                                        </th>
                                        <th
                                            title="Avg Currency spent on each sale"
                                            onClick={() => handleSort("dollarPerVisitor")}
                                        >
                                            {baseCurrency} Per Visitor <FaSort className="sortIcon" />
                                        </th>
                                        <th
                                            title="Avg Currency spent on each visitor"
                                            onClick={() => handleSort("dollarPerSale")}
                                        >
                                            {baseCurrency} Per Sale <FaSort className="sortIcon" />
                                        </th>

                                    </tr>
                                    {currentTodos.length > 0 ?
                                        currentTodos.map((curElem, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td>{curElem.productSN}  </td>
                                                    <td>{curElem.productName}</td>
                                                    <td>{curElem.productPrice} </td>
                                                    <td>{curElem.productVisitor} </td>
                                                    <td>{curElem.productSalesComplete}</td>
                                                    <td>{curElem.productSalesIncomplete}</td>
                                                    <td>{curElem.productConversion} </td>
                                                    <td>{curElem.totalSalePrice} </td>
                                                    <td>{curElem.dollerPerVistor} </td>
                                                    <td>{curElem.dollerPerSale} </td>

                                                </tr>
                                            )
                                        }) :
                                        <td className="no-result report-loader-css py-5" colSpan="10"> {loader ?
                                            <i className="fa fa-spinner fa-spin" /> :
                                            <h5>No Results</h5>
                                        }</td>
                                    }

                                    <tr>
                                        <td className="expCv" colSpan="10">
                                            <a className="demoLink mt-0" href="">Export CSV File</a>
                                        </td>
                                    </tr>
                                </table>

                                <div className="tablepage mx-3">
                                    <ul>
                                        <li
                                            onClick={() => handleBackward(pagination.currentPage)}
                                            className="cursor-pointer"
                                        ><a><FaChevronLeft /></a></li>
                                        {pageNumber.length > 0 ?
                                            pageNumber.map((curElem, index) => {
                                                return (
                                                    <li
                                                        key={index}
                                                        id={curElem}
                                                        style={{ color: 'white' }}
                                                        onClick={() => handleClick(curElem)}
                                                        className="cursor-pointer"
                                                    >
                                                        <a style={curElem === pagination.currentPage ? { backgroundColor: 'white', color: '#400C46' } : {}}>{curElem}</a>
                                                    </li>
                                                )
                                            }) : ''}
                                        <li
                                            onClick={() => handleForward(pagination.currentPage)}
                                            className="cursor-pointer"
                                        ><a><FaChevronRight /></a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
        </>
    )
}

export default ProductsReport;