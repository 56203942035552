import axios from 'axios';
import React, { useEffect, useState } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import { useDispatch, useSelector } from 'react-redux';
import { setAlert } from '../../actions/alert';
import { getIntegration } from '../../actions/paymentAction';
import { baseURL } from '../../global/global';


const IntegrationConnected = (props) => {

    const auth = useSelector(state => state.auth)
    const dispatch = useDispatch()
    const [button, setButton] = useState('Delete');
    const [showDelete, setShowDelete] = useState(false);
    const [deleteData, setDeleteData] = useState({
        type: '',
        id: -1
    });
    const accountType = {
        client: auth.user.is_client_account === "0" ? false : true,
        team: auth.user.is_virtual_account === "0" ? false : true,
        reseller: auth.user.is_reseller_account === "0" ? false : true,
    }

    const handleDeleteIntegration = (type, integrationId) => {
        const data = {
            type: type,
            id: integrationId
        }
        axios({
            method: 'POST',
            url: `${baseURL}delete-connection`,
            data: data,
            headers: { 'Content-Type': 'application/json', 'Authorization': auth.token }
        }).then((res) => {
            if (res.data.status === true) {
                dispatch(setAlert(res.data.message, "success"))
                dispatch(getIntegration(auth.token))
            } else {
                dispatch(setAlert(res.data.message, "danger"))
            }
            onCancel()
        }).catch((error) => {
            console.log(error)
        })
    }

    const handleSetDelete = (type, integrationId) => {
        setDeleteData({
            ...deleteData,
            type: type,
            id: integrationId
        })
        setShowDelete(true)
    }
    const onConfirm = () => {
        setButton('Deleting')
        handleDeleteIntegration(deleteData.type, deleteData.id)

    }
    const onCancel = () => {
        setShowDelete(false)
        setDeleteData({
            ...deleteData,
            type: '',
            id: -1
        })
    }
    return (
        <>
            <div className="connection-box mt-3">
                <div className="row">
                    <div className="col-md-1 d-flex justify-content-center">
                        <div className={`connection-img ${props.data.length > 0 ? '' : 'notConnected'}`}><img alt="" src={props.logo} /><br />
                            <div className="int_sign_up">
                                <a href={props.signUpUrl} target="_blank"><span>Sign up</span></a>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-11">
                        {props.data.length > 0 ?
                            props.data.map((curElem, index) => {
                                return (
                                    <div key={index} className="youbtube-connection text-white d-flex align-items-center justify-content-between connect-list mb-2">
                                        <div className="youtube-content">
                                            <h6><strong>{props.name}</strong></h6>
                                            <p>{curElem.name} - Added On - {curElem.created}</p>
                                        </div>
                                        {accountType.client || accountType.team ? "" :
                                            <div className="facebook-connect-account">
                                                <button className="btn-change7" onClick={() => handleSetDelete(curElem.app_name, curElem.id)}>Delete</button>
                                            </div>}
                                    </div>
                                )
                            }) : <div className="youbtube-connection text-white d-flex align-items-center justify-content-between notConnected">
                                <div className="youtube-content">
                                    <h6><strong>{props.name}</strong></h6>
                                    <p>Not Connected</p>
                                </div>
                                <div className="facebook-connect-account">
                                    <button onClick={() => props.handleModel(props.name, props.endPoint)} className="btn-change7">Connect</button>
                                </div>
                            </div>}

                        {props.data.length > 0 ?
                            <div className="facebook-connect-account d-flex justify-content-center mt-4">
                                <button onClick={() => props.handleModel(props.name, props.endPoint)} className="btn-change7">{props.data.length > 0 ? 'Connect New Accout ' : 'Connect'}</button>
                            </div> : ''}
                    </div>
                </div>
            </div>
            <SweetAlert
                warning
                showCancel
                confirmBtnText={button}
                confirmBtnBsStyle="danger"
                cancelBtnBsStyle="light"
                title="Are you sure?"
                onConfirm={(e) => onConfirm(e)}
                onCancel={(e) => onCancel(e)}
                focusCancelBtn
                show={showDelete}
            >
                Are you sure to disconnect this connection?
            </SweetAlert>
        </>
    );
}

export default IntegrationConnected;
